import {
  Avatar,
  Button,
  Card,
  Col,
  Input,
  Menu,
  Modal,
  Row,
  Select,
  Tag
} from "antd";
import axios from "axios";
import _ from "lodash";
import React, { Component } from "react";
import ImageUploadCrop from "../../components/ImageUploadCrop";
import Loading from "../../components/Loading";
import Sort from "../../components/Sort";
import {
  ADD_DATAPOINT,
  DELETE_DATAPOINT,
  EDIT_DATAPOINT,
  GET_DATAPOINTS,
  GET_DATA_TYPE,
  GET_ONE_DATAPOINT,
  SUGGEST_DATAPOINT
} from "../../graphql";
import emptyStateActivity from "../../static/activity/emptystateactivity.svg";
import emptyState from "../../static/activity/emptyStateSearch.png";
import {
  openNotification,
  showNotification,
  triggerGAEvent
} from "../../utils";

const Search = Input.Search;
const Option = Select.Option;
const { Meta } = Card;

export default class DataView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmDialog: null,
      isFormSubmitting: false,
      shouldBlockNavigation: true,
      loading: false,
      visible: false,
      suggest_visible: false,
      modalType: "",
      dataPoints: [],
      dataPointName: "",
      dataPointDeets: null,
      dataTypes: [],
      data_grade_types_id: "",
      newDataPointName: "",
      newDataPointDesc: "",
      dataTypeID: null,
      searchText: "",
      thumb_image: "",
      pageTitle: "",
      sort: "newest",
      data_grade_name: "",
      onSearchFetch: false,
      truthObj: {
        newDataPointName: false,
        newDataPointDesc: false,
        data_grade_types_id: false,
        thumb_image: false,
      },
    };
    this.onSearchFetchData = _.debounce(this.onSearchFetchData, 700);
  }

  fetchData = () => {
    this.setState({
      loading: true,
      onSearchFetch: false,
      dataPointDeets: null
    });
    let query = GET_DATAPOINTS;
    query.variables = {
      data_grade_types_id: this.props.match.params.id,
      search: this.state.searchText,
      sort: this.state.sort
    };

    axios
      .post(`${process.env.REACT_APP_API}`, query)
      .then(res => {
        this.setState({
          dataPoints: res.data.data.getDataPoint.data_point_details,
          data_grade_name: res.data.data.getDataPoint.data_grade_name.name,
          loading: false
        });
        if (res.data.data.getDataPoint.data_point_details.length > 0) {
          this.setState({
            dataTypeID: res.data.data.getDataPoint.data_point_details[0].id
          });
          this.fetchDataTypeDeets();
        }
      })
      .catch(err => console.log(err));
  };

  passDataDeetsFromMenu = (key, index, value) => {
    triggerGAEvent("Data", "Viewing a Data point");
    this.setState(
      {
        loading: true,
        dataTypeID: Number(key.item.props.name)
      },
      () => this.fetchDataTypeDeets()
    );
  };

  fetchDataTypeDeets = value => {
    this.setState({ loading: true });
    let query = GET_ONE_DATAPOINT;
    query.variables = {
      id: this.state.dataTypeID
    };

    axios
      .post(`${process.env.REACT_APP_API}`, query)
      .then(res => {
        this.setState({
          dataPointDeets: res.data.data.getOneDataPoint.data_point_details,
          loading: false
        });
      })
      .catch(err => console.log(err));
  };

  editDataPoint = e => {
    triggerGAEvent("Data", "Editing a Data point");
    let mutation = EDIT_DATAPOINT;
    mutation.variables = {
      id: this.state.dataPointDeets[0].id,
      name: this.state.newDataPointName.trim(),
      desc: this.state.newDataPointDesc,
      data_grade_types_id: this.state.data_grade_types_id,
      profile_image: this.state.thumb_image
    };

    axios
      .post(`${process.env.REACT_APP_API}`, mutation)
      .then(res => {
        if (res.data.errors) {
          openNotification("error", res.data.errors[0].message);
        } else {
          this.props.history.push(`/data/${this.state.data_grade_types_id}`);
          this.fetchData();
          this.fetchDataTypeDeets();
          openNotification("success", "Data Point Edited");
          this.setState({
            shouldBlockNavigation: false,
            visible: false,
            loading: false,
            isFormSubmitting: false,
          });
        }
      })
      .catch(err => console.log(err));
  };

  addDataPoint = e => {
    triggerGAEvent("Data", "Creating a new Data point");
    let mutation = ADD_DATAPOINT;
    mutation.variables = {
      name: this.state.newDataPointName.trim(),
      desc: this.state.newDataPointDesc,
      data_grade_types_id: this.state.data_grade_types_id,
      profile_image: this.state.thumb_image
    };
    axios
      .post(`${process.env.REACT_APP_API}`, mutation)
      .then(res => {
        if (res.data.errors) {
          openNotification("error", res.data.errors[0].message);
        } else {
          this.fetchData();
          this.fetchDataTypeDeets();
          this.props.history.push(`/data/${this.state.data_grade_types_id}`);
          openNotification("success", "Data Point Added");
          this.setState({
            shouldBlockNavigation: false,
            visible: false,
            loading: false,
            isFormSubmitting: false,
          });
        }
      })
      .catch(err => console.log(err));
  };

  suggestDataPoint = e => {
    this.setState({ isFormSubmitting: true });
    triggerGAEvent("Data", "Suggesting a Data point");
    let truthObj = {
      newCatName: this.state.newCatName === "",
      newCatDesc: false,
      interest_categories_id: false,
      thumb_image: false,
    };
    this.setState({ truthObj })
    if (this.state.newDataPointName === "") {
      openNotification("error", "Enter a data point name to suggest.");
      this.setState({ isFormSubmitting: false });
    } else {
      let mutation = SUGGEST_DATAPOINT;
      mutation.variables = {
        name: this.state.newDataPointName.trim(),
        desc: this.state.newDataPointDesc,
        data_grade_types_id: this.state.data_grade_types_id,
        profile_image: this.state.thumb_image
      };
      axios
        .post(`${process.env.REACT_APP_API}`, mutation)
        .then(res => {
          if (res.data.errors) {
            openNotification("error", res.data.errors[0].message);
            this.setState({ isFormSubmitting: false });
          } else {
            this.setState({
              suggest_visible: false,
              thumb_image: "",
              shouldBlockNavigation: false,
              isFormSubmitting: false,
            });
            openNotification(
              "success",
              "Thanks for your suggestion. It has been sent for Admin’s approval."
            );
          }
        })
        .catch(err => console.log(err));
    }
  };

  deleteDataPoint = () => {
    triggerGAEvent("Data", "Deleting a Data point");
    if (
      this.state.dataPointDeets[0].lc_name.length !== 0 ||
      this.state.dataPointDeets[0].activity_name.length !== 0 ||
      this.state.dataPointDeets[0].library_name.length !== 0
    ) {
      showNotification(
        "error",
        "Unable to delete Data Point",
        "Cannot delete Data Point when it is tagged to a Learning Content"
      );
    } else {
      const confirmDialog = Modal.confirm({
        title: "Delete a Data point",
        content: "Are you sure?",
        okText: "Yes",
        onOk: () => {
          let mutation = DELETE_DATAPOINT;
          mutation.variables = { id: this.state.dataTypeID };
          axios
            .post(`${process.env.REACT_APP_API}`, mutation)
            .then(res => {
              if (res.data.errors) {
                openNotification("error", res.data.errors[0].message);
              } else {
                openNotification("success", "Data point deleted successfully.");
                this.fetchData();
              }
            })
            .catch(err => console.log(err));
        }
      });
      this.setState({ confirmDialog });
    }
  };

  fetchDataTypes = () => {
    this.setState({ loading: true });
    let query = GET_DATA_TYPE;
    query.variables = {
      search: this.state.searchText,
      sort: this.state.sort
    };
    axios
      .post(`${process.env.REACT_APP_API}`, query)
      .then(res => {
        // console.log(res.data.data.getDataGrade.data_grade || [])
        this.setState({
          dataTypes: res.data.data.getDataGrade.data_grade || [],
          loading: false
        });
      })
      .catch(err => console.log(err));
  };

  handleCancel = e => {
    triggerGAEvent("Data", "Closing Data point Modal");
    this.setState({
      visible: false,
      suggest_visible: false,
      isFormSubmitting: false,
      thumb_image: "",
      modalType: "",
      shouldBlockNavigation: false,
      newDataPointName: "",
      newDataPointDesc: "",
      truthObj: {
        newDataPointName: false,
        newDataPointDesc: false,
        data_grade_types_id: false,
        thumb_image: false,
      },
    });
  };

  showModal = type => {
    this.setState({
      visible: true,
      modalType: type,
      newDataPointName:
        type === "edit" ? this.state.dataPointDeets[0].name : "",
      newDataPointDesc:
        type === "edit" ? this.state.dataPointDeets[0].desc : "",
      data_grade_types_id: this.props.match.params.id,
      thumb_image:
        type === "edit" ? this.state.dataPointDeets[0].profile_image : "",
      shouldBlockNavigation: true
    });
    this.fetchDataTypes();
  };

  showSuggestionModal = () => {
    this.setState({
      suggest_visible: true,
      data_grade_types_id: this.props.match.params.id
    });
    this.fetchDataTypes();
  };

  onInputChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleDataTypeSelect = value => {
    this.setState({
      data_grade_types_id: value
    });
  };

  FeaturedImageUploadSuccess = value => {
    this.setState({ thumb_image: value });
  };

  getLocalStorage = () => {
    this.setState({
      role_id: Number(localStorage.getItem("role_id"))
    });
  };

  onSearchFetchData = () => {
    triggerGAEvent("Data", "Searching a Data point");
    this.fetchData();
  };

  onSearch = e => {
    this.setState({ searchText: e.target.value, onSearchFetch: true }, () =>
      this.onSearchFetchData()
    );
  };

  sortChange = value => {
    triggerGAEvent("Data", "Changing sort in Data point");
    this.setState({ sort: value }, () => {
      this.fetchData();
    });
  };
  
  validateStateValues = () => {
    this.setState({ isFormSubmitting: true });
    let truthObj = {
      newDataPointName: this.state.newDataPointName === "",
      newDataPointDesc: this.state.newDataPointDesc === "",
      data_grade_types_id: this.state.data_grade_types_id === "",
      thumb_image: this.state.thumb_image === "",
    };
    this.setState({ truthObj })
    if (this.state.newDataPointName !== "" && this.state.newDataPointDesc !== "" && this.state.data_grade_types_id !== "" && this.state.thumb_image !== "") {
      this.state.modalType === "edit" ? this.editDataPoint() : this.addDataPoint()
    } else {
      this.setState({ isFormSubmitting: false });
      openNotification("error", "Fill all the mandatory fields");
    }
  };

  componentDidMount() {
    this.setState({ loading: true });
    setTimeout(() => {
      this.fetchData();
    }, 2000);
    this.getLocalStorage();
  }

  componentDidUpdate = () => {
    if (this.state.shouldBlockNavigation) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
  };

  componentWillUnmount() {
    this.state.confirmDialog && this.state.confirmDialog.destroy();
  }

  render() {
    const { dataPoints, dataPointDeets, dataTypes, modalType } = this.state;
    const extraButton = (
      <div className="d-flex align-items-center justify-content-end">
        {this.state.role_id === 1 && (
          <React.Fragment>
            <Button
              icon="edit"
              className="edit-button mr-3 d-flex align-items-center"
              onClick={() => this.showModal("edit")}
            >
              Edit
            </Button>
            <Button
              icon="delete"
              className="edit-button d-flex align-items-center"
              onClick={this.deleteDataPoint}
            >
              Delete
            </Button>
          </React.Fragment>
        )}
      </div>
    );
    return (
      <React.Fragment>
        <Loading is_loading={this.state.loading} />
        <div style={{ width: "100%", padding: "1.7rem" }}>
          <div className="d-flex align-items-center justify-content-between">
            <h4 className="m-0">{this.state.data_grade_name}</h4>
            {this.state.role_id === 1 && (
              <Button
                type="primary"
                block
                size="large"
                className="btn-w250"
                onClick={() => this.showModal("new")}
              >
                Create New Data Point
              </Button>
            )}
            {(this.state.role_id === 2 ||
              this.state.role_id === 4 ||
              this.state.role_id === 5) && (
              <Button
                type="primary"
                block
                size="large"
                className="btn-w250"
                onClick={this.showSuggestionModal}
              >
                Suggest Data Point
              </Button>
            )}
          </div>
          {!this.state.loading &&
            this.state.dataPoints.length === 0 &&
            this.state.searchText === "" &&
            !this.state.onSearchFetch && (
              <div className="tabscroll d-flex justify-content-center align-items-center flex-column">
                <img className="mb-3" src={emptyStateActivity} alt="" />
                <div className="text-center">
                  <p className="FontAvenirMedium font-20 mb-2">
                    Oops! It seems the admin has not created any data points
                    yet.
                    <div>
                      Go ahead and start suggesting data points by clicking on
                      suggest data point
                    </div>
                  </p>
                </div>
              </div>
            )}
          {(this.state.dataPoints.length !== 0 ||
            this.state.searchText !== "" ||
            this.state.onSearchFetch) && (
            <React.Fragment>
              {this.state.dataPoints.length !== 0 && (
                <Row className="mt-4">
                  <Sort
                    Edit={true}
                    data={this.state.sort}
                    sortChange={this.sortChange}
                  />
                </Row>
              )}
              {this.state.dataPoints.length === 0 && (
                <div className="mt-4" style={{ height: 36 }}>
                  {" "}
                </div>
              )}
              <Row gutter={24} style={{ margin: "0.5rem 0rem 0 0rem" }}>
                <Col span={6} style={{ padding: 0 }}>
                  <Search
                    size="large"
                    placeholder="Search"
                    style={{ width: "100%" }}
                    onChange={this.onSearch}
                  />
                  <Menu
                    className="w-100 tabscroll"
                    style={{ height: "calc(100vh - 255px)" }}
                    defaultSelectedKeys={["0"]}
                    onClick={this.passDataDeetsFromMenu}
                  >
                    {dataPoints.map((dataPoint, index) => (
                      <Menu.Item key={index} name={dataPoint.id}>
                        {dataPoint.name}
                      </Menu.Item>
                    ))}
                  </Menu>
                </Col>
                <Col span={18} style={{ padding: 0 }}>
                  {dataPointDeets !== null ? (
                    <React.Fragment>
                      <Card
                        title={dataPointDeets[0].name}
                        extra={extraButton}
                        className="tabscroll"
                      >
                        <Meta
                          avatar={
                            <Avatar
                              size={200}
                              shape="square"
                              src={dataPointDeets[0].profile_image}
                            />
                          }
                          title={dataPointDeets[0].name}
                          description={dataPointDeets[0].desc}
                        />
                        <hr style={{ margin: "1.5rem 0rem 0rem" }} />
                        {dataPointDeets[0].lc_name !== null &&
                          dataPointDeets[0].lc_name.length !== 0 && (
                            <Row
                              type="flex"
                              justify="space-between"
                              align="middle"
                            >
                              <Col style={{ marginTop: "0.5rem" }}>
                                <h5 style={{ textTransform: "uppercase" }}>
                                  In Learning Centres{" "}
                                </h5>
                                {dataPointDeets[0].lc_name.map((lc, index) => (
                                  <Tag
                                    color="green"
                                    key={index}
                                    style={{ marginBottom: "0.5rem" }}
                                  >
                                    {" "}
                                    {lc}{" "}
                                  </Tag>
                                ))}
                              </Col>
                            </Row>
                          )}
                        {dataPointDeets[0].do !== null &&
                          dataPointDeets[0].do.length !== 0 && (
                            <Row
                              type="flex"
                              justify="space-between"
                              align="middle"
                            >
                              <Col style={{ marginTop: "0.5rem" }}>
                                <h5 style={{ textTransform: "uppercase" }}>
                                  In Do Cards{" "}
                                </h5>
                                {dataPointDeets[0].do.map((activity, index) => (
                                  <Tag
                                    color="green"
                                    key={index}
                                    style={{ marginBottom: "0.5rem" }}
                                  >
                                    {" "}
                                    {activity}{" "}
                                  </Tag>
                                ))}
                              </Col>
                            </Row>
                          )}
                        {dataPointDeets[0].read !== null &&
                          dataPointDeets[0].read.length !== 0 && (
                            <Row
                              type="flex"
                              justify="space-between"
                              align="middle"
                            >
                              <Col style={{ marginTop: "0.5rem" }}>
                                <h5 style={{ textTransform: "uppercase" }}>
                                  In Read Cards{" "}
                                </h5>
                                {dataPointDeets[0].read.map(
                                  (activity, index) => (
                                    <Tag
                                      color="green"
                                      key={index}
                                      style={{ marginBottom: "0.5rem" }}
                                    >
                                      {" "}
                                      {activity}{" "}
                                    </Tag>
                                  )
                                )}
                              </Col>
                            </Row>
                          )}
                        {dataPointDeets[0].watch !== null &&
                          dataPointDeets[0].watch.length !== 0 && (
                            <Row
                              type="flex"
                              justify="space-between"
                              align="middle"
                            >
                              <Col style={{ marginTop: "0.5rem" }}>
                                <h5 style={{ textTransform: "uppercase" }}>
                                  In Watch Cards{" "}
                                </h5>
                                {dataPointDeets[0].watch.map(
                                  (activity, index) => (
                                    <Tag
                                      color="green"
                                      key={index}
                                      style={{ marginBottom: "0.5rem" }}
                                    >
                                      {" "}
                                      {activity}{" "}
                                    </Tag>
                                  )
                                )}
                              </Col>
                            </Row>
                          )}
                        {dataPointDeets[0].listen !== null &&
                          dataPointDeets[0].listen.length !== 0 && (
                            <Row
                              type="flex"
                              justify="space-between"
                              align="middle"
                            >
                              <Col style={{ marginTop: "0.5rem" }}>
                                <h5 style={{ textTransform: "uppercase" }}>
                                  In Listen Cards{" "}
                                </h5>
                                {dataPointDeets[0].listen.map(
                                  (activity, index) => (
                                    <Tag
                                      color="green"
                                      key={index}
                                      style={{ marginBottom: "0.5rem" }}
                                    >
                                      {" "}
                                      {activity}{" "}
                                    </Tag>
                                  )
                                )}
                              </Col>
                            </Row>
                          )}
                      </Card>
                    </React.Fragment>
                  ) : (
                    <div className="tabscroll d-flex align-items-center justify-content-center">
                      {(this.state.dataTypes || []).length === 0 &&
                        !this.state.loading && (
                          <div className=" d-flex justify-content-center align-items-center flex-column mt-4">
                            <img className="mb-3" src={emptyState} alt="" />
                            <div className="text-center">
                              <p className="FontAvenirMedium font-20 mb-2">
                                No results found. Please try again with
                                different keywords
                              </p>
                            </div>
                          </div>
                        )}
                      {(this.state.dataTypes || []).length !== 0 && (
                        <h5>Click on a data point to view the details</h5>
                      )}
                    </div>
                  )}
                </Col>
              </Row>
            </React.Fragment>
          )}
        </div>
        <Modal
          title={
            modalType === "edit" ? "Edit Data Point" : "Create New Data Point"
          }
          visible={this.state.visible}
          onCancel={this.handleCancel}
          width={700}
          maskClosable={false}
          footer={[
            <Button key="back" size="large" onClick={this.handleCancel}>
              Cancel
            </Button>,
            <Button
              key="submit"
              size="large"
              type="primary"
              onClick={this.validateStateValues}
              disabled={this.state.isFormSubmitting}
              loading={this.state.isFormSubmitting}
            >
              {modalType === "edit" ? "Save Changes" : "Add Data Point"}
            </Button>
          ]}
        >
          <Row justify="center" align="middle" type="flex">
            <Col span={6} className="interest-image">
              <ImageUploadCrop
                imageUrl={this.state.thumb_image}
                onSuccess={this.FeaturedImageUploadSuccess}
                aspect={1}
                id={this.state.truthObj["thumb_image"] ? "has-error" : ""}
              />
            </Col>
            <Col span={18} className="pl-3">
              <div className="mb-3">
                <div className="FontAvenirRoman text-secondary font-16 text-uppercase requiredAsterisk mb-8px">
                  Data Point name {this.state.modalType}
                </div>
                <Input
                  name="newDataPointName"
                  onChange={this.onInputChange}
                  value={this.state.newDataPointName}
                  size="large"
                  id={this.state.truthObj["newDataPointName"] ? "has-error" : ""}
                />
              </div>
              <div className="mb-3">
                <div className=" FontAvenirRoman text-secondary font-16 text-uppercase requiredAsterisk mb-8px">
                  Data Type
                </div>
                <Select
                  style={{ width: "100%" }}
                  onChange={this.handleDataTypeSelect}
                  value={this.state.data_grade_types_id}
                  size="large"
                  id={this.state.truthObj["data_grade_types_id"] ? "has-error" : ""}
                >
                  {dataTypes.map((dataType, index) => {
                    return <Option value={dataType.id}>{dataType.name}</Option>;
                  })}
                </Select>
              </div>
            </Col>
          </Row>
          <div className="mb-3">
            <div className="FontAvenirRoman text-secondary font-16 text-uppercase requiredAsterisk mb-8px">
              {" "}
              Description{" "}
            </div>
            <Input.TextArea
              rows={4}
              name="newDataPointDesc"
              onChange={this.onInputChange}
              value={this.state.newDataPointDesc}
              id={this.state.truthObj["newDataPointDesc"] ? "has-error" : ""}
            />
          </div>
        </Modal>
        <Modal
          title="Suggest Data Point"
          visible={this.state.suggest_visible}
          onCancel={this.handleCancel}
          width={700}
          maskClosable={false}
          footer={[
            <Button key="back" size="large" onClick={this.handleCancel}>
              Cancel
            </Button>,
            <Button
              key="submit"
              size="large"
              type="primary"
              onClick={this.suggestDataPoint}
              disabled={this.state.isFormSubmitting}
              loading={this.state.isFormSubmitting}
            >
              Suggest Data Point
            </Button>
          ]}
        >
          <Row justify="center" align="middle" type="flex">
            <Col span={6} className="interest-image">
              <ImageUploadCrop
                imageUrl={this.state.thumb_image}
                onSuccess={this.FeaturedImageUploadSuccess}
                aspect={1}
                id={this.state.truthObj["thumb_image"] ? "has-error" : ""}
              />
            </Col>
            <Col span={18} className="pl-3">
              <div className="mb-3">
                <div className="FontAvenirRoman text-secondary font-16 text-uppercase requiredAsterisk mb-8px">
                  Data Point name
                </div>
                <Input
                  name="newDataPointName"
                  onChange={this.onInputChange}
                  value={this.state.newDataPointName}
                  size="large"
                  id={this.state.truthObj["newDataPointName"] ? "has-error" : ""}
                />
              </div>
              <div className="mb-3">
                <div className=" FontAvenirRoman text-secondary font-16 text-uppercase mb-8px">
                  Data Type
                </div>
                <Select
                  style={{ width: "100%" }}
                  onChange={this.handleDataTypeSelect}
                  value={this.state.data_grade_types_id}
                  size="large"
                  id={this.state.truthObj["data_grade_types_id"] ? "has-error" : ""}
                >
                  {dataTypes.map((dataType, index) => (
                    <Option value={dataType.id} key={index}>
                      {dataType.name}
                    </Option>
                  ))}
                </Select>
              </div>
            </Col>
          </Row>
          <div className="mb-3">
            <div className="FontAvenirRoman text-secondary font-16 text-uppercase mb-8px">
              {" "}
              Description{" "}
            </div>
            <Input.TextArea
              rows={4}
              name="newDataPointDesc"
              onChange={this.onInputChange}
              value={this.state.newDataPointDesc}
              id={this.state.truthObj["newDataPointDesc"] ? "has-error" : ""}
            />
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}
