import {
  Button,
  Col,
  Collapse,
  Icon,
  Input,
  Modal,
  Radio,
  Row,
  Checkbox,
  Breadcrumb,
  Carousel,
  notification,
  Tag
} from 'antd'
import axios, { post, get } from 'axios'
import _ from 'lodash'
import React from 'react'
import LinesEllipsis from 'react-lines-ellipsis'
import styled, { css, keyframes } from 'styled-components'

import ReactPlayer from 'react-player'
import ButtonC from '../../components/ButtonC'
import FireImg from '../../static/workshop/workshopFire.svg'
import ClockImg from '../../static/workshop/workClock.svg'
import Tick from '../../static/workshop/tick.png'
import Link from '../../static/workshop/link.svg'
import Twitter from '../../static/workshop/twitter.svg'
import Whatsapp from '../../static/workshop/whatsapp.svg'
import Facebook from '../../static/workshop/facebook.svg'
import UserProfile from '../../static/workshop/user_profile.svg'
import PlaceHolder from '../../static/workshop/placeholder.svg'
import Thumbnail from '../../static/workshop/thumbnail.svg'
import dateArrow from '../../static/workshop/dateArrow.svg'

import { GET_PARENT_DASHBOARD } from '../../graphql'

import { REGISTER_WORKSHOP, VIEW_WORKSHOP } from './graphql'

import Error500Activity from '../../static/activity/500Err.svg'
import emptyState from '../../static/activity/emptyStateSearch.png'
import ErrorActivity from '../../static/activity/error.svg'
import contentFace from '../../static/workshop/noProfileImage.png'
import Moment from 'moment'

import {
  isLink,
  openNotification,
  titleCase,
  triggerGAEvent
} from '../../utils'
import Empty from './../../static/empty.svg'
import './workshop.css'
import './parentView.css';
import WorkshopImageView from '../../components/ImageUploadCrop/workshopImageview'

const Panel = Collapse.Panel
const RadioGroup = Radio.Group
const Search = Input.Search
const { TextArea } = Input

const DateContainer = styled.div`
  background: #f9f9f9;
  border-radius: 4px;
  padding: 8px;
  .white-space-nowrap {
    white-space: nowrap;
  }
  .title {
    font-family: Avenir;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    @media (min-width: 768px) {
      font-size: 14px;
      line-height: 18px;
    }
    text-align: center;
    color: #333333;
  }
  .desc {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    padding: 0;
    @media (min-width: 768px) {
      font-size: 14px;
      line-height: 15px;
    }
    font-size: 12px;
    line-height: 13px;
    color: #828282;
  }
  .subTitle {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    @media (min-width: 768px) {
      font-size: 12px;
      line-height: 16px;
    }
    color: #57479c;
    // max-width: 40px;
  }
  .title-border {
    border: 1px solid #e0e0e0;
    margin-left: 8px;
    margin-right: 12px;
  }
`
const CarosuelContainer = styled.div`
  width: 12px;
  height: 12px;
  background: #e0e0e0;
  border-radius: 50%;
  &.active {
    background: #42c0ef;
  }
`

const ImageContainer = styled.div`
  img {
    border-radius: 4px;
    @media (min-width: 768px) {
      &.sliderMainImg {
        width: 760px;
        height: 370px;
      }
    }
  }
  .react-player {
    position: relative;
    width: 140px !important;
    height: 82px !important;
  }
  iframe {
    border-radius: 5px;
  }
  .leftSlide,
  .rightSlide {
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
  }
  .leftSlide {
    left: 0;
    & img {
      width: 28px;
      @media (min-width: 992px) {
        width: 50px;
      }
    }
  }
  .rightSlide {
    right: 0;
    & img {
      width: 28px;
      @media (min-width: 992px) {
        width: 50px;
      }
    }
  }
`

class ViewWorkshop extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      is_selected: false,
      is_loading: true,
      is_error: false,
      is_500: false,
      carouselIndex: 0,
      selectedSlide: 0,
      is_updated: false,
      changesChildpaidModal: false,
      changesChildfreeModal: false,
      regStud_status: false,
      changesComments: '',
      workshop_details: {
        workshop_id: '',
        user_id: '',
        user_details: {
          id: '',
          name: '',
          role_id: ''
        },
        user_activity_type: '',
        activity_state: '',
        workshop_name: '',
        learning_outcome: '',
        description: '',
        workshop_time: '',
        workshop_date: '',
        workshop_duration: '',
        workshop_type: '',
        workshop_amount: '',
        prerequisites: '',
        reference_link: '',
        skills_required: '',
        status: '',
        trailer_link: '',
        minimum_participants: '',
        maximum_participants: '',
        pdf_name: '',
        pdf_url: '',
        pdf_source_text: '',
        pdf_source_url: ''
      },
      interest_category_name: '',
      interest_category_id: '',
      age_groups: [],
      thumb_images: [],
      age_groups_dificulty: [],
      interests: [],
      materials_required: [],
      steps: [],
      tags: [],
      prerequisites: [],
      mashup_data: [],
      recommended: [],
      getCollectionPlaylist_details: [],
      pdf_name: '',
      land_url: '',
      selected_studId: [],
      req_studId: [],
      reg_studId: [],
      name: '',
      changesReqModal: false,
      registerWorkshop: false,
      sort: 'newest',
      searchText: '',
      filter: {},
      zoom_details: {},
      nextSession: [],
      pastWorkshop: [false],
      workInProgress: false,
      allCollection: [],
      allOrgCollection: [],
      selectedCollection: [],
      truthObj: {
        changesReq: false
      }
    }
    this.carousel = React.createRef()
  }

  fetchWorkshopData = () => {
    let query = VIEW_WORKSHOP
    query.variables = {
      id: parseInt(this.props.match.params.id, 10)
    }
    post(`${process.env.REACT_APP_API}`, query).then(async res => {
      if (res.data.errors) {
        openNotification('error', res.data.errors[0].message)
        this.setState({ is_error: true, is_loading: false })
      } else {
        if (
          this.state.role_id === 3 &&
          res.data.data.getOneWorkshop.workshop_details.id
        ) {
          console.log('Workshop parent view ==> ', res.data.data.getOneWorkshop)
          this.setState({ is_updated: true })
        }

        let start_time = res.data.data.getOneWorkshop.workshop_details
          .workshop_time
          ? Moment(
              res.data.data.getOneWorkshop.workshop_details.workshop_time,
              'h m a'
            ).format('h:mm A')
          : ''
        let workshop_dateText =
          res.data.data.getOneWorkshop.workshop_details.workshopDates &&
          res.data.data.getOneWorkshop.workshop_details.workshopDates.length
            ? res.data.data.getOneWorkshop.workshop_details.workshopDates
            : ''

        let thumb_images = []
        if (
          (res.data.data.getOneWorkshop.workshop_details.featured_image || [])
            .length
        ) {
          for (let x of res.data.data.getOneWorkshop.workshop_details
            .featured_image) {
            if (x.type == 'video') {
              if (x.image_url.includes('youtu')) {
                var videoid = x.image_url.match(
                  /(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/
                )
                if (videoid != null) {
                  x.thumbnail = `https://img.youtube.com/vi/${videoid[1]}/hqdefault.jpg`
                }
              }
              if (x.image_url.includes('vimeo')) {
                var videoid = x.image_url.match(
                  /https:\/\/(www\.)?vimeo.com\/(\d+)($|\/)/
                )
                if (videoid != null) {
                  let videoJSON = await fetch(
                    `https://vimeo.com/api/v2/video/${videoid[2]}.json`
                  )
                  let vimeoVideoJSON = await videoJSON.json()
                  x.thumbnail = vimeoVideoJSON[0].thumbnail_large
                }
              }
              x.thumbnail = x.thumbnail ? x.thumbnail : Thumbnail
            }
            thumb_images.push(x)
          }
        }

        let zoom_det = res.data.data.getOneWorkshop.workshop_details
          .zoom_meeting
          ? JSON.parse(
              res.data.data.getOneWorkshop.workshop_details.zoom_meeting
            )
          : {}

        let pastWorkshop =
          res.data.data.getOneWorkshop.workshop_details &&
          res.data.data.getOneWorkshop.workshop_details.Dates &&
          res.data.data.getOneWorkshop.workshop_details.Dates.length > 0
            ? res.data.data.getOneWorkshop.workshop_details.Dates.map(x => {
                if (
                  Moment.utc(parseInt(x.end_date))
                    // .add(x.duration, 'hours')
                    .format() <= Moment().format()
                ) {
                  return true
                } else {
                  return false
                }
              })
            : [false]
        // console.log('pastWorkshop date time', pastWorkshop)
        let nextSession = []
        let workshop_start =
          res.data.data.getOneWorkshop.workshop_details &&
          res.data.data.getOneWorkshop.workshop_details.Dates &&
          res.data.data.getOneWorkshop.workshop_details.Dates.length > 0
            ? res.data.data.getOneWorkshop.workshop_details.Dates.map(xx => {
                if (
                  Moment.utc(parseInt(xx.end_date))
                    // .add(xx.duration, 'hours')
                    .format() <= Moment().format()
                ) {
                  return true
                } else {
                  nextSession.push(xx)
                  return false
                }
              })
            : [false]

        let sorteddates =
          res.data.data.getOneWorkshop.workshop_details &&
          res.data.data.getOneWorkshop.workshop_details.Dates &&
          res.data.data.getOneWorkshop.workshop_details.Dates.length > 0
            ? res.data.data.getOneWorkshop.workshop_details.Dates.sort(
                function (a, b) {
                  return (
                    Moment(a.workshop_Date).format('X') -
                    Moment(b.workshop_Date).format('X')
                  )
                }
              )
            : []

        let workInprogress = false
        if (sorteddates.length) {
          let len = sorteddates.length - 1
          if (
            Moment.utc(parseInt(sorteddates[0].workshop_Date)).format() <=
              Moment().format() &&
            !(
              Moment.utc(parseInt(sorteddates[len].end_date))
                // .add(sorteddates[len].duration, 'hours')
                .format() <= Moment().format()
            )
          ) {
            workInprogress = true
          } else {
            workInprogress = false
          }
        }

        // console.log("sorted dates ", sorteddates);
        // console.log('workshop_start date time', workshop_start)

        this.setState(
          {
            workInProgress: workInprogress,
            // is_loading: false,
            workshop_details: res.data.data.getOneWorkshop.workshop_details,
            thumb_images: thumb_images || [],
            age_groups: res.data.data.getOneWorkshop.Age_group
              ? res.data.data.getOneWorkshop.Age_group
              : [],
            interests: res.data.data.getOneWorkshop.interest_details
              ? res.data.data.getOneWorkshop.interest_details.interests
              : [],
            interest_category_name: res.data.data.getOneWorkshop
              .interest_details
              ? res.data.data.getOneWorkshop.interest_details
                  .interest_categories_name
              : '',
            interest_category_id: res.data.data.getOneWorkshop.interest_details
              ? res.data.data.getOneWorkshop.interest_details
                  .interest_categories_id
              : '',
            pdf_name: res.data.data.getOneWorkshop.workshop_details.pdf_name
              ? res.data.data.getOneWorkshop.workshop_details.pdf_name
              : '',
            req_studId:
              res.data.data.getOneWorkshop.workshop_details.InterestedChild &&
              res.data.data.getOneWorkshop.workshop_details.InterestedChild
                .length
                ? res.data.data.getOneWorkshop.workshop_details.InterestedChild.map(
                    x => parseInt(x.id)
                  )
                : [],
            reg_studId:
              res.data.data.getOneWorkshop.workshop_details.RegisteredChild &&
              res.data.data.getOneWorkshop.workshop_details.RegisteredChild
                .length
                ? res.data.data.getOneWorkshop.workshop_details.RegisteredChild.map(
                    x => parseInt(x.id)
                  )
                : [],
            workshops_timeText: `${start_time}`,
            workshops_dateText: workshop_dateText,
            zoom_details: zoom_det,
            nextSession,
            pastWorkshop,
            materials_required: res.data.data.getOneWorkshop.Materials_required
              ? res.data.data.getOneWorkshop.Materials_required
              : [],
            tags: res.data.data.getOneWorkshop.Workshop_tags
              ? res.data.data.getOneWorkshop.Workshop_tags
              : []
          },
          () => {
            this.setState({
              land_url: `${process.env.REACT_APP_LAND_URL}/workshopdetails?id=${this.state.workshop_details.id}`
            })
            this.fetchAllStudentsFilterData()
          }
        )
      }
    })
  }

  fetchAllStudentsFilterData = () => {
    if (this.state.role_id != 3) {
      return
    }
    let query = GET_PARENT_DASHBOARD
    query.variables = {
      date: ''
    }
    axios
      .post(`${process.env.REACT_APP_API}`, query)
      .then(res => {
        if (res.data.errors) {
          this.setState({
            studentsData: [],
            is_loading: false,
          })
        } else {
          let studentsFilter = res.data.data.ParentDashbordV2.students
            ? res.data.data.ParentDashbordV2.students.map(data => {
                if (this.state.req_studId.includes(data.id)) {
                  data.is_active = true
                } else {
                  data.is_active = false
                }
                return data
              })
            : []
          let studentsDataFilter = []
          if (this.state.age_groups.length) {
            this.state.age_groups.map(x => {
              studentsFilter.map(age => {
                if (
                  // !this.state.req_studId.includes(age.id) &&
                  !this.state.reg_studId.includes(age.id) &&
                  x.age_group_id == age['student_details'].age_group_id
                ) {
                  studentsDataFilter.push(age)
                }
              })
            })
          }
          let regStud_status =
            this.state.reg_studId.length == studentsFilter.length
          this.setState({
            studentsData: studentsDataFilter,
            regStud_status
          }, () => {
            this.setState({
              is_loading: false,
            })
          })
        }
      })
      .catch(err => console.log(err))
  }

  TitleAndDesc = (title, desc) => {
    return (
      <React.Fragment>
        <div className='d-flex'>
          <div className='title pb-18 text-uppercase'>{title}</div>
        </div>
        {desc !== '' && (
          <div className='desc'>
            <h6>{desc}</h6>
          </div>
        )}
      </React.Fragment>
    )
  }

  // carouselGoTo = index => {
  //   this.setState(
  //     {
  //       carouselIndex: index
  //     },
  //     () => this.carousel.goTo(index)
  //   )
  // }

  Thumb_imageCarousel = (images, selectedSlide) => {
    return (
      <React.Fragment>
        <div className=''>
          {/* <Carousel
            ref={node => {
              this.carousel = node
            }}
            afterChange={this.carouselGoTo}
          >
            {images
              ? images.map(x => {
                  return (
                    <div>
                      <img
                        className='carousalImg'
                        src={x.image_url}
                        alt='workshop Image'
                      />
                    </div>
                  )
                })
              : ``}
          </Carousel> */}

          <ImageContainer className='position-relative'>
            {/* <div className='leftSlide'>
              <img
                src={carousalLeftArrow}
                alt='left arrow'
                onClick={() => {
                  this.setState({
                    selectedSlide: selectedSlide
                      ? selectedSlide - 1
                      : images.length - 1
                  })
                }}
              />
            </div> */}
            {/* <div className='rightSlide'>
              <img
                src={carousalRightArrow}
                alt='right arrow'
                onClick={() => {
                  this.setState({
                    selectedSlide: (selectedSlide + 1) % (images || []).length
                  })
                }}
              />
            </div> */}
            {(images[selectedSlide] || {}).type !== 'video' && (
              <img
                src={
                  (images[selectedSlide] || {}).image_url
                    ? (images[selectedSlide] || {}).image_url
                    : PlaceHolder
                }
                className='img-fluid sliderMainImg'
                alt='slider1'
                onError={e => {
                  e.target.onerror = null
                  e.target.src = PlaceHolder
                }}
              />
            )}
            {(images[selectedSlide] || {}).type == 'video' && (
              <ReactPlayer
                className="mbl-h-videoPlayer"
                url={(images[selectedSlide] || {}).image_url}
                width='100%'
              />
            )}
          </ImageContainer>
          {images.length > 1 && (
            <div className='d-none d-lg-flex justify-content-center align-items-center mt-2 pt-1 mb-3'>
              {images.map((slider, sliderIndex) => {
                return (
                  <CarosuelContainer
                    key={`Carosuel-${sliderIndex}`}
                    className={`cursor-pointer mr-2 ${
                      selectedSlide === sliderIndex ? 'active' : ''
                    }`}
                    onClick={() => {
                      this.setState({
                        selectedSlide: sliderIndex
                      })
                    }}
                  />
                )
              })}
            </div>
          )}
        </div>
      </React.Fragment>
    )
  }

  ThumbImageList = (images, selectedSlide) => {
    return (
      <React.Fragment>
        <div
          className='min-w-300 w-300 min-w-80per w-80per d-flex overflow-scroll pb-md-2 pb-lg-0'
          id={`workshop-image`}
        >
          {images.map((x, index) => {
            // if (x.type == 'video') {
            //   if (x.image_url.includes('youtu')) {
            //     var videoid = x.image_url.match(
            //       /(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/
            //     )
            //     if (videoid != null) {
            //       x.thumbnail = `https://img.youtube.com/vi/${videoid[1]}/hqdefault.jpg`
            //     }
            //   }
            //   if (x.image_url.includes('vimeo')) {
            //     var videoid = x.image_url.match(
            //       /https:\/\/(www\.)?vimeo.com\/(\d+)($|\/)/
            //     )
            //     if (videoid != null) {
            //       // let videoJSON = fetch(
            //       //   `https://vimeo.com/api/v2/video/${videoid[2]}.json`
            //       // ).then(res => {
            //       //   console.log('json ', videoJSON)
            //       // })

            //       // let vimeoVideoJSON = videoJSON.json();
            //       // x.thumbnail = vimeoVideoJSON[0].thumbnail_large
            //       x.thumbnail = Thumbnail
            //     }
            //   }
            // }
            return (
              <span className='pr-2 d-inline-block'>
                <img
                  style={{
                    objectFit: 'cover',
                    width: 135,
                    height: 70,
                    borderRadius: 5
                  }}
                  className={`carousalImg1 ${
                    selectedSlide == index ? 'caroactive' : ''
                  } mbl-list-img`}
                  src={
                    x.type
                      ? x.thumbnail
                        ? x.thumbnail
                        : Thumbnail
                      : x.image_url
                  }
                  alt='thumb images'
                  onClick={() => {
                    this.setState({
                      selectedSlide: index
                    })
                  }}
                  onError={e => {
                    e.target.onerror = null
                    e.target.src = Thumbnail
                  }}
                />
              </span>
            )
          })}
        </div>
        {images.length > 2 && (
          <img
            className='ml-3 cursor-pointer mbl-scroll-smallimg'
            src={dateArrow}
            alt='arrow'
            onClick={() => {
              document.getElementById(`workshop-image`).scrollTo({
                left:
                  document.getElementById(`workshop-image`).scrollLeft + 250,
                behavior: 'smooth'
              })
            }}
          />
        )}
      </React.Fragment>
    )
  }

  copyToClipboard = e => {
    var textField = document.createElement('textarea')
    textField.innerText = `${process.env.REACT_APP_LAND_URL}/workshopdetails?id=${this.state.workshop_details.id}`
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
    e.target.focus()
    notification['success']({
      message: 'Link Copied!',
      description: '',
      duration: 1
    })
  }

  ChildEdit = () => {
    this.setState({
      changesChildModal: true
    })
  }

  getLocalStorage = () => {
    this.setState(
      {
        role_id: Number(localStorage.getItem('role_id'))
      },
      () => {
        if (this.state.role_id !== 3) {
          this.props.history.push('/')
        }
      }
    )
  }

  registerSuccessPopup = () => {
    let modal = Modal.success({
      content: (
        <React.Fragment>
          <div className='d-flex flex-column align-items-center justify-content-center'>
            <div className='mx-auto mt-2 pb-3 text-center'>
              <img src={Tick} alt='success' className='img-fluid' />
            </div>
            <h6 style={{ fontSize: 16 }} className='payment-title'>
              Your registration is successful
            </h6>
            <p className='payment-para'>
              Thank you for registering! The details have been sent to your
              Whatsapp number and e-mail.
            </p>
            <Button
              style={{ width: 100, background: '#1890FF', textAlign: 'center' }}
              type='primary'
              onClick={() => {
                modal.destroy()
                window.location.reload()
              }}
            >
              Ok
            </Button>
          </div>
        </React.Fragment>
      ),
      icon: null,
      okButtonProps: { style: { display: 'none' } },
      closable: false
    })
  }

  onSelectstudentID = (index, val) => {
    const state = { ...this.state }
    state.studentsData[index].is_active = val.target.checked
    let selected_studId = state.studentsData
      .filter(x => x.is_active)
      .map(x => x.id)
    this.setState({
      selected_studId
    })
  }

  registerWorkshopPaidModel = () => {
    this.setState({
      changesChildpaidModal: true
    })
  }

  addToCart = () => {
    let state = { ...this.state }
    if (this.state.workshop_details.workshop_type == 'free') {
      this.setState({
        is_loading: true
      })
    }
    let mutation = REGISTER_WORKSHOP

    let selected_studId = []
    let studData = state.studentsData || []
    if (studData.length == 1 && this.state.req_studId.length == 0) {
      selected_studId = studData.map(x => x.id)
      // this.setState({
      //   selected_studId: selected_studId
      // })
    }
    // console.log("stud id", selected_studId);

    mutation.variables = {
      workshop_id: this.state.workshop_details.id,
      student_id: this.state.req_studId
        .concat(this.state.selected_studId)
        .concat(selected_studId)
    }

    // console.log("stud id", mutation.variables);

    axios
      .post(`${process.env.REACT_APP_API}`, mutation)
      .then(res => {
        if (res.data.errors) {
        } else {
          this.setState({
            selected_studId: [],
            changesChildpaidModal: false,
            changesChildfreeModal: false,
            is_loading: false
          })

          if (this.state.workshop_details.workshop_type == 'Paid') {
            notification['success']({
              message: 'Added to the cart successfully',
              description: '',
              duration: 2
            })
            setTimeout(() => {
              window.location.reload()
            }, 500)
          } else {
            this.setState(
              {
                is_loading: false
              },
              () => this.registerSuccessPopup()
            )
          }
        }
      })
      .catch(err => console.log(err))
  }

  registerWorkshopFreeModel = () => {
    this.setState({
      changesChildfreeModal: true
    })
  }

  componentDidMount () {
    this.getLocalStorage()
    if (
      this.props.history.location.pathname.includes('parent') &&
      this.props.history.location.pathname.includes('view')
    ) {
      this.fetchWorkshopData()
      // setTimeout(() => {
      //   this.fetchAllStudentsFilterData()
      // }, 100)
      // this.carouselGoTo(0)
    }
  }

  render () {

    const DateView = ({ date, time, every, duration }) => {
      return (
        <DateContainer className='d-flex'>
          {every && every.length && (
            <div className='d-flex'>
              <div className='subTitle'>{every}</div>
              <div className='title-border' />
            </div>
          )}
          <div>
            <h4 className='title white-space-nowrap'>
              {date ? Moment.utc(parseInt(date)).format(`ddd, Do MMM ’YY`) : ''}
            </h4>
            <div className='desc'>
              {time ? Moment(time, 'h m a').format('h:mm A') : ''}
              {duration ? ` - ${duration}` : ``}
            </div>
          </div>
        </DateContainer>
      )
    }

    if (this.state.is_error || this.state.is_500) {
      return (
        <div className='col-8 mx-auto mt-5 pb-5 text-center'>
          <img
            src={this.state.is_500 ? Error500Activity : ErrorActivity}
            alt='error'
            className='img-fluid'
          />
        </div>
      )
    } else {
      let encodedURL = encodeURIComponent(this.state.land_url)
      let facebook = `https://www.facebook.com/sharer/sharer.php?u=${encodedURL}`
      let whatsapp = `https://api.whatsapp.com/send/?phone&text=I+just+discovered+this+fabulous+workshop+*${this.state.workshop_details.workshop_name}*+on+Breadcrumb!+Do+check+it+out+${encodedURL}+and+follow+@learnwithbreadcrumb+for+more.&app_absent=0`
      let twitter = `https://twitter.com/intent/tweet?url=${encodedURL}`

      return (
        <React.Fragment>
          <div className='max-dev-workshopView mt-4 p-4'>
            <div className='d-flex align-items-center justify-content-start'>
              <div>
                <Breadcrumb>
                  <Breadcrumb.Item
                    className='breadcrumbCursor'
                    onClick={() => this.props.history.push(`/parent/workshops`)}
                  >
                    Workshops
                  </Breadcrumb.Item>
                  <Breadcrumb.Item className='parentWorkview'>
                    {this.state.workshop_details.workshop_name}
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>
            </div>
          </div>

          {this.state.is_loading && (
            <div className='vh-100 z-index-1 opacity-black-5 align-items-center position-fixed col-12 fixed-top d-flex justify-content-center'>
              <Icon type='loading' theme='outlined' className='loading' />
            </div>
          )}
          {/* max dev workshop view details */}
          <Row className="max-dev-workshopView">
            <Col span={14}>
              <div className='m-3'>
                <div className='border-style'>
                  {this.Thumb_imageCarousel(
                    this.state.thumb_images,
                    this.state.selectedSlide
                  )}
                  {this.state.thumb_images.length > 1 && (
                    <div className='d-flex'>
                      {this.ThumbImageList(
                        this.state.thumb_images,
                        this.state.selectedSlide
                      )}
                    </div>
                  )}
                </div>
                <div className='row m-0'>
                  <div
                    header='DETAILS OF THIS ACTIVITY'
                    className='col-12 border-style'
                    key='1'
                  >
                    <div>
                      {this.TitleAndDesc('ABOUT', '')}
                      <div className='long-description'>
                        <h6
                          dangerouslySetInnerHTML={{
                            __html: this.state.workshop_details.description
                          }}
                        />
                      </div>
                    </div>

                    <div className='mt-2 mb-2' />
                    {this.state.workshop_details.prerequisites &&
                      this.state.workshop_details.prerequisites.length !==
                        0 && (
                        <div>
                          {this.TitleAndDesc(
                            'YOU SHOULD ALREADY KNOW',
                            '',
                            false
                          )}
                          <div className='long-description'>
                            <h6
                              dangerouslySetInnerHTML={{
                                __html: this.state.workshop_details
                                  .prerequisites
                              }}
                            />
                          </div>
                        </div>
                      )}

                    <div className='mt-2 mb-2' />

                    {this.state.materials_required.length !== 0 && (
                      <div>
                        <div className='row m-0'>
                          {this.TitleAndDesc('YOU WOULD NEED', '')}
                          <div className='col-12 row m-0 p-0'>
                            {this.state.materials_required.map(
                              (data, index) => (
                                <div className='col-4 p-0 row m-0' key={index}>
                                  <div className='p-0 pr-2'>
                                    <img
                                      className='pr-2 pl-2'
                                      src={`${data.image ? data.image : ``}`}
                                      alt='materials'
                                      style={{
                                        width: 80,
                                        height: 80,
                                        objectFit: 'contain'
                                      }}
                                    />
                                    <div
                                      className='col-9 pl-4 d-flex align-items-center'
                                      style={{ height: 80 }}
                                    >
                                      <h6 className='material_name'>
                                        {data.name}
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                        <div className='mt-2 mb-2' />
                      </div>
                    )}

                    {this.state.workshop_details.learning_outcome && (
                      <div>
                        <div>
                          {this.TitleAndDesc('LEARNING OUTCOME', '')}
                          <div className='long-description'>
                            <h6
                              dangerouslySetInnerHTML={{
                                __html: this.state.workshop_details
                                  .learning_outcome
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    )}

                    <div className='mt-2 mb-2' />
                  </div>
                </div>
              </div>
            </Col>
            <Col span={10}>
              <div className='m-3'>
                <div className='row m-0'>
                  <div
                    header='Workshop DETAILS'
                    className='col-12 p-3 border-style'
                    key='1'
                  >
                    <div className='heading mb-2'>
                      {this.state.workshop_details.workshop_name && (
                        <LinesEllipsis
                          style={{ whiteSpace: 'pre-wrap' }}
                          text={this.state.workshop_details.workshop_name}
                          trimRight={true}
                          maxLine='4'
                          ellipsis='...'
                          basedOn='letters'
                        />
                      )}
                    </div>
                    <div className='workRow'>
                      <div className='workCol'>
                        <div className='WorkshopViewTitleText'>AGE GROUP</div>
                        <div className='workshoptypeText'>
                          {this.state.workshop_details.age_group
                            ? this.state.workshop_details.age_group
                            : ''}
                        </div>
                      </div>
                      <div className='workCol'>
                        <div className='WorkshopViewTitleText'>SESSIONS</div>
                        <div className='workshoptypeText'>
                          {this.state.workshop_details.workshop_session
                            ? this.state.workshop_details.workshop_session
                            : ' '}
                        </div>
                      </div>
                      <div className='workCol'>
                        <div className='WorkshopViewTitleText'>DURATION</div>
                        <div className='workshoptypeText'>
                          {this.state.workshop_details.workshop_plan_id != 3 ? (
                            <span>
                              {this.state.workshop_details &&
                              this.state.workshop_details.workshop_duration
                                ? `${this.state.workshop_details.workshop_duration}`
                                : ''}
                              {/* {this.state.workshop_details &&
                              this.state.workshop_details.workshop_duration_type
                                ? this.state.workshop_details
                                    .workshop_duration_type == 'hr'
                                  ? 'h'
                                  : ''
                                : ''} */}
                              {this.state.workshop_details &&
                              this.state.workshop_details.workshop_session
                                ? this.state.workshop_details.workshop_session >
                                  1
                                  ? ` each`
                                  : ''
                                : ''}
                            </span>
                          ) : this.state.workshop_details.workshop_plan_id ==
                              3 &&
                            this.state.workshop_details.is_common_timing ? (
                            <span>
                              {this.state.workshop_details &&
                              this.state.workshop_details.workshop_duration
                                ? `${this.state.workshop_details.workshop_duration}`
                                : ''}
                              {/* {this.state.workshop_details &&
                              this.state.workshop_details.workshop_duration_type
                                ? this.state.workshop_details
                                    .workshop_duration_type == 'hr'
                                  ? 'h'
                                  : ''
                                : ''} */}
                              {this.state.workshop_details &&
                              this.state.workshop_details.workshop_session
                                ? this.state.workshop_details.workshop_session >
                                  1
                                  ? ` each`
                                  : ''
                                : ''}
                            </span>
                          ) : (
                            `Varies`
                          )}
                        </div>
                      </div>
                    </div>

                    <div className='workRow'>
                      {this.state.workshop_details &&
                        this.state.workshop_details.workshop_plan_id &&
                        [1, 2, 3].includes(
                          parseInt(this.state.workshop_details.workshop_plan_id)
                        ) &&
                        this.state.workshop_details.repeats_type == 'dnr' &&
                        this.state.workshop_details.Dates &&
                        this.state.workshop_details.Dates.length > 0 && (
                          <div
                            className='d-flex flex-row min-w-400 w-400 d-flex overflow-scroll'
                            id={`workshop-scrollIndex1`}
                          >
                            {this.state.workshop_details.Dates.map(
                              (x, DateIndex) => (
                                <span
                                  className='pr-3 d-inline-block'
                                  key={`DateIndex-${DateIndex}`}
                                >
                                  <div>
                                    <DateView
                                      date={x.workshop_Date}
                                      time={x.start_time}
                                      every=''
                                      duration={
                                        this.state.workshop_details
                                          .workshop_plan_id == 3 &&
                                        !this.state.workshop_details
                                          .is_common_timing
                                          ? x.duration
                                          : ''
                                      }
                                    />
                                  </div>
                                </span>
                              )
                            )}
                          </div>
                        )}
                      {this.state.workshop_details &&
                        this.state.workshop_details.workshop_plan_id &&
                        (this.state.workshop_details.workshop_plan_id == 1 ||
                          this.state.workshop_details.workshop_plan_id == 2) &&
                        this.state.workshop_details.repeats_type != 'dnr' &&
                        this.state.workshop_details.Dates &&
                        this.state.workshop_details.Dates.length > 0 && (
                          <DateContainer className='d-flex'>
                            <div className='d-flex align-items-center'>
                              <div className='subTitle'>
                                {this.state.workshop_details.repeatDays
                                  ? this.state.workshop_details.repeatDays
                                  : ''}
                              </div>
                              &nbsp;
                            </div>
                            <div className='title-border' />
                            <div>
                              <h4 className='title white-space-nowrap'>
                                {`
                                    ${
                                      this.state.workshop_details.Dates[0]
                                        .workshop_Date
                                        ? Moment(
                                            parseInt(
                                              this.state.workshop_details
                                                .Dates[0].workshop_Date
                                            )
                                          ).format(`MMM DD`)
                                        : ''
                                    } -
                                    ${
                                      this.state.workshop_details.Dates[0]
                                        .workshop_Date
                                        ? Moment(
                                            parseInt(
                                              this.state.workshop_details.Dates[
                                                this.state.workshop_details
                                                  .Dates.length - 1
                                              ].workshop_Date
                                            )
                                          ).format(`MMM DD, YYYY`)
                                        : ''
                                    }
                                    `}
                              </h4>
                              <div className='desc'>
                                {this.state.workshop_details.workshop_time
                                  ? Moment(
                                      this.state.workshop_details.workshop_time,
                                      'h m a'
                                    ).format('h:mm A')
                                  : ''}
                              </div>
                            </div>
                          </DateContainer>
                        )}
                    </div>

                    <div className='workRow'>
                      <div>
                        <span
                          style={{
                            color: '#42C0EF',
                            textTransform: 'uppercase'
                          }}
                        >
                          {this.state.workshop_details.workshop_type == 'Paid'
                            ? `\u20B9 ${this.state.workshop_details.workshop_amount}`
                            : this.state.workshop_details.workshop_type}
                        </span>
                        {this.state.workshop_details.available_seats ? (
                          [11,12,13,14,15].includes(this.state.workshop_details.available_seats) ? (
                            <img
                              className='fireimg'
                              src={ClockImg}
                              alt='WorkshopFire'
                            />
                          ) : this.state.workshop_details.available_seats <= 10 ? (
                            <img
                              className='fireimg'
                              src={FireImg}
                              alt='WorkshopFire'
                            />
                          ) : ' '
                        ) : (
                          ' '
                        )}

                        <span className='hurryText'>
                          {this.state.workshop_details.available_seats ? (
                            this.state.workshop_details.available_seats > 0 ? (
                              <span>
                                {this.state.workshop_details.available_seats
                                  ? this.state.workshop_details
                                      .available_seats > 10
                                    ? ``
                                    : `Hurry! `
                                  : ``}
                                Only{' '}
                                {this.state.workshop_details.available_seats
                                  ? this.state.workshop_details.available_seats
                                  : ''}{' '}
                                seat{this.state.workshop_details.available_seats
                                ? this.state.workshop_details.available_seats > 1 ? 's' : ''
                                : ''} left
                              </span>
                            ) : ``
                          ) : (
                            <span style={{color: '#68B415'}}>
                                &ensp;We are fully booked!
                            </span>
                          )}
                        </span>
                      </div>
                    </div>
                    <div className='d-flex flex-row justify-content-between'>
                      <div className='mb-2'>
                        {this.state.pastWorkshop.includes(false) &&
                        this.state.workInProgress ? (
                          <Button
                            key='inProgress'
                            category='workshop In progress'
                            action='Add to cart for paid workshop'
                            type='primary'
                            className='btn-inProgress'
                            size='large'
                            disabled={true}
                          >
                            {/* Workshop In-Progress */}
                            Registrations closed
                          </Button>
                        ) : !this.state.pastWorkshop.includes(false) &&
                          !this.state.workInProgress ? (
                          <Button
                            key='Ended'
                            category='workshop Ended'
                            action='Add to cart for paid workshop'
                            type='primary'
                            className='btn-inProgress'
                            size='large'
                            disabled={true}
                          >
                            Workshop ended
                          </Button>
                        ) : this.state.workshop_details.workshop_type &&
                          this.state.workshop_details.workshop_type == 'Paid' &&
                          this.state.workshop_details.is_cart ? (
                          <Button
                            key='goToCart'
                            category='workshop Contents'
                            action='Go to cart for workshop'
                            type='primary'
                            className='btn-addCart'
                            size='large'
                            onClick={() =>
                              this.props.history.push(`/parent/workshops/cart`)
                            }
                          >
                            Go to cart
                          </Button>
                        ) : this.state.workshop_details.workshop_type &&
                          this.state.workshop_details.workshop_type ==
                            'Paid' ? (
                          this.state.workshop_details.available_seats &&
                          this.state.workshop_details.available_seats > 0 ? (
                            this.state.studentsData &&
                            this.state.studentsData.length > 1 &&
                            !this.state.regStud_status ? (
                              <Button
                                key='addCart'
                                category='more students register workshop Contents'
                                action='Add to cart for paid workshop'
                                type='primary'
                                className='btn-addCart'
                                size='large'
                                onClick={this.registerWorkshopFreeModel}
                              >
                                Add to cart
                              </Button>
                            ) : this.state.studentsData &&
                              (this.state.studentsData.length == 1 ||
                                this.state.req_studId.length > 0) ? (
                              <Button
                                key='addCart'
                                category='one students register workshop Contents'
                                action='Add to cart for paid workshop'
                                type='primary'
                                className='btn-addCart'
                                size='large'
                                onClick={() => this.addToCart()}
                                // disabled={
                                //   this.state.workshop_details.available_seats
                                //     ? this.state.workshop_details.available_seats <
                                //       1
                                //       ? true
                                //       : false
                                //     : false
                                // }
                              >
                                Add to cart
                              </Button>
                            ) : (
                              !this.state.regStud_status && !this.state.is_loading && (
                                // <h6>
                                //   Yours child age category doesn’t match the
                                //   workshop
                                // </h6>
                                <h6>
                                  This workshop is not available for your
                                  child's age group. Check your child's age (you
                                  can login to see this) or choose another
                                  workshop that is meant for their age.
                                </h6>
                                // <Button
                                //   key='addCart'
                                //   category='students registered workshops'
                                //   action='registered workshops'
                                //   type='primary'
                                //   className='btn-addCart'
                                //   size='large'
                                //   disabled={true}
                                //   style={{
                                //     background: '#4C39B3',
                                //     color: '#fff',
                                //     opacity: 0.5
                                //   }}
                                // >
                                //   Registered
                                // </Button>
                              )
                            )
                          ) : (
                            ``
                          )
                        ) : this.state.workshop_details.available_seats &&
                          this.state.workshop_details.available_seats > 0 ? (
                          this.state.studentsData &&
                          this.state.studentsData.length > 1 ? (
                            <Button
                              key='freeRegister'
                              category='more students register workshop Contents'
                              action='Add to cart for free workshop'
                              type='primary'
                              className='btn-addCart'
                              size='large'
                              onClick={this.registerWorkshopFreeModel}
                            >
                              Register now!
                            </Button>
                          ) : this.state.studentsData &&
                            (this.state.studentsData.length == 1 ||
                              this.state.req_studId.length > 0) ? (
                            <Button
                              key='freeRegister'
                              category='one students register workshop Contents'
                              action='Add to cart for free workshop'
                              type='primary'
                              className='btn-addCart'
                              size='large'
                              onClick={() => this.addToCart()}
                            >
                              Register now!
                            </Button>
                          ) : (
                            !this.state.regStud_status && !this.state.is_loading && (
                              // <h6>
                              //   Your child age category doesn’t match the
                              //   workshop
                              // </h6>
                              <h6>
                                This workshop is not available for your child's
                                age group. Check your child's age (you can login
                                to see this) or choose another workshop that is
                                meant for their age.
                              </h6>
                              //         <Button
                              //         key='addCart'
                              //         category='students registered workshops'
                              //         action='registered workshops'
                              //         type='primary'
                              //         className='btn-addCart'
                              //         size='large'
                              //         disabled={true}
                              //         style={{
                              //           background: '#4C39B3',
                              //           color: '#fff',
                              //           opacity: 0.5
                              //         }}
                              //       >
                              //         Registered
                              // </Button>
                            )
                          )
                        ) : (
                          ``
                        )}

                        {this.state.pastWorkshop.includes(false) &&
                          !this.state.workInProgress &&
                          this.state.regStud_status && (
                            <Button
                              key='addCart'
                              category='students registered workshops'
                              action='registered workshops'
                              type='primary'
                              className='btn-addCart'
                              size='large'
                              disabled={true}
                              style={{
                                background: '#4C39B3',
                                color: '#fff',
                                opacity: 0.5
                              }}
                            >
                              Registered
                            </Button>
                          )}
                      </div>
                      <div className='d-flex'>
                        <div className='mr-2 pl-1'>
                          <img
                            src={Link}
                            alt='Link'
                            style={{
                              cursor: 'pointer'
                            }}
                            onClick={this.copyToClipboard}
                          />
                        </div>
                        <div className='mr-2 pl-1'>
                          <img
                            src={Twitter}
                            alt='Twitter'
                            style={{
                              cursor: 'pointer'
                            }}
                            onClick={() => window.open(twitter)}
                          />
                        </div>
                        <div className='mr-2 pl-1'>
                          <img
                            src={Whatsapp}
                            alt='Whatsapp'
                            style={{
                              cursor: 'pointer'
                            }}
                            onClick={() => window.open(whatsapp)}
                          />
                        </div>
                        <div className='mr-2 pl-1'>
                          <img
                            src={Facebook}
                            alt='Facebook'
                            style={{
                              cursor: 'pointer'
                            }}
                            onClick={() => window.open(facebook)}
                          />
                        </div>
                      </div>
                    </div>

                    <div className='d-flex flex-row justify-content-between'>
                      <div className='d-flex flex-column'>
                        {this.state.workshop_details.InterestedChild &&
                          this.state.workshop_details.InterestedChild
                            .length && (
                            <div className='d-flex preqTextDiv mb-2 pr-2'>
                              <div className='preqTextBord'></div>
                              <span style={{ padding: 5 }}>
                                Interest shown by
                              </span>
                              <span className='preqText'>
                                {this.state.workshop_details.InterestedChild.map(
                                  x => x.username
                                ).join(', ')}
                              </span>
                            </div>
                          )}
                      </div>
                    </div>

                    <div className='mt-2 mb-2' />
                  </div>
                </div>
              </div>
              {this.state.workshop_details.RegisteredChild &&
                this.state.workshop_details.RegisteredChild.length > 0 && (
                  <div className='m-3 border-style' header='Content Creator'>
                    {this.state.workshop_details.RegisteredChild &&
                    this.state.workshop_details.RegisteredChild.length == 0 &&
                    this.state.workInProgress ? (
                      <div className='row'>
                        <h6 className='RegText'>Status:</h6>
                        <div className='regDateTitleText'>
                          The registrations for this workshop has wrapped up.
                          Stay tuned for more!
                        </div>
                      </div>
                    ) : (
                      <React.Fragment>
                        <div className='row mb-2' key='1'>
                          <div className='col-4'>
                            <h6 className='RegText'>Status:</h6>
                            {this.state.workshop_details.RegisteredChild &&
                              this.state.workshop_details.RegisteredChild
                                .length > 0 && (
                                <div className='d-flex flex-column'>
                                  {this.state.workshop_details.RegisteredChild.map(
                                    x =>
                                      !this.state.pastWorkshop.includes(
                                        false
                                      ) ? (
                                        !this.state.pastWorkshop.includes(
                                          false
                                        ) && x.is_attended ? (
                                          <Tag
                                            className='registerTag mb-2'
                                            key='Attended'
                                          >
                                            Attended
                                          </Tag>
                                        ) : (
                                          <Tag
                                            className='notAttendedTag mb-2'
                                            key='notAttended'
                                          >
                                            Not attended
                                          </Tag>
                                        )
                                      ) : (
                                        <Tag
                                          className='registerTag mb-2'
                                          key='Registered'
                                        >
                                          Registered
                                        </Tag>
                                      )
                                  )}
                                </div>
                              )}
                          </div>
                          <div className='col-4'>
                            <h6 className='RegText'>
                              {!this.state.pastWorkshop.includes(false) &&
                              !this.state.workInProgress
                                ? `Attendees`
                                : `Registered for`}
                              :
                            </h6>
                            {this.state.workshop_details.RegisteredChild &&
                              this.state.workshop_details.RegisteredChild
                                .length && (
                                <div className='d-flex flex-column'>
                                  {this.state.workshop_details.RegisteredChild.map(
                                    x => (
                                      <div className='regTex mb-2'>
                                        {x.username}
                                      </div>
                                    )
                                  )}
                                </div>
                              )}
                          </div>
                          <div className='col-4'>
                            <h6 className='RegText'>Next session:</h6>
                            {this.state.pastWorkshop.includes(false) ? (
                              this.state.nextSession.length > 0 && (
                                <div>
                                  <h5 className='regDateTitleText white-space-nowrap'>
                                    {this.state.nextSession[0].workshop_Date
                                      ? Moment.utc(
                                          parseInt(
                                            this.state.nextSession[0]
                                              .workshop_Date
                                          )
                                        ).format(`ddd, Do MMM ’YY`)
                                      : ''}
                                  </h5>
                                  <div className='regDateText'>
                                    {this.state.nextSession[0].start_time
                                      ? Moment(
                                          this.state.nextSession[0].start_time,
                                          'h m a'
                                        ).format('h:mm A')
                                      : ''}
                                    {this.state.nextSession[0].duration
                                      ? ` - ${this.state.nextSession[0].duration}`
                                      : ``}
                                  </div>
                                </div>
                              )
                            ) : (
                              <h5 className='regDateTitleText'>
                                No new sessions
                              </h5>
                            )}
                          </div>
                        </div>
                        {this.state.pastWorkshop.includes(false) && (
                          <div className='row mb-2' key='1'>
                            <div className='col-12'>
                              <h6 className='RegText'>Meeting link:</h6>
                              <div>
                                <a
                                  href={this.state.zoom_details.join_url}
                                  target='__blank'
                                  className='meetingText'
                                >
                                  {this.state.zoom_details.join_url}
                                </a>
                                <div>
                                  Meeting ID: {this.state.zoom_details.id}
                                </div>
                                <div>
                                  Password: {this.state.zoom_details.password}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </React.Fragment>
                    )}
                  </div>
                )}
              <div className='m-3'>
                <div className='row m-0'>
                  <div
                    header='Content Creator'
                    className='col-12 p-3 border-style'
                    key='1'
                  >
                    <div className='heading'>
                      <h5 className='contentCreat'>Content Creator</h5>
                    </div>
                    <div className='row'>
                      <div className='col-4'>
                        <div
                          className='ContentDiv'
                          style={{
                            backgroundImage: `url(${UserProfile})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            backgroundSize: 'contain'
                          }}
                        >
                          <div>
                            <img
                              className='ContentImg'
                              src={
                                this.state.workshop_details.user_details &&
                                this.state.workshop_details.user_details
                                  .profile_image
                                  ? this.state.workshop_details.user_details
                                      .profile_image
                                  : contentFace
                              }
                              alt='Contentcreator-Face'
                              onError={e => {
                                e.target.onerror = null
                                e.target.src = contentFace
                              }}
                            />
                          </div>
                          <div className='contentName'>
                            <LinesEllipsis
                              style={{ whiteSpace: 'pre-wrap', width: '100%' }}
                              text={
                                this.state.workshop_details.user_details
                                  ? this.state.workshop_details.user_details
                                      .displayName
                                    ? this.state.workshop_details.user_details
                                        .displayName
                                    : this.state.workshop_details.user_details
                                        .name
                                  : ''
                              }
                              trimRight={true}
                              maxLine='1'
                              ellipsis='...'
                              // lineHeight='16'
                              basedOn='letters'
                            />
                          </div>
                        </div>
                      </div>
                      <div className='col-8'>
                        <div className='ContentDiv1'>
                          <div className='Conhead1'>
                            <h6>
                              About{' '}
                              {this.state.workshop_details.user_details
                                ? this.state.workshop_details.user_details.name
                                : ''}
                            </h6>
                          </div>
                          <div className='bioDetail'>
                            <p>
                              {this.state.workshop_details.user_details
                                ? this.state.workshop_details.user_details.bio
                                : ''}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='mt-2 mb-2' />
                  </div>
                </div>
              </div>
            </Col>
          </Row>

            {/* mbl dev workshop view details */}
            <div className="mbl-dev-workshopView">
              <div className="d-flex align-items-center justify-content-between" style={{
                padding: 15
              }}>
                <div className="d-flex align-items-center">
                  <Icon
                    type='arrow-left'
                    style={{
                      color: '#333333',
                      cursor: 'pointer',
                      fontSize: 18,
                      marginRight: 5
                    }}
                    onClick={() => this.props.history.goBack()}
                  />
                </div>
                <div>
                  <div className='d-flex'>
                      <div className='mr-1 pl-1'>
                        <img
                          src={Link}
                          alt='Link'
                          style={{
                            cursor: 'pointer'
                          }}
                          onClick={this.copyToClipboard}
                        />
                      </div>
                      <div className='mr-1 pl-1'>
                        <img
                          src={Facebook}
                          alt='Facebook'
                          style={{
                            cursor: 'pointer'
                          }}
                          onClick={() => window.open(facebook)}
                        />
                      </div>
                      <div className='mr-1 pl-1'>
                        <img
                          src={Twitter}
                          alt='Twitter'
                          style={{
                            cursor: 'pointer'
                          }}
                          onClick={() => window.open(twitter)}
                        />
                      </div>
                      <div className='pl-1'>
                        <img
                          src={Whatsapp}
                          alt='Whatsapp'
                          style={{
                            cursor: 'pointer'
                          }}
                          onClick={() => window.open(whatsapp)}
                        />
                      </div>
                    </div>
                </div>
              </div>
              <Row>
              <Col sm={12} xs={24}>
                <div>
                  <div className="pl-3 pr-3" 
                      style={{ whiteSpace: 'pre-wrap', color: '#000', fontWeight: 800, fontSize: 20 }}
                  >
                  {this.state.workshop_details.workshop_name && (
                    this.state.workshop_details.workshop_name
                    )}
                  </div>
                  <div className='p-3'>
                    {this.Thumb_imageCarousel(
                      this.state.thumb_images,
                      this.state.selectedSlide
                    )}
                    {this.state.thumb_images.length > 1 && (
                      <div className='mbl-listImg d-flex'>
                        {this.ThumbImageList(
                          this.state.thumb_images,
                          this.state.selectedSlide
                        )}
                      </div>
                    )}
                  </div>
                  <div className='row m-0'>
                  <div
                      header='DETAILS OF THIS ACTIVITY'
                      className='col-12 border-style p-3'
                      key='1'
                    >
                    <div className='workRow'>
                        <div className='workCol'>
                          <div className='WorkshopViewTitleText'>AGE GROUP</div>
                          <div className='workshoptypeText'>
                            {this.state.workshop_details.age_group
                              ? this.state.workshop_details.age_group
                              : ''}
                          </div>
                        </div>
                        <div className='workCol'>
                          <div className='WorkshopViewTitleText'>SESSIONS</div>
                          <div className='workshoptypeText'>
                            {this.state.workshop_details.workshop_session
                              ? this.state.workshop_details.workshop_session
                              : ' '}
                          </div>
                        </div>
                        <div className='workCol'>
                          <div className='WorkshopViewTitleText'>DURATION</div>
                          <div className='workshoptypeText'>
                            {this.state.workshop_details.workshop_plan_id != 3 ? (
                              <span>
                                {this.state.workshop_details &&
                                this.state.workshop_details.workshop_duration
                                  ? `${this.state.workshop_details.workshop_duration}`
                                  : ''}
                                {/* {this.state.workshop_details &&
                                this.state.workshop_details.workshop_duration_type
                                  ? this.state.workshop_details
                                      .workshop_duration_type == 'hr'
                                    ? 'h'
                                    : ''
                                  : ''} */}
                                {this.state.workshop_details &&
                                this.state.workshop_details.workshop_session
                                  ? this.state.workshop_details.workshop_session >
                                    1
                                    ? ` each`
                                    : ''
                                  : ''}
                              </span>
                            ) : this.state.workshop_details.workshop_plan_id ==
                                3 &&
                              this.state.workshop_details.is_common_timing ? (
                              <span>
                                {this.state.workshop_details &&
                                this.state.workshop_details.workshop_duration
                                  ? `${this.state.workshop_details.workshop_duration}`
                                  : ''}
                                {/* {this.state.workshop_details &&
                                this.state.workshop_details.workshop_duration_type
                                  ? this.state.workshop_details
                                      .workshop_duration_type == 'hr'
                                    ? 'h'
                                    : ''
                                  : ''} */}
                                {this.state.workshop_details &&
                                this.state.workshop_details.workshop_session
                                  ? this.state.workshop_details.workshop_session >
                                    1
                                    ? ` each`
                                    : ''
                                  : ''}
                              </span>
                            ) : (
                              `Varies`
                            )}
                          </div>
                        </div>
                      </div>
                    <div className='workRow'>
                        <div>
                          <span
                            style={{
                              color: '#42C0EF',
                              textTransform: 'uppercase',
                              fontSize: 14
                            }}
                          >
                            {this.state.workshop_details.workshop_type == 'Paid'
                              ? `\u20B9 ${this.state.workshop_details.workshop_amount}`
                              : this.state.workshop_details.workshop_type}
                          </span>
                          {this.state.workshop_details.available_seats ? (
                            [11,12,13,14,15].includes(this.state.workshop_details.available_seats) ? (
                              <img
                                className='fireimg'
                                src={ClockImg}
                                alt='WorkshopFire'
                              />
                            ) : this.state.workshop_details.available_seats <= 10 ? (
                              <img
                                className='fireimg'
                                src={FireImg}
                                alt='WorkshopFire'
                              />
                            ) : ' '
                          ) : (
                            ' '
                          )}

                          <span className='hurryText'>
                            {this.state.workshop_details.available_seats ? (
                              this.state.workshop_details.available_seats > 0 ? (
                                <span>
                                  {this.state.workshop_details.available_seats
                                    ? this.state.workshop_details
                                        .available_seats > 10
                                      ? ``
                                      : `Hurry! `
                                    : ``}
                                  Only{' '}
                                  {this.state.workshop_details.available_seats
                                    ? this.state.workshop_details.available_seats
                                    : ''}{' '}
                                  seat{this.state.workshop_details.available_seats
                                  ? this.state.workshop_details.available_seats > 1 ? 's' : ''
                                  : ''} left
                                </span>
                              ) : ``
                            ) : (
                              <span style={{color: '#68B415'}}>
                                  &ensp;We are fully booked!
                              </span>
                            )}
                          </span>
                        </div>
                      </div>
                    <div className='workRow'>
                        {this.state.workshop_details &&
                          this.state.workshop_details.workshop_plan_id &&
                          [1, 2, 3].includes(
                            parseInt(this.state.workshop_details.workshop_plan_id)
                          ) &&
                          this.state.workshop_details.repeats_type == 'dnr' &&
                          this.state.workshop_details.Dates &&
                          this.state.workshop_details.Dates.length > 0 && (
                            <div
                              className='d-flex flex-row min-w-80per w-80per d-flex overflow-scroll'
                              id={`workshop-scrollIndex`}
                            >
                              {this.state.workshop_details.Dates.map(
                                (x, DateIndex) => (
                                  <span
                                    className='pr-3 d-inline-block'
                                    key={`DateIndex-${DateIndex}`}
                                  >
                                    <div>
                                      <DateView
                                        date={x.workshop_Date}
                                        time={x.start_time}
                                        every=''
                                        duration={
                                          this.state.workshop_details
                                            .workshop_plan_id == 3 &&
                                          !this.state.workshop_details
                                            .is_common_timing
                                            ? x.duration
                                            : ''
                                        }
                                      />
                                    </div>
                                  </span>
                                )
                              )}
                            </div>
                          )}
                        {this.state.workshop_details &&
                          this.state.workshop_details.workshop_plan_id &&
                          (this.state.workshop_details.workshop_plan_id == 1 ||
                            this.state.workshop_details.workshop_plan_id == 2) &&
                          this.state.workshop_details.repeats_type != 'dnr' &&
                          this.state.workshop_details.Dates &&
                          this.state.workshop_details.Dates.length > 0 && (
                            <DateContainer className='d-flex'>
                              <div className='d-flex align-items-center'>
                                <div className='subTitle'>
                                  {this.state.workshop_details.repeatDays
                                    ? this.state.workshop_details.repeatDays
                                    : ''}
                                </div>
                                &nbsp;
                              </div>
                              <div className='title-border' />
                              <div>
                                <h4 className='title white-space-nowrap'>
                                  {`
                                      ${
                                        this.state.workshop_details.Dates[0]
                                          .workshop_Date
                                          ? Moment(
                                              parseInt(
                                                this.state.workshop_details
                                                  .Dates[0].workshop_Date
                                              )
                                            ).format(`MMM DD`)
                                          : ''
                                      } -
                                      ${
                                        this.state.workshop_details.Dates[0]
                                          .workshop_Date
                                          ? Moment(
                                              parseInt(
                                                this.state.workshop_details.Dates[
                                                  this.state.workshop_details
                                                    .Dates.length - 1
                                                ].workshop_Date
                                              )
                                            ).format(`MMM DD, YYYY`)
                                          : ''
                                      }
                                      `}
                                </h4>
                                <div className='desc'>
                                  {this.state.workshop_details.workshop_time
                                    ? Moment(
                                        this.state.workshop_details.workshop_time,
                                        'h m a'
                                      ).format('h:mm A')
                                    : ''}
                                </div>
                              </div>
                            </DateContainer>
                          )}
                          {this.state.workshop_details.workshop_plan_id &&
                          [1, 2, 3].includes(
                            parseInt(this.state.workshop_details.workshop_plan_id)
                          ) &&
                          this.state.workshop_details.repeats_type == 'dnr' && 
                          this.state.workshop_details.Dates &&
                          this.state.workshop_details.Dates.length > 2 && (
                            <img
                              className='ml-3 cursor-pointer'
                              src={dateArrow}
                              alt='arrow'
                              onClick={(e) => {
                                document.getElementById(`workshop-scrollIndex`).scrollTo({
                                  left:
                                    document.getElementById(`workshop-scrollIndex`).scrollLeft + 250,
                                  behavior: 'smooth'
                                })
                              }}
                            />
                          )}
                      </div>
                    <div className='d-flex flex-row justify-content-between'>
                        <div className='d-flex flex-column'>
                          {this.state.workshop_details.InterestedChild &&
                            this.state.workshop_details.InterestedChild
                              .length && (
                              <div className='d-flex preqTextDiv mb-2 pr-2'>
                                <div className='preqTextBord'></div>
                                <span style={{ padding: 5 }}>
                                  Interest shown by
                                </span>
                                <span className='preqText'>
                                  {this.state.workshop_details.InterestedChild.map(
                                    x => x.username
                                  ).join(', ')}
                                </span>
                              </div>
                            )}
                        </div>
                      </div>  
                      
                      {this.state.workshop_details.RegisteredChild &&
                  this.state.workshop_details.RegisteredChild.length > 0 && (
                    <div className='border-style mb-3' header='Content Creator'
                    style={{background: '#fafafa', padding: 12}}
                    >
                      {this.state.workshop_details.RegisteredChild &&
                      this.state.workshop_details.RegisteredChild.length == 0 &&
                      this.state.workInProgress ? (
                        <div className='row'>
                          <h6 className='RegText'>Status:</h6>
                          <div className='regDateTitleText'>
                            The registrations for this workshop has wrapped up.
                            Stay tuned for more!
                          </div>
                        </div>
                      ) : (
                        <React.Fragment>
                          <div className='row mb-2' key='1'>
                          <div className='col-6'>
                              <h6 className='RegText'>
                                {!this.state.pastWorkshop.includes(false) &&
                                !this.state.workInProgress
                                  ? `Attendees`
                                  : `Registered for`}
                                :
                              </h6>
                              {this.state.workshop_details.RegisteredChild &&
                                this.state.workshop_details.RegisteredChild
                                  .length && (
                                  <div className='d-flex flex-column'>
                                    {this.state.workshop_details.RegisteredChild.map(
                                      x => (
                                        <div className='regTex mb-2'>
                                          {x.username}
                                        </div>
                                      )
                                    )}
                                  </div>
                                )}
                            </div>
                            <div className='col-6'>
                              <h6 className='RegText'>Status:</h6>
                              {this.state.workshop_details.RegisteredChild &&
                                this.state.workshop_details.RegisteredChild
                                  .length > 0 && (
                                  <div className='d-flex flex-column'>
                                    {this.state.workshop_details.RegisteredChild.map(
                                      x =>
                                        !this.state.pastWorkshop.includes(
                                          false
                                        ) ? (
                                          !this.state.pastWorkshop.includes(
                                            false
                                          ) && x.is_attended ? (
                                            <Tag
                                              className='registerTag mb-2'
                                              key='Attended'
                                            >
                                              Attended
                                            </Tag>
                                          ) : (
                                            <Tag
                                              className='notAttendedTag mb-2'
                                              key='notAttended'
                                            >
                                              Not attended
                                            </Tag>
                                          )
                                        ) : (
                                          <Tag
                                            className='registerTag mb-2'
                                            key='Registered'
                                          >
                                            Registered
                                          </Tag>
                                        )
                                    )}
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="row mb-2">
                            <div className='col-12'>
                              <h6 className='RegText'>Next session:</h6>
                              {this.state.pastWorkshop.includes(false) ? (
                                this.state.nextSession.length > 0 && (
                                  <div>
                                    <h5 className='regDateTitleText white-space-nowrap'>
                                      {this.state.nextSession[0].workshop_Date
                                        ? Moment.utc(
                                            parseInt(
                                              this.state.nextSession[0]
                                                .workshop_Date
                                            )
                                          ).format(`ddd, Do MMM ’YY`)
                                        : ''}
                                    </h5>
                                    <div className='regDateText'>
                                      {this.state.nextSession[0].start_time
                                        ? Moment(
                                            this.state.nextSession[0].start_time,
                                            'h m a'
                                          ).format('h:mm A')
                                        : ''}
                                      {this.state.nextSession[0].duration
                                        ? ` - ${this.state.nextSession[0].duration}`
                                        : ``}
                                    </div>
                                  </div>
                                )
                              ) : (
                                <h5 className='regDateTitleText'>
                                  No new sessions
                                </h5>
                              )}
                            </div>
                          </div>
                          {this.state.pastWorkshop.includes(false) && (
                            <div className='row mb-2' key='1'>
                              <div className='col-12'>
                                <h6 className='RegText'>Meeting link:</h6>
                                <div>
                                  <a
                                    href={this.state.zoom_details.join_url}
                                    target='__blank'
                                    className='meetingText'
                                  >
                                    {this.state.zoom_details.join_url}
                                  </a>
                                  <div className="btn-txt-black">
                                    Meeting ID: {this.state.zoom_details.id}
                                  </div>
                                  <div className="btn-txt-black">
                                    Password: {this.state.zoom_details.password}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </React.Fragment>
                      )}
                    </div>
                  )}
                      
                      <div>
                        {this.TitleAndDesc('ABOUT', '')}
                        <div className='long-description'>
                          <h6
                            dangerouslySetInnerHTML={{
                              __html: this.state.workshop_details.description
                            }}
                          />
                        </div>
                      </div>

                      {this.state.workshop_details.prerequisites &&
                        this.state.workshop_details.prerequisites.length !==
                          0 && (
                          <div>
                            {this.TitleAndDesc(
                              'YOU SHOULD ALREADY KNOW',
                              '',
                              false
                            )}
                            <div className='long-description'>
                              <h6
                                dangerouslySetInnerHTML={{
                                  __html: this.state.workshop_details
                                    .prerequisites
                                }}
                              />
                            </div>
                          </div>
                        )}

                      {this.state.materials_required.length !== 0 && (
                        <div>
                          <div className='row m-0'>
                            {this.TitleAndDesc('YOU WOULD NEED', '')}
                            <div className='col-12 row m-0 p-0'>
                              {this.state.materials_required.map(
                                (data, index) => (
                                  <div className='col-4 p-0 row m-0' key={index}>
                                    <div className='p-0 pr-2'>
                                      <img
                                        className='pr-2 pl-2'
                                        src={`${data.image ? data.image : ``}`}
                                        alt='materials'
                                        style={{
                                          width: 80,
                                          height: 80,
                                          objectFit: 'contain'
                                        }}
                                      />
                                      <div
                                        className='d-flex justify-content-center'
                                      >
                                        <h6 className='material_name'>
                                          {data.name}
                                        </h6>
                                      </div>
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                          <div className='mt-2 mb-2' />
                        </div>
                      )}

                      {this.state.workshop_details.learning_outcome && (
                        <div>
                          <div>
                            {this.TitleAndDesc('LEARNING OUTCOME', '')}
                            <div className='long-description'>
                              <h6
                                dangerouslySetInnerHTML={{
                                  __html: this.state.workshop_details
                                    .learning_outcome
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      )}


                      <div>
                        <div className='row m-0'>
                          <div
                            header='Content Creator'
                            className='col-12 p-2 mbl-content-creator border-style'
                            key='1'
                          >
                            <div className='heading'>
                              <h5 className='contentCreat'>Content Creator</h5>
                            </div>
                            <div className='row'>
                              <div className='col-4'>
                                <div
                                  className='ContentDiv'
                                  style={{
                                    backgroundImage: `url(${UserProfile})`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center',
                                    backgroundSize: 'contain'
                                  }}
                                >
                                  <div>
                                    <img
                                      className='ContentImg'
                                      src={
                                        this.state.workshop_details.user_details &&
                                        this.state.workshop_details.user_details
                                          .profile_image
                                          ? this.state.workshop_details.user_details
                                              .profile_image
                                          : contentFace
                                      }
                                      alt='Contentcreator-Face'
                                      onError={e => {
                                        e.target.onerror = null
                                        e.target.src = contentFace
                                      }}
                                    />
                                  </div>
                                  <div className='contentName'>
                                    <LinesEllipsis
                                      style={{ whiteSpace: 'pre-wrap', width: '100%', color: '#fff', fontSize: 10, fontWeight: 'bold' }}
                                      text={
                                        this.state.workshop_details.user_details
                                          ? this.state.workshop_details.user_details
                                              .displayName
                                            ? this.state.workshop_details.user_details
                                                .displayName
                                            : this.state.workshop_details.user_details
                                                .name
                                          : ''
                                      }
                                      trimRight={true}
                                      maxLine='1'
                                      ellipsis='...'
                                      // lineHeight='16'
                                      basedOn='letters'
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className='col-8'>
                                <div className='ContentDiv1'>
                                  <div className='Conhead1'>
                                    <h6>
                                      About{' '}
                                      {this.state.workshop_details.user_details
                                        ? this.state.workshop_details.user_details.name
                                        : ''}
                                    </h6>
                                  </div>
                                  <div className='bioDetail'>
                                    <p>
                                      {this.state.workshop_details.user_details
                                        ? this.state.workshop_details.user_details.bio
                                        : ''}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>

                          <div className='mt-2 mb-2' />
                        </div>
                      </div>
                    </div>

                      <div className='mt-mb-6' />
                    </div>

                    <div className='mbl-bottom-btn border-style d-flex flex-row justify-content-center'>
                        {/* <div className='mb-2'> */}
                          {this.state.pastWorkshop.includes(false) &&
                          this.state.workInProgress ? (
                            <Button
                              key='inProgress'
                              category='workshop In progress'
                              action='Add to cart for paid workshop'
                              type='primary'
                              className='btn-inProgress'
                              size='large'
                              disabled={true}
                            >
                              {/* Workshop In-Progress */}
                              Registrations closed
                            </Button>
                          ) : !this.state.pastWorkshop.includes(false) &&
                            !this.state.workInProgress ? (
                            <Button
                              key='Ended'
                              category='workshop Ended'
                              action='Add to cart for paid workshop'
                              type='primary'
                              className='btn-inProgress'
                              size='large'
                              disabled={true}
                            >
                              Workshop ended
                            </Button>
                          ) : this.state.workshop_details.workshop_type &&
                            this.state.workshop_details.workshop_type == 'Paid' &&
                            this.state.workshop_details.is_cart ? (
                            <Button
                              key='goToCart'
                              category='workshop Contents'
                              action='Go to cart for workshop'
                              type='primary'
                              className='btn-addCart'
                              size='large'
                              onClick={() =>
                                this.props.history.push(`/parent/workshops/cart`)
                              }
                            >
                              Go to cart
                            </Button>
                          ) : this.state.workshop_details.workshop_type &&
                            this.state.workshop_details.workshop_type ==
                              'Paid' ? (
                            this.state.workshop_details.available_seats &&
                            this.state.workshop_details.available_seats > 0 ? (
                              this.state.studentsData &&
                              this.state.studentsData.length > 1 &&
                              !this.state.regStud_status ? (
                                <Button
                                  key='addCart'
                                  category='more students register workshop Contents'
                                  action='Add to cart for paid workshop'
                                  type='primary'
                                  className='btn-addCart'
                                  size='large'
                                  onClick={this.registerWorkshopFreeModel}
                                >
                                  Add to cart
                                </Button>
                              ) : this.state.studentsData &&
                                (this.state.studentsData.length == 1 ||
                                  this.state.req_studId.length > 0) ? (
                                <Button
                                  key='addCart'
                                  category='one students register workshop Contents'
                                  action='Add to cart for paid workshop'
                                  type='primary'
                                  className='btn-addCart'
                                  size='large'
                                  onClick={() => this.addToCart()}
                                  // disabled={
                                  //   this.state.workshop_details.available_seats
                                  //     ? this.state.workshop_details.available_seats <
                                  //       1
                                  //       ? true
                                  //       : false
                                  //     : false
                                  // }
                                >
                                  Add to cart
                                </Button>
                              ) : (
                                !this.state.regStud_status && !this.state.is_loading && (
                                  // <h6>
                                  //   Yours child age category doesn’t match the
                                  //   workshop
                                  // </h6>
                                  <h6>
                                    This workshop is not available for your
                                    child's age group. Check your child's age (you
                                    can login to see this) or choose another
                                    workshop that is meant for their age.
                                  </h6>
                                  // <Button
                                  //   key='addCart'
                                  //   category='students registered workshops'
                                  //   action='registered workshops'
                                  //   type='primary'
                                  //   className='btn-addCart'
                                  //   size='large'
                                  //   disabled={true}
                                  //   style={{
                                  //     background: '#4C39B3',
                                  //     color: '#fff',
                                  //     opacity: 0.5
                                  //   }}
                                  // >
                                  //   Registered
                                  // </Button>
                                )
                              )
                            ) : (
                              ``
                            )
                          ) : this.state.workshop_details.available_seats &&
                            this.state.workshop_details.available_seats > 0 ? (
                            this.state.studentsData &&
                            this.state.studentsData.length > 1 ? (
                              <Button
                                key='freeRegister'
                                category='more students register workshop Contents'
                                action='Add to cart for free workshop'
                                type='primary'
                                className='btn-addCart'
                                size='large'
                                onClick={this.registerWorkshopFreeModel}
                              >
                                Register now!
                              </Button>
                            ) : this.state.studentsData &&
                              (this.state.studentsData.length == 1 ||
                                this.state.req_studId.length > 0) ? (
                              <Button
                                key='freeRegister'
                                category='one students register workshop Contents'
                                action='Add to cart for free workshop'
                                type='primary'
                                className='btn-addCart'
                                size='large'
                                onClick={() => this.addToCart()}
                              >
                                Register now!
                              </Button>
                            ) : (
                              !this.state.regStud_status && !this.state.is_loading && (
                                // <h6>
                                //   Your child age category doesn’t match the
                                //   workshop
                                // </h6>
                                <h6>
                                  This workshop is not available for your child's
                                  age group. Check your child's age (you can login
                                  to see this) or choose another workshop that is
                                  meant for their age.
                                </h6>
                                //         <Button
                                //         key='addCart'
                                //         category='students registered workshops'
                                //         action='registered workshops'
                                //         type='primary'
                                //         className='btn-addCart'
                                //         size='large'
                                //         disabled={true}
                                //         style={{
                                //           background: '#4C39B3',
                                //           color: '#fff',
                                //           opacity: 0.5
                                //         }}
                                //       >
                                //         Registered
                                // </Button>
                              )
                            )
                          ) : (
                            ``
                          )}

                          {this.state.pastWorkshop.includes(false) &&
                            !this.state.workInProgress &&
                            this.state.regStud_status && (
                              <Button
                                key='addCart'
                                category='students registered workshops'
                                action='registered workshops'
                                type='primary'
                                className='btn-addCart'
                                size='large'
                                disabled={true}
                                style={{
                                  background: '#4C39B3',
                                  color: '#fff',
                                  opacity: 0.5
                                }}
                              >
                                Registered
                              </Button>
                            )}
                        {/* </div> */}
                      </div>

                  </div>
                </div>
              </Col>
            </Row>
            </div>

          {/* paid modal cart */}
          <Modal
            visible={this.state.changesChildpaidModal}
            closable={true}
            centered={true}
            footer={[
              <div className='d-flex justify-content-between align-items-center pl-3 pr-3 p-1'>
                <div className='fontColor-blue font-16 FontAvenirMedium' />
                <div>
                  <Button
                    key='wsubmit'
                    category='workshop Contents'
                    action='Changes required for workshop Content'
                    type='primary'
                    className='btn-changeHover'
                    size='large'
                    onClick={() => this.addToCart()}
                    disabled={
                      this.state.req_studId.length < 1 &&
                      this.state.studentsData &&
                      this.state.studentsData.length > 0 &&
                      this.state.selected_studId.length < 1
                    }
                  >
                    {this.state.workshop_details.InterestedChild &&
                    this.state.workshop_details.InterestedChild.length > 0
                      ? `Continue`
                      : `Register`}
                  </Button>
                </div>
              </div>
            ]}
            onCancel={() =>
              this.setState({
                changesChildpaidModal: false,
                selected_studId: [],
                studentsData: this.state.studentsData.map(x => {
                  x.is_active = false
                  return x
                })
              })
            }
            // width='30%'
          >
            <h4 className='heading'>Learner selection</h4>

            <div className='border-bottom'></div>
            <div className='mt-4'>
              {this.state.workshop_details.InterestedChild &&
              this.state.workshop_details.InterestedChild.length > 0 ? (
                <p className='FontAvenirRoman font-16 mb-2'>
                  {this.state.workshop_details.InterestedChild.map(x => (
                    <strong className='FontAvenirHeavy'>{x.username} </strong>
                  ))}
                  has requested to attending this. If you would like to make
                  this available for the others, please select their names too
                </p>
              ) : (
                <p className='FontAvenirRoman font-16 mb-2'>
                  Please select the learner to make the purchase
                </p>
              )}

              {this.state.studentsData &&
                this.state.studentsData.length > 0 &&
                this.state.studentsData.map((x, i) => (
                  <div className='col-12 p-0'>
                    <Checkbox
                      onChange={e => this.onSelectstudentID(i, e)}
                      className='pt-2 pb-2'
                      checked={x.is_active}
                      value={x.id}
                    >
                      {x.student_details.username}
                    </Checkbox>
                  </div>
                ))}
            </div>
          </Modal>

          {/* free modal cart */}
          <Modal
            visible={this.state.changesChildfreeModal}
            closable={true}
            centered={true}
            footer={[
              <div className='d-flex justify-content-between align-items-center pl-3 pr-3 p-1'>
                <div className='fontColor-blue font-16 FontAvenirMedium' />
                <div>
                  <Button
                    key='wsubmit'
                    category='workshop Contents'
                    action='Changes required for workshop Content'
                    type='primary'
                    className='btn-changeHover'
                    size='large'
                    onClick={() => this.addToCart()}
                    disabled={
                      this.state.req_studId.length < 1 &&
                      this.state.studentsData &&
                      this.state.studentsData.length > 0 &&
                      this.state.selected_studId.length < 1
                    }
                  >
                    {this.state.workshop_details.InterestedChild &&
                    this.state.workshop_details.InterestedChild.length
                      ? `Continue`
                      : `Register`}
                  </Button>
                </div>
              </div>
            ]}
            onCancel={() =>
              this.setState({
                changesChildfreeModal: false,
                selected_studId: [],
                studentsData: this.state.studentsData.map(x => {
                  x.is_active = false
                  return x
                })
              })
            }
            // width='30%'
          >
            <h4 className='heading'>Learner selection</h4>

            <div className='border-bottom'></div>
            <div className='mt-4'>
              {this.state.workshop_details.InterestedChild &&
              this.state.workshop_details.InterestedChild.length > 0 ? (
                <p className='FontAvenirRoman font-16 mb-2'>
                  {this.state.workshop_details.InterestedChild.map(x => (
                    <strong className='FontAvenirHeavy'>{x.username} </strong>
                  ))}
                  has requested to attending this. If you would like to make
                  this available for the others, please select their names too
                </p>
              ) : (
                <p className='FontAvenirRoman font-16 mb-2'>
                  Please select the learner to make the purchase
                </p>
              )}

              {this.state.studentsData &&
                this.state.studentsData.length > 0 &&
                this.state.studentsData.map((x, i) => (
                  <div className='col-12 p-0'>
                    <Checkbox
                      onChange={e => this.onSelectstudentID(i, e)}
                      className='pt-2 pb-2'
                      checked={x.is_active}
                      value={x.id}
                    >
                      {x.student_details.username}
                    </Checkbox>
                  </div>
                ))}
            </div>
          </Modal>
        </React.Fragment>
      )
    }
  }
}

export default ViewWorkshop
