import React, { Component } from "react";
import {Button, Card, Col, DatePicker, Input, message, Modal, notification, Popover, Row, Tabs, Tag, Tooltip, Select} from "antd";
import axios from "axios";
import _ from "lodash";
import moment from "moment";
import { Link } from "react-router-dom";
import Form from "react-validation/build/form";
import AudioRecord from "../../components/AudioRecord";
import CategoryCard from "../../components/Card/CategoryCard";
import StudentCard from "../../components/Card/StudentCard";
import InputC from "../../components/InputC";
import JournalEntry from "../../components/JournalEntry";
import Loading from "../../components/Loading";
import { RESEND_INVITE } from "../../graphql";
import emptyStateActivity from "../../static/activity/emptystatecontent.svg";
import { openNotification, triggerGAEvent } from "../../utils";
import { email, isEmail, isRequired } from "../../validation";
import {
  GET_ONE_STUDENT_ACTIVITIES,
  GET_ONE_STUDENT_ATTENDANCE,
  GET_ONE_STUDENT_DETAILS,
  GET_ONE_STUDENT_LIBRARIES,
  INITIATE_PASSWORD_RESET,
  SEND_PARENT_INVITE,
  STUDENT_GENERAL_FEEDBACK,
  STUDENT_LC_FEEDBACK,
  REMOVE_PARENT_EMAIL
} from "./api";
import { country_codes } from "../../static/countryCodes.json";

const TabPane = Tabs.TabPane;

export default class StudentView extends Component {
  state = {
    role_id: "",
    confirmDialog: null,
    loading: false,
    visible: false,
    requestSent: false,
    open: false,
    generalFeedbackVisible: false,
    lcFeedbackVisible: false,
    addParentModal: false,
    is_password_changed: null,
    student_details: {},
    interest_categories: [],
    activity_details: [],
    library_details: [],
    calender_details: [],
    feedbackText: "",
    feedback_voice: "",
    lcDetails: {},
    parent: [],
    parentData: [
      {
        email: "",
        name: "",
        last_name: "",
        address: "",
        phone_no: "",
        country: "India",
        country_code: "+91",
        status: ""
      },
      {
        email: "",
        name: "",
        last_name: "",
        address: "",
        phone_no: "",
        country: "India",
        country_code: "+91",
        status: ""
      }
    ],
    selected_date: "",
    overall_journals: [],
    journal_calender_details: [],
    truthObj: {
      email_0: false,
      name_0: false,
      last_name_0: false,
      address_0: false,
      phone_no_0: false,
      email_1: false,
      name_1: false,
      last_name_1: false,
      address_1: false,
      phone_no_1: false
    }
  };

  //get details of a student suing student_id
  fetchData() {
    let query = GET_ONE_STUDENT_DETAILS;
    query.variables = {
      student_id: this.props.match.params.id,
      date: this.state.selected_date,
    };
    axios
      .post(`${process.env.REACT_APP_API}`, query)
      .then(res => {
        const { getOnestudentv2 } = res.data.data;
        const parentData = {
          email: "",
          name: "",
          last_name: "",
          address: "",
          phone_no: "",
          country: "India",
          country_code: "+91",
          status: ""
        };
        let modified_parent_data = getOnestudentv2.student_details.parent.map(p => ({
          ...p,
          country: p.country || "India",
          country_code: p.phone_no && p.phone_no.includes("+") ? p.phone_no.split(" ")[0] : "+91",
          phone_no: p.phone_no ? p.phone_no.includes("+") ? p.phone_no.split(" ")[1] : p.phone_no : "",
        }));
        this.setState({
          student_details: getOnestudentv2.student_details,
          overall_journals: getOnestudentv2.journal.overall_journal_list,
          interest_categories: getOnestudentv2.interest_categories,
          requestSent: getOnestudentv2.requestSent,
          is_password_changed: getOnestudentv2.is_password_changed,
          parent: getOnestudentv2.student_details.parent.map(p => ({
            ...p,
            isFixed: true
          })),
          parentData:
            getOnestudentv2.student_details.parent.length === 0
              ? [parentData, parentData]
              : getOnestudentv2.student_details.parent.length === 1
              ? [...modified_parent_data, parentData]
              : modified_parent_data
        });
      })
      .catch(err => console.log(err));
  }

  //get all activities for a student
  fetchActivities() {
    let query = GET_ONE_STUDENT_ACTIVITIES;
    query.variables = {
      student_id: this.props.match.params.id
    };
    axios
      .post(`${process.env.REACT_APP_API}`, query)
      .then(res => {
        this.setState({
          activity_details: res.data.data.getStudentActivity.activity_details
        });
      })
      .catch(err => console.log(err));
  }

  //get all librarise for a student
  fetchLibraries() {
    let query = GET_ONE_STUDENT_LIBRARIES;
    query.variables = {
      student_id: this.props.match.params.id
    };
    axios
      .post(`${process.env.REACT_APP_API}`, query)
      .then(res => {
        this.setState({
          library_details: res.data.data.getStudentLibrary.library_details,
          loading: false
        });
      })
      .catch(err => console.log(err));
  }

  //get attendance for a student
  fetchAttendance = month => {
    let query = GET_ONE_STUDENT_ATTENDANCE;
    query.variables = {
      student_id: this.props.match.params.id,
      date: `${month}`
    };
    axios
      .post(`${process.env.REACT_APP_API}`, query)
      .then(res => {
        let dateArray = this.state.calender_details.slice();
        let cal_deets = res.data.data.webCalenderView.calender_details;
        let journalArray = this.state.journal_calender_details;
        cal_deets.forEach((item, i) => {
          dateArray.push(item.date);
        });
        this.setState({
          journal_calender_details: [...journalArray, ...cal_deets],
          calender_details: dateArray
        });
      })
      .catch(err => console.log(err));
  };

  //initiate reset password for student
  approveResetPassword = () => {
    triggerGAEvent("Students", "Approve Password reset request");
    this.setState({ loading: true });
    let mutation = INITIATE_PASSWORD_RESET;
    mutation.variables = {
      student_id: parseInt(this.props.match.params.id, 10)
    };
    axios
      .post(`${process.env.REACT_APP_API}`, mutation)
      .then(res => {
        if (res.data.data.adminAcceptResetPasswordV2.requestAccepted) {
          this.openNotification("success", "Password Reset Accepted");
          this.setState({ loading: false });
          this.fetchData();
          this.fetchActivities();
          this.fetchLibraries();
          this.getMonths();
        } else {
          this.openNotification("error", "Something Went Wrong");
        }
      })
      .catch(err => console.log(err));
  };

  // show notification dynamically
  openNotification = (type, message) => {
    if (type === "success") {
      notification["success"]({
        message: `Success`,
        description: `${message}`
      });
    } else {
      notification["error"]({
        message: "Something went wrong",
        description: `${message}`
      });
    }
  };

  getLocalStorage = () => {
    this.setState({
      loading: true,
      role_id: Number(localStorage.getItem("role_id"))
    });
  };

  onPanelChange = (value, mode) => {
    console.log(value, mode);
  };

  getMonths = () => {
    // get all months
    let allMonths = moment.months();
    // get current month and year
    let thisYear = moment().year();
    let thisMonth = moment().month();
    // get current month from the array of months
    // let getCurrentMonth = allMonths[thisMonth];
    let monthArray = [];
    // let yearArray = [];

    if (thisMonth === 0) {
      // if the month is January then get last three months by offseting year
      monthArray.push(
        `November ${thisYear - 1}`,
        `December ${thisYear - 1}`,
        `January ${thisYear}`
      );
      // this.fetchLastThreeMonths(monthArray)
    } else if (thisMonth === 1) {
      // if the month is February then get last three months by offseting year
      monthArray.push(
        `December ${thisYear - 1}`,
        `January ${thisYear}`,
        `February ${thisYear}`
      );
      // this.fetchLastThreeMonths(monthArray)
    } else {
      // for all other months subtract 1 from thisMonth and return with thisYear
      monthArray = this.getLastTwelveMonths(allMonths, thisMonth, thisYear);
      // this.fetchLastThreeMonths(monthArray)
    }
    this.fetchLastThreeMonths(monthArray);
  };

  getLastTwelveMonths = (allMonths, thisMonth, thisYear) => {
    let timesArr = [
      thisMonth,
      thisMonth - 1,
      thisMonth - 2,
      thisMonth - 3,
      thisMonth - 4,
      thisMonth - 5,
      thisMonth - 6,
      thisMonth - 7,
      thisMonth - 8,
      thisMonth - 9,
      thisMonth - 10,
      thisMonth - 11
    ];
    let localMonthArr = [];
    _.forEach(timesArr, function(value) {
      localMonthArr.push(`${allMonths[value]} ${thisYear}`);
    });
    return localMonthArr;
  };

  fetchLastThreeMonths = monthArray => {
    monthArray.forEach((month, index) => {
      setTimeout(() => {
        this.fetchAttendance(month);
      }, 1000);
    });
  };

  componentDidMount() {
    this.getLocalStorage();
    this.getMonths();
    this.fetchData();
    this.fetchActivities();
    this.fetchLibraries();
  }

  componentWillUnmount() {
    this.state.confirmDialog && this.state.confirmDialog.destroy();
  }

  openAttendanceModal = () => {
    triggerGAEvent("Students", "View Attendance Modal");
    this.setState({ open: true });
  };

  closeAttendanceModal = () => {
    triggerGAEvent("Students", "Closing Attendance Modal");
    this.setState({ open: false });
  };

  datePickerChangeHandler = (date, dateString) => {
    triggerGAEvent("Students", "Changing Date in Journal Entry");
    this.setState({ selected_date: date ? date.format("YYYY-MM-DD") : "" }, () => {
      this.fetchData();
    });
  };

  journalDateRender = (current, today) => {
    let filterData = this.state.journal_calender_details.filter(
      x => x.date === current.format("YYYY-MM-DD")
    );
    if (filterData.length !== 0) {
      return (
        <div className="ant-calendar-date journal-date">
          {current.date()}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            {filterData[0].activity_types.map(d => d.default_activities_id).includes(4) && (
              <div
                className="dot"
                style={{
                  backgroundColor: "#00B7FF",
                  height: 5,
                  width: 5,
                  margin: 4
                }}
              />
            )}
            {filterData[0].activity_types.map(d => d.default_activities_id).includes(2) && (
              <div
                className="dot"
                style={{
                  backgroundColor: "#FEC832",
                  height: 5,
                  width: 5,
                  margin: 4
                }}
              />
            )}
            {filterData[0].playlist && (
              <div
                className="dot"
                style={{
                  backgroundColor: "#9A9A9A",
                  height: 5,
                  width: 5,
                  margin: 4
                }}
              />
            )}
          </div>
        </div>
      );
    } else {
      return (
        <div className="ant-calendar-date journal-date">{current.date()}</div>
      );
    }
  };

  disableDateHandler = current => {
    return !moment().isAfter(current);
  };

  renderAttendanceDate = current => {
    if (this.state.calender_details.includes(current.format("YYYY-MM-DD"))) {
      return (
        <div
          className="ant-calendar-date present"
          style={{ border: "1px solid green", borderRadius: "50%" }}
        >
          {current.date()}
        </div>
      );
    } else {
      return <div className="ant-calendar-date">{current.date()}</div>;
    }
  };

  openFeedBackModal = (type, value) => {
    this.setState({
      lcFeedbackVisible: true,
      feedbackText: "",
      lcDetails: { ...value, type }
    });
  };

  onGeneralFeedbackSubmit = () => {
    triggerGAEvent("Students", "Adding General Feedback");
    if (
      this.state.feedbackText.length > 0 ||
      this.state.feedback_voice.length > 0
    ) {
      let mutation = STUDENT_GENERAL_FEEDBACK;
      mutation.variables = {
        student_id: parseInt(this.props.match.params.id, 10),
        feedback_type: "general",
        feedback: this.state.feedbackText,
        feedback_voice: this.state.feedback_voice
      };
      axios
        .post(`${process.env.REACT_APP_API}`, mutation)
        .then(res => {
          if (res.data.data.lcFeedback.status) {
            openNotification("success", res.data.data.lcFeedback.message);
            this.setState({
              generalFeedbackVisible: false,
              feedbackText: "",
              feedback_voice: ""
            });
          }
        })
        .catch(err => console.log(err));
    } else {
      openNotification(
        "error",
        "Enter a feedback or record an Audio to add feedback"
      );
    }
  };

  onLCFeedbackSubmit = () => {
    triggerGAEvent("Students", "Adding Feedback for Learning Content");
    if (
      this.state.feedbackText.length > 0 ||
      this.state.feedback_voice.length > 0
    ) {
      let mutation = STUDENT_LC_FEEDBACK;
      mutation.variables = {
        student_id: parseInt(this.props.match.params.id, 10),
        // feedback_type: this.state.lcDetails.type,
        feedback_type:
          this.state.lcDetails.default_activities_id === 4
            ? "Do"
            : this.state.lcDetails.default_activities_id === 1
            ? "Read"
            : this.state.lcDetails.default_activities_id === 2
            ? "Watch"
            : this.state.lcDetails.default_activities_id === 3
            ? "Listen"
            : "general",
        feedback: this.state.feedbackText,
        card_id: parseInt(this.state.lcDetails.id, 10),
        feedback_voice: this.state.feedback_voice
      };
      console.log(this.state.lcDetails);
      axios
        .post(`${process.env.REACT_APP_API}`, mutation)
        .then(res => {
          if (res.data.data.lcFeedback.status) {
            openNotification("success", res.data.data.lcFeedback.message);
            this.setState({
              lcFeedbackVisible: false,
              feedbackText: "",
              lcDetails: {},
              feedback_voice: ""
            });
          }
        })
        .catch(err => console.log(err));
    } else {
      openNotification(
        "error",
        "Enter a feedback or record an Audio to add feedback"
      );
    }
  };

  onChangeFeedbackText = e => {
    if (e.target.value.length <= 2000) {
      this.setState({ [e.target.name]: e.target.value });
    }
  };

  onTabChange = key => {
    triggerGAEvent("Students", "Changing Tabs");
  };

  onFeedbackVoiceUploadSuccess = audio_url => {
    triggerGAEvent("Students", "Recording Audio for Feedback");
    this.setState({ feedback_voice: audio_url });
  };

  handleModalCancel = () => {
    const emptyParentData = {
      email: "",
      name: "",
      last_name: "",
      address: "",
      country: "India",
      country_code: "+91",
      phone_no: "",
      status: ""
    };
    let parentData = this.state.parentData.filter(p => p.status !== "");
    this.setState({
      addParentModal: false,
      parentData:
        parentData.length === 0
          ? [emptyParentData, emptyParentData]
          : parentData.length === 1
          ? [...parentData, emptyParentData]
          : parentData
    });
  };

  updateParentData = (key, value, index) => {
    let state = { ...this.state };
    state.parentData[index] = { ...state.parentData[index], [key]: value };
    this.setState({ parentData: state.parentData });
  };

  parentEmailUpdate = () => {
    let mutation = SEND_PARENT_INVITE;
    mutation.variables = {
      invite: this.state.parentData
        .filter(
          p =>
            p.status === "" &&
            (p.email !== "" ||
              p.name !== "" ||
              p.last_name !== "" ||
              p.address !== "" ||
              p.phone_no !== "")
        )
        .map(p => ({
          first_name: p.name,
          last_name: p.last_name,
          email: p.email,
          address: p.address,
          phone_no: `${p.country_code} ${p.phone_no}`,
          country: p.country
        })),
      student_id: parseInt(this.props.match.params.id, 10)
    };
    axios
      .post(`${process.env.REACT_APP_API}`, mutation)
      .then(res => {
        if (res.data.errors) {
          openNotification("error", res.data.errors[0].message);
        } else {
          this.setState({ addParentModal: false });
          this.fetchData();
        }
      })
      .catch(err => console.log(err));
  };

  onParentAccountSetupSubmit = () => {
    triggerGAEvent("Students", "Sending Invites to Parents");
    if (this.state.parentData[0].email === this.state.parentData[1].email) {
      message.error("Enter any one Email (or) different Email IDs");
    } else {
      let truthObj = {};
      this.state.parentData.forEach((parent, index) => {
        if (parent.status === "Conformed" || parent.status === "Pending") {
          Object.keys(parent).forEach(key => {
            truthObj[`${key}_${index}`] = false;
          });
        } else if (
          parent.email !== "" ||
          parent.name !== "" ||
          parent.last_name !== "" ||
          parent.address !== "" ||
          parent.phone_no !== ""
        ) {
          Object.keys(parent).forEach(key => {
            truthObj[`${key}_${index}`] =
              parent[key] === null ? true : isRequired(parent[key]);
          });
          truthObj[`email_${index}`] = !isEmail(parent.email);
          truthObj[`status_${index}`] = false;
        }
      });
      setTimeout(() => this.setState({ truthObj: truthObj }), 500);
      setTimeout(() => this.checkErrorExistence(), 1000);
    }
  };

  checkErrorExistence = () => {
    if (_.includes(_.values(this.state.truthObj), true)) {
      openNotification("error", "Please Fill all mandatory fields correctly");
    } else {
      this.parentEmailUpdate();
    }
  };

  resendParentInvite = email => {
    triggerGAEvent("Parents", "Resending Parent Invite");
    const confirmDialog = Modal.confirm({
      title: "Do you want to resend the invite email?",
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        let query = RESEND_INVITE;
        query.variables = {
          email: email
        };
        axios
          .post(`${process.env.REACT_APP_API}`, query)
          .then(res => {
            if (res.data.errors) {
              openNotification("error", res.data.errors[0].message);
            } else {
              openNotification("success", "Invite sent successfully");
            }
          })
          .catch(err => console.log(err));
      }
    });
    this.setState({ confirmDialog });
  };
  
  removeParentInvite = email => {
    triggerGAEvent("Parents", "Resending Parent Invite");
    const confirmDialog = Modal.confirm({
      title: "Are you sure you want to remove this email ID?",
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        let query = REMOVE_PARENT_EMAIL;
        query.variables = {
          email: email
        };
        axios
          .post(`${process.env.REACT_APP_API}`, query)
          .then(res => {
            if (res.data.errors) {
              openNotification("error", res.data.errors[0].message);
            } else {
              this.fetchData();
              openNotification("success", "The email ID was successfully removed");
            }
          })
          .catch(err => console.log(err));
      }
    });
    this.setState({ confirmDialog });
  };
  
  onCountryChange = (value, index) => {
    let parent_copy = [...this.state.parentData];
    parent_copy[index].country_code = value.split("~~")[0];
    parent_copy[index].country = value.split("~~")[1];
    this.setState({ parentData: parent_copy });
  };

  render() {
    const {
      student_details,
      interest_categories,
      activity_details,
    } = this.state;
    
    return (
      <React.Fragment>
        <Loading is_loading={this.state.loading} />
        <div className="studentView">
          {this.state.role_id === 1 && (
            <div className="d-flex align-items-start justify-content-start">
              <h4
                style={{ paddingLeft: "1rem" }}
              >{`${student_details.fname || ""} ${student_details.lname || ""}`}</h4>
            </div>
          )}
          {this.state.role_id === 2 && (
            <div className="d-flex align-items-start justify-content-between">
              <h4
                style={{ paddingLeft: "1rem" }}
              >{`${student_details.fname || ""} ${student_details.lname || ""}`}</h4>
              {this.state.parent.length === 0 && (
                <h4
                  className="color-blue pointer-cursor p-1"
                  onClick={() => this.setState({ addParentModal: true })}
                >
                  Set Up Parent Account
                </h4>
              )}
              {this.state.parent.length > 0 && (
                <h4 className="p-l d-flex">
                  {this.state.parent.filter(x => x.status === "Pending")
                    .length > 0
                    ? "Parent ID Verification Pending"
                    : "Parent ID"}
                  <span className="ml-3 mr-3">{`-`}</span>
                  {this.state.parent.map((x, index) => (
                    <div>
                      {x.status === "Conformed" && (
                        <Link
                          to={{ pathname: `/parents/1/${x.email}` }}
                          key={index}
                        >
                          <Tooltip
                            title="Verified Parent ID"
                            key={index}
                            placement="top"
                          >
                            <span className="text-dark pointer-cursor">
                              {`${x.email}`}
                              {this.state.parent.length === 2 &&
                                index === 0 && (
                                  <span className="mr-3">{`,`}</span>
                                )}
                            </span>
                          </Tooltip>
                        </Link>
                      )}
                      {x.status === "Pending" && (
                        <Popover
                          placement="bottom"
                          content={
                            <div className="d-flex flex-column align-items-center justify-content-center">
                              <span className="pointer-cursor mb-2" onClick={() => this.resendParentInvite(x.email)}>
                                Resend Invite
                              </span>
                              <span className="pointer-cursor" onClick={() => this.removeParentInvite(x.email)}>
                                Remove Email
                              </span>
                            </div>
                          }
                        >
                          <span
                            className="color-red pointer-cursor"
                          >
                          {`${x.email}`}
                            {this.state.parent.length === 2 && index === 0 && (
                              <span className="mr-3">{`,`}</span>
                            )}
                        </span>
                        </Popover>
                      )}
                    </div>
                  ))}
                  {this.state.parent.length === 1 && (
                    <div>
                      <span className="ml-3 mr-3">{`|`}</span>
                      <span
                        className="color-blue pointer-cursor"
                        onClick={() => this.setState({ addParentModal: true })}
                      >
                        {" "}
                        Add Parent ID - 2
                      </span>
                    </div>
                  )}
                </h4>
              )}
            </div>
          )}
          <div style={{ padding: "1rem" }}>
            <Card
              style={{
                borderColor: "#4A90E2",
                borderWidth: 1,
                borderRadius: 5,
                paddingBottom: "0"
              }}
            >
              <Row>
                <Col span={4} style={{ fontSize: 17 }}>
                  <div className="text-uppercase">User Name</div>
                  <h5>{student_details.username}</h5>
                </Col>
                <Col span={4} style={{ fontSize: 17 }}>
                  <div className="text-uppercase">D.O.B</div>
                  <h5>{student_details.dob}</h5>
                </Col>
                <Col span={4} style={{ fontSize: 17 }}>
                  <div className="text-uppercase">Age Category</div>
                  <h5>{student_details.age_group}</h5>
                </Col>
                <Col span={8} style={{ fontSize: 17 }}>
                  <div className="text-uppercase">Learnt with Breadcrumb today</div>
                  <div style={{ padding: 0, margin: 0 }}>
                    <Tag
                      color={
                        student_details.attendance === "Present"
                          ? "green"
                          : "red"
                      }
                    >
                      {student_details.attendance=== "Present"
                          ? "Yes"
                          : "No"}
                    </Tag>
                    <span
                      style={{
                        color: "#1890ff",
                        fontWeight: 500,
                        cursor: "pointer"
                      }}
                      onClick={this.openAttendanceModal}
                    >
                      {!this.state.open ? "View all days" : ""}
                    </span>
                  </div>
                  <DatePicker
                    className="datePicker"
                    showToday={false}
                    open={this.state.open}
                    size="small"
                    placeholder=""
                    dateRender={this.renderAttendanceDate}
                    renderExtraFooter={() => (
                      <span
                        style={{
                          color: "#1890ff",
                          fontWeight: 500,
                          cursor: "pointer"
                        }}
                        onClick={this.closeAttendanceModal}
                      >
                        Close
                      </span>
                    )}
                    onPanelChange={this.onPanelChange}
                  />
                </Col>
                <Col span={4}>
                  {this.state.role_id === 1 && (
                    <div style={{ paddingBottom: 10, fontSize: 17 }}>
                      <div className="text-uppercase">Passcode Settings</div>
                      {this.state.requestSent && (
                        <h5
                          style={{ color: "#1890ff", cursor: "pointer" }}
                          onClick={this.approveResetPassword}
                        >
                          Reset Passcode
                        </h5>
                      )}
                      {!this.state.requestSent && (
                        <h6>No Request for Passcode Reset</h6>
                      )}
                    </div>
                  )}
                  {this.state.role_id === 2 && (
                    <div style={{ paddingBottom: 10, fontSize: 17 }}>
                      <div className="text-uppercase">Feedback</div>
                      <Link
                        to={{
                          pathname: `/learners/${this.props.match.params.id}/feedbacks`
                        }}
                      >
                        <span
                          style={{
                            color: "#1890ff",
                            cursor: "pointer",
                            fontWeight: 500
                          }}
                        >
                          View All Feedback
                        </span>
                      </Link>
                      <span
                        style={{
                          color: "#1890ff",
                          cursor: "pointer",
                          fontWeight: 500,
                          marginLeft: 30
                        }}
                        onClick={() =>
                          this.setState({ generalFeedbackVisible: true })
                        }
                      >
                        Add New
                      </span>
                    </div>
                  )}
                </Col>
              </Row>
            </Card>
            <hr style={{ margin: "1.5rem 0rem 0rem" }} />
            <div
              className="intCatSection"
              style={{ margin: "1.5rem 0rem 0rem 0rem" }}
            >
              {interest_categories.length > 0 && (
                <Row gutter={6} style={{}}>
                  <h5>Interest Categories</h5>
                  {interest_categories.map((intCat, index) => (
                    <Col span={6} key={intCat.id} style={{ padding: "1rem" }}>
                      <CategoryCard
                        title={intCat.name}
                        loading={this.state.loading}
                        image={intCat.background_image}
                      />
                    </Col>
                  ))}
                </Row>
              )}
            </div>
          </div>

          <hr style={{ margin: "1.5rem 1rem 0rem" }} />
          <div style={{ padding: "1rem" }}>
            <Tabs defaultActiveKey="1" onChange={this.onTabChange}>
              <TabPane tab="Contents" key="1">
                <div
                  className="activitySection"
                  style={{ margin: "1rem 0rem 0rem 0rem" }}
                >
                  {activity_details.length > 0 && (
                    <Row gutter={6}>
                      {activity_details.map((activity, index) => (
                        <Col span={8} key={index} style={{ padding: "1rem" }}>
                          <StudentCard
                            id={activity.id}
                            type="activity"
                            image={
                              activity.thumb_images[0] !== undefined
                                ? activity.thumb_images[0].image_url
                                : ""
                            }
                            title={activity.name}
                            interests={activity.interests}
                            activity_state={activity.activity_state}
                            openFeedBackModal={() =>
                              this.openFeedBackModal("activity", activity)
                            }
                            role_id={this.state.role_id}
                          />
                        </Col>
                      ))}
                    </Row>
                  )}
                  {activity_details.length < 1 && (
                    <Row gutter={6} style={{ textAlign: "center" }}>
                      <img className="mb-3" src={emptyStateActivity} alt="" />
                      <p className="FontAvenirMedium font-20 mb-2">
                        Learning contents completed by{" "}
                        {student_details.username} will show up here
                      </p>
                    </Row>
                  )}
                </div>
              </TabPane>
              {/* <TabPane tab="Libraries" key="2">
                <div className="librarySection" style={{ margin: "1rem 0rem 0rem 0rem" }}>
                  {library_details.length > 0 && (
                    <Row gutter={6}>
                      {library_details.map((library, index) => (
                        <Col span={8} key={index} style={{ padding: "1rem" }}>
                          <StudentCard
                            id={library.id}
                            type="library"
                            library_default_id={library.default_libraries_id}
                            image={library.thumb_images[0] !== undefined ? library.thumb_images[0].image_url : ""}
                            title={library.name}
                            interests={library.interests}
                            activity_state={library.activity_state}
                            openFeedBackModal={() => this.openFeedBackModal("library", library)}
                            role_id={this.state.role_id}
                          />
                        </Col>
                      ))}
                    </Row>
                  )}
                  {library_details.length < 1 && (
                    <Row gutter={6} style={{textAlign: "center"}}>
                      <img className="mb-3" src={emptyStateActivity} alt="" />
                      <p className="FontAvenirMedium font-20 mb-2">
                        Library cards of {student_details.username} will show up
                        here when available.
                      </p>
                    </Row>
                  )}
                </div>
              </TabPane> */}
              <TabPane tab="Journal Updates" key="3">
                <DatePicker
                  defaultValue={moment()}
                  value={this.state.selected_date ? moment(this.state.selected_date, "YYYY-MM-DD") : undefined}
                  disabledDate={this.disableDateHandler}
                  format="DD-MM-YYYY"
                  style={{ marginLeft: "1rem" }}
                  size="large"
                  onChange={this.datePickerChangeHandler}
                  dateRender={this.journalDateRender}
                />
                <div style={{ margin: "1rem" }}>
                  <JournalEntry
                    overall_journals={this.state.overall_journals}
                  />
                </div>
              </TabPane>
            </Tabs>
          </div>
          <Modal
            visible={this.state.generalFeedbackVisible}
            title="ADD GENERAL FEEDBACK"
            width={720}
            centered
            maskClosable={false}
            footer={[
              <div
                className="d-flex justify-content-between align-items-center pl-3 pr-3 p-1"
                key={0}
              >
                <div className="fontColor-blue font-16 FontAvenirMedium" />
                <div>
                  <Button
                    type="primary"
                    size="large"
                    key="back"
                    onClick={this.onGeneralFeedbackSubmit}
                  >
                    Add Feedback
                  </Button>
                </div>
              </div>
            ]}
            onCancel={() =>
              this.setState({
                generalFeedbackVisible: false,
                feedbackText: "",
                feedback_voice: ""
              })
            }
          >
            <div className="mb-4">
              <Input.TextArea
                style={
                  this.state.feedbackText.length >= 2000
                    ? { borderColor: "#FF0A00" }
                    : {}
                }
                autosize={{ minRows: 4, maxRows: 6 }}
                name="feedbackText"
                onChange={e => this.onChangeFeedbackText(e)}
                value={this.state.feedbackText}
                placeholder="Enter your feedback here"
              />
              <div
                style={
                  this.state.feedbackText.length >= 2000
                    ? { color: "#FF0A00", float: "right", marginTop: 8 }
                    : { float: "right", marginTop: 8 }
                }
              >
                {this.state.feedbackText.length} / 2000
              </div>
            </div>
            {this.state.generalFeedbackVisible && (
              <AudioRecord
                onSuccess={this.onFeedbackVoiceUploadSuccess}
                feedback_voice={this.state.feedback_voice}
              />
            )}
          </Modal>
          <Modal
            visible={this.state.lcFeedbackVisible}
            title={`ADD FEEDBACK FOR ${this.state.lcDetails.name}`}
            centered
            width={720}
            maskClosable={false}
            footer={[
              <div
                className="d-flex justify-content-between align-items-center pl-3 pr-3 p-1"
                key={0}
              >
                <div className="fontColor-blue font-16 FontAvenirMedium" />
                <div>
                  <Button
                    type="primary"
                    size="large"
                    onClick={this.onLCFeedbackSubmit}
                  >
                    Add Feedback
                  </Button>
                </div>
              </div>
            ]}
            onCancel={() =>
              this.setState({
                lcFeedbackVisible: false,
                feedbackText: "",
                lcDetails: {},
                feedback_voice: ""
              })
            }
          >
            <div className="mb-4">
              <Input.TextArea
                style={
                  this.state.feedbackText.length >= 2000
                    ? { borderColor: "#FF0A00" }
                    : {}
                }
                autosize={{ minRows: 4, maxRows: 6 }}
                name="feedbackText"
                onChange={e => this.onChangeFeedbackText(e)}
                value={this.state.feedbackText}
                placeholder="Enter your feedback here"
              />
              <div
                style={
                  this.state.feedbackText.length >= 2000
                    ? { color: "#FF0A00", float: "right", marginTop: 8 }
                    : { float: "right", marginTop: 8 }
                }
              >
                {this.state.feedbackText.length} / 2000
              </div>
            </div>
            {this.state.lcFeedbackVisible && (
              <AudioRecord
                onSuccess={this.onFeedbackVoiceUploadSuccess}
                feedback_voice={this.state.feedback_voice}
              />
            )}
          </Modal>
          <Modal
            visible={this.state.addParentModal}
            title="Set Up Parent Account"
            centered
            maskClosable={false}
            footer={[
              <div
                className="d-flex justify-content-between align-items-center pl-3 pr-3 p-1"
                key={0}
              >
                <div className="fontColor-blue font-16 FontAvenirMedium" />
                <div>
                  <Button
                    type="primary"
                    size="large"
                    key="back"
                    onClick={this.onParentAccountSetupSubmit}
                  >
                    Send Invite
                  </Button>
                </div>
              </div>
            ]}
            onCancel={this.handleModalCancel}
          >
            <p className="FontAvenirRoman text-secondary text-capitalize font16">
              Learner Name
            </p>
            <h4 className="FontAvenirRoman text-capitalize font18 mb-3">
              {`${student_details.fname} ${student_details.lname}`}
            </h4>
            <Tabs
              defaultActiveKey="1"
              onChange={() =>
                triggerGAEvent("Students", "Changing Tabs in Parent Modal")
              }
            >
              <TabPane tab="Parent - 1" key="1">
                <Form
                  ref={this.state.form}
                  className={
                    this.state.parentData[0].email ? "formSubmitted" : ""
                  }
                >
                  <InputC
                    title="Email ID"
                    type="text"
                    placeholder="Enter Parent's Email ID"
                    id={this.state.truthObj["email_0"] ? "has-error" : ""}
                    className="col-12 ant-input ant-input-lg mb-4 font-16"
                    titleStyle="mb-3 FontAvenirMedium text-secondary font-16 requiredAsterisk"
                    value={this.state.parentData[0].email}
                    stateKey="email"
                    validations={[email]}
                    disabled={
                      this.state.parentData[0].status === "Pending" ||
                      this.state.parentData[0].status === "Conformed"
                    }
                    onChange={(key, value) =>
                      this.updateParentData(key, value, 0)
                    }
                  />
                  <InputC
                    title="First Name"
                    type="text"
                    placeholder="Enter Parent's First Name"
                    id={this.state.truthObj["name_0"] ? "has-error" : ""}
                    className="col-12 ant-input ant-input-lg mb-4 font-16"
                    titleStyle="mb-3 FontAvenirMedium text-secondary font-16 requiredAsterisk"
                    value={this.state.parentData[0].name}
                    stateKey="name"
                    validations={[]}
                    disabled={
                      this.state.parentData[0].status === "Pending" ||
                      this.state.parentData[0].status === "Conformed"
                    }
                    onChange={(key, value) =>
                      this.updateParentData(key, value, 0)
                    }
                  />
                  <InputC
                    title="Last Name"
                    type="text"
                    placeholder="Enter Parent's Last Name"
                    id={this.state.truthObj["last_name_0"] ? "has-error" : ""}
                    className="col-12 ant-input ant-input-lg mb-4 font-16"
                    titleStyle="mb-3 FontAvenirMedium text-secondary font-16 requiredAsterisk"
                    value={this.state.parentData[0].last_name}
                    stateKey="last_name"
                    validations={[]}
                    disabled={
                      this.state.parentData[0].status === "Pending" ||
                      this.state.parentData[0].status === "Conformed"
                    }
                    onChange={(key, value) =>
                      this.updateParentData(key, value, 0)
                    }
                  />
                  <InputC
                    title="City"
                    type="text"
                    placeholder="Enter Parent's City"
                    id={this.state.truthObj["address_0"] ? "has-error" : ""}
                    className="col-12 ant-input ant-input-lg mb-4 font-16"
                    titleStyle="mb-3 FontAvenirMedium text-secondary font-16 requiredAsterisk"
                    value={this.state.parentData[0].address}
                    stateKey="address"
                    validations={[]}
                    disabled={
                      this.state.parentData[0].status === "Pending" ||
                      this.state.parentData[0].status === "Conformed"
                    }
                    onChange={(key, value) =>
                      this.updateParentData(key, value, 0)
                    }
                  />
                  <div className="position-relative w-100 mb-4">
                    <div className="mb-3 FontAvenirMedium text-uppercase text-secondary font-16 requiredAsterisk">
                      Country
                    </div>
                    <div className="position-relative ">
                    <Select
                      defaultValue="+91~~India"
                      className="w-100"
                      size="large"
                      value={`${this.state.parentData[0].country_code}~~${this.state.parentData[0].country}`}
                      onChange={(value) => this.onCountryChange(value, 0)}
                      disabled={
                        this.state.parentData[0].status === "Pending" ||
                        this.state.parentData[0].status === "Conformed"
                      }
                    >
                      {country_codes.map((code, index) => (
                        <Select.Option key={index} value={`${code.dial_code}~~${code.name}`}>{`${code.name} (${code.dial_code})`}</Select.Option>
                      ))}
                    </Select>
                    </div>
                  </div>
                  <InputC
                    title="Phone Number"
                    type="text"
                    size="large"
                    placeholder="Enter Parent's phone number"
                    id={this.state.truthObj["phone_no_0"] ? "has-error" : ""}
                    className="mb-4 font-16 w-100"
                    titleStyle="mb-3 FontAvenirMedium text-secondary font-16 requiredAsterisk"
                    value={this.state.parentData[0].phone_no}
                    stateKey="phone_no"
                    disabled={
                      this.state.parentData[0].status === "Pending" ||
                      this.state.parentData[0].status === "Conformed"
                    }
                    onChange={(key, value) =>
                      this.updateParentData(key, value, 0)
                    }
                  />
                </Form>
              </TabPane>
              <TabPane tab="Parent - 2" key="2">
                <Form
                  ref={this.state.form}
                  className={
                    this.state.parentData[1].email ? "formSubmitted" : ""
                  }
                >
                  <InputC
                    title="Email ID"
                    type="text"
                    placeholder="Enter Parent's Email ID"
                    id={this.state.truthObj["email_1"] ? "has-error" : ""}
                    className="col-12 ant-input ant-input-lg mb-4 font-16"
                    titleStyle="mb-3 FontAvenirMedium text-secondary font-16 requiredAsterisk"
                    value={this.state.parentData[1].email}
                    stateKey="email"
                    validations={[email]}
                    disabled={
                      this.state.parentData[1].status === "Pending" ||
                      this.state.parentData[1].status === "Conformed"
                    }
                    onChange={(key, value) =>
                      this.updateParentData(key, value, 1)
                    }
                  />
                  <InputC
                    title="First Name"
                    type="text"
                    placeholder="Enter Parent's First Name"
                    id={this.state.truthObj["name_1"] ? "has-error" : ""}
                    className="col-12 ant-input ant-input-lg mb-4 font-16"
                    titleStyle="mb-3 FontAvenirMedium text-secondary font-16 requiredAsterisk"
                    value={this.state.parentData[1].name}
                    stateKey="name"
                    validations={[]}
                    disabled={
                      this.state.parentData[1].status === "Pending" ||
                      this.state.parentData[1].status === "Conformed"
                    }
                    onChange={(key, value) =>
                      this.updateParentData(key, value, 1)
                    }
                  />
                  <InputC
                    title="Last Name"
                    type="text"
                    placeholder="Enter Parent's Last Name"
                    id={this.state.truthObj["last_name_1"] ? "has-error" : ""}
                    className="col-12 ant-input ant-input-lg mb-4 font-16"
                    titleStyle="mb-3 FontAvenirMedium text-secondary font-16 requiredAsterisk"
                    value={this.state.parentData[1].last_name}
                    stateKey="last_name"
                    validations={[]}
                    disabled={
                      this.state.parentData[1].status === "Pending" ||
                      this.state.parentData[1].status === "Conformed"
                    }
                    onChange={(key, value) =>
                      this.updateParentData(key, value, 1)
                    }
                  />
                  <InputC
                    title="City"
                    type="text"
                    placeholder="Enter Parent's City"
                    id={this.state.truthObj["address_1"] ? "has-error" : ""}
                    className="col-12 ant-input ant-input-lg mb-4 font-16"
                    titleStyle="mb-3 FontAvenirMedium text-secondary font-16 requiredAsterisk"
                    value={this.state.parentData[1].address}
                    stateKey="address"
                    validations={[]}
                    disabled={
                      this.state.parentData[1].status === "Pending" ||
                      this.state.parentData[1].status === "Conformed"
                    }
                    onChange={(key, value) =>
                      this.updateParentData(key, value, 1)
                    }
                  />
                  <div className="position-relative w-100 mb-4">
                    <div className="mb-3 FontAvenirMedium text-uppercase text-secondary font-16 requiredAsterisk">
                      Country
                    </div>
                    <div className="position-relative ">
                    <Select
                      defaultValue="+91~~India"
                      className="w-100"
                      size="large"
                      value={`${this.state.parentData[1].country_code}~~${this.state.parentData[1].country}`}
                      onChange={(value) => this.onCountryChange(value, 1)}
                      disabled={
                        this.state.parentData[1].status === "Pending" ||
                        this.state.parentData[1].status === "Conformed"
                      }
                    >
                      {country_codes.map((code, index) => (
                        <Select.Option key={index} value={`${code.dial_code}~~${code.name}`}>{`${code.name} (${code.dial_code})`}</Select.Option>
                      ))}
                    </Select>
                    </div>
                  </div>
                  <InputC
                    title="Phone Number"
                    type="text"
                    size="large"
                    placeholder="Enter Parent's phone number"
                    id={this.state.truthObj["phone_no_1"] ? "has-error" : ""}
                    className="mb-4 font-16 w-100"
                    titleStyle="mb-3 FontAvenirMedium text-secondary font-16 requiredAsterisk"
                    value={this.state.parentData[1].phone_no}
                    stateKey="phone_no"
                    disabled={
                      this.state.parentData[1].status === "Pending" ||
                      this.state.parentData[1].status === "Conformed"
                    }
                    onChange={(key, value) =>
                      this.updateParentData(key, value, 1)
                    }
                  />
                </Form>
              </TabPane>
            </Tabs>
            <div className="hint">
              Parents will receive an invitation e-mail. Once they verify and
              create a profile for themselves, they will be able to view their
              child's learning journey.
            </div>
          </Modal>
        </div>
      </React.Fragment>
    );
  }
}
