import React, {Component} from 'react';
import Form from "react-validation/build/form";
import InputC from "../../components/InputC";
import {confirmpassword, password, required} from "../../validation";
import {Button} from "antd";
import './index.css';


class SetUpParent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			form: React.createRef(),
			showHide: {
				showPwd: false,
				showconfirmpwd: false
			},
		}
	}
	
	showPwdClick = (key) => {
		const state = { ...this.state };
		state.showHide[key] = !state.showHide[key];
		this.setState({ showHide: state.showHide })
	};
	
	handleSubmit = (e) => {
		e.preventDefault();
		this.setState({ isSubmit: true });
		this.state.form.current.validateAll();
		setTimeout(() => {
			if (document.querySelectorAll('.form-error').length === 0) {
				this.props.nextStep();
			}
		}, 1);
	};
	
	render() {
		return (
			<Form ref={this.state.form} className={this.state.isSubmit ? "formSubmitted" : ""}>
				<h3 className="pb-3 mb-3 border-bottom FontAvenirHeavy font-18 max-dev-parentSignupTitle">
					Thanks for verifying! Let's set up your profile
				</h3>
				<h3 className="d-flex flex-column mb-3 FontAvenirHeavy text-center font-16 mbl-dev-parentSignupTitle">
							<div style={{
								paddingBottom: 5
							}}>
								Thanks for verifying!
							</div>
							<div>
								Let's set up your profile
							</div>
				</h3>
				<div className="d-flex flex-column align-items-center justify-content-center">
					<div className="mbl-parentProfile-w-90" style={{ width: 350 }}>
						<InputC
							type="email"
							placeholder="Registered Email"
							title="Registered Email"
							titleStyle="mb-3 FontAvenirMedium text-secondary font-16"
							className="col-12 ant-input ant-input-lg mb-4"
							value={this.props.data.email}
							stateKey="email"
							name="email"
							onChange={this.props.updateData}
							disabled={true}
						/>
						<InputC
							type="password"
							placeholder="Enter password"
							title="Set Password"
							titleStyle="mb-3 FontAvenirMedium text-secondary font-16"
							className="col-12 ant-input ant-input-lg mb-4"
							value={this.props.data.password}
							stateKey="password"
							name="password"
							showPwd={this.state.showHide.showpwd}
							onChange={this.props.updateData}
							showPwdClick={this.showPwdClick}
							showPwdKey="showpwd"
							validations={[required, password, confirmpassword]}
							autoFocus
						/>
						<InputC
							type="password"
							placeholder="Confirm password"
							title="Confirm Password"
							titleStyle="mb-3 FontAvenirMedium text-secondary font-16"
							name="confirm"
							showPwd={this.state.showHide.showconfirmpwd}
							className="col-12 ant-input ant-input-lg mb-4"
							value={this.props.data.confirm}
							stateKey="confirm"
							onChange={this.props.updateData}
							showPwdClick={this.showPwdClick}
							validations={[required, password, confirmpassword]}
							showPwdKey="showconfirmpwd"
						/>
						<div className="hint mb-3 pb-4 font-14 max-dev-parent-note">
							Use 8 or more characters with a mix of letters, numbers & symbols
						</div>
						<div className="hint mb-3 pb-4 font-13 mbl-dev-parent-note">
							Note: Use 8 or more characters with a mix of capital and small letters, numbers and symbols.
						</div>
					</div>
					<div className="d-flex align-items-center justify-content-end w-100 max-dev-parentSignup-Btn">
						<Button
							size="large"
							type="large"
							onClick={this.props.previousStep}
							className="pl-5 pr-5 mr-3"
						>
							BACK
						</Button>
						<Button
							size="large"
							type="primary"
							onClick={this.handleSubmit}
							className="pl-5 pr-5"
							style={{ backgroundColor: '#3EC1F0', color: '#FFFFFF', border: 'none' }}
						>
							CREATE ACCOUNT
						</Button>
					</div>
					<div className="d-flex align-items-center justify-content-between w-100 mbl-dev-parentSignup-Btn">
						<Button
							size="large"
							type="large"
							onClick={this.props.previousStep}
							className="mr-3"
							style={{ fontSize: 14, backgroundColor: '#fff', color: '#595959', width: 158 }}
						>
							BACK
						</Button>
						<Button
							size="large"
							type="primary"
							onClick={this.handleSubmit}
							style={{ fontSize: 14, fontWeight: 800, width: 158, backgroundColor: '#42C0EF', color: '#FFFFFF', border: 'none' }}
						>
							CREATE ACCOUNT
						</Button>
					</div>
				</div>
			</Form>
		);
	}
}

export default SetUpParent;
