import {
  Button,
  Card,
  Col,
  DatePicker,
  Icon,
  Input,
  Menu,
  Modal,
  Row,
  Tag,
  Tabs
} from 'antd'
import { StickyContainer, Sticky } from 'react-sticky';
import axios from 'axios/index'
import _ from 'lodash'
import moment from 'moment/moment'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import JournalEntry from '../../components/JournalEntry'
import Loading from '../../components/Loading'
import {
  GET_PARENT_DASHBOARD,
  PARENT_ADD_STUDENT,
  PARENT_CALENDER_VIEW
} from '../../graphql'
import RightArrow from '../../static/activity/icon/parentIntro/rightArrow.svg'
import RightFaceArrow from '../../static/activity/icon/parentIntro/rightFaceArrow.svg'
import TopArrow from '../../static/activity/icon/parentIntro/topArrow.svg'
import TopFaceArrow from '../../static/activity/icon/parentIntro/topFaceArrow.svg'
import noStudents from '../../static/journal/noStudents.svg'
import ProfileEmptyState from '../../static/journal/profile_emptyState.svg'
import Profile1 from '../../static/nav/parent_profile_icon.svg'
import QuestionIcon from '../../static/nav/parent_notification_icon.svg'
import ParentWarningModal from '../../components/Modals/ParentWarningModal'
import { openNotification } from '../../utils'
import TopStrip from '../../components/Modals/TopStrip'
import './ParentDashboard.css'
import WeeklyReports from './ParentDashboardComponents/WeeklyReports'

const IntroContainer = styled.div`
  .feedbackText {
    color: '#1890ff';
    cursor: 'pointer';
    fontweight: 500;
  }
  .fixedContainer {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.58);
    width: 100%;
    z-index: 2;
  }
  .fixedSliderContainer {
    display: none;
  }
  .slider {
    width: 10px;
    height: 10px;
    cursor: pointer;

    background: #c4c4c4;
    box-shadow: 0px 1px 5px rgba(255, 255, 255, 0.18);
    border-radius: 1px;
    margin-right: 12px;
  }
  .sliderContainer {
    position: fixed;
    bottom: 30px;
    width: 100%;
    z-index: 3;
  }
  .sliderActive {
    background: #3ec1f0;
  }
  .fixedSliderImageContainer {
    display: none;
  }
  .showContainer {
    z-index: 4;
    position: relative;
    .fixedSliderContainer {
      display: block;
      position: absolute;
      top: 100%;
      min-width: 436px;
    }
    & a,
    & .ant-calendar-picker {
      pointer-events: none;
    }
    &.slider4 {
      .fixedSliderContainer {
        top: 0;
        left: 16%;
        max-width: 539px;
      }
    }
    &.slider5 {
      .fixedSliderContainer {
        top: 100%;
        right: 0;
        min-width: 340px;
      }
    }
    &.slider3 {
      .fixedSliderContainer {
        right: 0;
      }
      .fixedSliderImageContainer {
        position: absolute;
        display: block;
        right: 100%;
      }
    }

    .sliderText {
      font-family: Avenir, sans-serif;
      font-style: normal;
      font-weight: 800;
      font-size: 27px;
      line-height: 40px;
      /* or 150% */

      color: #ffffff;
      white-space: initial;
    }
    .student-name-details span:first-child,
    .feedbackText {
      color: #fff;
      opacity: 1 !important;
    }
    .ant-btn-primary {
      background: #3ec1f0;
      border-color: #3ec1f0;
      font-weight: 800;
      font-size: 16px;
      line-height: 22px;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  .slider5 {
    background: transparent !important;
  }
`
const IntroSlider = styled.div`
  .ant-menu {
    padding: 8px;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .ant-menu-item {
    padding: 0 9px;
    margin-top: 3px;
    justify-content: center;
    align-items: center;
    overflow: visible;
  }
`

class ParentDashboard extends React.Component {
  constructor (props) {
    super(props)
    this.myRef = React.createRef();
    this.state = {
      is_loading: false,
      mbldatePicState: false,
      maxdatePicState: false,
      modalVisible: false,
      mblmodalVisible: false,
      warning_visible: true,
      overall_journals: [],
      journal_calender_details: [],
      calender_details: [],
      selected_student_index: 0,
      selected_student_id: 0,
      selected_date: '',
      isTour: parseInt(this.props.history.location.search.split('=')[1]) || 0,
      active_tab:
        new URLSearchParams(this.props.location.search).get(
          'weekly_reports'
        ) === 'yes'
          ? '2'
          : '1',
      students: [],
      first_name: '',
      last_name: '',
      dob: moment(),
      username: '',
      mblopen: false
    }
  }

  fetchData = () => {
    this.setState({ is_loading: true })
    let query = GET_PARENT_DASHBOARD
    query.variables = {
      date: this.state.selected_date
    }
    axios
      .post(`${process.env.REACT_APP_API}`, query)
      .then(res => {
        let selected_student_id = res.data.data.ParentDashbordV2.students
          ? res.data.data.ParentDashbordV2.students[0].id
          : ''
        let filter_data = res.data.data.ParentDashbordV2.journal.filter(
          jd => Number(jd.student_id) === selected_student_id
        )
        this.setState({
          overall_journals: filter_data.length
            ? filter_data[0].overall_journal_list
            : [],
          students: res.data.data.ParentDashbordV2.students
            ? res.data.data.ParentDashbordV2.students
            : [],
          selected_student_id: selected_student_id,
          is_loading: false
        })
      })
      .catch(err => console.log(err))
  }

  fetchJournalData = (date = '') => {
    this.setState({ is_loading: true })
    let query = GET_PARENT_DASHBOARD
    query.variables = {
      date: date
    }
    axios
      .post(`${process.env.REACT_APP_API}`, query)
      .then(res => {
        let filter_data = res.data.data.ParentDashbordV2.journal.filter(
          jd => Number(jd.student_id) === this.state.selected_student_id
        )
        this.setState({
          overall_journals: filter_data.length
            ? filter_data[0].overall_journal_list
            : [],
          is_loading: false
        })
      })
      .catch(err => console.log(err))
  }

  datePickerChangeHandler = (date, dateString) => {
    this.setState({ selected_date: date ? date.format('YYYY-MM-DD') : ''})
    setTimeout(
      () => {
        this.setState({
          mbldatePicState: false, maxdatePicState: false
        })
        this.fetchJournalData(date ? date.format('YYYY-MM-DD') : '')
    },
      200
    )
  }

  dobChangeHandler = (date, dateString) => {
    if (date.isBefore(moment().subtract(4, 'years'))) {
      this.setState({ dob: date })
    } else {
      openNotification('error', 'The minimum age of a learner is 4 years')
    }
  }

  onInputChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  getMonths = () => {
    // get all months
    let allMonths = moment.months()
    // get current month and year
    let thisYear = moment().year()
    let thisMonth = moment().month()
    // get current month from the array of months
    // let getCurrentMonth = allMonths[thisMonth];
    let monthArray = []
    // let yearArray = [];

    if (thisMonth === 0) {
      // if the month is January then get last three months by offseting year
      monthArray.push(
        `November ${thisYear - 1}`,
        `December ${thisYear - 1}`,
        `January ${thisYear}`
      )
      // this.fetchLastThreeMonths(monthArray)
    } else if (thisMonth === 1) {
      // if the month is February then get last three months by offseting year
      monthArray.push(
        `December ${thisYear - 1}`,
        `January ${thisYear}`,
        `February ${thisYear}`
      )
      // this.fetchLastThreeMonths(monthArray)
    } else {
      // for all other months subtract 1 from thisMonth and return with thisYear
      monthArray = this.getLastTwelveMonths(allMonths, thisMonth, thisYear)
      // this.fetchLastThreeMonths(monthArray)
    }
    this.fetchLastThreeMonths(monthArray)
  }

  getLastTwelveMonths = (allMonths, thisMonth, thisYear) => {
    let timesArr = [
      thisMonth,
      thisMonth - 1,
      thisMonth - 2,
      thisMonth - 3,
      thisMonth - 4,
      thisMonth - 5,
      thisMonth - 6,
      thisMonth - 7,
      thisMonth - 8,
      thisMonth - 9,
      thisMonth - 10,
      thisMonth - 11
    ]
    let localMonthArr = []
    _.forEach(timesArr, function (value) {
      localMonthArr.push(`${allMonths[value]} ${thisYear}`)
    })
    return localMonthArr
  }

  fetchLastThreeMonths = monthArray => {
    monthArray.forEach((month, index) => {
      setTimeout(() => {
        this.fetchAttendance(month)
      }, 1000)
    })
  }

  fetchAttendance = month => {
    this.setState({ loading: true })
    let query = PARENT_CALENDER_VIEW
    query.variables = {
      student_id: this.state.selected_student_id,
      date: `${month}`
    }
    axios
      .post(`${process.env.REACT_APP_API}`, query)
      .then(res => {
        let dateArray = this.state.calender_details.slice()
        let cal_deets = res.data.data.webCalenderView.calender_details
        let journalArray = this.state.journal_calender_details
        cal_deets.forEach((item, i) => {
          dateArray.push(item.date)
        })
        this.setState({
          journal_calender_details: [...journalArray, ...cal_deets],
          calender_details: dateArray,
          loading: false
        })
      })
      .catch(err => console.log(err))
  }

  onSelectStudent = e => {
    this.setState(
      {
        selected_student_id: e,
        selected_date: '',
        journal_calender_details: [],
        calender_details: [],
        overall_journals: []
      },
      () => {
        this.fetchJournalData()
        this.getMonths()
      }
    )
  }

  componentDidMount () {
    this.fetchData()
    setTimeout(() => {
      if (this.state.students.length !== 0) {
        this.getMonths()
      }
    }, 1500)
  }

  journalDateRender = (current, today) => {
    let filterData = this.state.journal_calender_details.filter(
      x => x.date === current.format('YYYY-MM-DD')
    )
    if (filterData.length !== 0) {
      return (
        <div className='ant-calendar-date journal-date'>
          {current.date()}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            {filterData[0].activity_types
              .map(d => d.default_activities_id)
              .includes(4) && (
              <div
                className='dot'
                style={{
                  backgroundColor: '#00B7FF',
                  height: 5,
                  width: 5,
                  margin: 4
                }}
              />
            )}
            {filterData[0].activity_types
              .map(d => d.default_activities_id)
              .includes(2) && (
              <div
                className='dot'
                style={{
                  backgroundColor: '#B500EC',
                  height: 5,
                  width: 5,
                  margin: 4
                }}
              />
            )}
            {filterData[0].playlist && (
              <div
                className='dot'
                style={{
                  backgroundColor: '#E07710',
                  height: 5,
                  width: 5,
                  margin: 4
                }}
              />
            )}
          </div>
        </div>
      )
    } else {
      return (
        <div className='ant-calendar-date journal-date'>{current.date()}</div>
      )
    }
  }

  renderAttendanceDate = current => {
    if (this.state.calender_details.includes(current.format('YYYY-MM-DD'))) {
      return (
        <div
          className='ant-calendar-date present'
          style={{ border: '1px solid green', borderRadius: '50%' }}
        >
          {current.date()}
        </div>
      )
    } else {
      return <div className='ant-calendar-date'>{current.date()}</div>
    }
  }

  disableDateHandler = current => {
    return !moment().isAfter(current)
  }

  disableDobHandler = current => {
    return current.isAfter(moment())
  }

  handleModalCancel = () => {
    this.setState({
      modalVisible: false,
      mblmodalVisible: false,
      first_name: '',
      last_name: '',
      dob: moment(),
      username: ''
    })
  }

  addStudent = () => {
    let mutation = PARENT_ADD_STUDENT
    mutation.variables = {
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      dob: this.state.dob.format('ll'),
      username: this.state.username
    }
    axios
      .post(`${process.env.REACT_APP_API}`, mutation)
      .then(res => {
        if (res.data.errors) {
          openNotification('error', res.data.errors[0].message)
        } else {
          this.fetchData()
          this.getMonths()
          openNotification('success', 'Learner profile created successfully')
          this.handleModalCancel()
        }
      })
      .catch(err => console.log(err))
  }

  onAddStudent = () => {
    if (
      this.state.first_name !== '' &&
      this.state.last_name !== '' &&
      this.state.username !== ''
    ) {
      if (this.state.dob.isBefore(moment().subtract(4, 'years'))) {
        this.addStudent()
      } else {
        openNotification('error', 'The minimum age of a learner is 4 years')
      }
    } else {
      openNotification('error', 'Enter all the mandatory fields')
    }
  }

  onAddStudentBtnClick = () => {
    this.setState({ modalVisible: true })
  }
  onSliderStepChange = () => {
    this.setState({ isTour: this.state.isTour + 1 }, () => {
      this.props.history.push(`/parent/dashboard?isTour=${this.state.isTour}`)
    })
  }
  onSetSliderStepChange = index => {
    this.setState({ isTour: index }, () => {
      this.props.history.push(`/parent/dashboard?isTour=${this.state.isTour}`)
    })
  }
  render () {
    const { overall_journals, students, selected_student_id } = this.state
    const renderTabBar = (props, DefaultTabBar) => (
      <Sticky topOffset={10}>
        {({style}) => (
          <DefaultTabBar {...props} className="site-custom-tab-bar" 
          style={{ ...style }} />
        )}
      </Sticky>
    );
    return (
      <IntroContainer>
        {this.state.isTour <= 6 && this.state.isTour > 0 && (
          <React.Fragment>
            <div className='fixedContainer'></div>
            <IntroSlider>
              <Menu
                style={{
                  backgroundColor: 'transparent'
                }}
              >
                <Menu.ItemGroup
                  style={{
                    float: 'right',
                    display: 'flex',
                    flexWrap: 'wrap',
                    flexDirection: 'row'
                  }}
                >
                  <Menu.Item
                    index='12'
                    className={`d-flex float-right UserDropDown ${
                      this.state.isTour === 5 ? 'showContainer' : ''
                    } slider5`}
                  >
                    <img
                      src={Profile1}
                      style={{ height: 25, width: 25, marginRight: '0.3rem' }}
                      alt='Profile'
                    />
                    <div className='fixedSliderContainer '>
                      <div className='mr-4'>
                        <div className='text-right'>
                          <img
                            className='ml-4'
                            src={RightFaceArrow}
                            alt='Top Arrow'
                          />
                        </div>

                        <div className='sliderText mt-3 mb-3'>
                          You can edit your profile details here.
                        </div>
                        <div className='w-100 justify-content-end d-flex pr-5'>
                          <Button
                            size='large'
                            type='primary'
                            onClick={this.onSliderStepChange}
                          >
                            Next
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Menu.Item>
                  <Menu.Item
                    index='10'
                    className={`d-flex float-right UserDropDown ${
                      this.state.isTour === 6 ? 'showContainer' : ''
                    } slider5`}
                  >
                    <div>
                      <img
                        src={QuestionIcon}
                        style={{ height: 25, width: 25, marginRight: '0.5rem' }}
                        alt='Question'
                      />
                      <div className='fixedSliderContainer '>
                        <div className='mr-4'>
                          <div className='text-right'>
                            <img
                              className='ml-4'
                              src={RightFaceArrow}
                              alt='Top Arrow'
                            />
                          </div>

                          <div className='sliderText mt-3 mb-3'>
                            This will take you through this tour again.
                          </div>
                          <div className='w-100 justify-content-end d-flex pr-5'>
                            <Button
                              size='large'
                              type='primary'
                              onClick={() => {
                                window.location.href = `/parent/dashboard`
                              }}
                            >
                              Got it
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Menu.Item>
                </Menu.ItemGroup>
              </Menu>
            </IntroSlider>
            <div className='d-flex align-items-center justify-content-center sliderContainer'>
              <div
                className={`slider ${
                  this.state.isTour === 1 ? 'sliderActive' : ''
                }`}
                onClick={() => {
                  this.onSetSliderStepChange(1)
                }}
              />
              <div
                className={`slider ${
                  this.state.isTour === 2 ? 'sliderActive' : ''
                }`}
                onClick={() => {
                  this.onSetSliderStepChange(2)
                }}
              />
              <div
                className={`slider ${
                  this.state.isTour === 3 ? 'sliderActive' : ''
                }`}
                onClick={() => {
                  this.onSetSliderStepChange(3)
                }}
              />
              <div
                className={`slider ${
                  this.state.isTour === 4 ? 'sliderActive' : ''
                }`}
                onClick={() => {
                  this.onSetSliderStepChange(4)
                }}
              />
              <div
                className={`slider ${
                  this.state.isTour === 5 ? 'sliderActive' : ''
                }`}
                onClick={() => {
                  this.onSetSliderStepChange(5)
                }}
              />
            </div>
          </React.Fragment>
        )}
        {/* <TopStrip />
        <ParentWarningModal
          visible={this.state.warning_visible}
          setVisible={(value) => this.setState({ warning_visible: value })}
        /> */}
        <Loading is_loading={this.state.is_loading} />
        {!this.state.is_loading && this.state.students.length === 0 && (
          <div className='no-students d-flex align-items-center justify-content-center flex-column'>
            <img
              src={noStudents}
              alt='No learners'
              style={{ maxWidth: '100vw' }}
            />
            <Button
              block
              type='primary'
              style={{ width: 350, marginTop: 40 }}
              size='large'
              onClick={this.onAddStudentBtnClick}
            >
              SETUP CHILD ACCOUNT
            </Button>
          </div>
        )}
        {this.state.students.length !== 0 && (
          <React.Fragment>
              {/* mobile view student list */}
            <div className='mbl-students-det'>
              {/* Mbl top view title and names */}
              <div className='d-flex justify-content-between studentView mb-2'>
                <div className='font-16 text-black font-weight-800'>
                  Learner details
                </div>
                {
                  (this.state.students && this.state.students.length < 4) && (
                    <React.Fragment>
                      <div
                        className={`mbl-student-container d-flex align-items-center justify-content-start pointer-cursor`}
                        onClick={() =>
                          this.state.isTour === 0
                            ? this.setState({
                                mblmodalVisible: true
                              })
                            : null
                        }>
                          <div className='student-name-plus d-flex align-items-center justify-content-center'>
                            <Icon type='plus' style={{ color: '#0076FF' }} />
                          </div>
                          <div className='student-name-details d-flex flex-column align-items-start justify-content-start'>
                            <span>Add child</span>
                          </div>
                      </div>
                      <div
                          className={`max-student-container d-flex align-items-center justify-content-start pointer-cursor`}
                          onClick={() =>
                            this.state.isTour === 0
                              ? this.onAddStudentBtnClick()
                              : null
                          }
                        >
                          <div className='student-name-plus d-flex align-items-center justify-content-center'>
                            <Icon type='plus' style={{ color: '#0076FF' }} />
                          </div>
                          <div className='student-name-details d-flex flex-column align-items-start justify-content-start'>
                            <span>Add child</span>
                          </div>
                      </div>
                    </React.Fragment>
                  )
                }
              </div>
              {/* mbl child names */}
              <div className='d-flex mbl-dev-student-name'>
                  {this.state.students.map((student, index) => (
                    <div
                      onClick={() => {
                        this.onSelectStudent(student.id)
                        var elem = document.getElementById(`StudName-${index}`);
                        elem.scrollIntoView()
                      }
                      }
                      key={index}
                      className="w-100"
                      id={`StudName-${index}`}
                    >
                      <div
                        className={`student-name-container1 d-flex align-items-center justify-content-start pointer-cursor ${
                          student.id === selected_student_id
                            ? 'mbl-student-selected'
                            : ''
                        }`}
                      >
                        <div className='student-name-image d-flex align-items-center justify-content-center'>
                        <img class="ChildrenImgFilter-det" 
                            src={student.student_details.profile_imageUrl 
                              ? student.student_details.profile_imageUrl : ProfileEmptyState
                            } 
                            alt="leaner profile image" 
                            onError={e => {
                              e.target.onerror = null
                              e.target.src = ProfileEmptyState
                            }}
                          />
                          {/* <Icon type='user' /> */}
                        </div>
                        <div className='mbl-stud-name d-flex flex-column align-items-start justify-content-start'>
                          <span>{`${student.student_details.fname} ${student.student_details.lname}`}</span>
                          {/* <span>{`Child ${index + 1}`}</span> */}
                        </div>
                      </div>
                    </div>
                  ))}
                  {this.state.students.length < 4 && (
                    <div
                      className={this.state.isTour === 1 ? 'showContainer' : ''}
                    >
                      <div className='fixedSliderContainer'>
                        <div className='ml-4'>
                          <img
                            className='ml-4'
                            src={TopArrow}
                            alt='Top Arrow'
                          />
                          <div className='sliderText mt-3 mb-3'>
                            Add child will let you create another learner
                            profile on the app
                          </div>
                          <div className='w-100 justify-content-end d-flex'>
                            <Button
                              size='large'
                              type='primary'
                              onClick={this.onSliderStepChange}
                            >
                              Next
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
            </div>
            <div className='studentView'>
              <div className='d-flex student-list'>
                {this.state.students.map((student, index) => (
                  <div
                    onClick={() => this.onSelectStudent(student.id)}
                    key={index}
                  >
                    <div
                      className={`student-name-container d-flex align-items-center justify-content-start pointer-cursor ${
                        student.id === selected_student_id
                          ? 'student-selected'
                          : ''
                      }`}
                    >
                      <div className='student-name-image d-flex align-items-center justify-content-center'>
                        <img class="ChildrenImgFilter-det" 
                        src={student.student_details.profile_imageUrl 
                          ? student.student_details.profile_imageUrl : ProfileEmptyState
                        } 
                        alt="leaner profile image" 
                        onError={e => {
                          e.target.onerror = null
                          e.target.src = ProfileEmptyState
                        }}
                        />
                        {/* <Icon type='user' /> */}
                      </div>
                      <div className='student-name-details d-flex flex-column align-items-start justify-content-start'>
                        <span>{`${student.student_details.fname} ${student.student_details.lname}`}</span>
                        <span>{`Child ${index + 1}`}</span>
                      </div>
                    </div>
                  </div>
                ))}
                {this.state.students.length < 4 && (
                  <div
                    className={this.state.isTour === 1 ? 'showContainer' : ''}
                  >
                    <div className='fixedSliderContainer'>
                      <div className='ml-4'>
                        <img className='ml-4' src={TopArrow} alt='Top Arrow' />
                        <div className='sliderText mt-3 mb-3'>
                          Add child will let you create another learner profile
                          on the app
                        </div>
                        <div className='w-100 justify-content-end d-flex'>
                          <Button
                            size='large'
                            type='primary'
                            onClick={this.onSliderStepChange}
                          >
                            Next
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`student-name-container d-flex align-items-center justify-content-start pointer-cursor`}
                      onClick={() =>
                        this.state.isTour === 0
                          ? this.onAddStudentBtnClick()
                          : null
                      }
                    >
                      <div className='student-name-plus d-flex align-items-center justify-content-center'>
                        <Icon type='plus' />
                      </div>
                      <div className='student-name-details d-flex flex-column align-items-start justify-content-start'>
                        <span style={{ opacity: '0.3' }}>Add child</span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className='mbl-studdet' style={{ padding: '1rem' }}>
                <div className="mbl-m-0" style={{ margin: '2rem 0' }}>
                  {students.filter(stud => stud.id === selected_student_id)
                    .length && (
                    <h4 className="mbl-studname-head">
                      <span style={{
                            textTransform: 'capitalize'
                          }}>
                            {`${
                      students.filter(
                        stud => stud.id === selected_student_id
                      )[0].student_details.fname
                    }'s`}
                      </span>
                      {` Learning Journey`}</h4>
                  )}
                </div>
                {students.filter(stud => stud.id === selected_student_id)
                  .length ? (
                  <Card
                    style={{
                      borderColor: '#4A90E2',
                      borderWidth: 1,
                      borderRadius: 5,
                      paddingBottom: '0'
                    }}
                  >
                    {/* mbl card details */}
                    <div className="mbl-dev-carddet">
                      <div className="row m-0 mb-2">
                        <div className="col-4 p-0">
                          <div className='text-uppercase mbl-child-title'>User Name</div>
                          <h5 className='mb-0 mbl-child-name'>
                            {
                              students.filter(
                                stud => stud.id === selected_student_id
                              )[0].student_details.username
                            }
                          </h5>
                        </div>
                        <div className="col-4 p-0">
                          <div className='text-uppercase mbl-child-title'>D.O.B</div>
                          <h5 className='mb-0 mbl-child-name'>
                            {
                              students.filter(
                                stud => stud.id === selected_student_id
                              )[0].student_details.dob
                            }
                          </h5>
                        </div>
                        <div className="col-4 p-0">
                          <div className='text-uppercase mbl-child-title'>Age Category</div>
                          <h5 className='mb-0 mbl-child-name'>
                            {
                              students.filter(
                                stud => stud.id === selected_student_id
                              )[0].student_details.age_group
                            }
                          </h5>
                        </div>
                      </div>
                      <div className="row m-0 mt-3 mb-3">
                        <div>
                          <div className='text-uppercase mbl-child-title'>
                            Learnt with Breadcrumb today
                          </div>
                          <div
                            style={{ padding: 0, margin: 0 }}
                            className='d-flex'
                          >
                            <div
                              className={
                                this.state.isTour === 2 ? 'showContainer' : ''
                              }
                            >
                              <div className='fixedSliderContainer'>
                                <div className='pt-2'>
                                  <img
                                    className='ml-2'
                                    src={TopArrow}
                                    alt='Top Arrow'
                                  />
                                  <div className='sliderText mt-3 mb-3'>
                                    This helps you understand whether your child
                                    has logged in today or not.
                                  </div>
                                  <div className='w-100 justify-content-center d-flex'>
                                    <Button
                                      size='large'
                                      type='primary'
                                      onClick={this.onSliderStepChange}
                                    >
                                      Next
                                    </Button>
                                  </div>
                                </div>
                              </div>
                              <Tag
                              className={`mbl-child-${students.filter(
                                stud => stud.id === selected_student_id
                              )[0].student_details.attendance === 'Present'
                                ? 'green'
                                : 'red'}tag`}
                                color={
                                  students.filter(
                                    stud => stud.id === selected_student_id
                                  )[0].student_details.attendance === 'Present'
                                    ? 'green'
                                    : 'red'
                                }
                              >
                                {students.filter(
                                  stud => stud.id === selected_student_id
                                )[0].student_details.attendance === 'Present'
                                  ? 'Yes'
                                  : 'No'}
                              </Tag>
                            </div>
                            <span
                              style={{
                                color: '#0076FF',
                                fontWeight: 500,
                                cursor: 'pointer',
                                fontSize: 14
                              }}
                              onClick={() =>
                                this.setState({ open: !this.state.open })
                              }
                            >
                              {!this.state.mblopen ? 'View all days' : ''}
                            </span>
                          </div>
                          {this.state.mblopen && (
                            <DatePicker
                              className='datePicker'
                              showToday={false}
                              open={this.state.mblopen}
                              size='small'
                              placeholder=''
                              renderExtraFooter={() => (
                                <span
                                  className='color-blue cursor-pointer'
                                  onClick={() => this.setState({ mblopen: false })}
                                >
                                  Close
                                </span>
                              )}
                              dateRender={this.renderAttendanceDate}
                              onPanelChange={this.onPanelChange}
                            />
                          )}
                        </div>
                      </div>
                      <div className="row m-0">
                        <div>
                          <div className='text-uppercase mbl-child-title'>Feedback</div>
                          {/*showContainer*/}
                          <div
                            className={` ${
                              this.state.isTour === 3 ? 'showContainer' : ''
                            }

                            slider3 d-inline"
                            `}
                          >
                            <Link
                              to={{
                                pathname: `/parent/feedback/${
                                  students.filter(
                                    stud => stud.id === selected_student_id
                                  )[0].id
                                }`
                              }}
                            >
                              <span className='feedbackText'>
                                View all
                              </span>
                            </Link>
                            <div className='fixedSliderImageContainer'>
                              <img
                                className='ml-2'
                                src={TopFaceArrow}
                                alt='Top Arrow'
                              />
                            </div>
                            <div className='fixedSliderContainer'>
                              <div className='pt-2'>
                                <div style={{ height: '69px' }}></div>
                                <div className='sliderText mt-3 mb-3'>
                                  Helps you view all the feedback your child has
                                  received from experts.
                                </div>
                                <div className='w-100 justify-content-center d-flex'>
                                  <Button
                                    size='large'
                                    type='primary'
                                    onClick={this.onSliderStepChange}
                                  >
                                    Next
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* max device card details */}
                    <Row className="max-dev-carddet">
                      <Col span={4} style={{ fontSize: 17 }}>
                        <div className='text-uppercase'>User Name</div>
                        <h5 className='mb-0'>
                          {
                            students.filter(
                              stud => stud.id === selected_student_id
                            )[0].student_details.username
                          }
                        </h5>
                      </Col>
                      <Col span={4} style={{ fontSize: 17 }}>
                        <div className='text-uppercase'>D.O.B</div>
                        <h5 className='mb-0'>
                          {
                            students.filter(
                              stud => stud.id === selected_student_id
                            )[0].student_details.dob
                          }
                        </h5>
                      </Col>
                      <Col span={4} style={{ fontSize: 17 }}>
                        <div className='text-uppercase'>Age Category</div>
                        <h5 className='mb-0'>
                          {
                            students.filter(
                              stud => stud.id === selected_student_id
                            )[0].student_details.age_group
                          }
                        </h5>
                      </Col>
                      <Col span={8} style={{ fontSize: 17 }}>
                        <div className='text-uppercase'>
                          Learnt with Breadcrumb today
                        </div>
                        <div
                          style={{ padding: 0, margin: 0 }}
                          className='d-flex'
                        >
                          <div
                            className={
                              this.state.isTour === 2 ? 'showContainer' : ''
                            }
                          >
                            <div className='fixedSliderContainer'>
                              <div className='pt-2'>
                                <img
                                  className='ml-2'
                                  src={TopArrow}
                                  alt='Top Arrow'
                                />
                                <div className='sliderText mt-3 mb-3'>
                                  This helps you understand whether your child
                                  has logged in today or not.
                                </div>
                                <div className='w-100 justify-content-center d-flex'>
                                  <Button
                                    size='large'
                                    type='primary'
                                    onClick={this.onSliderStepChange}
                                  >
                                    Next
                                  </Button>
                                </div>
                              </div>
                            </div>

                            <Tag
                              color={
                                students.filter(
                                  stud => stud.id === selected_student_id
                                )[0].student_details.attendance === 'Present'
                                  ? 'green'
                                  : 'red'
                              }
                            >
                              {students.filter(
                                stud => stud.id === selected_student_id
                              )[0].student_details.attendance === 'Present'
                                ? 'Yes'
                                : 'No'}
                            </Tag>
                          </div>
                          <span
                            style={{
                              color: '#1890ff',
                              fontWeight: 500,
                              cursor: 'pointer'
                            }}
                            onClick={() =>
                              this.setState({ open: !this.state.open })
                            }
                          >
                            {!this.state.open ? 'View all days' : ''}
                          </span>
                        </div>
                        {this.state.open && (
                          <DatePicker
                            className='datePicker'
                            showToday={false}
                            open={this.state.open}
                            size='small'
                            placeholder=''
                            renderExtraFooter={() => (
                              <span
                                className='color-blue cursor-pointer'
                                onClick={() => this.setState({ open: false })}
                              >
                                Close
                              </span>
                            )}
                            dateRender={this.renderAttendanceDate}
                            onPanelChange={this.onPanelChange}
                          />
                        )}
                      </Col>
                      <Col span={4}>
                        <div style={{ fontSize: 17 }}>
                          <div className='text-uppercase'>Feedback</div>
                          {/*showContainer*/}
                          <div
                            className={` ${
                              this.state.isTour === 3 ? 'showContainer' : ''
                            }

                            slider3 d-inline"
                            `}
                          >
                            <Link
                              to={{
                                pathname: `/parent/feedback/${
                                  students.filter(
                                    stud => stud.id === selected_student_id
                                  )[0].id
                                }`
                              }}
                            >
                              <span className='feedbackText'>
                                View All Feedback
                              </span>
                            </Link>
                            <div className='fixedSliderImageContainer'>
                              <img
                                className='ml-2'
                                src={TopFaceArrow}
                                alt='Top Arrow'
                              />
                            </div>
                            <div className='fixedSliderContainer'>
                              <div className='pt-2'>
                                <div style={{ height: '69px' }}></div>
                                <div className='sliderText mt-3 mb-3'>
                                  Helps you view all the feedback your child has
                                  received from experts.
                                </div>
                                <div className='w-100 justify-content-center d-flex'>
                                  <Button
                                    size='large'
                                    type='primary'
                                    onClick={this.onSliderStepChange}
                                  >
                                    Next
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Card>
                ) : null}
              </div>
              <hr className="mbl-border-margin" style={{ margin: '1.5rem 0rem 1.5rem 0rem' }} />
              {/* Max dev tabs view  */}
              <Tabs
                activeKey={this.state.active_tab}
                onChange={activeKey => this.setState({ active_tab: activeKey })}
                className="max-dev-ChildsTab"
              >
                <Tabs.TabPane key='1' tab='Journal Updates'>
                  <div
                    className={`${
                      this.state.isTour === 4 ? 'showContainer' : ''
                    } slider4`}
                  >
                    <div className='fixedSliderContainer'>
                      <div className='pl-3 d-flex align-items-start'>
                        <img
                          className='mr-3'
                          src={RightArrow}
                          alt='Top Arrow'
                        />
                        <div>
                          <div className='sliderText mt-3 mb-3'>
                            You can navigate to different dates and see a
                            calender view of all the journal updates your child
                            has made.
                          </div>
                          <div className='w-100 justify-content-center d-flex pl-5'>
                            <div className='pl-5'>
                              <Button
                                size='large'
                                type='primary'
                                onClick={this.onSliderStepChange}
                              >
                                Next
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div onClick={() => this.setState({
                      maxdatePicState: true
                    })}>
                      <DatePicker
                        defaultValue={moment()}
                        value={
                          this.state.selected_date
                            ? moment(this.state.selected_date, 'YYYY-MM-DD')
                            : undefined
                        }
                        disabledDate={this.disableDateHandler}
                        open={this.state.maxdatePicState}
                        showToday={false}
                        renderExtraFooter={ (mode) =>
                          (<React.Fragment>
                            <div className="d-flex align-items-center justify-content-center">
                              <div style={{
                                color: '#1890ff',
                                cursor: 'pointer'
                              }}
                              onClick={
                                () => this.setState({
                                  selected_date: moment().format('YYYY-MM-DD')
                                }, () => {
                                  setTimeout(() => this.fetchJournalData(this.state.selected_date ? this.state.selected_date : ''), 200)
                                  this.setState({
                                    maxdatePicState: false
                                  });
                                })
                              }
                              >Today</div>
                            </div>
                          </React.Fragment>)
                        }
                        format='DD-MM-YYYY'
                        style={{ marginLeft: '1rem' }}
                        size='large'
                        onChange={this.datePickerChangeHandler}
                        dateRender={this.journalDateRender}
                      />
                    </div>
                  </div>
                  <div style={{ padding: '1rem' }}>
                    <JournalEntry
                      overall_journals={overall_journals}
                      role_id={3}
                    />
                  </div>
                </Tabs.TabPane>
                <Tabs.TabPane key='2' tab='Weekly Updates'>
                  {this.state.selected_student_id && (
                    <div style={{ padding: '1rem' }}>
                      <WeeklyReports
                        students_id={this.state.selected_student_id}
                        start_date={new URLSearchParams(
                          this.props.location.search
                        ).get('start_date')}
                        end_date={new URLSearchParams(
                          this.props.location.search
                        ).get('end_date')}
                      />
                    </div>
                  )}
                </Tabs.TabPane>
              </Tabs>
            </div>
              {/* Mbl dev tabs view  */}
          <StickyContainer>
            <Tabs
                activeKey={this.state.active_tab}
                onChange={activeKey => this.setState({ active_tab: activeKey })}
                className="mbl-dev-ChildsTab"
                renderTabBar={renderTabBar}
              >
                <Tabs.TabPane key='1' tab='Journal Updates'>
                  <div
                    className={`${
                      this.state.isTour === 4 ? 'showContainer' : ''
                    } slider4`}
                  >
                    <div className='fixedSliderContainer'>
                      <div className='pl-3 d-flex align-items-start'>
                        <img
                          className='mr-3'
                          src={RightArrow}
                          alt='Top Arrow'
                        />
                        <div>
                          <div className='sliderText mt-3 mb-3'>
                            You can navigate to different dates and see a
                            calender view of all the journal updates your child
                            has made.
                          </div>
                          <div className='w-100 justify-content-center d-flex pl-5'>
                            <div className='pl-5'>
                              <Button
                                size='large'
                                type='primary'
                                onClick={this.onSliderStepChange}
                              >
                                Next
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div onClick={() => this.setState({
                      mbldatePicState: true
                    })}>
                    <DatePicker
                      defaultValue={moment()}
                      value={
                        this.state.selected_date
                          ? moment(this.state.selected_date, 'YYYY-MM-DD')
                          : undefined
                      }
                      disabledDate={this.disableDateHandler}
                      format='DD-MM-YYYY'
                      open={this.state.mbldatePicState}
                      // style={{ marginLeft: '1rem' }}
                      size='large'
                      showToday={false}
                      renderExtraFooter={ (mode) =>
                        (<React.Fragment>
                          <div className="d-flex align-items-center justify-content-center">
                            <div style={{
                              color: '#1890ff',
                              cursor: 'pointer'
                            }}
                            onClick={
                              () => this.setState({
                                selected_date: moment().format('YYYY-MM-DD')
                              }, () => {
                                setTimeout(() => this.fetchJournalData(this.state.selected_date ? this.state.selected_date : ''), 200)
                                this.setState({
                                  mbldatePicState: false
                                });
                              })
                            }
                            >Today</div>
                          </div>
                        </React.Fragment>)
                      }
                      onChange={this.datePickerChangeHandler}
                      dateRender={this.journalDateRender}
                      className="jounalTabDatepic"
                      onPanelChange={this.onPanelChange}
                    />
                    </div>
                  </div>
                  <div className="pt-2">
                    <JournalEntry
                      overall_journals={overall_journals}
                      role_id={3}
                      student_name={
                        students.filter(stud => stud.id === selected_student_id)
                          .length && 
                            students.filter(
                              stud => stud.id === selected_student_id
                            )[0].student_details.fname
                      }
                      date={this.state.selected_date}
                      is_loading={this.state.is_loading}
                    />
                  </div>
                </Tabs.TabPane>
                <Tabs.TabPane key='2' tab='Weekly Updates'>
                  {this.state.selected_student_id && (
                    <div className="pt-2">
                      <WeeklyReports
                        students_id={this.state.selected_student_id}
                        start_date={new URLSearchParams(
                          this.props.location.search
                        ).get('start_date')}
                        end_date={new URLSearchParams(
                          this.props.location.search
                        ).get('end_date')}
                        student_name={
                          students.filter(stud => stud.id === selected_student_id)
                            .length && 
                              students.filter(
                                stud => stud.id === selected_student_id
                              )[0].student_details.username
                        }
                      />
                    </div>
                  )}
                </Tabs.TabPane>
              </Tabs>
            </StickyContainer>
          </React.Fragment>
        )}
        {/* Mbl device modal popup */}
        <Modal
          title='Let’s setup your child’s profile'
          visible={this.state.mblmodalVisible}
          maskClosable={false}
          centered={true}
          onCancel={this.handleModalCancel}
          className='mbl-dev-Childmodal'
          width={400}
          footer={
            <div className='d-flex p-2 justify-content-end'>
              <div className='m-2'>
                <Button
                  key='1'
                  block
                  size='large'
                  onClick={() => {
                    this.setState({
                      mblmodalVisible: false
                    })
                  }}
                >
                  Cancel
                </Button>
              </div>
              <div className='m-2'>
                <Button
                  key='1'
                  block
                  size='large'
                  type='primary'
                  onClick={this.onAddStudent}
                >
                  {/* Setup Account */}
                  Save changes
                </Button>
              </div>
            </div>
          }
        >
          {/* <div className="mb-4">
            <div className="d-flex w-100 justify-content-center align-items-center">
              {this.state.first_name === "" ? (
                <div className="student-plus d-flex flex-column justify-content-center align-items-center">
                  <Icon type="plus" />
                </div>
              ) : (
                <div className="student-image d-flex flex-column justify-content-center align-items-center">
                  <span>{this.state.first_name.charAt(0).toUpperCase()}</span>
                </div>
              )}
            </div>
          </div> */}
          <div className='mb-2'>
            <div className='FontAvenirRoman text-secondary font-16 requiredAsterisk mb-5px'>
              Child's First Name
            </div>
            <Input
              name='first_name'
              onChange={this.onInputChange}
              value={this.state.first_name}
              size='large'
              placeholder='Add Full Name'
            />
          </div>
          <div className='mb-2'>
            <div className='FontAvenirRoman text-secondary font-16 requiredAsterisk mb-5px'>
              Child's Last Name
            </div>
            <Input
              name='last_name'
              onChange={this.onInputChange}
              value={this.state.last_name}
              size='large'
              placeholder='Add Last Name'
            />
          </div>
          <div className='mb-2'>
            <div className='FontAvenirRoman text-secondary font-16 requiredAsterisk mb-5px'>
              Date Of Birth
            </div>
            <DatePicker
              defaultValue={moment()}
              value={this.state.dob}
              disabledDate={this.disableDobHandler}
              format='DD-MM-YYYY'
              size='large'
              className='w-100'
              onChange={this.dobChangeHandler}
            />
          </div>
          <div className='mb-2'>
            <div className='FontAvenirRoman text-secondary font-16 requiredAsterisk mb-5px'>
              Preferred Username
            </div>
            <Input
              name='username'
              onChange={this.onInputChange}
              value={this.state.username}
              size='large'
              placeholder='Add username'
            />
          </div>
          <div className='hint' style={{ margin: 0 }}>
            Once you setup the account, share the username with your child so
            that they can create an account on the iPad
          </div>
        </Modal>
        {/* Max device modal popup */}
        <Modal
          title='Let’s setup your child’s profile'
          visible={this.state.modalVisible}
          maskClosable={false}
          onCancel={this.handleModalCancel}
          width={400}
          className='max-dev-Childmodal'
          footer={[
            <Button
              key='1'
              block
              size='large'
              type='primary'
              onClick={this.onAddStudent}
            >
              Setup Account
            </Button>
          ]}
        >
          <div className='mb-4'>
            <div className='d-flex w-100 justify-content-center align-items-center'>
              {this.state.first_name === '' ? (
                <div className='student-plus d-flex flex-column justify-content-center align-items-center'>
                  <Icon type='plus' />
                </div>
              ) : (
                <div className='student-image d-flex flex-column justify-content-center align-items-center'>
                  <span>{this.state.first_name.charAt(0).toUpperCase()}</span>
                </div>
              )}
            </div>
          </div>
          <div className='mb-3'>
            <div className='FontAvenirRoman text-secondary font-16 text-uppercase requiredAsterisk mb-8px'>
              Child's First Name
            </div>
            <Input
              name='first_name'
              onChange={this.onInputChange}
              value={this.state.first_name}
              size='large'
              placeholder='Add Full Name'
            />
          </div>
          <div className='mb-3'>
            <div className='FontAvenirRoman text-secondary font-16 text-uppercase requiredAsterisk mb-8px'>
              Child's Last Name
            </div>
            <Input
              name='last_name'
              onChange={this.onInputChange}
              value={this.state.last_name}
              size='large'
              placeholder='Add Last Name'
            />
          </div>
          <div className='mb-3'>
            <div className='FontAvenirRoman text-secondary font-16 text-uppercase requiredAsterisk mb-8px'>
              Date of Birth
            </div>
            <DatePicker
              defaultValue={moment()}
              value={this.state.dob}
              disabledDate={this.disableDobHandler}
              format='DD-MM-YYYY'
              size='large'
              className='w-100'
              onChange={this.dobChangeHandler}
            />
          </div>
          <div className='mb-3'>
            <div className='FontAvenirRoman text-secondary font-16 text-uppercase requiredAsterisk mb-8px'>
              Preferred Username
            </div>
            <Input
              name='username'
              onChange={this.onInputChange}
              value={this.state.username}
              size='large'
              placeholder='Add username'
            />
          </div>
          <div className='hint' style={{ margin: 0 }}>
            Once you setup the account, share the username with your child so
            that they can create an account on the iPad
          </div>
        </Modal>
      </IntroContainer>
    )
  }
}

export default ParentDashboard
