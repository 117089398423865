import {
  Avatar,
  Button,
  Card,
  Col,
  Input,
  Menu,
  Modal,
  Row,
  Select,
  Tag
} from "antd";
import axios from "axios";
import _ from "lodash";
import React, { Component } from "react";
import { Prompt } from "react-router-dom";
import ImageUploadCrop from "../../components/ImageUploadCrop";
import Loading from "../../components/Loading";
import Sort from "../../components/Sort";
import {
  ADD_INTEREST,
  DELETE_INTEREST,
  EDIT_INTEREST,
  GET_GLOBAL_INTEREST_CATEGORY,
  GET_INTERESTS,
  GET_ONE_INTEREST,
  SUGGEST_INTEREST
} from "../../graphql";
import Error500Activity from "../../static/activity/500Err.svg";
import emptyStateActivity from "../../static/activity/emptystateactivity.svg";
import emptyState from "../../static/activity/emptyStateSearch.png";
import ErrorActivity from "../../static/activity/error.svg";
import {
  openNotification,
  showNotification,
  triggerGAEvent
} from "../../utils";
import "./index.css";

const Search = Input.Search;
const Option = Select.Option;
const { Meta } = Card;

export default class InterestView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmDialog: null,
      isFormSubmitting: false,
      shouldBlockNavigation: true,
      loading: false,
      visible: false,
      suggest_visible: false,
      modalType: "",
      interestCategories: [],
      interests: [],
      interestCatName: "",
      interestDeets: null,
      newCatName: "",
      newCatDesc: "",
      interest_categories_id: null,
      interest_id: null,
      thumb_image: "",
      searchText: "",
      pageTitle: "",
      sort: "newest",
      onSearchFetch: false,
      truthObj: {
        newCatName: false,
        newCatDesc: false,
        interest_categories_id: false,
        thumb_image: false,
      },
    };
    this.onSearchFetchData = _.debounce(this.onSearchFetchData, 700);
  }

  fetchData = () => {
    this.setState({
      loading: true,
      interestDeets: null,
      onSearchFetch: false
    });

    let query = GET_INTERESTS;
    query.variables = {
      interest_categories_id: this.props.match.params.id,
      search: this.state.searchText,
      sort: this.state.sort
    };

    axios
      .post(`${process.env.REACT_APP_API}`, query)
      .then(res => {
        this.setState({
          interests: res.data.data.getInterest.interest_details,
          interestCatName: res.data.data.getInterest.interest_category_name.name
        });
        if (res.data.data.getInterest.interest_details.length > 0) {
          this.setState(
            { interest_id: res.data.data.getInterest.interest_details[0].id },
            () => this.fetchIntDeets()
          );
        } else {
          this.setState({ loading: false });
        }
      })
      .catch(err => console.log(err));
  };

  passIntDeetsFromMenu = (key, index, value) => {
    triggerGAEvent("Interests", "View an Interest");
    this.setState(
      {
        loading: true,
        interest_id: Number(key.item.props.name)
      },
      () => this.fetchIntDeets()
    );
  };

  fetchIntDeets = () => {
    this.setState({ loading: true });
    let query = GET_ONE_INTEREST;
    query.variables = { id: this.state.interest_id };
    axios
      .post(`${process.env.REACT_APP_API}`, query)
      .then(res => {
        this.setState({
          interestDeets: res.data.data.getOneInterest.interest_details[0],
          loading: false
        });
      })
      .catch(err => {
        console.log(err);
        this.setState({ loading: false, is_500: true });
      });
  };

  addNewInterest = () => {
    triggerGAEvent("Interests", "Create new Interest");
    this.setState({ shouldBlockNavigation: false });
    let mutation = ADD_INTEREST;
    mutation.variables = {
      name: this.state.newCatName.trim(),
      desc: this.state.newCatDesc,
      interest_categories_id: this.state.interest_categories_id,
      profile_image: this.state.thumb_image
    };

    axios
      .post(`${process.env.REACT_APP_API}`, mutation)
      .then(res => {
        this.setState({ isFormSubmitting: false });
        if (res.data.errors) {
          openNotification("error", res.data.errors[0].message);
        } else {
          openNotification("success", "New Interest created");
          this.handleCancel();
          this.fetchData();
        }
      })
      .catch(err => console.log(err));
  };

  editInterest = () => {
    triggerGAEvent("Interests", "Edit an Interest");
    let mutation = EDIT_INTEREST;
    mutation.variables = {
      id: this.state.interestDeets.id,
      name: this.state.newCatName.trim(),
      desc: this.state.newCatDesc,
      interest_categories_id: this.state.interest_categories_id,
      profile_image: this.state.thumb_image
    };
    axios
      .post(`${process.env.REACT_APP_API}`, mutation)
      .then(res => {
        this.setState({ isFormSubmitting: false });
        if (res.data.errors) {
          openNotification("error", res.data.errors[0].message);
        } else {
          openNotification("success", "Interest Edited Successfully");
          this.handleCancel();
          this.fetchData();
        }
      })
      .catch(err => console.log(err));
  };

  deleteInterest = () => {
    triggerGAEvent("Interests", "Delete an Interest");
    if (
      this.state.interestDeets.lc_name.length !== 0 ||
      this.state.interestDeets.activity_name.length !== 0 ||
      this.state.interestDeets.library_name.length !== 0
    ) {
      showNotification(
        "error",
        "Unable to delete Interest",
        "Cannot delete Interest when it is tagged to a Learning Content"
      );
    } else {
      const confirmDialog = Modal.confirm({
        title: "Delete an Interest",
        content: "Are you sure?",
        okText: "Yes",
        onOk: () => {
          let mutation = DELETE_INTEREST;
          mutation.variables = { id: this.state.interest_id };
          axios
            .post(`${process.env.REACT_APP_API}`, mutation)
            .then(res => {
              if (res.data.errors) {
                openNotification("error", res.data.errors[0].message);
              } else {
                openNotification("success", "Interest deleted successfully.");
                this.fetchData();
              }
            })
            .catch(err => console.log(err));
        }
      });
      this.setState({ confirmDialog });
    }
  };

  suggestInterest = () => {
    this.setState({ isFormSubmitting: true });
    triggerGAEvent("Interests", "Suggest an Interest");
    let truthObj = {
      newCatName: this.state.newCatName === "",
      newCatDesc: false,
      interest_categories_id: false,
      thumb_image: false,
    };
    this.setState({ truthObj })
    if (this.state.newCatName === "") {
      openNotification("error", "Enter a Interest name to suggest.");
      this.setState({ isFormSubmitting: false });
    } else {
      let mutation = SUGGEST_INTEREST;
      mutation.variables = {
        name: this.state.newCatName.trim(),
        desc: this.state.newCatDesc,
        interest_categories_id: this.state.interest_categories_id,
        profile_image: this.state.thumb_image
      };
      axios
        .post(`${process.env.REACT_APP_API}`, mutation)
        .then(res => {
          this.setState({ isFormSubmitting: false });
          if (res.data.errors) {
            openNotification("error", res.data.errors[0].message);
          } else {
            openNotification(
              "success",
              "Thanks for your suggestion. It has been sent for Admin’s approval."
            );
            this.handleCancel();
          }
        })
        .catch(err => console.log(err));
    }
  };

  fetchInterestCategory = () => {
    this.setState({ loading: true });
    axios
      .post(`${process.env.REACT_APP_API}`, GET_GLOBAL_INTEREST_CATEGORY)
      .then(res => {
        this.setState({
          interestCategories:
            res.data.data.getGlobalInterestCategory.intrest_cat,
          loading: false
        });
      })
      .catch(err => console.log(err));
  };

  handleCancel = () => {
    triggerGAEvent("Interests", "Cancel in Create Modal Popup");
    this.setState({
      visible: false,
      suggest_visible: false,
      isFormSubmitting: false,
      thumb_image: "",
      modalType: "",
      newCatName: "",
      newCatDesc: "",
      shouldBlockNavigation: false,
      truthObj: {
        newCatName: false,
        newCatDesc: false,
        interest_categories_id: false,
        thumb_image: false,
      },
    
    });
  };

  // show a modal
  showModal = type => {
    this.setState({
      visible: true,
      modalType: type,
      newCatName: type === "edit" ? this.state.interestDeets.name : "",
      newCatDesc: type === "edit" ? this.state.interestDeets.desc : "",
      thumb_image:
        type === "edit" ? this.state.interestDeets.profile_image : "",
      interest_categories_id: this.props.match.params.id,
      shouldBlockNavigation: true
    });
    this.fetchInterestCategory();
  };

  showSuggestionModal = () => {
    this.setState({
      suggest_visible: true,
      interest_categories_id: this.props.match.params.id
    });
    this.fetchInterestCategory();
  };

  // handleInput
  onInputChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleCategorySelect = value => {
    this.setState({
      interest_categories_id: value
    });
  };

  FeaturedImageUploadSuccess = value => {
    this.setState({ thumb_image: value });
  };

  getLocalStorage = () => {
    this.setState({
      role_id: Number(localStorage.getItem("role_id"))
    });
  };

  onSearchFetchData = () => {
    triggerGAEvent("Interests", "Search an Interest");
    this.fetchData();
  };

  onSearch = e => {
    this.setState({ searchText: e.target.value, onSearchFetch: true }, () =>
      this.onSearchFetchData()
    );
  };

  sortChange = value => {
    triggerGAEvent("Interests", "Change sort in Interests");
    this.setState({ sort: value }, () => {
      this.fetchData();
    });
  };
  
  validateStateValues = () => {
    let truthObj = {
      newCatName: this.state.newCatName === "",
      newCatDesc: this.state.newCatDesc === "",
      interest_categories_id: this.state.interest_categories_id === "",
      thumb_image: this.state.thumb_image === "",
    };
    this.setState({ truthObj })
    if (this.state.newCatName !== "" && this.state.newCatDesc !== "" && this.state.interest_categories_id !== "" && this.state.thumb_image !== "") {
      this.state.modalType === "edit" ? this.editInterest() : this.addNewInterest()
    } else {
      this.setState({ isFormSubmitting: false });
      openNotification("error", "Fill all the mandatory fields");
    }
  };

  componentDidUpdate = () => {
    if (this.state.shouldBlockNavigation) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
  };

  componentDidMount() {
    this.setState({ loading: true });
    this.getLocalStorage();
    setTimeout(() => {
      this.fetchData();
    }, 1000);
  }

  componentWillUnmount() {
    this.state.confirmDialog && this.state.confirmDialog.destroy();
  }

  render() {
    const { interestDeets, modalType, interestCatName } = this.state;
    const extraButton = (
      <div className="d-flex align-items-center justify-content-end">
        {this.state.role_id === 1 && (
          <React.Fragment>
            <Button
              icon="edit"
              className="edit-button mr-3 d-flex align-items-center"
              onClick={() => this.showModal("edit")}
            >
              Edit
            </Button>
            <Button
              icon="delete"
              className="edit-button d-flex align-items-center"
              onClick={this.deleteInterest}
            >
              Delete
            </Button>
          </React.Fragment>
        )}
      </div>
    );
    if (this.state.is_error || this.state.is_500) {
      return (
        <div className="col-8 mx-auto mt-5 pb-5 text-center">
          <img
            src={this.state.is_500 ? Error500Activity : ErrorActivity}
            alt="error"
            className="img-fluid"
          />
        </div>
      );
    } else {
      return (
        <React.Fragment>
          {this.state.visible && (
            <Prompt
              when={this.state.shouldBlockNavigation}
              message="You have unsaved changes, are you sure you want to leave?"
            />
          )}
          <Loading is_loading={this.state.loading} />
          <div style={{ width: "100%", padding: "1.7rem" }}>
            <div className="d-flex align-items-center justify-content-between">
              <h4 className="m-0">{interestCatName}</h4>
              {this.state.role_id === 1 && (
                <Button
                  type="primary"
                  block
                  size="large"
                  className="btn-w250"
                  onClick={() => this.showModal("new")}
                >
                  Create New Interest
                </Button>
              )}
              {(this.state.role_id === 2 ||
                this.state.role_id === 4 ||
                this.state.role_id === 5) && (
                <Button
                  type="primary"
                  block
                  size="large"
                  className="btn-w250"
                  onClick={this.showSuggestionModal}
                >
                  Suggest Interest
                </Button>
              )}
            </div>
            {!this.state.loading &&
              this.state.interests.length === 0 &&
              this.state.searchText === "" &&
              !this.state.onSearchFetch && (
                <div className="tabscroll d-flex justify-content-center align-items-center flex-column">
                  <img className="mb-3" src={emptyStateActivity} alt="" />
                  <div className="text-center">
                    <p className="FontAvenirMedium font-20 mb-2">
                      Oops! It seems the admin has not created any interests
                      yet.
                      <div>
                        Go ahead and start suggesting interest by clicking on
                        suggest interest
                      </div>
                    </p>
                  </div>
                </div>
              )}
            {(this.state.interests.length !== 0 ||
              this.state.searchText !== "" ||
              this.state.onSearchFetch) && (
              <React.Fragment>
                {this.state.interests.length !== 0 && (
                  <Row className="mt-4">
                    <Sort
                      Edit={true}
                      data={this.state.sort}
                      sortChange={this.sortChange}
                    />
                  </Row>
                )}
                {this.state.interests.length === 0 && (
                  <div className="mt-4" style={{ height: 36 }}>
                    {" "}
                  </div>
                )}
                <Row gutter={24} style={{ margin: "0.5rem 0rem 0 0rem" }}>
                  <Col span={6} style={{ padding: 0 }}>
                    <Search
                      size="large"
                      placeholder="Search"
                      style={{ width: "100%" }}
                      onChange={this.onSearch}
                    />
                    <Menu
                      className="w-100 tabscroll"
                      style={{ height: "calc(100vh - 255px)" }}
                      defaultSelectedKeys={["0"]}
                      onClick={this.passIntDeetsFromMenu}
                    >
                      {this.state.interests.map((interest, index) => (
                        <Menu.Item key={index} name={interest.id}>
                          {" "}
                          {interest.name}{" "}
                        </Menu.Item>
                      ))}
                    </Menu>
                  </Col>
                  <Col span={18} className="p-0">
                    {interestDeets !== null ? (
                      <React.Fragment>
                        <Card
                          title={interestDeets.name}
                          extra={extraButton}
                          className="tabscroll"
                        >
                          <Meta
                            avatar={
                              <Avatar
                                size={200}
                                shape="square"
                                src={interestDeets.profile_image}
                              />
                            }
                            title={interestDeets.name}
                            description={interestDeets.desc}
                          />
                          <hr style={{ margin: "1.5rem 0rem 0rem" }} />
                          {interestDeets.lc_name !== null &&
                            interestDeets.lc_name.length !== 0 && (
                              <Row
                                type="flex"
                                justify="space-between"
                                align="middle"
                              >
                                <Col style={{ marginTop: "0.5rem" }}>
                                  <h5 style={{ textTransform: "uppercase" }}>
                                    In Learning Centres{" "}
                                  </h5>
                                  {interestDeets.lc_name.map((lc, index) => (
                                    <Tag
                                      color="green"
                                      key={index}
                                      style={{ marginBottom: "0.5rem" }}
                                    >
                                      {" "}
                                      {lc}{" "}
                                    </Tag>
                                  ))}
                                </Col>
                              </Row>
                            )}
                          {interestDeets.do !== null &&
                            interestDeets.do.length !== 0 && (
                              <Row
                                type="flex"
                                justify="space-between"
                                align="middle"
                              >
                                <Col style={{ marginTop: "0.5rem" }}>
                                  <h5 style={{ textTransform: "uppercase" }}>
                                    In Do Cards{" "}
                                  </h5>
                                  {interestDeets.do.map((activity, index) => (
                                    <Tag
                                      color="green"
                                      key={index}
                                      style={{ marginBottom: "0.5rem" }}
                                    >
                                      {" "}
                                      {activity}{" "}
                                    </Tag>
                                  ))}
                                </Col>
                              </Row>
                            )}
                          {interestDeets.read !== null &&
                            interestDeets.read.length !== 0 && (
                              <Row
                                type="flex"
                                justify="space-between"
                                align="middle"
                              >
                                <Col style={{ marginTop: "0.5rem" }}>
                                  <h5 style={{ textTransform: "uppercase" }}>
                                    In Read Cards{" "}
                                  </h5>
                                  {interestDeets.read.map((activity, index) => (
                                    <Tag
                                      color="green"
                                      key={index}
                                      style={{ marginBottom: "0.5rem" }}
                                    >
                                      {" "}
                                      {activity}{" "}
                                    </Tag>
                                  ))}
                                </Col>
                              </Row>
                            )}
                          {interestDeets.watch !== null &&
                            interestDeets.watch.length !== 0 && (
                              <Row
                                type="flex"
                                justify="space-between"
                                align="middle"
                              >
                                <Col style={{ marginTop: "0.5rem" }}>
                                  <h5 style={{ textTransform: "uppercase" }}>
                                    In Watch Cards{" "}
                                  </h5>
                                  {interestDeets.watch.map(
                                    (activity, index) => (
                                      <Tag
                                        color="green"
                                        key={index}
                                        style={{ marginBottom: "0.5rem" }}
                                      >
                                        {" "}
                                        {activity}{" "}
                                      </Tag>
                                    )
                                  )}
                                </Col>
                              </Row>
                            )}
                          {interestDeets.listen !== null &&
                            interestDeets.listen.length !== 0 && (
                              <Row
                                type="flex"
                                justify="space-between"
                                align="middle"
                              >
                                <Col style={{ marginTop: "0.5rem" }}>
                                  <h5 style={{ textTransform: "uppercase" }}>
                                    In Listen Cards{" "}
                                  </h5>
                                  {interestDeets.listen.map(
                                    (activity, index) => (
                                      <Tag
                                        color="green"
                                        key={index}
                                        style={{ marginBottom: "0.5rem" }}
                                      >
                                        {" "}
                                        {activity}{" "}
                                      </Tag>
                                    )
                                  )}
                                </Col>
                              </Row>
                            )}
                        </Card>
                      </React.Fragment>
                    ) : (
                      <div className="tabscroll d-flex align-items-center justify-content-center">
                        {(this.state.interests || []).length === 0 &&
                          !this.state.loading && (
                            <div className=" d-flex justify-content-center align-items-center flex-column mt-4">
                              <img className="mb-3" src={emptyState} alt="" />
                              <div className="text-center">
                                <p className="FontAvenirMedium font-20 mb-2">
                                  No results found. Please try again with
                                  different keywords
                                </p>
                              </div>
                            </div>
                          )}
                        {(this.state.interests || []).length !== 0 && (
                          <h5>Click on an interest to view the details</h5>
                        )}
                      </div>
                    )}
                  </Col>
                </Row>
              </React.Fragment>
            )}
          </div>
          <Modal
            title={modalType === "edit" ? "Edit Interest" : "Add New Interest"}
            visible={this.state.visible}
            maskClosable={false}
            onCancel={this.handleCancel}
            width={700}
            footer={[
              <Button key="back" size="large" onClick={this.handleCancel}>
                Cancel
              </Button>,
              <Button
                key="submit"
                size="large"
                type="primary"
                onClick={this.validateStateValues}
                disabled={this.state.isFormSubmitting}
                loading={this.state.isFormSubmitting}
              >
                {modalType === "edit" ? "Save Changes" : "Add Interest"}
              </Button>
            ]}
          >
            <Row justify="center" align="middle" type="flex">
              <Col span={6} className="interest-image">
                <ImageUploadCrop
                  imageUrl={this.state.thumb_image}
                  onSuccess={this.FeaturedImageUploadSuccess}
                  aspect={1}
                  id={this.state.truthObj["thumb_image"] ? "has-error" : ""}
                />
              </Col>
              <Col span={18} className="pl-3">
                <div className="mb-3">
                  <div className="FontAvenirRoman text-secondary font-16 text-uppercase requiredAsterisk mb-8px">
                    Interest name
                  </div>
                  <Input
                    name="newCatName"
                    onChange={this.onInputChange}
                    value={this.state.newCatName}
                    id={this.state.truthObj["newCatName"] ? "has-error" : ""}
                    size="large"
                    placeholder="Enter the name of the Interest"
                  />
                </div>
                <div className="mb-3">
                  <div className="FontAvenirRoman text-secondary font-16 text-uppercase requiredAsterisk mb-8px">
                    Interest Category
                  </div>
                  <Select
                    style={{ width: "100%" }}
                    onChange={this.handleCategorySelect}
                    value={this.state.interest_categories_id}
                    id={this.state.truthObj["interest_categories_id"] ? "has-error" : ""}
                    size="large"
                  >
                    {this.state.interestCategories.map((category, index) => (
                      <Option value={category.id} key={index}>
                        {category.name}
                      </Option>
                    ))}
                  </Select>
                </div>
              </Col>
            </Row>
            <div className="mb-3">
              <div className="FontAvenirRoman text-secondary font-16 text-uppercase requiredAsterisk mb-8px">
                Description
              </div>
              <Input.TextArea
                rows={4}
                name="newCatDesc"
                id={this.state.truthObj["newCatDesc"] ? "has-error" : ""}
                placeholder="Enter a description on the interest"
                value={this.state.newCatDesc}
                onChange={this.onInputChange}
                size="large"
              />
            </div>
          </Modal>
          <Modal
            title="Suggest New Interest"
            visible={this.state.suggest_visible}
            maskClosable={false}
            onCancel={this.handleCancel}
            width={700}
            footer={[
              <Button key="back" size="large" onClick={this.handleCancel}>
                Cancel
              </Button>,
              <Button
                key="submit"
                size="large"
                type="primary"
                onClick={this.suggestInterest}
                disabled={this.state.isFormSubmitting}
                loading={this.state.isFormSubmitting}
              >
                Suggest Interest
              </Button>
            ]}
          >
            <Row justify="center" align="middle" type="flex">
              <Col span={6} className="interest-image">
                <ImageUploadCrop
                  imageUrl={this.state.thumb_image}
                  onSuccess={this.FeaturedImageUploadSuccess}
                  aspect={1}
                  id={this.state.truthObj["thumb_image"] ? "has-error" : ""}
                />
              </Col>
              <Col span={18} className="pl-3">
                <div className="mb-3">
                  <div className="FontAvenirRoman text-secondary font-16 text-uppercase requiredAsterisk mb-8px">
                    Interest name
                  </div>
                  <Input
                    name="newCatName"
                    onChange={this.onInputChange}
                    value={this.state.newCatName}
                    size="large"
                    placeholder="Enter the name of the Interest"
                    id={this.state.truthObj["newCatName"] ? "has-error" : ""}
                  />
                </div>
                <div className="mb-3">
                  <div className="FontAvenirRoman text-secondary font-16 text-uppercase mb-8px">
                    Interest Category
                  </div>
                  <Select
                    style={{ width: "100%" }}
                    onChange={this.handleCategorySelect}
                    value={this.state.interest_categories_id}
                    size="large"
                    id={this.state.truthObj["interest_categories_id"] ? "has-error" : ""}
                  >
                    {this.state.interestCategories.map((category, index) => (
                      <Option value={category.id} key={index}>
                        {category.name}
                      </Option>
                    ))}
                  </Select>
                </div>
              </Col>
            </Row>
            <div className="mb-3">
              <div className="FontAvenirRoman text-secondary font-16 text-uppercase mb-8px">
                {" "}
                Description{" "}
              </div>
              <Input.TextArea
                rows={4}
                name="newCatDesc"
                placeholder="Enter a description on the interest"
                value={this.state.newCatDesc}
                onChange={this.onInputChange}
                size="large"
                id={this.state.truthObj["newCatDesc"] ? "has-error" : ""}
              />
            </div>
          </Modal>
        </React.Fragment>
      );
    }
  }
}
