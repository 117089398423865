export const GET_ALL_STUDENT_PROFILES = {
  query: `
		query getStudentData($search: String!, $page: Int!, $filter: [StudFilterInputType]!) {
			getStudentData (search: $search, page: $page, filter: $filter) {
				count
				students {
					id
					fname
					lname
					age_group
					attendance
					last_login
				}
  		}
  		getAge{
				age_groups{
					id
					age
				}
			}
		}
	`
};

export const GET_ONE_STUDENT_DETAILS = {
  query: `
		query getOnestudentv2($student_id: ID!, $date: String) {
			getOnestudentv2(student_id: $student_id, date: $date) {
				student_details {
					username
					fname
					lname
					dob
					address
					age_group
					attendance
					parent {
						email
						status
						id
						name
						last_name
						address
						country
						phone_no
					}
				}
				interest_categories {
					id
					name
					color_code
					background_image
				}
				requestSent
				is_password_changed
				journal {
					overall_journal_list {
						card_details {
							id
							name
							card_id
							playlist_name
							image
							notes
							activity_status
							library_status
							resumed_time
							time_spent
							total_time_spent
							time
							url
							steps
							video_link
							video_view
							steps_view
							pictures {
								id
								image
								type
								notes
								createdAt
							}
							journal_state
							journal_image_notes_state
							disp_time
							journal_time
							lc_name
							feedback_state
							feedback_desc
							feedback_voice
							type
							student_response
							playlist_id
							default_activities_id
							user {
								name
								role_id
							}
						}
						lc_name
					}
					student_id
					student_name
				}
			}
		}
	`
};

export const GET_ONE_STUDENT_ACTIVITIES = {
  query: `
		query getStudentActivity($student_id: ID!){
			getStudentActivity(student_id:$student_id) {
				activity_details {
					id
					name
					breif_desc
					default_activities_id
					activity_state
					interests {
						interest_details {
							name
						}
					}
					thumb_images {
						id
						image_url
					}
				}
			}
		}
	`
};

export const GET_ONE_STUDENT_LIBRARIES = {
  query: `
		query getStudentLibrary($student_id: ID!){
			getStudentLibrary(student_id:$student_id) {
				library_details {
					id,
					default_libraries_id,
					user_activity_type,
					activity_state,
					name,
					title,
					author,
					illustrator,
					publisher,
					no_of_pages,
					brief_desc,
					long_desc,
					time,
					suitable_for_id,
					suitable_for,
					read_along_text,
					read_along_link,
					safety,
					safety_desc,
					interests {
						interest_details {
							id
							name
						}
					}
					thumb_images {
						id
						image_url
					}
				}
			}
		}
	`
};

export const GET_ONE_STUDENT_ATTENDANCE = {
  query: `
		query webCalenderView($date: String!, $student_id: ID!){
			webCalenderView(date: $date, student_id:$student_id) {
				calender_details{
					activity
					libraries
					playlist
					date
					journal_image_notes_state
					default_activities_id
					activity_types {
						default_activities_id
					}
				}
			}
		}
	`
};

export const INITIATE_PASSWORD_RESET = {
  query: `
  	mutation adminAcceptResetPasswordV2($student_id: ID!) {
			adminAcceptResetPasswordV2(student_id: $student_id) {
				requestAccepted
				requestSent
			}
		}
  `
};

export const GET_STUDENT_ALL_FEEDBACK = {
  query: `
		query lcFeedbacklistV2 ($student_id: ID!, $notification_id: ID, $sort: Boolean){
			lcFeedbacklistV2(student_id: $student_id, notification_id: $notification_id, sort: $sort){
				student_details{
					fname
					lname
				}
				feedbacklist{
					id
					student_id
					feedback_type
					feedback
					card_id
					date
					lc_name
					card_name
					feedback_voice
					student_response
				}
			}
		}
	`
};

export const STUDENT_GENERAL_FEEDBACK = {
  query: `
		mutation lcFeedback($student_id: ID!, $feedback_type: String!, $feedback: String!, $feedback_voice: String!,$card_id:ID) {
			lcFeedback(student_id: $student_id, feedback_type: $feedback_type, feedback: $feedback, feedback_voice: $feedback_voice,card_id:$card_id) {
				status
				message
			}
		}
	`
};

export const STUDENT_LC_FEEDBACK = {
  query: `
		mutation lcFeedback($student_id: ID!, $feedback_type: String!, $feedback: String!, $card_id: ID!, $feedback_voice: String!) {
			lcFeedback(student_id: $student_id, feedback_type: $feedback_type, feedback: $feedback, card_id: $card_id, feedback_voice: $feedback_voice) {
				status
				message
			}
		}
	`
};

export const SEND_PARENT_INVITE = {
  query: `
		mutation postParentV2($invite: [InviteTypeInput], $student_id: ID) {
			postParentV2(invite: $invite, student_id: $student_id) {
				invite {
					id
					invite_token
				}
			}
		}
	`
};

export const REMOVE_PARENT_EMAIL = {
	query: `
		mutation RemoveParentEmailV2($email: String!){
			RemoveParentEmailV2(email: $email) {
				status
			}
		}
	`
};
