import React, {Component} from 'react';
import Form from "react-validation/build/form";
import {Button, Col, Row, Icon, DatePicker, Tooltip} from "antd";
import InputC from "../../components/InputC";
import {required} from "../../validation";
import moment from "moment";
import {openNotification} from "../../utils";
import './index.css';

class SetUpChild extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isSubmit: false,
			form: React.createRef(),
		};
	}
	
	disableDobHandler = (current) => {
		return current.isAfter(moment());
	};
	
	dobChangeHandler = (date, dateString) => {
		if (date.isBefore(moment().subtract(4, "years"))) {
			this.props.updateData('dob', dateString)
		} else {
			openNotification("error", "The minimum age of a learner is 4 years");
		}
	};
	
	handleSubmit = (e) => {
		e.preventDefault();
		this.setState({ isSubmit: true });
		this.state.form.current.validateAll();
		setTimeout(() => {
			if (document.querySelectorAll('.form-error').length === 0) {
				this.props.nextStep();
			}
		}, 1);
	};
	
	render() {
		return (
			<Form ref={this.state.form} className={this.state.isSubmit ? "formSubmitted" : ""}>
				<h3 className="pb-3 mb-3 border-bottom FontAvenirHeavy font-18 max-dev-parentSignupTitle">
					{`Set up ${this.props.data.student_fname}'s account`}
				</h3>
				<h3 className="mb-3 pb-3 FontAvenirHeavy text-center font-16 mbl-dev-parentSignupTitle">
					{`Set up ${this.props.data.student_fname}'s account`}
				</h3>
				<Row gutter={48} className="mbl-mr-neg">
					<Col xl={12} lg={12} xs={24} sm={24}>
						<InputC
							type="text"
							title="Child's First Name"
							titleStyle="mb-3 FontAvenirMedium text-secondary font-16"
							className="col-12 ant-input ant-input-lg mb-4"
							value={this.props.data.student_fname}
							stateKey="student_fname"
							name="student_fname"
							onChange={this.props.updateData}
							validations={[required]}
						/>
					</Col>
					<Col xl={12} lg={12} xs={24} sm={24}>
						<InputC
							type="text"
							title="Child's Last Name"
							titleStyle="mb-3 FontAvenirMedium text-secondary font-16"
							className="col-12 ant-input ant-input-lg mb-4"
							value={this.props.data.student_lname}
							stateKey="student_lname"
							name="student_lname"
							onChange={this.props.updateData}
							validations={[required]}
						/>
					</Col>
				</Row>
				<Row gutter={48} className="mbl-mr-neg">
					<Col xl={12} lg={12} xs={24} sm={24}>
						<div className="mb-3 position-relative">
							<div className="FontAvenirRoman text-secondary font-16 mb-8px">
								Date of Birth
							</div>
							<div className="d-flex align-items-center justify-content-center">
								<DatePicker
									value={this.props.data.dob ? moment(this.props.data.dob, "MMM D, YYYY") : undefined}
									disabledDate={this.disableDobHandler}
									format="MMM D, YYYY"
									size="large"
									className={`w-100 ${(this.state.isSubmit && !this.props.data.dob) ? "has-error" : ""}`}
									onChange={this.dobChangeHandler}
								/>
								<div className="mbl-dob-icon" style={{ position: 'absolute', right: '-50px' }}>
									<Tooltip
										placement="right"
										title="Breadcrumb filters age appropriate content based on how old your child is"
									>
										<Icon type="info-circle" className="mr-3 mt-1 font-20" />
									</Tooltip>
								</div>
							</div>
							<div style={{ minHeight: 16 }}>
								{(this.state.isSubmit && !this.props.data.dob) && (
									<span style={{ bottom: '-3px' }} className="form-error is-visible">Required</span>
								)}
							</div>
						</div>
					</Col>
				</Row>
				<Row gutter={48} className="mbl-mr-neg mbl-parent-childProfile-mr">
					<Col xl={12} lg={12} xs={24} sm={24}>
						<div className="d-flex flex-column align-items-start justify-content-start">
							<InputC
								type="text"
								title="Preferred Username"
								placeholder="Add username"
								titleStyle="mb-3 FontAvenirMedium text-secondary font-16"
								className="col-12 ant-input ant-input-lg mb-4"
								value={this.props.data.username}
								stateKey="username"
								name="username"
								onChange={this.props.updateData}
								validations={[required]}
							/>
						</div>
						<div className="d-flex align-items-start justify-content-start">
							<Icon type="info-circle" className="mr-3 mt-1 font-20" />
							<p style={{ fontWeight: 800 }} className="mbl-childProf-Txt font-16 m-0">This username is what your child would use on the iPad, to login</p>
						</div>
					</Col>
				</Row>
				<div className="d-flex align-items-center justify-content-end mt-4 max-dev-parentSignup-Btn">
					<Button
						size="large"
						type="primary"
						onClick={this.handleSubmit}
						className="pl-4 pr-4"
						style={{ backgroundColor: '#3EC1F0', color: '#FFFFFF', border: 'none' }}
					>
						SETUP CHILD ACCOUNT
					</Button>
				</div>
				<div className="mbl-dev-parentSignup-Btn">
					<Button
						size="large"
						type="primary"
						onClick={this.handleSubmit}
						className="pl-4 pr-4"
						style={{ width: '100%', backgroundColor: '#42C0EF', color: '#FFFFFF', border: 'none' }}
					>
						SETUP CHILD ACCOUNT
					</Button>
				</div>
			</Form>
		);
	}
}

export default SetUpChild;
