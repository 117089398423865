import gql from "graphql-tag";

export const LOGIN = {
  query: `
    mutation login($email: String!, $password: String!, $id_token: String!, $login_type: String!) {
      login(email: $email, password: $password, ID_token: $id_token, loginType: $login_type) {
        user_details {
          token
          role_id
          isNewLogin
		}
		error
		msg
		is_email
      }
    }
  `,
};

export const CHECK_INVITE_LINK = {
  query: `
  	mutation checkInviteLinkV2($inviteToken: String!) {
			checkInviteLinkV2(inviteToken: $inviteToken) {
				email,
				status,
				message
				role_id
			}
  	}
  `,
};

export const ACCEPT_PASSWORD_RESET = {
  query: `
    query acceptResetV2 ($token: String) {
      acceptResetV2(token: $token) {
        id
        status
        message
      }
    }
  `,
};

export const VERIFY_PARENT_EMAIL = {
  query: `
    query parentVerifyEmailV3($token: String!) {
      parentVerifyEmailV3(token: $token) {
        status
        message
      }
    }
  `,
};

export const CHECK_PARENT_INVITE = {
  query: `
  	mutation checkInviteLinkV2($inviteToken: String!) {
			checkInviteLinkV2(inviteToken: $inviteToken) {
				email
				status
				message
				student_fname
				student_lname
				name
				last_name
				phone_no
				city
				comments
				role_id
				isChild
				country
			}
  	}
  `,
};

export const PARENT_ONBOARDING = {
  query: `
    mutation OnboardingParentV2($inviteToken: String!, $password: String!, $first_name: String, $last_name: String, $student_firstName: String, $student_lastName: String, $dob: String, $username: String, $country: String) {
      OnboardingParentV2(inviteToken: $inviteToken, password: $password, first_name: $first_name, last_name: $last_name, student_firstName: $student_firstName, student_lastName: $student_lastName, dob: $dob, username: $username, country: $country) {
        email
        status
        token
      }
    }
  `,
};

export const LC_FORGOT_PSWD = {
  query: `
  	mutation forgotPasswordRequestMutation($email: String!) {
			forgotPasswordRequest(email: $email) {
				status,
				message
			}
		}
  `,
};

export const LC_ON_BOARDING = gql`
  mutation onboardingLearningCentreV2(
    $inviteToken: String!
    $name: String!
    $displayName: String!
    $password: String!
    $bio: String!
    $phoneNo: String!
    $address: String!
    $img: String!
    $latitude: Float
    $longitude: Float
    $interest_categories_id: [ID]!
  ) {
    onboardingLearningCentreV2(
      inviteToken: $inviteToken
      name: $name
      displayName: $displayName
      password: $password
      bio: $bio
      phoneNo: $phoneNo
      address: $address
      profileImage: $img
      latitude: $latitude
      longitude: $longitude
      interest_categories_id: $interest_categories_id
    ) {
      user_details {
        id
        name
        email
        token
        role_id
      }
    }
  }
`;

export const GET_LIBRARY_PREREQ = {
  query: `
  	query getLibararyPrerequisites($default_libraries_id:ID) {
			getLibararyPrerequisites(default_libraries_id:$default_libraries_id){
  			libarary_prerequisites{
    			name,
    			id
  			}
			}
		}
	`,
};

export const GET_CREATE_ACTIVITY = {
  query: `
		query get_create_activity{
      getAge{
        age_groups{
          id,
          age,
          is_active
        }
        age_dificulty{
          id,
          dificulty,
          is_active
        }
      },
      getDataGrade{
        data_grade{
          id,
          name,
          total_data_points
        }
      },
      getInterestCategory{
        intrest_cat{
          id,
          name,
          is_active,
          total_interest
        },
				total_count
			},
			getGlobalInterestCategory {
        intrest_cat{
          id,
          name,
          is_active,
          color_code,
          total_interest,
          background_image
        }
      }
			getSuitableFor{
				id,
				people
			},
      getDataPoint(data_grade_types_id:1){
        data_grade_name{
          name
        },
        data_point_details{
          name,
          id
        }
      },
      getDefultDataPoint{
        id,
        name,
        data_point_details{
          id,
          name
        }
      }
      getTags {
        tags {
          id
          name
        }
      }
    }
  `,
};

export const GET_PREREQUISITES = {
  query: `
    query getActivityndLPPrerequisites($user_id: Int, $age_group_id: [Int],$activity_id:Int){
      getActivityndLPPrerequisites(user_id: $user_id, age_group_id: $age_group_id,activity_id:$activity_id) {
        activity_details {
          id
          default_activities_id
          activity_state
          name
          title
          age_group_id
        }
        learningpath_details {
          id
          name
          title
          background_image
          age_group_id
        }
      }
    }
  `,
};

export const GET_TAGS = {
  query: `
    query getTags {
      getTags {
        tags {
          id
          name
        }
      }
    }
  `,
};

export const ADD_TAG = {
  query: `
    mutation AddTagsV2($name: [String]) {
      AddTagsV2(name: $name) {
				tags_ids
        name
      }
    }
  `,
};

export const CHECK_ACTIVITY_NAME = {
  query: `
		query checkActivityName($name: String!) {
			checkActivityName(name:$name){
				is_available
			}
		}
	`,
};

export const CHECK_YOUTUBE_LINK = {
  query: `
		query checkValidUrl($url: String!) {
			checkValidUrl(url: $url){
				embeddable
        regionRestriction
			}
		}
	`,
};

export const CHECK_LIBRARY_NAME = {
  query: `
  	query checkLibraryNameMutation($default_libraries_id:ID,$name: String!) {
      checkLibraryName(default_libraries_id:$default_libraries_id,name:$name){
				is_available
			}
		}
	`,
};

export const GET_ALL_COLLECTION_PLAYLIST = {
  query: `
		query GetCollectionsPlayList($only_playlist:Boolean,$only_collection:Boolean,$sort:String,$search:String,$filter:[FilterInputType]){
			GetCollectionsPlayList(sort:$sort,only_playlist:$only_playlist,only_collection:$only_collection,search:$search,filter:$filter){
				cards{
					id
					user_id
					title
					name
					is_archive
					is_publish
					background_image
					is_playlist
				}
			}
		}
	`,
};

export const GET_ALL_COLLECTION = {
  query: `
    query getAllCollection($sort:String, $search:String, $filter:[FilterInputType],$activities_id:Int){
      getAllCollection(sort:$sort,search: $search, filter: $filter,activities_id:$activities_id){
        filter_apply
       	inprogress_collection{
					id
					name

					is_active
					total_activity
					background_image
					is_archive
					all_activities{
						total_count
					}
					is_publish

				}
				inprogress_playlist{
					id
					title

					is_active
					total_activity
					background_image
					is_archive
					all_activities{
						total_count
					}
					is_publish

				}
       	archived_collection{
					id
					name

					is_active
					total_activity
					background_image
					is_archive
					all_activities{
						total_count
					}
					is_publish

				}
        published_collection{
          id
					name

					is_active
					total_activity
					background_image
					is_archive
					all_activities{
						total_count
					}
					is_publish

        }
        collection_details{
          id
          user_id
          is_publish
          background_image
          background_image
          total_activity
        }
      }
		}
	`,
};

export const GET_ALL_ACTIVITIES = {
  query: `
    query AllCardsLCNew($sort:String,$search:String,$filter:[FilterInputType], $only_draft: Boolean, $page: Int,$get_pending_only:Boolean, $ungroupedContent_only: Boolean){
      AllCardsLCNew(sort:$sort, search:$search, filter:$filter, only_draft: $only_draft, page: $page,get_pending_only:$get_pending_only, ungroupedContent_only: $ungroupedContent_only){
				total_count
    		activity_details{
					age_groups{
						age_group{
							id,
							age,
							is_active
						}
						age_group_dificulty{
							id,
							dificulty,
							is_active
						}
					}
					difficulty,
					default_activities_id,
					user_activity_type,
					time,
      		activity_reference_images,
      		url,
      		pdf_url,
      		steps,
      		id,
      		activities_draft_id,
					name,
					user_name,
					activity_state,
					status,
					is_draft,
					is_changed,
					video_link,
					activities_draft_state,

					insights_on_activity{
						easy{
							age_group
						}
						medium{
							age_group
						}
						hard{
							age_group
						}
					}
					thumb_images{
						id,image_url
					}
					interests{
						interest_details{
							id,
							name
						}
					}
    		}
  		}
    }
  `,
};

export const GET_ALL_COLLECTION_FOR_ADMIN = {
  query: `
    query getAllCollecionForAdmin($sort:String,$search:String,$filter:[FilterInputType]){
      getAllCollection(sort:$sort,search: $search, filter: $filter){
        filter_apply
       	inprogress_collection{
					id
					name
					is_active
					total_activity
					background_image
					is_archive
					all_activities{
						total_count
					}
					is_publish
				}
      	archived_collection{
					id
					name
					is_active
					total_activity
					background_image
					is_archive
					all_activities{
						total_count
					}
					is_publish
				}
        published_collection{
          id
					name
					is_active
					total_activity
					background_image
					is_archive
					all_activities{
						total_count
					}
					is_publish
					}
      	}
			}
		}
	`,
};

export const ADD_COLLECTION = {
  query: `
		mutation addcollection($name: String!) {
      addCollection(name:$name){
        collection_details{
          id,
          name
        }
			}
		}
	`,
};

export const CHECK_LC_NAME = {
  query: `
		query checkLearningCentreNameV2($inviteToken: String!,$email: String!,$name: String!) {
			checkLearningCentreNameV2(inviteToken:$inviteToken,email:$email,name:$name){
				is_available
			}
		}
	`,
};

export const USER_UPDATE_PASSWORD = {
  query: `
		mutation userUpdatePassword($forgotPasswordToken: String!,$password: String!) {
			userUpdatePassword(forgotPasswordToken:$forgotPasswordToken,password:$password){
				user_details{
					email,
					password,
					role_id
				}
			}
		}
	`,
};

export const GET_INTEREST = {
  query: `
		query getInterest($interest_categories_id: ID!) {
			getInterest(interest_categories_id:$interest_categories_id){
				interest_details{
					id,
					name
				}
			}
		}
	`,
};

export const GET_DATA_POINT = {
  query: `
		query getDataPoint($data_grade_types_id: ID!) {
			getDataPoint(data_grade_types_id:$data_grade_types_id){
				data_point_details{
					name,
					id
				}
			}
		}
	`,
};

export const ADD_DRAFT_ACTIVITY = {
  query: `mutation addDraftActivity(
		$activities_id: ID
		$activities_draft_id: ID
    $user_activity_type: String
    $activity_state: String
    $name: String
    $title: String
    $thumb_image: [ActivityThumbImageType]
    $age_group: [ActivityAgeGroupType]
    $interest_categories_id: ID
    $interest_id: [ID]
    $steps_link: String
    $notes: String
    $breif_desc: String
    $long_desc: String
    $time: String
    $suitable_for_id: ID
    $safety: String
    $safety_desc: String
    $material_required: [ActivityMaterialType]
    $image: [ActivityImageType]
    $image_source_text: String
    $image_source_link: String
    $url: String
    $channel_url: String
    $channel_name: String
    $step: [ActivityStepType]
    $steps_source_text: String
    $steps_source_link: String
    $prerequisites: [ActivityPrerequisitesType]
    $recommended: [ActivityRecommendedType]
    $mashup: [ActivityMasupdataType]
    $pdf_url: String
    $pdf_name: String
		$pdf_source_text: String
		$pdf_source_url: String
		$tags_id: [ID]
		$default_activities_id: Int,
		$author: String,
		$source: String,
		$video_link: String,
		$video_credit_text: String,
		$video_credit_link: String,
		$trailer_url: String,
		$trailer_credit_text: String,
		$trailer_credit_url: String,
		$no_of_episodes: String,
		$released_year: String,
		$country_of_origin: String,
		$feature_length:String
  ){
    addDraftActivity(
			author:$author
			source:$source,
				video_link:$video_link,
				video_credit_text:$video_credit_text,
				video_credit_link:$video_credit_link,
				trailer_url: $trailer_url,
				trailer_credit_text: $trailer_credit_text,
				trailer_credit_url: $trailer_credit_url,
				no_of_episodes:$no_of_episodes,
				released_year:$released_year,
				feature_length:$feature_length
				country_of_origin:$country_of_origin,
    	activities_id: $activities_id
    	activities_draft_id: $activities_draft_id
			user_activity_type: $user_activity_type
			default_activities_id: $default_activities_id
      activity_state: $activity_state
      name: $name
      title: $title
      thumb_image: $thumb_image
      age_group: $age_group
      interest_categories_id: $interest_categories_id
      interest_id: $interest_id
      steps_link: $steps_link
      notes: $notes
      breif_desc: $breif_desc
      long_desc: $long_desc
      time: $time
      suitable_for_id: $suitable_for_id
      safety: $safety
      safety_desc: $safety_desc
      material_required: $material_required
      image: $image
      image_source_text: $image_source_text
      image_source_link: $image_source_link
      url: $url
      channel_url: $channel_url
      channel_name: $channel_name
      step: $step
      steps_source_text: $steps_source_text
      steps_source_link: $steps_source_link
      prerequisites: $prerequisites
      recommended: $recommended
      mashup: $mashup
      pdf_name: $pdf_name
      pdf_url: $pdf_url
      pdf_source_text: $pdf_source_text
			pdf_source_url: $pdf_source_url
			tags_id: $tags_id,
    ) {
			activity_details{
				id,
				activities_draft_id,
				name
      }
    }
  }
`,
};

export const SEND_ACTIVITY_FOR_APPROVAL = {
  query: `
		mutation sendForApprovalV2($activities_draft_id: ID){
			sendForApprovalV2(activities_draft_id: $activities_draft_id){
				id
				status
				message
			}
		}
	`,
};

export const APPROVE_ACTIVITY = {
  query: `
    mutation approveActivityV2(
			$activities_id: ID
			$default_activities_id:Int
      $activities_draft_id: ID
      $user_activity_type: String
      $activity_state: String
      $name: String
      $title: String
      $thumb_image: [ActivityThumbImageType]
      $age_group: [ActivityAgeGroupType]
      $interest_categories_id: ID
      $interest_id: [ID]
      $steps_link: String
      $notes: String
      $breif_desc: String
      $long_desc: String
      $time: String
      $suitable_for_id: ID
      $safety: String
      $safety_desc: String
      $material_required: [ActivityMaterialType]
      $image: [ActivityImageType]
      $image_source_text: String
      $image_source_link: String
      $url: String
      $channel_url: String
      $channel_name: String
      $step: [ActivityStepType]
      $steps_source_text: String
      $steps_source_link: String
      $prerequisites: [ActivityPrerequisitesType]
      $recommended: [ActivityRecommendedType]
      $mashup: [ActivityMasupdataType]
      $pdf_url: String
    	$pdf_name: String
      $pdf_source_text: String
      $pdf_source_url: String
			$tags_id: [ID]
			$author: String,
			$source: String,
			$video_link: String,
			$video_credit_text: String,
			$video_credit_link: String,
			$trailer_url: String,
			$trailer_credit_text: String,
			$trailer_credit_url: String,
			$no_of_episodes: String,
			$released_year: String,
			$country_of_origin: String,
			$feature_length:String
    ){
      approveActivityV2(
				default_activities_id:$default_activities_id
        activities_id: $activities_id
        activities_draft_id: $activities_draft_id
        user_activity_type: $user_activity_type
        activity_state: $activity_state
        name: $name
        title: $title
        thumb_image: $thumb_image
        age_group: $age_group
        interest_categories_id: $interest_categories_id
        interest_id: $interest_id
        steps_link: $steps_link
        notes: $notes
        breif_desc: $breif_desc
        long_desc: $long_desc
        time: $time
        suitable_for_id: $suitable_for_id
        safety: $safety
        safety_desc: $safety_desc
        material_required: $material_required
        image: $image
        image_source_text: $image_source_text
        image_source_link: $image_source_link
        url: $url
        channel_url: $channel_url
        channel_name: $channel_name
        step: $step
        steps_source_text: $steps_source_text
        steps_source_link: $steps_source_link
        prerequisites: $prerequisites
        recommended: $recommended
        mashup: $mashup
        pdf_name: $pdf_name
      	pdf_url: $pdf_url
      	pdf_source_text: $pdf_source_text
      	pdf_source_url: $pdf_source_url
				tags_id: $tags_id
				author: $author,
				source:$source,
				video_link:$video_link,
				video_credit_text:$video_credit_text,
				video_credit_link:$video_credit_link,
				trailer_url: $trailer_url,
				trailer_credit_text: $trailer_credit_text,
				trailer_credit_url: $trailer_credit_url,
				no_of_episodes:$no_of_episodes,
				released_year:$released_year,
				country_of_origin:$country_of_origin,
				feature_length:$feature_length
      ){
        activity_details{
        	id
          user_id
        }
      }
    }
  `,
};

export const ACTIVITY_FILTER_QUERY = {
  query: `
  query getWebFilterData($id:ID){
  getWebFilterData(activity_collection_id:$id){
    interest_details{
      id,
      name
    },
    difficulty_details{
      age_groups{
        id,
        age
      }
    },
    time{
      min,
      max
    }
  }
}
  `,
};

export const GET_DATAGRADE_CATEGORY = {
  query: `
		query {
			getDataGrade{
				data_grade{
					id,
					name,
					total_data_points
				}
			}
		}
	`,
};

export const FORGOT_PASSWORD = {
  query: ` query forgotRequestV2($userName: ID!) {
    id
  }`,
};

export const GET_GLOBAL_INTEREST_CATEGORY = {
  query: `
    query getGlobalInterestCategory( $sort: String $search: String ){
      getGlobalInterestCategory( sort: $sort search: $search ) {
        intrest_cat{
          id,
          name,
          is_active,
          color_code,
          total_interest,
          background_image
        }
      }
    }
  `,
};

export const VIEW_ACTIVITY = {
  query: `
    query getOneActivityV2($id: ID!){
			getAge{
        age_groups{
          id,
          age,
          is_active
        }
        age_dificulty{
          id,
          dificulty,
          is_active
        }
      }
   		getOneActivityV2( id:$id ) {
				activity_details{
					is_selected
					default_activities_id
					user{
						id
						role_id
					}
					insights_on_activity{
						is_viewed,
						is_finished,
						like_count,
						dislike_count,
						average_time_complete,
						easy{
							age_group,
							count
						},
						medium{
							age_group,
							count
						},
						hard{
							age_group,
							count
						}
					},
					id,
					activities_draft_id,
					user_id,
					user_name,
					user_activity_type,
					activity_state,
					status,
					name,
					title,
					steps_link,
					notes,
					breif_desc,
					long_desc,
					time,
					suitable_for_id,
					suitable_for,
					activities_prerequisites_id,
					safety,
					safety_desc
					image_source_text,
					image_source_link,
					url,
					channel_url,
					channel_name,
					steps_source_text,
					steps_source_link,
					pdf_name,
					pdf_url,
					pdf_source_text,
					pdf_source_url,
					tags {
            id,
            name
					}
					author,
					source,
					video_link,
					pdf_name,
					pdf_url,
					pdf_source_text,
					pdf_source_url,
					video_credit_text,
					video_credit_link,
					trailer_url,
					trailer_credit_text,
					trailer_credit_url,
					no_of_episodes,
					released_year,
					feature_length,
					country_of_origin,
				},
				thumb_images{
					image_url id is_active
				}
				age_groups{
					id,
					is_active
					age_group{
						age, is_active id
					},
					age_group_dificulty{
						id dificulty, is_active
					}
				},
				interests{
					interest_category_name{
						name
					},
					interest_details{
						id name is_active interest_categories_id
					}
				},
				steps{
					title,
					image,
					desc
				},
				prerequisites{
					activity_name
					text
					id
					activities_id
					isPlaylist
          default_activities_id
				},
				images{
					id
					image_url
				}
				recommended{
					title,
					link
				},
				mashup_data{
					id,
					name,
					data_point_details{
						id,
						name
					}
				}
				materials_required{
					name,
					image
				}
				getCollectionPlaylist_details {
          id
          title
          background_image
          isPlaylist
        }
  		}
		}
	`,
};

export const VIEW_ACTIVITY_IMAGE = {
  query: `
		query getOneActivityV2($id: ID!){
   		getOneActivityV2( id:$id ) {
    		activity_details{
      		insights_on_activity{
						is_viewed,
						is_finished,
						like_count,
						dislike_count,
						average_time_complete,
						easy{
							age_group,
							count
						},
						medium{
							age_group,
							count
						},
						hard{
							age_group,
							count
						}
					},
					name,
					image_source_text,
					image_source_link,
					url,
					channel_url,
					channel_name,
					steps_source_text,
					steps_source_link,
				},
				steps{
					title,
					desc,
					image
				}
				images{
					image_url
				}
  		}
		}
	`,
};

export const VIEW_DRAFT_ACTIVITY = {
  query: `
    query getOneDraftActivity($activities_draft_id: ID) {
			getAge{
        age_groups{
          id,
          age,
          is_active
        }
        age_dificulty{
          id,
          dificulty,
          is_active
        }
      }
      getOneDraftActivity(activities_draft_id: $activities_draft_id) {
				activity_in_details{
					country_of_origin
					released_year
				}
        activity_details {
					default_activities_id
          is_selected
					id
					user{
						id
						role_id
					}
          activities_id
          user_id
          status
          user_activity_type
          activity_state
          name
          user_name
          title
          steps_link
          notes
          breif_desc
          long_desc
          time
          suitable_for_id
          suitable_for
          activities_prerequisites_id
          safety
          safety_desc
          image_source_text
          image_source_link
          url
          channel_url
          channel_name
          steps_source_text
          steps_source_link
          pdf_name
          pdf_url
          pdf_source_text
          pdf_source_url
          tags {
            id
            name
					}
					author,
					source,
					video_link,
					pdf_name,
					pdf_url,
					pdf_source_text,
					pdf_source_url,
					video_credit_text,
					video_credit_link,
					trailer_url,
					trailer_credit_text,
					trailer_credit_url,
					no_of_episodes,
					released_year,
					feature_length,
					country_of_origin,
        }
        thumb_images {
          image_url
          id
          is_active
        }
        age_groups {
          id
          is_active
          age_group {
            age
            is_active
            id
          }
          age_group_dificulty {
            id
            dificulty
            is_active
          }
        }
        interests {
          interest_category_name {
            name
          }
          interest_details {
            id
            name
            is_active
            interest_categories_id
          }
        }
        steps {
          title
          image
          desc
        }
        prerequisites {
					activity_name
					text
					id
					activities_id
					isPlaylist
          default_activities_id
				},
        images {
          id
          image_url
        }
        recommended {
          title
          link
        }
        mashup_data {
          id
          name
          data_point_details {
            id
            name
          }
        }
        materials_required {
          name
          image
        }
        getCollectionPlaylist_details {
          id
          title
          background_image
          isPlaylist
        }
      }
    }
  `,
};

export const VIEW_DRAFT_ACTIVITY_IMAGE = {
  query: `
		query getOneDraftActivity($activities_draft_id: ID) {
      getOneDraftActivity(activities_draft_id: $activities_draft_id) {
    		activity_details{
      		insights_on_activity{
						is_viewed,
						is_finished,
						like_count,
						dislike_count,
						average_time_complete,
						easy{
							age_group,
							count
						},
						medium{
							age_group,
							count
						},
						hard{
							age_group,
							count
						}
					},
					name,
					image_source_text,
					image_source_link,
					url,
					channel_url,
					channel_name,
					steps_source_text,
					steps_source_link,
				},
				steps{
					title,
					desc,
					image
				}
				images{
					image_url
				}
  		}
		}
	`,
};

export const VIEW_LIBRARY = {
  query: `
    query getOneLibrary($id: ID!){
			getAge{
        age_groups{
          id,
          age,
          is_active
        }
        age_dificulty{
          id,
          dificulty,
          is_active
        }
      }
   		getOneLibrary( id:$id ) {
				library_details{
					insights_on_library{
						is_viewed,
						is_finished,
						like_count,
						dislike_count,
						average_time_complete,
						easy{
							age_group,
							count
						},
						medium{
							age_group,
							count
						},
						hard{
							age_group,
							count
						}
					},
					video_link,
					id,
					libraries_draft_id,
					is_selected,
					status,
					default_libraries_id,
					user_activity_type,
					activity_state,
					name,
					user_name,
					title,
					author,
					source,
					video_link,
					pdf_name,
					pdf_url,
					pdf_source_text,
					pdf_source_url,
					video_credit_text,
					video_credit_link,
					trailer_url,
					trailer_credit_text,
					trailer_credit_url,
					no_of_episodes,
					released_year,
					feature_length,
					country_of_orgin,
					illustrator,
					publisher,
					no_of_pages,
					brief_desc,
					long_desc,
					time,
					suitable_for_id,
					suitable_for,
					read_along_text,
					read_along_link,
					safety,
					safety_desc,
					performer,
					album,
					label,
					lyrics_text,
					lyrics_link,
					lyrics,
					tags {
            id,
            name
          }
				},
				thumb_images{
					image_url id is_active
				},
				materials_required{
					name,
					image
				},
				age_groups{
					id,
					age_group{
						id
						age,
						is_active
					},
					age_group_dificulty{
						id
						dificulty,
						is_active
					}
					is_active
				},
				interests{
					interest_category_name{
						name
					},
					interest_details{
						id name is_active interest_categories_id
					}
				},
				prerequisites{
					library_name id libraries_id
				},
				recommended{
					title,
					link
				},
				mashup_data{
					id,
					name,
					data_point_details{
						id,
						name
					}
				}
  		}
		}
	`,
};

export const VIEW_DRAFT_LIBRARY = {
  query: `
    query getOneDraftLibrary($libraries_draft_id: ID!){
			getAge{
        age_groups{
          id,
          age,
          is_active
        }
        age_dificulty{
          id,
          dificulty,
          is_active
        }
      }
   		getOneDraftLibrary(libraries_draft_id: $libraries_draft_id) {
				library_details{
					insights_on_library{
						is_viewed,
						is_finished,
						like_count,
						dislike_count,
						average_time_complete,
						easy{
							age_group,
							count
						},
						medium{
							age_group,
							count
						},
						hard{
							age_group,
							count
						}
					},
					video_link,
					id,
					libraries_id,
					libraries_draft_id,
					is_selected,
					status,
					default_libraries_id,
					user_activity_type,
					activity_state,
					name,
					user_name,
					title,
					author,
					source,
					video_link,
					pdf_name,
					pdf_url,
					pdf_source_text,
					pdf_source_url,
					video_credit_text,
					video_credit_link,
					trailer_url,
					trailer_credit_text,
					trailer_credit_url,
					no_of_episodes,
					released_year,
					feature_length,
					country_of_orgin,
					illustrator,
					publisher,
					no_of_pages,
					brief_desc,
					long_desc,
					time,
					suitable_for_id,
					suitable_for,
					read_along_text,
					read_along_link,
					safety,
					safety_desc,
					performer,
					album,
					label,
					lyrics_text,
					lyrics_link,
					lyrics,
					tags {
            id,
            name
          }
				},
				thumb_images{
					image_url id is_active
				},
				materials_required{
					name,
					image
				},
				age_groups{
					id,
					age_group{
						id
						age,
						is_active
					},
					age_group_dificulty{
						id
						dificulty,
						is_active
					}
					is_active
				},
				interests{
					interest_category_name{
						name
					},
					interest_details{
						id name is_active interest_categories_id
					}
				},
				prerequisites{
					library_name id libraries_id
				},
				recommended{
					title,
					link
				},
				mashup_data{
					id,
					name,
					data_point_details{
						id,
						name
					}
				}
  		}
		}
	`,
};

export const VIEW_PROGRESS_ACTIVITY = {
  query: `
    query getAllActivity($id: ID!,$sort:String,$filter:[FilterInputType]){
   		getAllActivity( collections_id:$id,sort:$sort,filter:$filter ) {
				activity_details{
					activity_reference_images
					url
					steps
					name
					status
					id
					activity_state
					pdf_url
					thumb_images{
						id
						image_url
					}
    		}
    		collection_details{
      		id
      		name
      		is_publish
      	}
      	activity_in_details{
        	name
        	status
        	activity_status
      	}
    	}
  	}
	`,
};
export const APPROVED_ACTIVITY = {
  query: `
		query getApprovedActivity($search:String){
			getApprovedActivity( search:$search ){
				id,
				name,
				is_selected,
				interests{
					name
				}
				thumb_images{
					image_url
				}
			}
		}
	`,
};

export const APPROVED_LIBRARY = {
  query: `
		query getApprovedLibrary($default_libraries_id: ID, $search: String!){
  		getApprovedLibrary(default_libraries_id:$default_libraries_id, search: $search){
  			id,
				is_selected,
				activity_state,
				name,
				status,
  			interests{
    			name
				}
				thumb_images{
					image_url
				}
			}
  	}
  `,
};

export const CHANGE_ACTIVITY_STATE = {
  query: `mutation changeActivityState($activities_id:ID,$activity_state:String){
		changeActivityState(activities_id:$activities_id,activity_state:$activity_state){
			activity_details {
				activity_changed
				activity_message
			}
  	}
	}
`,
};

export const CHANGE_LIBRARY_STATE = {
  query: `mutation changeLibActivityState($libraries_id:ID,$activity_state:String){
		changeLibActivityState(libraries_id:$libraries_id,activity_state:$activity_state){
			library_details {
				library_changed
				library_message
			}
  	}
	}
`,
};

export const ADD_ACTIVITY_COLLECTION = {
  query: `
    mutation addActivityCollection($activities_id: ID, $collections_id: [ID]){
			addActivityCollection(activities_id: $activities_id, collections_id: $collections_id){
    		activity_details {
					id
					name
				}
				collection_details {
					id
					name
				}
  		}
		}
  `,
};

export const ADD_ACTIVITY_PLAYLIST = {
  query: `
    mutation addActivityPlaylist($activities_id: ID, $playlist_id: [ID]){
			addActivityPlaylist(activities_id: $activities_id, playlist_id: $playlist_id){
    		activity_details {
					id
					name
				}
				playlist_details {
					id
					name
				}
  		}
		}
  `,
};

export const APPROVED_ACTIVITY_MUTATION = {
  query: `
		mutation addActivityCollection($activities_id: ID, $collections_id: [ID], $playlist_id: [ID]){
			addActivityCollection(activities_id: $activities_id, collections_id: $collections_id){
    		activity_details {
					id
					name
				}
				collection_details {
					id
					name
				}
  		}
  		addActivityPlaylist(activities_id: $activities_id, playlist_id: $playlist_id){
    		activity_details {
					id
					name
				}
				playlist_details {
					id
					name
				}
  		}
		}
	`,
};
export const SAVE_COLLECTION_MUTATION = {
  query: `
		mutation editActivityCollection($collections_id: ID, $activities_id: [ID], $name: String){
			editActivityCollection(collections_id: $collections_id, activities_id: $activities_id, name: $name){
				activity_details{
					id
					name
				}
				collection_details{
					id
					name
				}
  		}
		}
	`,
};

export const SAVE_LIBRARY_MUTATION = {
  query: `
		mutation editLibraryCollection($libcollections_id:ID,$libraries_id:[ID]){
    	editLibraryCollection(libcollections_id:$libcollections_id,libraries_id:$libraries_id){
				library_details{
					video_link,
					id,
					name,
					activity_state,
					status
				},
				collection_details{
					id,
					name,
					total_library
				}
			}
  	}
	`,
};

export const PUBLISH_COLLECTION_MUTATION = {
  query: `
		mutation editActivityCollection($collections_id: ID){
			publishCollection(collections_id: $collections_id){
    		collection_details{
				is_publish
    	}
  	}
	}
`,
};

export const PUBLISH_LIBRARY_COLLECTION = {
  query: `
  	mutation publishLibraryCollection($libcollections_id:ID){
      publishLibraryCollection(libcollections_id:$libcollections_id){
        collection_details{
          id,
          name,
          is_active,
          is_publish,
          total_library
        }
      }
		}
	`,
};

export const ARCHIVE_COLLECTION = {
  query: `mutation archiveCollection($id: ID){
  archiveCollection(collections_id:$id){
    collection_details{
      id
    }
  }
}
  `,
};

export const ARCHIVE_LIBRARY_COLLECTION = {
  query: `mutation archiveLibraryCollection($libcollections_id: ID){
      archiveLibraryCollection(libcollections_id:$libcollections_id){
    		collection_details{
      		id
    		}
  		}
		}
  `,
};

export const SEND_LIBRARY_FOR_APPROVAL = {
  query: `
		mutation sendForApproval($libraries_draft_id: ID){
			sendForApproval(libraries_draft_id: $libraries_draft_id){
				id
				status
				message
			}
		}
	`,
};

export const ADD_BOOK_DRAFT_LIBRARY = {
  query: `
		mutation addDraftLibrary(
			$libraries_draft_id: ID,
			$libraries_id: ID,
			$user_activity_type: String,
			$activity_state: String,
			$default_libraries_id: ID,
			$name: String,
			$author: String,
			$illustrator: String,
			$publisher: String,
			$thumb_image: [LibraryThumbImageInputType],
			$age_group: [LibraryAgeGroupInputType],
			$interest_categories_id: ID,
			$interest_id: [ID],
			$prerequisites: [LibraryPrerequisitesInputType],
			$brief_desc: String,
			$long_desc: String,
			$time: Int,
			$feature_length: String,
			$suitable_for_id: ID,
			$safety: String,
			$safety_desc: String,
			$no_of_pages: ID,
			$read_along_text: String,
			$read_along_link: String,
			$recommended: [LibraryRecommendedInputType]
			$pdf_url: String,
    	$pdf_name: String,
      $pdf_source_text: String,
      $pdf_source_url: String,
      $tags_id: [ID],
		){
			addDraftLibrary(
				libraries_draft_id: $libraries_draft_id,
				libraries_id: $libraries_id,
				user_activity_type: $user_activity_type,
				activity_state: $activity_state,
				default_libraries_id: $default_libraries_id,
				name: $name,
				author: $author,
				illustrator: $illustrator,
				publisher: $publisher,
				thumb_image: $thumb_image,
				age_group: $age_group,
				interest_categories_id: $interest_categories_id,
				interest_id: $interest_id,
				prerequisites: $prerequisites,
				brief_desc: $brief_desc,
				long_desc: $long_desc,
				time: $time,
				feature_length: $feature_length,
				suitable_for_id: $suitable_for_id,
				safety: $safety,
				safety_desc: $safety_desc,
				no_of_pages: $no_of_pages,
				read_along_text: $read_along_text,
				read_along_link: $read_along_link,
				recommended: $recommended,
				pdf_name: $pdf_name,
      	pdf_url: $pdf_url,
      	pdf_source_text: $pdf_source_text,
      	pdf_source_url: $pdf_source_url,
      	tags_id: $tags_id,
			){
  			library_details{
				id,
				libraries_draft_id,
				name,
			},
		}
	}
`,
};

export const APPROVE_BOOK_LIBRARY = {
  query: `mutation approveLibrary(
			$libraries_draft_id: ID,
			$libraries_id: ID,
			$user_activity_type: String,
			$activity_state: String,
			$default_libraries_id: ID,
			$name: String,
			$author: String,
			$illustrator: String,
			$publisher: String,
			$thumb_image: [LibraryThumbImageInputType],
			$age_group: [LibraryAgeGroupInputType],
			$interest_categories_id: ID,
			$interest_id: [ID],
			$prerequisites: [LibraryPrerequisitesInputType],
			$brief_desc: String,
			$long_desc: String,
			$time: Int,
			$feature_length: String,
			$suitable_for_id: ID,
			$safety: String,
			$safety_desc: String,
			$no_of_pages: ID,
			$read_along_text: String,
			$read_along_link: String,
			$recommended: [LibraryRecommendedInputType]
			$mashup: [LibraryMasupdataInputType]
			$pdf_url: String,
    	$pdf_name: String,
      $pdf_source_text: String,
      $pdf_source_url: String,
      $tags_id: [ID],
		){
			approveLibrary(
				libraries_draft_id: $libraries_draft_id,
				libraries_id: $libraries_id,
				user_activity_type: $user_activity_type,
				activity_state: $activity_state,
				default_libraries_id: $default_libraries_id,
				name: $name,
				author: $author,
				illustrator: $illustrator,
				publisher: $publisher,
				thumb_image: $thumb_image,
				age_group: $age_group,
				interest_categories_id: $interest_categories_id,
				interest_id: $interest_id,
				prerequisites: $prerequisites,
				brief_desc: $brief_desc,
				long_desc: $long_desc,
				time: $time,
				feature_length: $feature_length,
				suitable_for_id: $suitable_for_id,
				safety: $safety,
				safety_desc: $safety_desc,
				no_of_pages: $no_of_pages,
				read_along_text: $read_along_text,
				read_along_link: $read_along_link,
				recommended: $recommended,
				mashup: $mashup,
				pdf_name: $pdf_name,
      	pdf_url: $pdf_url,
      	pdf_source_text: $pdf_source_text,
      	pdf_source_url: $pdf_source_url,
      	tags_id: $tags_id,
			){
			  library_details{
			    id,
			    name,
			  },
			}
		}
	`,
};

export const ADD_DOC_DRAFT_LIBRARY = {
  query: `mutation addDraftLibrary(
			$libraries_draft_id: ID,
			$libraries_id: ID,
			$user_activity_type: String,
			$activity_state: String,
			$default_libraries_id: ID,
			$name: String,
			$author: String,
			$source: String,
			$video_link: String,
			$video_credit_text: String,
			$video_credit_link: String,
			$trailer_url: String,
			$trailer_credit_text: String,
			$trailer_credit_url: String,
			$no_of_episodes: String,
			$released_year: String,
			$country_of_orgin: String,
			$material_required:[LibraryMaterialType],
			$thumb_image: [LibraryThumbImageInputType],
			$age_group: [LibraryAgeGroupInputType],
			$interest_categories_id: ID,
			$interest_id: [ID],
			$prerequisites: [LibraryPrerequisitesInputType],
			$brief_desc: String,
			$long_desc: String,
			$time: Int,
			$feature_length: String,
			$suitable_for_id: ID,
			$safety: String,
			$safety_desc: String,
			$recommended: [LibraryRecommendedInputType]
			$mashup: [LibraryMasupdataInputType]
			$pdf_url: String,
    	$pdf_name: String,
      $pdf_source_text: String,
      $pdf_source_url: String,
      $tags_id: [ID],
		){
			addDraftLibrary(
				libraries_draft_id: $libraries_draft_id,
				libraries_id: $libraries_id,
				user_activity_type: $user_activity_type,
				activity_state: $activity_state,
				default_libraries_id: $default_libraries_id,
				name: $name,
				author: $author,
				source:$source,
				video_link:$video_link,
				video_credit_text:$video_credit_text,
				video_credit_link:$video_credit_link,
				trailer_url: $trailer_url,
				trailer_credit_text: $trailer_credit_text,
				trailer_credit_url: $trailer_credit_url,
				no_of_episodes:$no_of_episodes,
				released_year:$released_year,
				country_of_orgin:$country_of_orgin,
				material_required:$material_required,
				thumb_image: $thumb_image,
				age_group: $age_group,
				interest_categories_id: $interest_categories_id,
				interest_id: $interest_id,
				prerequisites: $prerequisites,
				brief_desc: $brief_desc,
				long_desc: $long_desc,
				time: $time,
				feature_length: $feature_length,
				suitable_for_id: $suitable_for_id,
				safety: $safety,
				safety_desc: $safety_desc,
				recommended: $recommended,
				mashup: $mashup,
				pdf_name: $pdf_name,
      	pdf_url: $pdf_url,
      	pdf_source_text: $pdf_source_text,
      	pdf_source_url: $pdf_source_url,
      	tags_id: $tags_id,
			){
  			library_details{
    			id,
					libraries_draft_id,
					name,
				}
			}
		}
	`,
};

export const APPROVE_DOC_LIBRARY = {
  query: `mutation approveLibrary(
			$libraries_draft_id: ID,
			$libraries_id: ID,
			$user_activity_type: String,
			$activity_state: String,
			$default_libraries_id: ID,
			$name: String,
			$author: String,
			$source: String,
			$video_link: String,
			$video_credit_text: String,
			$video_credit_link: String,
			$trailer_url: String,
			$trailer_credit_text: String,
			$trailer_credit_url: String,
			$no_of_episodes: String,
			$released_year: String,
			$country_of_orgin: String,
			$material_required:[LibraryMaterialType],
			$thumb_image: [LibraryThumbImageInputType],
			$age_group: [LibraryAgeGroupInputType],
			$interest_categories_id: ID,
			$interest_id: [ID],
			$prerequisites: [LibraryPrerequisitesInputType],
			$brief_desc: String,
			$long_desc: String,
			$time: Int,
			$feature_length: String,
			$suitable_for_id: ID,
			$safety: String,
			$safety_desc: String,
			$recommended: [LibraryRecommendedInputType]
			$mashup: [LibraryMasupdataInputType]
			$pdf_url: String,
    	$pdf_name: String,
      $pdf_source_text: String,
      $pdf_source_url: String,
      $tags_id: [ID],
		){
			approveLibrary(
				libraries_draft_id: $libraries_draft_id,
				libraries_id: $libraries_id,
				user_activity_type: $user_activity_type,
				activity_state: $activity_state,
				default_libraries_id: $default_libraries_id,
				name: $name,
				author: $author,
				source:$source,
				video_link:$video_link,
				video_credit_text:$video_credit_text,
				video_credit_link:$video_credit_link,
				trailer_url: $trailer_url,
        trailer_credit_text: $trailer_credit_text,
        trailer_credit_url: $trailer_credit_url,
				no_of_episodes:$no_of_episodes,
				released_year:$released_year,
				country_of_orgin:$country_of_orgin,
				material_required:$material_required,
				thumb_image: $thumb_image,
				age_group: $age_group,
				interest_categories_id: $interest_categories_id,
				interest_id: $interest_id,
				prerequisites: $prerequisites,
				brief_desc: $brief_desc,
				long_desc: $long_desc,
				time: $time,
				feature_length: $feature_length,
				suitable_for_id: $suitable_for_id,
				safety: $safety,
				safety_desc: $safety_desc,
				recommended: $recommended,
				mashup: $mashup,
				pdf_name: $pdf_name,
      	pdf_url: $pdf_url,
      	pdf_source_text: $pdf_source_text,
      	pdf_source_url: $pdf_source_url,
      	tags_id: $tags_id,
			){
        library_details{
          id,
          name
				}
			}
		}
	`,
};

export const ADD_MUSIC_DRAFT_LIBRARY = {
  query: `mutation addDraftLibrary(
			$libraries_draft_id: ID,
			$libraries_id: ID,
			$user_activity_type: String,
			$activity_state: String,
			$default_libraries_id: ID,
			$name: String,
			$thumb_image: [LibraryThumbImageInputType],
			$performer: String,
			$video_link: String,
			$video_credit_text: String,
			$video_credit_link: String,
			$author: String,
			$released_year: String,
			$age_group: [LibraryAgeGroupInputType],
			$interest_categories_id: ID,
			$interest_id: [ID],
			$brief_desc: String,
			$long_desc: String,
			$time: Int,
			$suitable_for_id: ID,
			$safety: String,
			$safety_desc: String,
			$album: String,
			$label: String,
			$lyrics_text: String,
			$lyrics_link: String,
			$lyrics: String,
			$prerequisites: [LibraryPrerequisitesInputType],
			$recommended: [LibraryRecommendedInputType]
			$mashup: [LibraryMasupdataInputType]
			$pdf_url: String,
    	$pdf_name: String,
      $pdf_source_text: String,
      $pdf_source_url: String,
      $tags_id: [ID],
		){
			addDraftLibrary(
				libraries_draft_id: $libraries_draft_id,
				libraries_id: $libraries_id,
				user_activity_type: $user_activity_type,
				activity_state: $activity_state,
				default_libraries_id: $default_libraries_id,
				name: $name,
				thumb_image: $thumb_image,
				performer:$performer,
				video_link:$video_link,
				video_credit_text:$video_credit_text,
				video_credit_link:$video_credit_link,
				author:$author,
				released_year:$released_year,
				age_group: $age_group,
				interest_categories_id: $interest_categories_id,
				interest_id: $interest_id,
				brief_desc: $brief_desc,
				long_desc: $long_desc,
				time: $time,
				suitable_for_id: $suitable_for_id,
				safety: $safety,
				safety_desc: $safety_desc,
				album:$album,
				label:$label,
				lyrics_text:$lyrics_text,
				lyrics_link:$lyrics_link,
				lyrics:$lyrics,
				prerequisites: $prerequisites,
				recommended: $recommended,
				mashup: $mashup,
				pdf_name: $pdf_name,
      	pdf_url: $pdf_url,
      	pdf_source_text: $pdf_source_text,
      	pdf_source_url: $pdf_source_url,
      	tags_id: $tags_id,
			){
  			library_details{
    			id,
    			libraries_draft_id,
					name
  			}
  		}
		}
	`,
};

export const APPROVE_MUSIC_LIBRARY = {
  query: `mutation approveLibrary(
		$libraries_draft_id: ID,
		$libraries_id: ID,
		$user_activity_type: String,
		$activity_state: String,
		$default_libraries_id: ID,
		$name: String,
		$thumb_image: [LibraryThumbImageInputType],
		$performer: String,
		$video_link: String,
		$video_credit_text: String,
		$video_credit_link: String,
		$author: String,
		$released_year: String,
		$age_group: [LibraryAgeGroupInputType],
		$interest_categories_id: ID,
		$interest_id: [ID],
		$brief_desc: String,
		$long_desc: String,
		$time: Int,
		$suitable_for_id: ID,
		$safety: String,
		$safety_desc: String,
		$album: String,
		$label: String,
		$lyrics_text: String,
		$lyrics_link: String,
		$lyrics: String,
		$prerequisites: [LibraryPrerequisitesInputType],
		$recommended: [LibraryRecommendedInputType]
		$mashup: [LibraryMasupdataInputType]
		$pdf_url: String,
    $pdf_name: String,
		$pdf_source_text: String,
		$pdf_source_url: String,
		$tags_id: [ID],
	){
		approveLibrary(
			libraries_draft_id: $libraries_draft_id,
			libraries_id: $libraries_id,
			user_activity_type: $user_activity_type,
			activity_state: $activity_state,
			default_libraries_id: $default_libraries_id,
			name: $name,
			thumb_image: $thumb_image,
			performer:$performer,
			video_link:$video_link,
			video_credit_text:$video_credit_text,
			video_credit_link:$video_credit_link,
			author:$author,
			released_year:$released_year,
			age_group: $age_group,
			interest_categories_id: $interest_categories_id,
			interest_id: $interest_id,
			brief_desc: $brief_desc,
			long_desc: $long_desc,
			time: $time,
			suitable_for_id: $suitable_for_id,
			safety: $safety,
			safety_desc: $safety_desc,
			album:$album,
			label:$label,
			lyrics_text:$lyrics_text,
			lyrics_link:$lyrics_link,
			lyrics:$lyrics,
			prerequisites: $prerequisites,
			recommended: $recommended,
			mashup: $mashup,
			pdf_name: $pdf_name,
			pdf_url: $pdf_url,
			pdf_source_text: $pdf_source_text,
			pdf_source_url: $pdf_source_url,
			tags_id: $tags_id,
		){
			library_details{
				id,
				name
			}
	  }
	}`,
};

export const GET_ALL_LIBRARY_COLLECTION = {
  query: `
  	query getAllLibraryCollection($filter:[FilterInputType]){
      getAllLibraryCollection(filter: $filter) {
        published_libcollection {
          id
          name
          total_library
          is_publish
          background_image
          default_libraries_id
        }
        inprogress_libcollection {
          id
          name
          total_library
          is_publish
          background_image
          default_libraries_id
        }
        archived_libcollection {
          id
          name
          total_library
          is_publish
          background_image
          default_libraries_id
        }
        library_cards_collection {
          id
          name
          total_library
          is_publish
          background_image
          default_libraries_id
        }
        total_published
        total_inprogress
        total_library_cards
      }
  	}
	`,
};

export const GET_LC_CHART_DATA = {
  query: `
  	query getDataForGraph($lc_id: ID! ) {
  		getDataForGraph (lc_id: $lc_id){
  			data
			}
		}
	`,
};

export const GET_INTEREST_CATEGORY = {
  query: `
  	query getInterestCategory($sort: String!, $search: String!) {
    	getInterestCategory(sort: $sort, search: $search) {
				intrest_cat{
					id,
					name,
					total_interest
					color_code
					is_active
				}
				total_count
  		}
  		getGlobalInterestCategory( sort: $sort search: $search ) {
        intrest_cat{
          id,
          name,
          is_active,
          color_code,
          total_interest,
          background_image
        }
      }
		}
	`,
};

export const LC_DASHBOARD = {
  query: `
  	query getDashboard($sort:String,$search:String, $userId: ID!, $page: Int){
  		getDashboardActivity(sort:$sort,search:$search, userId: $userId, page: $page){
				totalCount
				activity_details{
					id,
					name,
					activity_reference_images,
					url,
					steps,
					pdf_url,
					interests{
						interest_details{
							name
						}
					},
					thumb_images{
						image_url
					},
					insights_on_activity{
						is_finished,
						is_viewed,
						average_time_complete,
						like_count,
						dislike_count
						easy { age_group count }
            medium { age_group count }
            hard { age_group count }
					}
				},
  		}
			getDashboardLibrary(sort:$sort,search:$search, userId: $userId){
				library_details{
					video_link,
					id,
					name,
					pdf_url,
					interests{
						interest_details{
							name
						}
					},
					thumb_images{
						image_url
					},
					insights_on_library{
						is_finished,
						is_viewed,
						average_time_complete,
						like_count,
						dislike_count
						easy { age_group count }
            medium { age_group count }
            hard { age_group count }
					}
				},
			}
  	}
	`,
};

export const GET_INTERESTS = {
  query: `
    query getInterest($interest_categories_id: ID!, $search: String!,$sort: String! ){
      getInterest( interest_categories_id:$interest_categories_id, search: $search, sort: $sort ) {
        interest_details {
          id
          name
          interest_categories_id
        }
        interest_category_name {
          name
        }
      }
    }
  `,
};

export const GET_ONE_INTEREST = {
  query: `
    query getOneInterest($id: ID!){
      getOneInterest( id:$id ) {
        interest_details {
          id
          name
          desc
          profile_image
          interest_categories_id
          lc_name
          activity_name
					library_name
					do
				 read
				 watch
				 listen


        }
      }
    }
  `,
};

export const GET_PROFILE = {
  query: `
    query getLearningCentre {
      getLearningCentre {
        user_details {
          name
          displayName
          email
          profile_image
          address
          phone_no
          bio
          latitude
          longitude
          loginType
        }
      }
    }
  `,
};

export const GET_LC_PROFILE = {
  query: `
    query getLcProfile {
      getInterestCategory {
        is_added
        intrest_cat {
          id
          name
          is_active,
          total_interest
          background_image
        }
      }
      getLcInterestCategory {
        intrest_cat{
          id,
          name,
          is_active
          is_added,
          background_image
          total_interest
        }
      }
    }
  `,
};

export const SAVE_PROFILE = {
  query: `
    mutation editLearningCentre($name: String, $displayName: String, $bio: String, $profileImage: String, $phone_no: String, $address: String, $latitude: Float, $longitude: Float, $interest_categories_id: [ID]) {
      editLearningCentre(name: $name, displayName: $displayName, bio: $bio, profileImage: $profileImage, phone_no: $phone_no, address: $address, latitude: $latitude, longitude: $longitude, interest_categories_id: $interest_categories_id) {
        interest_categories {
          id
        }
        user_details {
          id
          name
          phone_no
          profile_image
        }
      }
    }
  `,
};

export const USER_CHANGE_PASSWORD = {
  query: `mutation userChangePassword($oldpassword: String, $password: String) {
    userChangePassword(oldpassword: $oldpassword, password: $password) {
      user_details {
        password
        }
      }
    }
  `,
};

export const GET_ALL_LIBRARY = {
  query: `
  	query getAllLibrary($libcollections_id: ID!,$filter:[FilterInputType], $search: String!, $sort: String!){
			getAllLibrary(libcollections_id:$libcollections_id,filter:$filter,search: $search, sort: $sort) {
				library_details{
					video_link,
					id,
					default_libraries_id
					name,
					status,
					title
					activity_state,
					thumb_images{
						image_url
					},
					pdf_url,
					interests{
						interest_details{
							name
						}
					}
				},
				total_count
			}
		}
	`,
};

export const GET_ALL_LIBRARY_CARD = {
  query: `
		query getAllLibrary($default_libraries_id: ID!, $filter:[FilterInputType], $search: String!, $sort: String!, $only_draft: Boolean, $page: Int){
			getAllLibrary(default_libraries_id:$default_libraries_id, filter:$filter, search: $search, sort: $sort, only_draft: $only_draft, page: $page) {
				library_details{
					video_link,
					id,
					libraries_id,
					libraries_draft_id,
					default_libraries_id,
					name,
					user_name,
					status,
					title,
					activity_state,
					pdf_url,
					is_draft,
					is_changed,
					libraries_draft_state,
					thumb_images{
						image_url
					},
					interests{
						interest_details{
							name
						}
					},
				},
				total_count
			}
		}
	`,
};

export const GET_ALL_PENDING_LIBRARY = {
  query: `
		query getPendingLibrary($default_libraries_id: ID! $filter: [FilterInputType] $search: String! $sort: String!) {
			getPendingLibrary(default_libraries_id: $default_libraries_id filter: $filter search: $search sort: $sort) {
				library_details {
					video_link
					id
					libraries_draft_id
					default_libraries_id
					name
					user_name
					status
					title
					activity_state
					pdf_url
					is_draft
					is_changed
					libraries_draft_state
					thumb_images {
						image_url
					}
					interests {
						interest_details {
							name
						}
					}
				}
				total_count
			}
		}
	`,
};

export const ADD_LIBRARY_COLLECTION = {
  query: `
    mutation addLibraryCollection($default_libraries_id:ID,$libraries_id:[ID]!){
      addLibraryCollection(default_libraries_id:$default_libraries_id,libraries_id:$libraries_id){
        library_details{
          video_link,
          id,
          name,
          activity_state,
          status
        },
        collection_details{
          id,
          name,
          is_publish
        }
      }
    }
  `,
};

export const ADD_INTEREST_CATEGORY = {
  query: `
    mutation addInterestCategory($name: String!, $background_image: String!) {
      addInterestCategory(name: $name, background_image: $background_image) {
        intrest_cat{
          id,
          name
        }
      }
    }
  `,
};

export const EDIT_INTEREST_CATEGORY = {
  query: `
    mutation editInterestCategory($id: ID!, $name: String!, $background_image: String!) {
      editInterestCategory(id: $id, name: $name, background_image: $background_image) {
        intrest_cat {
          name
        }
      }
    }
  `,
};

export const DELETE_INTEREST_CATEGORY = {
  query: `
    mutation deleteInterestCategory($cat_id: ID){
      deleteInterestCategory(cat_id: $cat_id) {
        id
        status
        message
      }
    }
  `,
};

export const ADD_INTEREST = {
  query: `
    mutation addInterest($name: String!, $desc: String!, $interest_categories_id: ID!, $profile_image: String!) {
      addInterest(name: $name, desc: $desc, interest_categories_id: $interest_categories_id, profile_image: $profile_image) {
        interest_details{
          id,
          name
        }
      }
    }
  `,
};

export const EDIT_INTEREST = {
  query: `
    mutation editInterest($id: ID!, $name: String!, $desc: String!, $interest_categories_id: ID!, $profile_image: String!) {
      editInterest(id: $id, name: $name, desc: $desc, interest_categories_id: $interest_categories_id, profile_image: $profile_image) {
        interest_details {
          id
          name
          desc
          profile_image
          interest_categories_id
          lc_name
          activity_name
          library_name
        }
      }
    }
  `,
};

export const DELETE_INTEREST = {
  query: `
    mutation deleteInterest($id: ID!){
      deleteInterest(id: $id){
        interest_details {
          id
          name
          desc
          profile_image
          interest_categories_id
          lc_name
          activity_name
          library_name
        }
      }
    }
  `,
};

export const SUGGEST_INTEREST = {
  query: `
    mutation suggestInterestV2($interest_categories_id: ID $name: String $profile_image: String $desc: String){
      suggestInterestV2(interest_categories_id: $interest_categories_id name: $name profile_image: $profile_image desc: $desc){
        interest_details{
          id
          suggestedBy
          name
          profile_image
          interest_categories_id
          desc
        }
      }
    }
  `,
};

export const GET_SUGGESSTED_INTEREST = {
  query: `
    query seeSuggestInterest($id: ID) {
      seeSuggestInterest(id: $id){
        interest_details{
          id
          suggestedBy
          name
          profile_image
          interest_categories_id
          desc
        }
      }
    }
  `,
};

export const APPROVE_INTEREST = {
  query: `
    mutation approveInterest($interest_id: ID $interest_categories_id: ID $name: String $profile_image: String $desc: String $status: Int){
      approveInterest(interest_id: $interest_id interest_categories_id: $interest_categories_id name: $name profile_image: $profile_image desc: $desc status: $status)
    }
  `,
};

export const GET_DATA_TYPE = {
  query: `
    query getDataGrade($search: String!, $sort: String!){
	    getDataGrade(search: $search, sort: $sort){
	      data_grade {
	        id
	        name
	        total_data_points
	      }
	    }
		}
  `,
};

export const ADD_DATA_TYPE = {
  query: `
    mutation addDataGrade($name: String!) {
      addDataGrade(name: $name) {
        data_grade{
          id,
          name,
          total_data_points
        }
      }
    }
  `,
};

export const DELETE_DATATYPE = {
  query: `
    mutation DeleteDataGrade($grade_id: ID) {
      DeleteDataGrade(grade_id: $grade_id) {
        id
        status
        message
      }
    }
   `,
};

export const GET_SUGGESSTED_DATAPOINTS = {
  query: `
    query seeSuggestDataPoint($id: ID) {
      seeSuggestDataPoint(id: $id){
        data_point_details{
          id
          suggestedBy
          name
          profile_image
          data_grade_types_id
          desc
        }
      }
    }
  `,
};

export const APPROVE_DATAPOINTS = {
  query: `
    mutation approveDataPointV2($data_point_id: ID $data_grade_types_id: ID $name: String $profile_image: String $desc: String $status: Int){
      approveDataPointV2(data_point_id: $data_point_id data_grade_types_id: $data_grade_types_id name: $name profile_image: $profile_image desc: $desc status: $status)
    }
  `,
};

export const SUGGEST_DATAPOINT = {
  query: `
    mutation suggestDataPointV2($data_grade_types_id: ID $name: String $profile_image: String $desc: String){
      suggestDataPointV2(data_grade_types_id: $data_grade_types_id name: $name profile_image: $profile_image desc: $desc){
        data_point_details{
          id
          suggestedBy
          name
          profile_image
          data_grade_types_id
          desc
        }
      }
    }
  `,
};

export const GET_DATAPOINTS = {
  query: `
   query getDataPoint($data_grade_types_id: ID!, $search: String!, $sort: String!){
     getDataPoint( data_grade_types_id:$data_grade_types_id, search: $search, sort: $sort ) {
       data_point_details {
         id
         name
         desc
         profile_image
         data_grade_types_id
       }
			 data_grade_name {
				 name
			 }
     }
 }`,
};

export const GET_ONE_DATAPOINT = {
  query: `
   query getOneDataPoint($id: ID!){
     getOneDataPoint( id:$id ) {
       data_point_details {
         id
         name
         desc
         profile_image
         data_grade_types_id
				 lc_name
				 activity_name
				 library_name
				 do
				 read
				 watch
				 listen

       }
     }
 }`,
};

export const EDIT_DATAPOINT = {
  query: `
  mutation editDataPoint($id: ID!, $name: String!, $desc: String!, $data_grade_types_id: ID!, $profile_image: String!) {
    editDataPoint(id: $id, name: $name, desc: $desc, data_grade_types_id: $data_grade_types_id, profile_image: $profile_image) {
			data_point_details {
				id
				name
				desc
				profile_image
				data_grade_types_id
			}
    }
  }
  `,
};

export const ADD_DATAPOINT = {
  query: `
  mutation addDataPoint($name: String!, $desc: String!, $data_grade_types_id: ID!, $profile_image: String!) {
    addDataPoint(name: $name, desc: $desc, data_grade_types_id: $data_grade_types_id, profile_image: $profile_image) {
			data_point_details {
				id
				name
				desc
				profile_image
				data_grade_types_id
			}
    }
  }
  `,
};

export const DELETE_DATAPOINT = {
  query: `
    mutation deleteDataPoint($id: ID!) {
      deleteDataPoint(id: $id){
        data_point_details {
          id
          name
          desc
          profile_image
          data_grade_types_id
        }
      }
    }
  `,
};

export const GET_ALL_LEARNING_CENTRE = {
  query: `
    query getDashboardLearningCentreV2($search: String!, $page: Int, $filter: [contentcreaterFilterInputType]){
      getDashboardLearningCentreV2(search: $search){
        learningCenterDetails {
          id
          name
          students_count
          interest_categories {
            name
          }
        }
      }
      getDashboardContentCreator(search: $search, page: $page, filter: $filter){
        contentCreatorDetails {
					totalPages
          contentCreator_details {
            id
            name
            role_id
            cards_count
            coll_plycount
          }
        }
      }
    }
  `
};

export const GET_ONE_LEARNING_CENTRE = {
  query: `
    query getLearningCentre($name: String!){
      getLearningCentre(name:$name) {
        user_details {
          id
          name
          email
          bio
          profile_image
          phone_no
          address
        }
        interest_categories{
          name
          color_code
          background_image
        }
        interests {
          id
          name
        }
      }
    }
  `,
};
export const GET_ACTIVITIES_OF_ONE_LEARNING_CENTRE = {
  query: `
   query getDashboardActivity($userId: ID!, $sort: String!, $search: String!){
     getDashboardActivity(userId:$userId, sort: $sort, search: $search) {
			total_count
			activity_details{
				age_groups{
					age_group{
						id,
						age,
						is_active
					}
					age_group_dificulty{
						id,
						dificulty,
						is_active
					}
				}
				difficulty,
				default_activities_id,
				user_activity_type,
				time,
				activity_reference_images,
				url,
				pdf_url,
				steps,
				id,
				activities_draft_id,
				name,
				user_name,
				activity_state,
				status,
				is_draft,
				is_changed,
				video_link,
				activities_draft_state,

				insights_on_activity{
					easy{
						age_group
					}
					medium{
						age_group
					}
					hard{
						age_group
					}
				}
				thumb_images{
					id,image_url
				}

			}
     }
 }`,
};

export const GET_LIBRARIES_OF_ONE_LEARNING_CENTRE = {
  query: `
		query getDashboardActivity($userId: ID!, $sort: String!, $search: String!){
			getDashboardLibrary(userId:$userId,  sort: $sort, search: $search) {
				library_details {
					video_link,
					id,
					default_libraries_id,
					user_activity_type,
					activity_state,
					name,
					title,
					author,
					illustrator,
					publisher,
					no_of_pages,
					brief_desc,
					long_desc,
					time,
					suitable_for_id,
					suitable_for,
					read_along_text,
					read_along_link,
					safety,
					safety_desc,
					interests {
						interest_details {
							id
							name
						}
					}
					thumb_images {
						id
						image_url
					}
				}
			}
		}
	`,
};

export const GET_ALL_APPROVED_ACTIVITIES = {
  query: `
	  query getAllCollection($sort: String, $search: String, $filter: [FilterInputType], $page: Int) {
      getAllCollection(sort: $sort, search: $search, filter: $filter, page: $page){
        all_activities{
          activity_details{
						thumb_images {
							id
							image_url
						}
            activity_reference_images
            url
            steps
            id
            name
            user_name
            status
            activity_state

            interests {
              interest_details {
                id
                name
              }
            }
          }
          total_count
			  }
      }
    }
  `,
};

export const GET_ALL_PENDING_ACTIVITIES = {
  query: `
	  query getPendingActivity($sort: String, $search: String,$filter: [FilterInputType], $page: Int) {
      getPendingActivity(sort: $sort, search: $search, filter: $filter, page: $page){
				activity_details{
					activity_reference_images
					url
					activities_draft_id
					steps
					id
					name
					user_name
					status
					activity_state
					is_draft
					is_changed
					thumb_images{
						id
						image_url
					}
					interests {
						interest_details {
							id
							name
						}
					}
				}
				total_count
      }
    }
  `,
};

export const GET_LIB_COLLECTION_FOR_ADMIN = {
  query: `query getDashboardLibrary($sort:String,$search:String, $userId: ID!){
		getDashboardLibrary(sort:$sort,search:$search, userId: $userId){
			library_details{
				video_link,
				id,
				name,
				interests{
					interest_details{
						name
					}
				},
				thumb_images{
					image_url
				},
				insights_on_library{
					is_finished,
					is_viewed,
					average_time_complete,
					like_count,
					dislike_count
				}
			},
		}
	}
	`,
};

export const GET_NOTIFICATIONS_FOR_ADMIN = {
  query: `
		query masterNotificationV2 {
			masterNotificationV2{
				count
				notification_details{
					name
					notifications{
						id
						created_by_id
						created_by_name
						first_name
						last_name
						parent_firstName
            parent_lastName
            student_firstName
            student_lastName
						notification_type
						notification_id
						library_default_id
						notification_name
						comments
						city
						phone_no
						email
						status
						seen
						createdAt
						updatedAt
						role_id
						workshop_details {
							id
							workshop_name
							creator_name
							workshop_date
							workshop_time
							workshop_amount
							workshop_type
							status
							user{
								id
								name
								last_name
								role_id
							  }
						  }
					}
				}
			}
		}
	`,
};

export const GET_PARENT_DASHBOARD = {
  query: `
		query ParentDashbordV2($date: String) {
			ParentDashbordV2 (date: $date){
				journal {
					overall_journal_list {
						card_details {
							id
							name
							card_id
							playlist_name
							image
							notes
							activity_status
							library_status
							resumed_time
							time_spent
							total_time_spent
							time
							url
							steps
							video_link
							video_view
							steps_view
							pictures {
								id
								image
								type
								notes
								createdAt
							}
							sharing_img_url
							bitly_url
							journal_state
							journal_image_notes_state
							disp_time
							journal_time
							lc_name
							feedback_state
							feedback_desc
							feedback_voice
							type
							session
							student_response
							playlist_id
							default_activities_id
							user {
								name
								role_id
							}
						}
						lc_name
					}
					student_id
					student_name
				}
				students{
					id
					student_details {
						fname
						lname
						username
						dob
						age_group
						age_group_id
						attendance
						profile_imageUrl
					}
				}
				user_details {
					id
					role_id
					first_name
					last_name
					email
					bio
					profile_image
					phone_no
					address
					country
					is_active
					status
					comments
					createdAt
					onboardedAt
				  }
			}
		}
	`,
};

export const GET_PARENT_FEEDBACK = {
  query: `
		query ParentFeedbacks ($student_id: ID, $sort: Boolean) {
			ParentFeedbacks(student_id: $student_id, sort: $sort) {
				feedbacklist{
					id
					student_id
					feedback_type
					feedback
					card_id
					date
					lc_name
					card_name
					feedback_voice
				}
			}
		}
	`,
};

export const PARENT_CALENDER_VIEW = {
  query: `
		query webCalenderView($date: String, $student_id: ID){
			webCalenderView(date: $date, student_id: $student_id){
				calender_details{
					activity
					libraries
					playlist
					date
					journal_image_notes_state
					default_activities_id
					activity_types {
						default_activities_id
					}
				}
			}
		}
	`,
};

export const ADD_LEARNING_CENTER = {
  query: `
		mutation addLearningCentreV2 ($email: String,$role_id:Int) {
			addLearningCentreV2(email: $email,role_id:$role_id) {
				id
				invite_token
				expiry_date
			}
		}
	`,
};

export const APPROVE_PARENT = {
  query: `
		mutation postParentV2($invite: [InviteTypeInput], $student_id: ID, $notification_id: ID, $comment: String) {
			postParentV2(invite: $invite, student_id: $student_id, notification_id: $notification_id, comment: $comment) {
				invite {
					id
					invite_token
				}
			}
		}
	`,
};

export const ONHOLD_PARENT = {
  query: `
    mutation ParentRejectV2($notification_id: ID, $comment: String){
      ParentRejectV2(notification_id: $notification_id, comment: $comment){
        id
        status
        message
      }
    }
  `,
};

export const PARENT_ADD_STUDENT = {
  query: `
    mutation ParentAddStudent($first_name: String, $last_name: String, $dob: String, $username: String){
      ParentAddStudent(first_name: $first_name, last_name: $last_name, dob: $dob, username: $username){
        id
        status
        message
      }
    }
  `,
};

export const GET_ALL_PARENTS = {
  query: `
    query getDashboardLearningCentreV2($search: String, $parent: Boolean, $page: Int, $filter: [parentFilterInputType]) {
      getDashboardLearningCentreV2(search: $search, parent: $parent, page: $page, filter: $filter) {
				count
        parent_details {
          id
          name
          email
          status
          comments
          phone_no
          address
          createdAt
          onboard
          onboardedAt
          invite_link
          country
          students {
            fname
            lname
          }
        }
      }
    }
  `,
};

export const RESEND_INVITE = {
  query: `
    query resendEmailV2 ($email: String!) {
      resendEmailV2(email: $email) {
        id
        status
      }
    }
  `,
};

export const GET_COUNTRY_CITY_LIST = {
  query: `
    query getCountryCityListV2($country: String, $city: String) {
      getCountryCityListV2(country: $country, city: $city) {
        country_list {
          name
        }
        city_list {
          name
        }
      }
    }
  `
};

export const GET_PARENT_DETAILS = {
  query: `
    query ParentDashbordV2($date: String, $email: String, $status: String){
      ParentDashbordV2(date: $date, email: $email, status: $status){
        user_details{
          id
					first_name
					last_name
          email
          bio
          address
          profile_image
          phone_no
          status
          comments
          createdAt
          onboardedAt
        }
        students{
          id
          student_details{
            fname
            lname
            username
            dob
            age_group
            attendance
						status

          }
        }
      }
    }
  `,
};
// requestSent
// is_password_changed

export const CLEAR_NOTIFICATION = {
  query: `
	mutation clearNotificationV2{
		clearNotificationV2{
			id
		}
	}`,
};

export const LIST_EXPERT_COLLECTION_PLAYLIST = {
  query: `
		query ListExpertCollectionPlaylist($only_playlist:Boolean,$only_collection:Boolean,$added:Boolean,$sort:String,$search:String,$filter:[FilterInputType]){
			ListExpertCollectionPlaylist(sort:$sort,only_playlist:$only_playlist,only_collection:$only_collection,search:$search,filter:$filter,added:$added){
				cards{
					user_id
					id
					title
					name
					is_archive
					is_publish
					background_image
					is_playlist
					no_cards
					is_added
					user{
						name
					}
				}
			}
		}
	`,
};
  