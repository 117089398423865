import React, {Component} from 'react';
import { Card, Icon } from "antd";
import axios from "axios";
import "./index.css"
import {CHECK_PARENT_INVITE, PARENT_ONBOARDING} from "../../graphql";
import {openNotification, triggerGAEvent} from "../../utils";
import Invalid from "../../static/PasswordReset/Invalid.svg";
import Logo from "../../static/nav/breadcrumb_logo.png";
import styled from "styled-components";
import VerifyDetails from "./VerifyDetails";
import SetUpParent from "./SetUpParent";
import Confirm from "./Confirm";
import SetUpChild from "./SetUpChild";
import TopStrip from "../../components/Modals/TopStrip";
import ParentWarningModal from "../../components/Modals/ParentWarningModal";

const StepContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 2rem;
`;

const Step = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-right: ${props => props.moreMargin ? '90px' : props.last ? '0' : props.custom ? props.custom : '86px'};
 	div {
 		position: relative;
		height: 45px;
		width: 45px;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #FFFFFF;
		border-radius: 4px;
		font-size: 20px;
		margin-bottom: 8px;
 	}
 	.step-bar {
		position: absolute;
		right: 45px;
		width: 146px;
		height: 4px;
 	}
 	span {
		font-weight: 500;
		font-size: 14px;
		line-height: 26px;
		color: #474747;
	 }
	@media only screen and (max-width: 576px) {
		div {
			position: relative;
			height: 35px;
			width: 35px;
		}
		span {
			font-weight: 800;
			font-size: 12px;
		}
		.step-bar {
			position: absolute;
			right: 35px;
			top: 15px;
			width: 56px;
			height: 4px;
		 }
		.step3 .step3-bar {
			width: 53px;
		}
	}
 	.step0 {
 		background-color: ${props => props.selected ? '#FFC90D' : '#D8D8D8'};
 	}.step1, .step1-bar {
 		background-color: ${props => props.selected ? '#5432BA' : '#D8D8D8'};
 	}.step2, .step2-bar {
 		background-color: ${props => props.selected ? '#3EC1F0' : '#D8D8D8'};
 	}.step3, .step3-bar {
 		background-color: ${props => props.selected ? '#EC1A58' : '#D8D8D8'};
 	}
`;

class ParentInvite extends Component {
	constructor(props) {
		super(props);
		this.state = {
			warning_visible: true,
			current_step: 0,
			email: "",
			status: true,
			name: "",
			last_name: "",
			password: "",
			confirm: "",
			isChild: false,
			phone_no: "",
			country: "India",
			country_code: "+91",
			city: "",
			dob: "",
			student_fname: "",
			student_lname: "",
			username: "",
		};
	}
	
	fetchData = () => {
		let mutation = CHECK_PARENT_INVITE;
		mutation.variables = {
			inviteToken: this.props.match.params.id,
		};
		axios.post(`${process.env.REACT_APP_API}`, mutation)
			.then(res => {
				let phone_no = "";
				let country_code = "";
				res.data.data.checkInviteLinkV2.phone_no.split(" ").forEach((p, i) => {
					console.log(res.data.data.checkInviteLinkV2.phone_no, p);
					if (p.includes("+")) {
						country_code = p;
					} else {
						country_code = "+91";
						phone_no = phone_no + p;
					}
				});
				this.setState({
					email: res.data.data.checkInviteLinkV2.email,
					name: res.data.data.checkInviteLinkV2.name,
					last_name: res.data.data.checkInviteLinkV2.last_name,
					student_fname: res.data.data.checkInviteLinkV2.student_fname,
					student_lname: res.data.data.checkInviteLinkV2.student_lname ? res.data.data.checkInviteLinkV2.student_lname : res.data.data.checkInviteLinkV2.isChild ? " " : "",
					status: res.data.data.checkInviteLinkV2.status,
					isChild: res.data.data.checkInviteLinkV2.isChild,
					city: res.data.data.checkInviteLinkV2.city,
					country: res.data.data.checkInviteLinkV2.country || "India",
					// country_code: res.data.data.checkInviteLinkV2.phone_no.includes("+") ? res.data.data.checkInviteLinkV2.phone_no.split(" ")[0] : "+91",
					// phone_no: res.data.data.checkInviteLinkV2.phone_no.includes("+") ? res.data.data.checkInviteLinkV2.phone_no.split(" ")[1] : res.data.data.checkInviteLinkV2.phone_no,
					country_code: country_code,
					phone_no: phone_no,
				});
				!res.data.data.checkInviteLinkV2.status && triggerGAEvent('Parent On-boarding', 'Visiting an Invalid link');
			}).catch(error => {
				this.setState({ status: false })
				console.log(error)
		});
	};
	
	onBoardParent = () => {
		triggerGAEvent('Parent On-boarding', 'On-borading a parent');
		let mutation = PARENT_ONBOARDING;
		mutation.variables = {
			inviteToken: this.props.match.params.id,
			password: this.state.password,
			first_name: this.state.name,
			last_name: this.state.last_name,
			student_firstName: this.state.student_fname,
			student_lastName: this.state.student_lname,
			dob: this.state.dob,
			username: this.state.username || null,
			country: this.state.country,
		};
		axios.post(`${process.env.REACT_APP_API}`, mutation)
			.then(res => {
				if (res.data.errors) {
					openNotification("error", res.data.errors[0].message);
				} else {
					localStorage.setItem("role_id", "3");
					localStorage.setItem("token", res.data.data.OnboardingParentV2.token);
					this.props.history.push('/parent/workshops?isTour=1')
				}
			}).catch(error => console.log(error));
	};
	
	componentDidMount() {
		this.fetchData();
	}
	
	updateData = (stateKey, value) => {
		let state_copy = { ...this.state };
		state_copy[stateKey] = value;
		this.setState({ ...state_copy });
	};
	
	nextStep = () => {
		this.setState((state, props) => ({
			current_step: state.current_step + 1
		}));
	};
	
	previousStep = () => {
		this.setState((state, props) => ({
			current_step: state.current_step - 1
		}));
	};
	
	render() {
		console.log("this.state", this.state);
		return (
			<React.Fragment>
				{/* <TopStrip /> */}
				{/* <ParentWarningModal
					visible={this.state.warning_visible}
					setVisible={(value) => this.setState({ warning_visible: value })}
				/> */}
				{!this.state.status && (
					<div className="tabscroll d-flex align-items-center justify-content-center">
						<div className="d-flex flex-column align-items-center justify-content-center bg-white">
							<img src={Invalid} alt="request processed" style={{ padding: '4rem 8rem 2rem 4rem', maxWidth: '100vw' }}/>
							<p className="text-dark font-16">This is an invalid link</p>
						</div>
					</div>
				)}
				{this.state.status && (
					<div className="parent-card-wrap">
						<div className="parent-nav">
							<img
								src={Logo}
								alt="breadcrumb logo"
								style={{ width: 152, height: 25 }}
							/>
						</div>
						{/* Max dev parent signup */}
						<Card className="max-dev-parentSignup" style={{ width: 1050 }}>
							<div className="d-flex align-items-center justify-content-center">
								<div style={{ width: '80%' }}>
									<StepContainer>
										<Step selected={this.state.current_step >= 0}>
											<div className="step0">
												<Icon type="form" />
											</div>
											<span>Verify Details</span>
										</Step>
										<Step selected={this.state.current_step >= 1}>
											<div className="step1">
												<div className="step-bar step1-bar"/>
												<Icon type="user-add" />
											</div>
											<span>Setup Parent Profile</span>
										</Step>
										<Step selected={this.state.current_step >= 2} moreMargin last={this.state.isChild}>
											<div className="step2">
												<div className="step-bar step2-bar"/>
												<Icon type="check-circle" />
											</div>
											<span>Confirmation</span>
										</Step>
										{!this.state.isChild && (
											<Step selected={this.state.current_step >= 3} last>
												<div className="step3">
													<div className="step-bar step3-bar"/>
													<Icon type="usergroup-add" />
												</div>
												<span>Setup Child Profile</span>
											</Step>
										)}
									</StepContainer>
									<div>
										{this.state.current_step === 0 && (
											<VerifyDetails data={this.state} updateData={this.updateData} nextStep={this.nextStep} previousStep={this.previousStep} />
										)}
										{this.state.current_step === 1 && (
											<SetUpParent data={this.state} updateData={this.updateData} nextStep={this.nextStep} previousStep={this.previousStep} />
										)}
										{this.state.current_step === 2 && (
											<Confirm data={this.state} updateData={this.updateData} nextStep={this.state.isChild ? this.onBoardParent : this.nextStep} previousStep={this.previousStep} />
										)}
										{this.state.current_step === 3 && (
											<SetUpChild data={this.state} updateData={this.updateData} nextStep={this.onBoardParent} previousStep={this.previousStep} />
										)}
									</div>
								</div>
							</div>
						</Card>
						{/* Mbl dev parent signup */}
						<div className="mbl-dev-parentSignup" >
							<div className="d-flex align-items-center justify-content-center">
								<div>
									<StepContainer>
										<Step selected={this.state.current_step >= 0} custom={`15px`}>
											<div className="step0">
												<Icon type="form" />
											</div>
											<span>Verify Details</span>
										</Step>
										<Step selected={this.state.current_step >= 1} custom={`15px`}>
											<div className="step1">
												<div className="step-bar step1-bar"/>
												<Icon type="user-add" />
											</div>
											<span>Parent Profile</span>
										</Step>
										<Step selected={this.state.current_step >= 2} custom={`15px`}>
											<div className="step2">
												<div className="step-bar step2-bar"/>
												<Icon type="check-circle" />
											</div>
											<span>Confirmation</span>
										</Step>
										{!this.state.isChild && (
											<Step selected={this.state.current_step >= 3} custom={`15px`}>
												<div className="step3">
													<div className="step-bar step3-bar"/>
													<Icon type="usergroup-add" />
												</div>
												<span>Child Profile</span>
											</Step>
										)}
									</StepContainer>
									<div>
										{this.state.current_step === 0 && (
											<VerifyDetails data={this.state} updateData={this.updateData} nextStep={this.nextStep} previousStep={this.previousStep} />
										)}
										{this.state.current_step === 1 && (
											<SetUpParent data={this.state} updateData={this.updateData} nextStep={this.nextStep} previousStep={this.previousStep} />
										)}
										{this.state.current_step === 2 && (
											<Confirm data={this.state} updateData={this.updateData} nextStep={this.state.isChild ? this.onBoardParent : this.nextStep} previousStep={this.previousStep} />
										)}
										{this.state.current_step === 3 && (
											<SetUpChild data={this.state} updateData={this.updateData} nextStep={this.onBoardParent} previousStep={this.previousStep} />
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
			</React.Fragment>
		);
	}
}

export default ParentInvite;
