import {
  Button,
  Col,
  Collapse,
  Icon,
  Input,
  message,
  Modal,
  Radio,
  Row,
  Tag,
  notification
} from 'antd'
import axios, { post } from 'axios'
import _ from 'lodash'
import React from 'react'
// import { Link } from "react-router-dom";
import pdf from '../../assets/pdf.png'
import BreadcrumbView from '../../components/BreadCrumb'
import ButtonC from '../../components/ButtonC'

import {
  APPROVE_WORKSHOP,
  VIEW_WORKSHOP,
  VIEW_DRAFT_WORKSHOP,
  REJECTED_WORKSHOP
} from './graphql'
import { CHANGE_ACTIVITY_STATE } from '../../graphql'
import Error500Activity from '../../static/activity/500Err.svg'
import emptyState from '../../static/activity/emptyStateSearch.png'
import ErrorActivity from '../../static/activity/error.svg'
import Moment from 'moment'

import {
  isLink,
  openNotification,
  titleCase,
  triggerGAEvent
} from '../../utils'
import Empty from './../../static/empty.svg'
import MeetingCopy from './../../static/workshop/meetingCopy.svg'
import './workshop.css'
import WorkshopImageView from '../../components/ImageUploadCrop/workshopImageview'

const Panel = Collapse.Panel
const RadioGroup = Radio.Group
const Search = Input.Search
const { TextArea } = Input

class ViewWorkshop extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      is_selected: false,
      is_loading: true,
      is_error: false,
      is_500: false,
      is_updated: false,
      showAllPlaylist: true,
      showAllCollection: true,
      imagePreview: false,
      changesReq: '',
      changecomm: false,
      changesComments: '',
      zoom_details: {},
      workshop_details: {
        insights_on_activity: {
          is_viewed: '',
          is_finished: '',
          like_count: '',
          dislike_count: '',
          average_time_complete: '',
          easy: { age_group: '', count: '' },
          medium: { age_group: '', count: '' },
          hard: { age_group: '', count: '' }
        },
        activities_id: '',
        user_id: '',
        user_details: {
          id: '',
          name: '',
          role_id: ''
        },
        user_activity_type: '',
        activity_state: '',
        status: '',
        workshop_name: '',
        learning_outcome: '',
        description: '',
        workshop_time: '',
        workshop_date: '',
        workshop_duration: '',
        workshop_duration_type: '',
        workshop_type: '',
        zoomJoinUrl: '',
        workshop_amount: '',
        prerequisites: '',
        reference_link: '',
        skills_required: '',
        status: '',
        trailer_link: '',
        minimum_participants: '',
        maximum_participants: '',
        pdf_name: '',
        pdf_url: '',
        pdf_source_text: '',
        pdf_source_url: ''
      },
      interest_category_name: '',
      interest_category_id: '',
      age_groups: [],
      thumb_images: [],
      age_groups_dificulty: [],
      interests: [],
      materials_required: [],
      steps: [],
      tags: [],
      prerequisites: [],
      mashup_data: [],
      recommended: [],
      getCollectionPlaylist_details: [],
      pdf_name: '',
      registered_stud: [1],
      oldWorkshopData: {
        workshop_details: {
          workshop_name: false,
          learning_outcome: false,
          description: false,
          workshop_time: false,
          workshop_date: false,
          workshop_duration: false,
          workshop_duration_type: false,
          workshop_type: false,
          workshop_amount: false,
          prerequisites: false,
          reference_link: false,
          trailer_link: false,
          minimum_participants: false,
          maximum_participants: false
        },
        age_groups: false,
        thumb_images: false,
        interests: false,
        materials_required: false,
        tags: false
      },
      name: '',
      registered_student_count: 0,
      changesReqModal: false,
      sort: 'newest',
      searchText: '',
      filter: {},
      allCollection: [],
      allOrgCollection: [],
      selectedCollection: [],
      workshop_start: [false],
      pastWorkshop: [false],
      changePrimaryHostModal: false,
      primary_host: '',
      truthObj: {
        changesReq: false
      }
    }
    // this.onSearchFetchData = _.debounce(this.onSearchFetchData, 700);
  }

  fetchWorkshopData = () => {
    let query = VIEW_WORKSHOP
    query.variables = {
      id: parseInt(this.props.match.params.id, 10)
    }
    post(`${process.env.REACT_APP_API}`, query).then(res => {
      // this.setState({
      //   is_selected:
      //     res.data.data.getOneActivityV2.workshop_details[0].is_selected
      // });
      if (res.data.errors) {
        openNotification('error', res.data.errors[0].message)
        this.setState({ is_error: true })
      } else {
        if (
          this.state.role_id === 1 &&
          res.data.data.getOneWorkshop.workshop_details.id
        ) {
          this.setState({ is_updated: true })
        }
        console.log(
          'getoneworkshop view ',
          res.data.data.getOneWorkshop.workshop_details
        )
        let start_time = res.data.data.getOneWorkshop.workshop_details
          .workshop_time
          ? Moment(
              res.data.data.getOneWorkshop.workshop_details.workshop_time,
              'h m a'
            ).format('h:mm A')
          : ''
        let workshop_dateText = res.data.data.getOneWorkshop.workshop_details
          .workshopDate
          ? res.data.data.getOneWorkshop.workshop_details.workshopDate.map(x =>
              Moment(x).format('ll')
            )
          : ''

        let imagePreview = res.data.data.getOneWorkshop.workshop_details
          .reference_link
          ? res.data.data.getOneWorkshop.workshop_details.reference_link
              .split('.')
              .reverse()[0] != 'pdf'
          : false

        let pastWorkshop =
          res.data.data.getOneWorkshop.workshop_details &&
          res.data.data.getOneWorkshop.workshop_details.Dates &&
          res.data.data.getOneWorkshop.workshop_details.Dates.length > 0
            ? res.data.data.getOneWorkshop.workshop_details.Dates.map(x => {
                if (
                  Moment.utc(parseInt(x.end_date))
                    // .add(x.duration, 'hours')
                    .format() <= Moment().format()
                ) {
                  return true
                } else {
                  return false
                }
              })
            : [false]
        console.log('date time', pastWorkshop)

        let workshop_start =
          res.data.data.getOneWorkshop.workshop_details &&
          res.data.data.getOneWorkshop.workshop_details.Dates &&
          res.data.data.getOneWorkshop.workshop_details.Dates.length > 0
            ? res.data.data.getOneWorkshop.workshop_details.Dates.filter(
                x =>
                  Moment.utc(parseInt(x.workshop_Date)).format('YYYY-MM-DD') ==
                  Moment().format('YYYY-MM-DD')
              ).map(xx => {
                // console.log(
                //   Moment(xx.start_time, "HH:mm").subtract(10, 'minutes').format("HH:mm"),
                //   Moment(xx.end_time, "HH:mm").format("HH:mm"),
                //   (Moment(xx.start_time, "HH:mm").subtract(10, 'minutes').format("HH:mm") <=
                //   Moment().format("HH:mm")),
                //   (Moment().format("HH:mm") <=
                //   Moment(xx.end_time, "HH:mm").format("HH:mm"))
                // )
                if (
                  Moment(xx.start_time, 'HH:mm')
                    .subtract(30, 'minutes')
                    .format('HH:mm') <= Moment().format('HH:mm') &&
                  Moment().format('HH:mm') <=
                    Moment(xx.end_time, 'HH:mm').format('HH:mm')
                ) {
                  return true
                } else {
                  return false
                }
              })
            : [false]

        let zoom_det = res.data.data.getOneWorkshop.workshop_details
          .zoom_meeting
          ? JSON.parse(
              res.data.data.getOneWorkshop.workshop_details.zoom_meeting
            )
          : {}

        this.setState({
          is_loading: false,
          imagePreview: imagePreview,
          // is_selected:
          //   res.data.data.getOneWorkshop.workshop_details
          //     .is_selected,
          workshop_start,
          pastWorkshop,
          zoom_details: zoom_det,
          workshop_details: res.data.data.getOneWorkshop.workshop_details,
          registered_student_count:
            res.data.data.getOneWorkshop.registered_student_count,
          thumb_images: res.data.data.getOneWorkshop.workshop_details.featured_image.map(
            x => ({ image_url: x.image_url })
          ),
          age_groups: res.data.data.getOneWorkshop.Age_group
            ? res.data.data.getOneWorkshop.Age_group
            : [],
          // age_groups_Comp: res.data.data.getOneDraftActivity.age_groups.map(
          //   (aG) => ({
          //     age_group: { age: aG.age_group.age },
          //     age_group_dificulty: {
          //       dificulty: aG.age_group_dificulty.dificulty,
          //     },
          //   })
          // ),
          interests: res.data.data.getOneWorkshop.interest_details
            ? res.data.data.getOneWorkshop.interest_details.interests
            : [],
          interest_category_name: res.data.data.getOneWorkshop.interest_details
            ? res.data.data.getOneWorkshop.interest_details
                .interest_categories_name
            : '',
          interest_category_id: res.data.data.getOneWorkshop.interest_details
            ? res.data.data.getOneWorkshop.interest_details
                .interest_categories_id
            : '',
          pdf_name: res.data.data.getOneWorkshop.workshop_details.pdf_name
            ? res.data.data.getOneWorkshop.workshop_details.pdf_name
            : '',
          // interests_Comp: res.data.data.getOneDraftActivity.interests.map(
          //   (int) => ({
          //     interest_category_name: {
          //       name: int.interest_category_name
          //         ? int.interest_category_name.name
          //         : "",
          //     },
          //     interest_details: int.interest_details.map((x) => ({
          //       name: x.name,
          //     })),
          //   })
          // ),
          workshops_timeText: `${start_time}`,
          workshops_dateText: workshop_dateText
            ? workshop_dateText.join(', ')
            : '',
          materials_required: res.data.data.getOneWorkshop.Materials_required
            ? res.data.data.getOneWorkshop.Materials_required
            : [],
          // images: res.data.data.getOneDraftActivity.images.map((x) => ({
          //   image_url: x.image_url,
          // })),
          tags: res.data.data.getOneWorkshop.Workshop_tags
            ? res.data.data.getOneWorkshop.Workshop_tags
            : [],
          zoomJoinUrl: res.data.data.getOneWorkshop.workshop_details
            .zoom_meeting
            ? JSON.parse(
                res.data.data.getOneWorkshop.workshop_details.zoom_meeting
              ).start_url
            : ''
        })
      }
    })
  }

  fetchDraftData = () => {
    try {
      let query = VIEW_DRAFT_WORKSHOP
      query.variables = {
        workshop_draft_id: parseInt(this.props.match.params.id, 10)
      }
      post(`${process.env.REACT_APP_API}`, query)
        .then(res => {
          if (res.data.errors) {
            this.setState({ is_error: true })
            openNotification('error', res.data.errors[0].message)
          } else {
            if (
              res.data.data.getOneDraftWorkshop.workshop_details.status ===
              'Approved'
            ) {
              window.location.href = `/workshops/${res.data.data.getOneDraftWorkshop.workshop_details.id}/view`
            } else if (
              res.data.data.getOneDraftWorkshop.workshop_details.status ===
              'Draft'
            ) {
              this.props.history.push(
                `/workshops/${res.data.data.getOneDraftWorkshop.workshop_details.id}/edit/draft`
              )
            } else {
              if (
                this.state.role_id === 1 &&
                res.data.data.getOneDraftWorkshop.workshop_details.id
              ) {
                this.fetchOldWorkshopData(
                  res.data.data.getOneDraftWorkshop.workshop_details.id
                )
                this.setState({ is_updated: true })
              }
              console.log(
                'getonedraftworkshop view ',
                res.data.data.getOneDraftWorkshop.workshop_details
              )
              let start_time = res.data.data.getOneDraftWorkshop
                .workshop_details.workshop_time
                ? Moment(
                    res.data.data.getOneDraftWorkshop.workshop_details
                      .workshop_time,
                    'h m a'
                  ).format('h:mm A')
                : ''
              let workshop_dateText = res.data.data.getOneDraftWorkshop
                .workshop_details.workshopDate
                ? res.data.data.getOneDraftWorkshop.workshop_details.workshopDate.map(
                    x => Moment(x).format('ll')
                  )
                : ''

              let imagePreview = res.data.data.getOneDraftWorkshop
                .workshop_details.reference_link
                ? res.data.data.getOneDraftWorkshop.workshop_details.reference_link
                    .split('.')
                    .reverse()[0] != 'pdf'
                : false

              this.setState({
                is_loading: false,
                imagePreview: imagePreview,
                workshop_details:
                  res.data.data.getOneDraftWorkshop.workshop_details,
                thumb_images: res.data.data.getOneDraftWorkshop.workshop_details.featured_image.map(
                  x => ({ image_url: x.image_url })
                ),
                age_groups: res.data.data.getOneDraftWorkshop.Age_group
                  ? res.data.data.getOneDraftWorkshop.Age_group
                  : [],
                durationVal: res.data.data.getOneDraftWorkshop
                  .workshop_duration_type
                  ? res.data.data.getOneDraftWorkshop.workshop_duration_type
                  : '',
                changecomm: res.data.data.getOneDraftWorkshop.notifications
                  ? res.data.data.getOneDraftWorkshop.notifications != ''
                  : false,
                changesComments: res.data.data.getOneDraftWorkshop.notifications
                  ? res.data.data.getOneDraftWorkshop.notifications.comments
                  : '',
                interests: res.data.data.getOneDraftWorkshop.interest_details
                  ? res.data.data.getOneDraftWorkshop.interest_details.interests
                  : [],
                interest_category_name: res.data.data.getOneDraftWorkshop
                  .interest_details
                  ? res.data.data.getOneDraftWorkshop.interest_details
                      .interest_categories_name
                  : '',
                interest_category_id: res.data.data.getOneDraftWorkshop
                  .interest_details
                  ? res.data.data.getOneDraftWorkshop.interest_details
                      .interest_categories_id
                  : '',
                pdf_name: res.data.data.getOneDraftWorkshop.workshop_details
                  .pdf_name
                  ? res.data.data.getOneDraftWorkshop.workshop_details.pdf_name
                  : '',
                workshops_timeText: `${start_time}`,
                workshops_dateText: workshop_dateText
                  ? workshop_dateText.join(', ')
                  : '',
                materials_required: res.data.data.getOneDraftWorkshop
                  .Materials_required
                  ? res.data.data.getOneDraftWorkshop.Materials_required
                  : [],
                tags: res.data.data.getOneDraftWorkshop.Workshop_tags
                  ? res.data.data.getOneDraftWorkshop.Workshop_tags
                  : []
              })
            }
          }
        })
        .catch(e => {
          console.log('Error ==> ', e)
          this.setState({ is_500: true })
        })
    } catch (e) {
      this.setState({ is_500: true })
    }
  }

  fetchOldWorkshopData = id => {
    let query = VIEW_WORKSHOP
    query.variables = {
      workshop_draft_id: id
    }
    post(`${process.env.REACT_APP_API}`, query).then(res => {
      this.setState({ is_loading: false })
      if (res.data.errors) {
        // this.setState({ is_error: true });
        // openNotification("error", res.data.errors[0].message);
      } else {
        console.log('fetch old workshop data ==> ', res.data.data)
        let oldworkData = res.data.data.getOneWorkshop.workshop_details
        let oldWorkshopData = {
          workshop_details: {
            workshop_name:
              this.state.workshop_details.workshop_name !==
              oldworkData.workshop_name,
            learning_outcome:
              this.state.workshop_details.workshop_name !==
              oldworkData.workshop_name,
            description:
              this.state.workshop_details.description !==
              oldworkData.description,
            workshop_time:
              JSON.stringify(this.state.workshop_details.Dates.map(
                t => { return {
                  start_time: t.start_time
                }
              })) !==
              JSON.stringify(oldworkData.Dates.map(
                t => { return {
                  start_time: t.start_time
                }
              })),
            workshop_date:
            JSON.stringify(this.state.workshop_details.Dates.map(
              d => { return {
                workshop_Date: d.workshop_Date
              }
            })) !==
            JSON.stringify(oldworkData.Dates.map(
              d => { return {
                workshop_Date: d.workshop_Date
              }
            })),
            workshop_duration:
              JSON.stringify(this.state.workshop_details.Dates.map(
                dur => { return {
                  duration: dur.duration
                }
              })) !==
              JSON.stringify(oldworkData.Dates.map(
                dur => { return {
                  duration: dur.duration
                }
              })),
            workshop_duration_type:
              this.state.workshop_details.workshop_duration_type !==
              oldworkData.workshop_duration_type,
            workshop_type:
              this.state.workshop_details.workshop_type !==
              oldworkData.workshop_type,
            workshop_amount:
              this.state.workshop_details.workshop_amount !==
              oldworkData.workshop_amount,
            prerequisites:
              this.state.workshop_details.prerequisites !==
              oldworkData.prerequisites,
            reference_link:
              this.state.workshop_details.reference_link !==
              oldworkData.reference_link,
            trailer_link:
              this.state.workshop_details.trailer_link !==
              oldworkData.trailer_link,
            minimum_participants:
              this.state.workshop_details.minimum_participants !==
              oldworkData.minimum_participants,
            maximum_participants:
              this.state.workshop_details.maximum_participants !==
              oldworkData.maximum_participants
          },
          age_groups:
            JSON.stringify(
              res.data.data.getOneWorkshop.Age_group.map(aG => ({
                age_group_id: aG.age_group_id
              }))
            ) !==
            JSON.stringify(
              this.state.age_groups.map(aG => ({
                age_group_id: aG.age_group_id
              }))
            ),
          thumb_images:
            JSON.stringify(
              res.data.data.getOneWorkshop.workshop_details.featured_image.map(
                x => ({ image_url: x.image_url })
              )
            ) !== JSON.stringify(this.state.thumb_images),
          interests:
            JSON.stringify(
              res.data.data.getOneWorkshop.interest_details.interests.map(
                int => {
                  return {
                    name: int.name
                  }
                }
              )
            ) !==
            JSON.stringify(
              this.state.interests.map(int => {
                return {
                  name: int.name
                }
              })
            ),
          materials_required:
            JSON.stringify(res.data.data.getOneWorkshop.Materials_required.map(
              mat => { return {
                name: mat.name,
                image: mat.image
              }
            })) !==
            JSON.stringify(this.state.materials_required.map(
              mat => { return {
                name: mat.name,
                image: mat.image
              }
            })),
          tags:
            JSON.stringify(
              res.data.data.getOneWorkshop.Workshop_tags.map(tag => tag.name)
            ) !== JSON.stringify(this.state.tags.map(tag => tag.name))
        }
        this.setState({ oldWorkshopData: oldWorkshopData })
      }
    })
  }

  approveWorkshop = () => {
    this.setState({ is_loading: true, changePrimaryHostModal: false })
    let mutation = APPROVE_WORKSHOP
    mutation.variables = {
      workshop_draft_id: this.state.workshop_details.id,
      description: this.state.workshop_details.description,
      workshop_name: titleCase(
        this.state.workshop_details.workshop_name.trim()
      ),
      featured_image: this.state.workshop_details.featured_image,
      workshop_type: this.state.workshop_details.workshop_type,
      workshop_amount: this.state.workshop_details.workshop_amount,
      maximum_participants: this.state.workshop_details.maximum_participants,
      minimum_participants: this.state.workshop_details.minimum_participants,
      interest_categories_id: Number(this.state.interest_category_id),
      workshop_time: this.state.workshop_details.workshop_time,
      workshop_duration: this.state.workshop_details.workshop_duration,
      workshop_duration_type: this.state.workshop_details
        .workshop_duration_type,
      workshop_date: Moment(
        parseInt(this.state.workshop_details.workshop_date)
      ).format('YYYY-MM-DD'),
      workshop_dates: this.state.workshop_details.workshop_Date.map(x =>
        Moment(x).format('YYYY-MM-DD')
      ),
      workshop_schedule_date: this.state.workshop_details.workshop_schedule_date.map(
        x => Moment(x).format('YYYY-MM-DD')
      ),
      workshop_plan_id: this.state.workshop_details.workshop_plan_id,
      repeats_type: this.state.workshop_details.repeats_type,
      repeats_day:
        this.state.workshop_details.repeats_type != 'dnr'
          ? this.state.workshop_details.repeats_day
          : null,
      repeats_occurance: this.state.workshop_details.occurance,
      workshop_EndDate: this.state.workshop_details.end_date,
      tags_id: this.state.tags.map(int => parseInt(int.id, 10)),
      currency: this.state.workshop_details.currency,
      pdf_name: this.state.workshop_details.pdf_name,
      // workshopdatedraft: this.state.formValue.act_time,
      learning_outcome: this.state.workshop_details.learning_outcome,
      reference_link: this.state.workshop_details.reference_link,
      workshopmaterials: this.state.materials_required
        .filter(item => item.name !== '' && item.image !== '')
        .map(item => ({ name: item.name, image: item.image })),
      prerequisites: this.state.workshop_details.prerequisites,
      interest_id: this.state.interests.map(int => parseInt(int.id, 10)),
      age_group: this.state.age_groups.map(age => ({
        age_group_id: age.age_group_id
      })),
      trailer_link: this.state.workshop_details.trailer_link,
      workshop_common_timing: this.state.workshop_details.is_common_timing
        ? 1
        : 0,
      primary_host: this.state.primary_host
    }

    // console.log(mutation.variables);

    if (
      this.state.workshop_details.workshop_plan_id == 3 &&
      !this.state.workshop_details.is_common_timing
    ) {
      const indSelectDays = this.state.workshop_details.Dates.map(x => ({
        workshop_Date: Moment.utc(parseInt(x.workshop_Date)).format(
          'YYYY-MM-DD'
        ),
        start_time: x.start_time,
        duration: x.duration,
        duration_type: x.duration_type
      }))
      mutation.variables.workshop_nonConsecutive_type = indSelectDays
    }

    axios
      .post(`${process.env.REACT_APP_API}`, mutation)
      .then(res => {
        if (res.data.errors) {
          openNotification('error', res.data.errors[0].message)
          this.setState({
            is_loading: false,
            loading: false,
            shouldBlockNavigation: false
          })
        } else {
          this.setState({
            is_loading: false,
            loading: false,
            shouldBlockNavigation: false
          })
          openNotification('success', 'Workshop Approved successfully')
          this.props.history.push(
            `/workshops/${res.data.data.approveWorkshop.workshop_details.id}/view`
          )
          this.fetchWorkshopData()
        }
      })
      .catch(err => console.log(err))
  }

  rejectedWorkshop = () => {
    const { changesReq, workshop_details } = this.state
    if (changesReq.length && workshop_details.workshop_draft_id) {
      let mutation = REJECTED_WORKSHOP
      mutation.variables = {
        workshop_draft_id: workshop_details.workshop_draft_id,
        comments: changesReq
      }
      axios
        .post(`${process.env.REACT_APP_API}`, mutation)
        .then(res => {
          if (res.data.errors) {
            openNotification('error', res.data.errors[0].message)
            this.setState({
              is_loading: false,
              loading: false
            })
          } else {
            workshop_details.status =
              res.data.data.rejectWorkshop.workshop_details.status
            openNotification('success', 'Changes Required Sended Successfully')
            this.setState({
              is_loading: false,
              changesReqModal: false,
              changecomm: workshop_details.status == 'Rejected',
              changesComments:
                res.data.data.rejectWorkshop.workshop_details
                  .notification_details.comments,
              workshop_details
            })
            // this.props.history.push(
            //   `/workshops/${res.data.data.rejectWorkshop.workshop_details[0].id}/view`
            // );
          }
        })
        .catch(err => console.log(err))
    }
  }

  approveWorkshopBtnClick = () => {
    this.approveWorkshop()
  }

  onChangePrimaryHostModal = () => {
    this.setState({
      changePrimaryHostModal: !this.state.changePrimaryHostModal
    })
  }

  onChangePrimaryHost = (key, value) => {
    this.setState({
      [key]: value
    })
  }

  onChange = e => {
    triggerGAEvent('Workshops', 'Changing the state of the workshop')
    let mutation = CHANGE_ACTIVITY_STATE
    mutation.variables = {
      activities_id: this.props.match.params.id,
      activity_state: e
    }
    axios
      .post(`${process.env.REACT_APP_API}`, mutation)
      .then(res => {
        if (res.data.errors) {
          message.error(res.data.errors[0].message)
        } else {
          message.warning(
            res.data.data.changeActivityState.workshop_details.activity_message
          )
        }
      })
      .catch(err => console.log(err))
  }

  openModal = (index, value) => {
    this.setState({
      previewVisible: value
    })
  }

  confirmDialogModal = type => {
    const confirmDialog = Modal.confirm({
      title: 'Do you Want to proceed this?',
      content:
        'You have sent for the changes required to the content creator and they are working on it. Do you want to ignore and proceed?',
      okText: 'Yes',
      onOk: () => {
        if (type == 'Approve') {
          this.onChangePrimaryHostModal()
        } else {
          this.props.history.push(
            `/workshops/${this.props.match.params.id}/edit/draft`
          )
        }
      }
    })
    this.setState({ confirmDialog })
  }

  onChangesReqBtn = (key, value) => {
    triggerGAEvent('Workshops', 'Changes Required for workshop content')
    this.setState({ [key]: value })
  }

  TitleAndDesc = (title, desc, isChanged) => {
    return (
      <React.Fragment>
        <div className='d-flex'>
          <div className='title pb-18 text-uppercase'>{title}</div>
          {this.state.is_updated &&
            this.props.history.location.pathname.includes('draft') &&
            isChanged &&
            this.state.role_id === 1 && (
              <div className='workshopupdated-box'>Updated</div>
            )}
        </div>
        {desc !== '' && (
          <div className='desc p-0'>
            <h6>{desc}</h6>
          </div>
        )}
      </React.Fragment>
    )
  }

  ChangesRequiredBtnClick = () => {
    this.setState({
      changesReqModal: true
    })
  }

  copyToClipboard = e => {
    var textField = document.createElement('textarea')
    textField.innerText = `${this.state.zoom_details.join_url}`
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
    e.target.focus()
    notification['success']({
      message: 'Meeting Link Copied!',
      description: '',
      duration: 1
    })
  }

  getLocalStorage = () => {
    this.setState({
      role_id: Number(localStorage.getItem('role_id'))
    })
  }

  componentDidMount () {
    this.getLocalStorage()
    if (this.props.history.location.pathname.includes('draft')) {
      this.fetchDraftData()
    } else {
      this.fetchWorkshopData()
    }
    // setTimeout(() => {
    //   (this.state.role_id === 2 ||
    //     this.state.role_id === 4 ||
    //     this.state.role_id === 5) &&
    //     this.fetchAllCollection();
    // }, 500);
  }

  onCollapseChange = () => {
    triggerGAEvent('Workshops', 'Changing collapse in workshop view')
  }
  onChangeCheckbox = (index, value) => {
    const allCollection = [...this.state.allCollection]
    allCollection[index].isChecked = !allCollection[index].isChecked
    let selectedCollection = this.state.allCollection.filter(
      data => data.isChecked
    )
    this.setState({ allCollection, selectedCollection })
  }
  filterAllCollection = () => {
    const allCollection = [...this.state.allOrgCollection]
    if (this.state.showAllCollection && this.state.showAllPlaylist) {
      this.setState({ allCollection })
    } else if (!this.state.showAllCollection && !this.state.showAllPlaylist) {
      this.setState({ allCollection: [] })
    } else {
      const filteredCollection = allCollection
        .map(collection => {
          if (this.state.showAllCollection) {
            if (!collection.is_Playlist) {
              return collection
            }
          } else if (this.state.showAllPlaylist) {
            if (collection.is_Playlist) {
              return collection
            }
          }
        })
        .filter(data => data)
      this.setState({ allCollection: filteredCollection })
    }
  }
  onChangeCollectionType = (key, value) => {
    this.setState({ [key]: value }, () => {
      this.filterAllCollection()
    })
  }
  render () {
    const radioStyle = {
      display: 'block',
      height: '30px',
      lineHeight: '30px'
    }

    if (this.state.is_error || this.state.is_500) {
      return (
        <div className='col-8 mx-auto mt-5 pb-5 text-center'>
          <img
            src={this.state.is_500 ? Error500Activity : ErrorActivity}
            alt='error'
            className='img-fluid'
          />
        </div>
      )
    } else {
      return (
        <React.Fragment>
          <div className='bg-white'>
            <BreadcrumbView
              title={this.state.workshop_details.workshop_name}
              data={`/Workshops/${this.state.workshop_details.workshop_name.replace(
                /\//g,
                '~~'
              )}`}
            >
              {this.state.role_id === 1 &&
                this.props.history.location.pathname.includes('draft') && (
                  <div className='d-flex'>
                    {!this.state.is_loading && this.state.role_id == 1 && (
                      <React.Fragment>
                        <ButtonC
                          category='Workshops'
                          action='Changes Required of workshop Content'
                          size='large'
                          ghost
                          className='mr-4'
                          type='danger'
                          onClick={this.ChangesRequiredBtnClick}
                          disabled={
                            this.state.workshop_details.status === 'Rejected'
                          }
                        >
                          Changes required
                        </ButtonC>
                        <ButtonC
                          category='Workshops'
                          action='Edit draft Learning Content'
                          size='large'
                          ghost
                          className='mr-3'
                          type='primary'
                          onClick={() =>
                            this.state.workshop_details.status == 'Rejected'
                              ? this.confirmDialogModal('Edit')
                              : this.props.history.push(
                                  `/workshops/${this.props.match.params.id}/edit/draft`
                                )
                          }
                        >
                          Edit
                        </ButtonC>
                        <ButtonC
                          category='Workshops'
                          action='Approve Workshop Content'
                          size='large'
                          block
                          className='workshopApprbtn mr-3'
                          type='primary'
                          onClick={() =>
                            this.state.workshop_details.status == 'Rejected'
                              ? this.confirmDialogModal('Approve')
                              : this.onChangePrimaryHostModal()
                          }
                        >
                          Proceed to approve
                        </ButtonC>
                      </React.Fragment>
                    )}
                  </div>
                )}

              {!this.state.is_loading &&
                [2, 4, 5].includes(this.state.role_id) &&
                this.props.history.location.pathname.includes('draft') && (
                  <React.Fragment>
                    <ButtonC
                      category='Workshops'
                      action='Edit draft Workshop Content'
                      size='large'
                      ghost
                      className='w-250 mr-3'
                      type='primary'
                      onClick={() =>
                        this.props.history.push(
                          `/workshops/${this.props.match.params.id}/edit/draft`
                        )
                      }
                      disabled={this.state.workshop_details.status == 'Pending'}
                    >
                      Edit
                    </ButtonC>
                  </React.Fragment>
                )}

              {(this.state.role_id === 2 ||
                this.state.role_id === 4 ||
                this.state.role_id === 5) &&
                this.state.workshop_details.status != 'Approved' && (
                  <div>
                    {this.props.history.location.pathname.includes('draft') ? (
                      <React.Fragment>
                        {this.state.workshop_details.status == 'Pending' && (
                          <div className='d-flex'>
                            <Button
                              size='large'
                              className='pendingBtn-w250'
                              ghost
                              disabled={
                                this.state.workshop_details.status == 'Pending'
                              }
                            >
                              Pending Approval
                            </Button>
                          </div>
                        )}
                      </React.Fragment>
                    ) : (
                      <div className='d-flex'>
                        <ButtonC
                          category='Workshops'
                          action='Edit Approved Workshop Content'
                          size='large'
                          ghost
                          className='mr-3'
                          type='primary'
                          onClick={() =>
                            this.props.history.push(
                              `/workshops/${this.props.match.params.id}/edit`
                            )
                          }
                        >
                          Edit
                        </ButtonC>
                      </div>
                    )}
                  </div>
                )}

              {this.state.role_id == 1 &&
                this.state.workshop_details.status == 'Approved' && (
                  <div className='d-flex'>
                    <Button
                      size='large'
                      className='registeredstudBtn-w250'
                      ghost
                      onClick={() =>
                        this.props.history.push(
                          `/workshops/${this.state.workshop_details.id}/registered-students`
                        )
                      }
                    >
                      {`View registered Participants - ${
                        this.state.registered_student_count
                          ? this.state.registered_student_count
                          : 0
                      }`}
                    </Button>
                  </div>
                )}
            </BreadcrumbView>
          </div>
          {this.state.is_loading && (
            <div className='vh-100 z-index-1 opacity-black-5 align-items-center position-fixed col-12 fixed-top d-flex justify-content-center'>
              <Icon type='loading' theme='outlined' className='loading' />
            </div>
          )}
          <Modal
            visible={this.state.changesReqModal}
            maskClosable={false}
            footer={[
              <div className='d-flex justify-content-between align-items-center pl-3 pr-3 p-1'>
                <div className='fontColor-blue font-16 FontAvenirMedium' />
                <div>
                  <ButtonC
                    category='Activities'
                    action='Cancel for worshop content changes required'
                    size='large'
                    key='wback'
                    onClick={() =>
                      this.setState({
                        changesReqModal: false,
                        changesReq: ''
                      })
                    }
                  >
                    Cancel
                  </ButtonC>
                  <Button
                    key='wsubmit'
                    category='workshop Contents'
                    action='Changes required for workshop Content'
                    type='primary'
                    className='btn-changeHover'
                    size='large'
                    onClick={this.rejectedWorkshop}
                    disabled={this.state.changesReq.length < 1}
                  >
                    Send
                  </Button>
                </div>
              </div>
            ]}
            onCancel={() =>
              this.setState({ changesReqModal: false, changesReq: '' })
            }
            width='40%'
          >
            <h5 className='FontAvenirMedium pb-3 mb-3'>
              Changes required for workshop{' '}
            </h5>

            <h6 className='FontAvenirMedium pb-3 mb-2'>
              {this.state.workshop_details.workshop_name}{' '}
            </h6>

            <div className='border-bottom'></div>
            <div className='mt-4'>
              <TextArea
                key='workComments'
                rows={6}
                id={this.state.truthObj['changesReq'] ? 'has-error' : ''}
                placeholder=''
                value={this.state.changesReq}
                onChange={e => {
                  if (e.target.value.length <= 280) {
                    this.onChangesReqBtn('changesReq', e.target.value)
                  }
                }}
                onPaste={e => {
                  if (e.clipboardData.getData('Text').length > 280) {
                    this.onChangesReqBtn(
                      'changesReq',
                      e.clipboardData.getData('Text').substring(0, 280)
                    )
                  }
                }}
              />
              <span
                className='d-flex align-items-center justify-content-end'
                style={
                  this.state.changesReq.length >= 300
                    ? { color: '#FF0A00' }
                    : {}
                }
              >
                {this.state.changesReq.length} / 280
              </span>
            </div>
          </Modal>

          {[1, 2, 4, 5].includes(this.state.role_id) && this.state.changecomm && (
            <div className='m-3'>
              <div className='border-style Changecomment-border'>
                <span>
                  <h6 className='Changecomment'>
                    <Icon className='Changecomment-icon' type='info-circle' />
                    &emsp;&emsp;&emsp;
                    <span>
                      {this.state.changesComments
                        ? this.state.changesComments
                        : ``}
                    </span>
                  </h6>
                </span>
              </div>
            </div>
          )}
          <div className='m-3'>
            <div className='border-style'>
              <Row>
                <Col span={24}>
                  <Row>
                    <Col span={10}>
                      {this.TitleAndDesc(
                        'WORKSHOP NAME',
                        this.state.workshop_details.workshop_name,
                        this.state.oldWorkshopData.workshop_details
                          .workshop_name
                      )}
                    </Col>
                    <Col span={6}>
                      {this.state.role_id == 1 &&
                        this.TitleAndDesc(
                          'CREATED BY',
                          this.state.workshop_details.user_details
                            ? this.state.workshop_details.user_details.name
                            : '',
                          false
                        )}
                      {[2, 4, 5].includes(this.state.role_id) && (
                        <React.Fragment>
                          {this.TitleAndDesc('STATUS', '', false)}
                          {this.state.workshop_details.status == 'Pending' ? (
                            <Tag className='tagStatusPending'>Pending</Tag>
                          ) : this.state.workshop_details.status ==
                            'Approved' ? (
                            <Tag className='tagStatusApproved'>Approved</Tag>
                          ) : this.state.workshop_details.status ==
                            'Rejected' ? (
                            <Tag className='tagStatusRejected'>Rejected</Tag>
                          ) : (
                            ''
                          )}
                        </React.Fragment>
                      )}
                    </Col>
                    <Col span={8}>
                      {this.state.role_id == 1 &&
                        this.state.workshop_details.primary_host &&
                        this.TitleAndDesc(
                          'PRIMARY HOST',
                          this.state.workshop_details.primary_host
                            ? this.state.workshop_details.primary_host
                            : '',
                          false
                        )}
                    </Col>
                  </Row>
                </Col>
              </Row>

              <div className='mt-2 mb-4'></div>

              <div className='mtb-34' />
              <Row>
                <Col span={24}>
                  {this.TitleAndDesc(
                    'FEATURED IMAGES',
                    '',
                    this.state.oldWorkshopData.thumb_images
                  )}
                  <div className='row col-12 m-0 p-0 featured-img'>
                    <WorkshopImageView thumb_images={this.state.thumb_images} />
                  </div>
                </Col>
              </Row>
            </div>
            <div className='row m-0'>
              <div
                header='DETAILS OF THIS ACTIVITY'
                className='col-12 border-style mb-4'
                key='1'
              >
                <div>
                  {this.state.workshop_details.trailer_link && (
                    <div>
                      <Row>
                        <Col span={24}>
                          {this.TitleAndDesc(
                            'TRAILER LINK',
                            '',
                            this.state.oldWorkshopData.workshop_details
                              .trailer_link
                          )}
                          <div className='desc'>
                            <a
                              href={this.state.workshop_details.trailer_link}
                              target='_blank'
                            >
                              {this.state.workshop_details.trailer_link}
                            </a>
                          </div>
                        </Col>
                      </Row>
                      <div className='mtb-24' />
                    </div>
                  )}
                  <div>
                    {this.TitleAndDesc(
                      'WORKSHOP DESCRIPTION',
                      '',
                      this.state.oldWorkshopData.workshop_details.description
                    )}
                    <div className='long-description'>
                      <h6
                        dangerouslySetInnerHTML={{
                          __html: this.state.workshop_details.description
                        }}
                      />
                    </div>
                  </div>

                  <div className=' mt-4 mb-4' />

                  <Row>
                    <Col span={8}>
                      {this.TitleAndDesc(
                        'DATE',
                        ``,
                        this.state.oldWorkshopData.workshop_details
                          .workshop_date
                      )}

                      <div className='d-flex flex-column'>
                        {this.state.workshop_details &&
                          this.state.workshop_details.workshop_plan_id &&
                          [1, 2, 3].includes(
                            parseInt(
                              this.state.workshop_details.workshop_plan_id
                            )
                          ) &&
                          this.state.workshop_details.repeats_type == 'dnr' &&
                          this.state.workshop_details.Dates &&
                          this.state.workshop_details.Dates.length > 0 &&
                          this.state.workshop_details.Dates.map(x => (
                            <div className='mb-1'>
                              <div className='workDate'>
                                {x
                                  ? Moment.utc(
                                      parseInt(x.workshop_Date)
                                    ).format(`ddd, MMM DD, YYYY`)
                                  : ''}
                              </div>
                            </div>
                          ))}
                        {this.state.workshop_details &&
                          this.state.workshop_details.workshop_plan_id &&
                          (this.state.workshop_details.workshop_plan_id == 1 ||
                            this.state.workshop_details.workshop_plan_id ==
                              2) &&
                          this.state.workshop_details.repeats_type != 'dnr' &&
                          this.state.workshop_details.Dates &&
                          this.state.workshop_details.Dates.length > 0 && (
                            <div className='workDate'>
                              {this.state.workshop_details.Dates &&
                                `${
                                  this.state.workshop_details.repeatDays
                                    ? this.state.workshop_details.repeatDays
                                    : ''
                                } | 
                                ${
                                  this.state.workshop_details.Dates[0]
                                    .workshop_Date
                                    ? Moment.utc(
                                        parseInt(
                                          this.state.workshop_details.Dates[0]
                                            .workshop_Date
                                        )
                                      ).format(`MMM DD`)
                                    : ''
                                } - 
                                ${
                                  this.state.workshop_details.Dates[0]
                                    .workshop_Date
                                    ? Moment.utc(
                                        parseInt(
                                          this.state.workshop_details.Dates[
                                            this.state.workshop_details.Dates
                                              .length - 1
                                          ].workshop_Date
                                        )
                                      ).format(`MMM DD, YYYY`)
                                    : ''
                                }`}
                            </div>
                          )}
                      </div>
                    </Col>
                    <Col span={8}>
                      {this.TitleAndDesc(
                        'TIME',
                        this.state.workshop_details.workshop_time ? `` : '',
                        this.state.oldWorkshopData.workshop_details
                          .workshop_time
                      )}
                      <div className='d-flex flex-column'>
                        {this.state.workshop_details &&
                          this.state.workshop_details.workshop_plan_id &&
                          [1, 2, 3].includes(
                            parseInt(
                              this.state.workshop_details.workshop_plan_id
                            )
                          ) &&
                          this.state.workshop_details.repeats_type == 'dnr' &&
                          this.state.workshop_details.Dates &&
                          this.state.workshop_details.Dates.length > 0 &&
                          this.state.workshop_details.Dates.map(x => (
                            <div className='mb-1'>
                              <div className='workDate'>
                                {x
                                  ? Moment(x.start_time, 'h:mm A').format(
                                      `h:mm A`
                                    )
                                  : ''}
                              </div>
                            </div>
                          ))}
                        {this.state.workshop_details &&
                          this.state.workshop_details.workshop_plan_id &&
                          (this.state.workshop_details.workshop_plan_id == 1 ||
                            this.state.workshop_details.workshop_plan_id ==
                              2) &&
                          this.state.workshop_details.repeats_type != 'dnr' &&
                          this.state.workshop_details.Dates &&
                          this.state.workshop_details.Dates.length > 0 && (
                            <div className='workDate'>
                              {this.state.workshop_details.workshop_time &&
                                `${
                                  this.state.workshop_details.workshop_time
                                    ? this.state.workshops_timeText
                                    : ''
                                }`}
                            </div>
                          )}
                      </div>
                    </Col>
                    <Col span={8}>
                      {this.TitleAndDesc(
                        'DURATION',
                        this.state.workshop_details.workshop_duration ? `` : '',
                        this.state.oldWorkshopData.workshop_details
                          .workshop_duration
                      )}

                      <div className='d-flex flex-column p-2'>
                        {this.state.workshop_details &&
                          this.state.workshop_details.workshop_plan_id &&
                          [1, 2, 3].includes(
                            parseInt(
                              this.state.workshop_details.workshop_plan_id
                            )
                          ) &&
                          this.state.workshop_details.repeats_type == 'dnr' &&
                          this.state.workshop_details.Dates &&
                          this.state.workshop_details.Dates.length > 0 &&
                          this.state.workshop_details.Dates.map(x => (
                            <div className='mb-1'>
                              <div className='workDate'>
                                {x.duration
                                  ? `${x.duration}`
                                  : // ${this.state.workshop_details
                                    //       .workshop_duration_type
                                    //       ? this.state.workshop_details
                                    //           .workshop_duration_type == 'hr'
                                    //         ? 'h'
                                    //         : ''
                                    //       : ''
                                    //   }

                                    ''}
                              </div>
                            </div>
                          ))}
                        {this.state.workshop_details &&
                          this.state.workshop_details.workshop_plan_id &&
                          (this.state.workshop_details.workshop_plan_id == 1 ||
                            this.state.workshop_details.workshop_plan_id ==
                              2) &&
                          this.state.workshop_details.repeats_type != 'dnr' &&
                          this.state.workshop_details.Dates &&
                          this.state.workshop_details.Dates.length > 0 && (
                            <div className='workDate'>
                              {this.state.workshop_details.workshop_duration
                                ? `${
                                    this.state.workshop_details
                                      .workshop_duration
                                  }
                                  ${
                                    this.state.workshop_details.workshop_session
                                      ? this.state.workshop_details
                                          .workshop_session > 1
                                        ? 'each'
                                        : ''
                                      : ''
                                  }`
                                : ''}
                            </div>
                          )}
                      </div>
                    </Col>
                  </Row>

                  <div className=' mt-4 mb-4' />

                  <div>
                    {this.TitleAndDesc(
                      'Interests',
                      '',
                      this.state.oldWorkshopData.interests
                    )}

                    <div className='desc d-flex'>
                      {/* <h6>{this.state.interest_category_name}</h6>
                      {this.state.interests !== 0 && (
                        <div className='d-inline pl-2 pr-2'> - </div>
                      )} */}
                      <div>
                        {this.state.interests.map((InterestData, i) => (
                          <Tag color='blue' className='mb-2' key={i}>
                            {InterestData.name}
                          </Tag>
                        ))}
                      </div>
                    </div>
                  </div>

                  <div className=' mt-4 mb-4' />
                  <div>
                    {this.TitleAndDesc(
                      `Age group`,
                      '',
                      this.state.oldWorkshopData.age_groups
                    )}
                  </div>
                  <div className='m-0 p-2'>
                    {this.state.age_groups.map((data, index) => (
                      <span key={index}>
                        <span
                          key={index}
                          className={index === 0 ? 'mr-3 ml-0' : 'm-3'}
                        >
                          <Tag color='blue' className='mb-2' key={index}>
                            {data.age}
                          </Tag>
                        </span>
                        <span
                          className={
                            index === this.state.age_groups.length - 1
                              ? ''
                              : 'border-right-grey'
                          }
                        />
                      </span>
                    ))}
                  </div>
                </div>

                <div className='mt-4 mb-4' />
                {this.state.workshop_details.prerequisites && (
                  <div>
                    {this.TitleAndDesc(
                      'YOU SHOULD ALREADY KNOW',
                      '',
                      false
                      // this.state.workshop_details.prerequisites !==
                      //   this.state.oldActivityData.workshop_details.prerequisites
                    )}
                    <div className='long-description'>
                      <h6
                        dangerouslySetInnerHTML={{
                          __html: this.state.workshop_details.prerequisites
                        }}
                      />
                    </div>
                  </div>
                )}
                <div className='mt-4 mb-4' />
                <div>
                  {this.TitleAndDesc(
                    'FEE',
                    this.state.workshop_details.workshop_type == 'Paid'
                      ? `\u20B9 ${this.state.workshop_details.workshop_amount}`
                      : this.state.workshop_details.workshop_type
                          .charAt(0)
                          .toUpperCase() +
                          this.state.workshop_details.workshop_type.slice(1),
                    this.state.oldWorkshopData.workshop_details.workshop_type ||
                      this.state.oldWorkshopData.workshop_details
                        .workshop_amount
                  )}
                </div>
                <div className='mt-4 mb-4' />

                {this.state.materials_required.length !== 0 && (
                  <div>
                    <div className='row m-0'>
                      {this.TitleAndDesc(
                        'YOU WOULD NEED',
                        '',
                        this.state.oldWorkshopData.materials_required
                      )}
                      <div className='col-12 row m-0 p-2'>
                        {this.state.materials_required.map((data, index) => (
                          <div className='col-4 p-0 row m-0' key={index}>
                            <div className='col-3 p-0 pr-2'>
                              <img
                                className='pr-2 pl-2'
                                src={data.image}
                                alt='materials'
                                style={{
                                  width: 80,
                                  height: 80,
                                  objectFit: 'contain'
                                }}
                              />
                            </div>
                            <div
                              className='col-9 p-0 d-flex align-items-center'
                              style={{ height: 80 }}
                            >
                              <h6 className='material_name'>{data.name}</h6>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className='mt-4 mb-4' />
                  </div>
                )}

                {this.state.workshop_details.learning_outcome && (
                  <div>
                    <div>
                      {this.TitleAndDesc(
                        'LEARNING OUTCOME',
                        '',
                        this.state.oldWorkshopData.workshop_details
                          .learning_outcome
                      )}
                      <div className='long-description'>
                        <h6
                          dangerouslySetInnerHTML={{
                            __html: this.state.workshop_details.learning_outcome
                          }}
                        />
                      </div>
                    </div>
                    <div className='mt-4 mb-4' />
                  </div>
                )}

                <div>
                  <Row>
                    <Col span={8}>
                      {this.TitleAndDesc(
                        'MINIMUM PARTICIPANTS',
                        this.state.workshop_details.minimum_participants,
                        this.state.oldWorkshopData.workshop_details
                          .minimum_participants
                      )}
                    </Col>
                    <Col span={8}>
                      {this.TitleAndDesc(
                        'MAXIMUM PARTICIPANTS',
                        this.state.workshop_details.maximum_participants,
                        this.state.oldWorkshopData.workshop_details
                          .maximum_participants
                      )}
                    </Col>
                  </Row>
                </div>
                <div className='mt-4 mb-4' />

                {this.state.workshop_details.reference_link && (
                  <div>
                    {this.TitleAndDesc(
                      'REFERENCES TO SHARE WITH PARTICIPANTS POST WORKSHOP',
                      '',
                      this.state.oldWorkshopData.workshop_details.reference_link
                    )}
                    <div className='d-flex justify-content-between align-items-center'>
                      {this.state.imagePreview ? (
                        <div className='p-0 col-6 pdf-upload'>
                          <img
                            src={this.state.workshop_details.reference_link}
                            alt='Image Preview'
                            style={{ width: 450, height: 250 }}
                          />{' '}
                        </div>
                      ) : (
                        <div>
                          <a
                            href={this.state.workshop_details.reference_link}
                            target='_blank'
                          >
                            <img
                              src={pdf}
                              alt='uploaded pdf'
                              style={{ width: 66 }}
                            />{' '}
                            <span className='ml-3 text-secondary'>
                              {this.state.pdf_name}
                            </span>
                          </a>
                        </div>
                      )}
                    </div>
                    <div className='m-4' />
                  </div>
                )}

                {this.state.tags && this.state.tags.length > 0 && (
                  <div style={{ marginBottom: '4rem' }}>
                    <div className='mt-4 mb-4' />
                    <div className='row'>
                      <div className='w-100'>
                        <div className='col-12'>
                          {this.TitleAndDesc(
                            'TAGS',
                            '',
                            this.state.oldWorkshopData.tags
                          )}
                          {this.state.tags.map((data, i) => (
                            <Tag color='blue' key={i}>
                              {data.name}
                            </Tag>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className='m-4' />
              </div>
            </div>
          </div>
          {[1, 2, 4, 5].includes(this.state.role_id) &&
            this.state.workshop_details &&
            this.state.workshop_details.status == 'Approved' &&
            this.state.pastWorkshop.includes(false) &&
            this.state.zoomJoinUrl && (
              <div className='joinWorkshop-div'>
                <div className='joinContentText'>Start the workshop now!</div>
                <div className='d-flex'>
                  <div className='mr-2'>
                    <Button
                      category='Workshops'
                      type='default'
                      size='large'
                      className='meetingBtn'
                      onClick={this.copyToClipboard}
                    >
                      <img src={MeetingCopy} />
                      &nbsp; Tap to copy Meeting link
                    </Button>
                  </div>
                  <div>
                    <ButtonC
                      category='Workshops'
                      action='Start Workshop'
                      size='large'
                      block
                      className='workshopApprbtn mr-3'
                      type='primary'
                      disabled={
                        this.state.workshop_details.zoom_meeting
                          ? !this.state.workshop_start.includes(true)
                          : true
                      }
                      onClick={() => window.open(this.state.zoomJoinUrl)}
                    >
                      Start workshop
                    </ButtonC>
                  </div>
                </div>
              </div>
            )}

          {/* proceed to approve workshop */}
          <Modal
            visible={this.state.changePrimaryHostModal}
            closable={true}
            footer={[
              <div className='d-flex justify-content-end align-items-center pl-2 p-1'>
                <div className='fontColor-blue font-16 FontAvenirMedium' />
                <div className='mr-2'>
                  <ButtonC
                    category='Workshops'
                    action='Approve Workshop Content'
                    size='large'
                    block
                    onClick={() => this.onChangePrimaryHostModal()}
                  >
                    Close
                  </ButtonC>
                </div>
                <div>
                  <ButtonC
                    style={{ width: 100 }}
                    category='Workshops'
                    action='Approve Workshop Content'
                    size='large'
                    block
                    className='workshopApprbtn mr-3'
                    type='primary'
                    disabled={!this.state.primary_host.length > 0}
                    onClick={() => this.approveWorkshopBtnClick()}
                  >
                    Approve
                  </ButtonC>
                </div>
              </div>
            ]}
            onCancel={() =>
              this.setState({
                changePrimaryHostModal: false
              })
            }
            width='30%'
          >
            <h4 className='heading'>Select the Primary Zoom Host account</h4>
            <div className='border-bottom'></div>
            <div className='mt-4'>
              <div>
                <Radio.Group
                  className='p-1 w-100'
                  onChange={e =>
                    this.onChangePrimaryHost('primary_host', e.target.value)
                  }
                  value={this.state.primary_host}
                >
                  {window.location.origin.includes(
                    process.env.REACT_APP_URL
                  ) ? (
                    <Radio
                      className='mt-2 primaryHostText'
                      value={'tamizh@nfnlabs.in'}
                    >
                      tamizh@nfnlabs.in
                    </Radio>
                  ) : (
                    <React.Fragment>
                      <Radio
                        className='mt-2 primaryHostText'
                        value={'contact@learnwithbreadcrumb.com'}
                      >
                        contact@learnwithbreadcrumb.com
                      </Radio>
                      <Radio
                        className='mt-2 primaryHostText'
                        value={'contact@questcommunity.in'}
                      >
                        contact@questcommunity.in
                      </Radio>
                    </React.Fragment>
                  )}
                </Radio.Group>
              </div>
              <div className='mb-4'></div>
            </div>
          </Modal>
        </React.Fragment>
      )
    }
  }
}

export default ViewWorkshop
