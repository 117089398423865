import {
  Button,
  Col,
  DatePicker,
  Icon,
  Input,
  Modal,
  Row,
  Table,
  Tag
} from "antd";
import axios from "axios";
import _ from "lodash";
import moment from "moment";
import React, { Component } from "react";
import Loading from "../../components/Loading";
import { PARENT_ADD_STUDENT } from "../../graphql";
import { openNotification, triggerGAEvent } from "../../utils";
import { GET_ALL_STUDENT_PROFILES } from "./api";
import TimeAgo from "react-timeago";
import Moment from "moment";

const Search = Input.Search;

export default class Students extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      modalVisible: false,
      students: [],
      searchText: "",
      filteredInfo: null,
      sortedInfo: null,
      first_name: "",
      last_name: "",
      page: 1,
      total: 0,
      age_filters: [],
      dob: moment(),
      username: ""
    };
    this.onSearchFetchData = _.debounce(this.onSearchFetchData, 700);
  }

  fetchData() {
    this.setState({ loading: true });
    let { sortedInfo, filteredInfo } = this.state;
    sortedInfo = sortedInfo || { columnKey: "", order: "" };
    filteredInfo = filteredInfo || { age_group: [] };
    let query = GET_ALL_STUDENT_PROFILES;
    query.variables = {
      search: this.state.searchText,
      page: this.state.page,
      filter: {
        fname: sortedInfo.columnKey === "fname" ? sortedInfo.order === "ascend" ? "ASC" : "DESC" : "",
        last_login: sortedInfo.columnKey === "last_login" ? sortedInfo.order === "ascend" ? "ASC" : "DESC" : "",
        age: filteredInfo.age_group,
      }
    };
    axios
      .post(`${process.env.REACT_APP_API}`, query)
      .then(res => {
        this.setState({
          students: res.data.data.getStudentData.students,
          loading: false,
          age_filters: res.data.data.getAge.age_groups,
          total: res.data.data.getStudentData.count,
        });
      })
      .catch(err => console.log(err));
  }

  onSearchFetchData = () => {
    triggerGAEvent("Students", "Searching a Learner");
    this.fetchData();
  };

  onSearch = e => {
    this.setState({ searchText: e.target.value }, () =>
      this.onSearchFetchData()
    );
  };

  handleTableChange = (pagination, filters, sorter) => {
    triggerGAEvent("Students", "Changing sort and filters in Students table");
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter
    }, () => this.fetchData());
  };

  onInputChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  dobChangeHandler = (date, dateString) => {
    if (date.isBefore(moment().subtract(4, "years"))) {
      this.setState({ dob: date });
    } else {
      openNotification("error", "The minimum age of a learner is 4 years");
    }
  };

  handleModalCancel = () => {
    triggerGAEvent("Students", "Closing add learner Popup");

    this.setState({
      modalVisible: false,
      first_name: "",
      last_name: "",
      dob: moment(),
      username: ""
    });
  };

  disableDobHandler = current => {
    return current.isAfter(moment());
  };

  addStudent = () => {
    triggerGAEvent("Students", "Adding a new Learner");
    let mutation = PARENT_ADD_STUDENT;
    mutation.variables = {
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      dob: this.state.dob.format("ll"),
      username: this.state.username
    };
    axios
      .post(`${process.env.REACT_APP_API}`, mutation)
      .then(res => {
        if (res.data.errors) {
          openNotification("error", res.data.errors[0].message);
        } else {
          this.fetchData();
          openNotification("success", "Learner profile created successfully");
          this.handleModalCancel();
        }
      })
      .catch(err => console.log(err));
  };

  onAddStudent = () => {
    if (this.state.first_name !== "" && this.state.last_name !== "" && this.state.username !== "") {
      if (this.state.dob.isBefore(moment().subtract(4, "years"))) {
        this.addStudent();
      } else {
        openNotification("error", "The minimum age of a learner is 4 years");
      }
    } else {
      openNotification("error", "Enter all the mandatory fields");
    }
  };

  onAddStudentBtnClick = () => {
    this.setState({ modalVisible: true });
  };

  componentDidMount() {
    this.fetchData();
  }

  render() {
    let { sortedInfo, filteredInfo } = this.state;
    sortedInfo = sortedInfo || {};
    filteredInfo = filteredInfo || {};

    const newColumns = [
      {
        title: "Name",
        dataIndex: "fname",
        width: "40%",
        sorter: true,
        sortOrder: sortedInfo.columnKey === "fname" && sortedInfo.order,
        defaultSortOrder: "ascend"
      },
      {
        title: "Last Login",
        dataIndex: "last_login",
        width: "20%",
        sorter: true,
        sortOrder: sortedInfo.columnKey === "last_login" && sortedInfo.order,
        defaultSortOrder: "ascend",
        render: date => <TimeAgo date={Number(Moment(date).format("x"))} />,
      },
      {
        title: "Age Category",
        dataIndex: "age_group",
        filters: this.state.age_filters.map((age, index) => ({
          value: Number(age.id), text: age.age
        })),
        // onFilter: (value, record) => record.age_group.includes(value),
        filteredValue: filteredInfo.age_group || [],
        width: "20%"
      },
      {
        title: `Attendance - ${moment().format("Do MMMM")}`,
        dataIndex: "attendance",
        render: att =>
          att && att === "Present" ? (
            <Tag color="green">Present</Tag>
          ) : (
            <Tag color="red">Absent</Tag>
          ),
        width: "20%"
      }
    ];

    return (
      <React.Fragment>
        <Loading is_loading={this.state.loading} />
        <div style={{ margin: "1.7rem" }}>
          <Row>
            <Col span={11}>
              <h4 style={{ margin: "0.5rem 0 0 0", padding: 0 }}>
                Learner profiles - {this.state.total}
              </h4>
            </Col>
            <Col span={8}>
              <Search
                placeholder="Search"
                style={{ width: "100%", height: "100%" }}
                size="large"
                onChange={this.onSearch}
              />
            </Col>
            <Col span={5}>
              <Button
                type="primary"
                block
                size="large"
                onClick={this.onAddStudentBtnClick}
                className="ml-3"
              >
                Add Learner
              </Button>
            </Col>
          </Row>
        </div>
        <div style={{ margin: "1rem" }} className="studentsDataTable">
          <Table
            className="table-full"
            columns={newColumns}
            rowKey={student => student.id}
            dataSource={this.state.students}
            onChange={this.handleTableChange}
            rowClassName="table-row"
            onRow={(record, rowIndex) => ({
              onClick: e => this.props.history.push(`/learners/${record.id}`)
            })}
            pagination={{
              current: this.state.page,
              total: this.state.total,
              onChange: (page, pageSize) => this.setState({ page: page }, () => this.fetchData()),
            }}
          />
        </div>
        <Modal
          title="Let’s setup the child’s profile"
          visible={this.state.modalVisible}
          maskClosable={false}
          onCancel={this.handleModalCancel}
          width={400}
          footer={[
            <Button
              key="1"
              block
              size="large"
              type="primary"
              onClick={this.onAddStudent}
            >
              Setup Account
            </Button>
          ]}
        >
          <div className="mb-4">
            <div className="d-flex w-100 justify-content-center align-items-center">
              {this.state.first_name === "" ? (
                <div className="student-plus d-flex flex-column justify-content-center align-items-center">
                  <Icon type="plus" />
                </div>
              ) : (
                <div className="student-image d-flex flex-column justify-content-center align-items-center">
                  <span>{this.state.first_name.charAt(0).toUpperCase()}</span>
                </div>
              )}
            </div>
          </div>
          <div className="mb-3">
            <div className="FontAvenirRoman text-secondary font-16 text-uppercase requiredAsterisk mb-8px">
              Child's First Name
            </div>
            <Input
              name="first_name"
              onChange={this.onInputChange}
              value={this.state.first_name}
              size="large"
              placeholder="Add First Name"
            />
          </div>
          <div className="mb-3">
            <div className="FontAvenirRoman text-secondary font-16 text-uppercase requiredAsterisk mb-8px">
              Child's Last Name
            </div>
            <Input
              name="last_name"
              onChange={this.onInputChange}
              value={this.state.last_name}
              size="large"
              placeholder="Add Last Name"
            />
          </div>
          <div className="mb-3">
            <div className="FontAvenirRoman text-secondary font-16 text-uppercase requiredAsterisk mb-8px">
              Date of Birth
            </div>
            <DatePicker
              defaultValue={moment()}
              value={this.state.dob}
              disabledDate={this.disableDobHandler}
              format="DD-MM-YYYY"
              size="large"
              className="w-100"
              onChange={this.dobChangeHandler}
              dateRender={this.journalDateRender}
            />
          </div>
          <div className="mb-3">
            <div className="FontAvenirRoman text-secondary font-16 text-uppercase requiredAsterisk mb-8px">
              Preferred Username
            </div>
            <Input
              name="username"
              onChange={this.onInputChange}
              value={this.state.username}
              size="large"
              placeholder="Add username"
            />
          </div>
          <div className="hint" style={{ margin: 0 }}>
            Once you setup the account, share the username with the child so
            that they can create an account on the iPad
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}
