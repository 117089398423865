import React, { Component } from "react";
import "./App.css";
// import { hot } from 'react-hot-loader'
import { Detector } from "react-detect-offline";
import { Spin } from "antd";
import Main from "./main";

console.log(`${process.env.REACT_APP_ENV}`);

class App extends Component {
  render() {
    return (
      <div className="App Container">
        <Detector
          render={({ online }) => (
            <Spin
              className="noNetError"
              spinning={!online}
              tip="No Internet Connection. Reconnecting..."
            >
              <Main />
            </Spin>
          )}
        />
      </div>
    );
  }
}

// export default hot(module)(App)
export default App;
