import { Input } from "antd";
import React, { Component } from "react";
import InputVal from "react-validation/build/input";
import "./index.css";

export default class InputC extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  onChangeHandle = e => {
    this.props.onChange(this.props.stateKey, e.target.value);
  };
  onClickHandle = e => {
    this.props.showPwdClick(this.props.showPwdKey);
  };
  // onKeyDownClick = (e) => {
    // console.log("events", e);
    // if (key == 'login') {
    //   this.props.onKeyDown(e);
    // }
  // }
  handleKeyPress = evt => {
    // console.log(this.props.type);
    if (this.props.type === "number") {
      if (evt.which < 48 || evt.which > 57) {
        evt.preventDefault();
      }
    } else if (this.props.type === "decimal") {
      if (evt.which !== 46 && evt.which > 31 && (evt.which < 48 || evt.which > 57)) {
        evt.preventDefault();
      }
    }
  };

  render() {
    return (
      <div className="position-relative w-100">
        <div
          className={
            this.props.titleStyle
              ? this.props.titleStyle
              : "mb-3 FontAvenirMedium text-uppercase text-secondary font-16"
          }
        >
          {this.props.title}
        </div>
        <div className="position-relative ">
          {this.props.validations ? (
            <InputVal
              {...this.props}
              id={this.props.id}
              name={this.props.name}
              value={this.props.value}
              placeholder={this.props.placeholder}
              disabled={this.props.disabled}
              type={
                this.props.type === "password"
                  ? this.props.showPwd
                    ? "text"
                    : "password"
                  : this.props.type
              }
              className={`${this.props.className} position-relative`}
              onChange={this.onChangeHandle}
              onBlur={this.props.onBlur}
              validations={this.props.validations}
              onKeyPress={this.handleKeyPress}
            />
          ) : ( this.props.onKeyDown ? 
            <Input
              {...this.props}
              id={this.props.id}
              name={this.props.name}
              value={this.props.value}
              placeholder={this.props.placeholder}
              disabled={this.props.disabled}
              type={
                this.props.type === "password"
                  ? this.props.showPwd
                    ? "text"
                    : "password"
                  : this.props.type
              }
              className={`${this.props.className} position-relative`}
              onChange={this.onChangeHandle}
              onBlur={this.props.onBlur}
              onKeyDown
              // onKeyPress={this.handleKeyPress}
            /> : 
            <Input
              {...this.props}
              id={this.props.id}
              name={this.props.name}
              value={this.props.value}
              placeholder={this.props.placeholder}
              disabled={this.props.disabled}
              type={
                this.props.type === "password"
                  ? this.props.showPwd
                    ? "text"
                    : "password"
                  : this.props.type
              }
              className={`${this.props.className} position-relative`}
              onChange={this.onChangeHandle}
              onBlur={this.props.onBlur}
              // onKeyDown
              // onKeyPress={this.handleKeyPress}
            />
          )}
          {this.props.type === "password" && (
            <div
              className="position-absolute text-uppercase pwd-show bg-white pl-2 pr-1"
              onClick={this.onClickHandle}
            >
              {this.props.showPwd ? (
                <span> hide </span>
              ) : (
                <span className="fontColor-blue">show</span>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}
