import { Button, Card, Col, Icon, Input, Modal, Row, Table, Tag, ConfigProvider } from "antd";
import axios from "axios";
import moment from "moment";
import React, { Component } from "react";
import Audio from "../../components/Audio";
import AudioRecord from "../../components/AudioRecord";
import Bars from "../../components/Bars";
import BreadcrumbView from "../../components/BreadCrumb";
import "../../components/JournalEntry/index.css";
import Loading from "../../components/Loading";
import DoIcon from "../../static/activity/icon/Do.svg";
import FeedbackCheck from "../../static/activity/icon/Expert.svg";
import WatchIcon from "../../static/activity/icon/watch.svg";
import PlayButton from "../../static/journal/playButton.svg";
import SetpsUsed from "../../static/journal/setpsUsed.svg";
import PlayAudio from "../../static/Polygon.svg";
import { base64regex, openNotification, triggerGAEvent } from "../../utils";
import {
  GET_EXPERT_JOURNAL,
  GET_ONE_CONTENT_FEEDBACK
} from "../Collection/api";
import { STUDENT_GENERAL_FEEDBACK } from "../Students/api";
import FeedbackContainer from "./FeedbackContainer";
import noFeedBacks from "../../static/no_feedback.svg";

const ImageContainer = props => {
  return (
    <div>
      <div className="mb-2">
        {props.iconWatch && <img src={WatchIcon} alt="play button" />}
      </div>
      <div className="mb-2">
        {props.iconDo && <img src={DoIcon} alt="play button" />}
      </div>
      <div className="mb-2">
        {props.iconFeedback && <img src={FeedbackCheck} alt="play button" />}
      </div>
    </div>
  );
};
export class ContentFeedback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredInfo: null,
      sortedInfo: null,
      students: [],
      overall_journals: [],
      fixedFeedback: false,
      addFeedbackVisible: false,
      feedbackData: {},
      imageVisible: false,
      imageData: {},
      feedbackText: "",
      feedback_voice: "",
      student_id: "",
      card_name: ""
    };
  }
  componentDidMount() {
    this.fetchData();
  }
  fetchData() {
    this.setState({ loading: true });
    let query = GET_ONE_CONTENT_FEEDBACK;
    query.variables = {
      card_id: this.props.match.params.id
    };
    axios
      .post(`${process.env.REACT_APP_API}`, query)
      .then(res => {
        this.setState({
          students: res.data.data.CompletedStudent.students,
          loading: false,
          card_name: res.data.data.CompletedStudent.card_name
        });
      })
      .catch(err => console.log(err));
  }
  handleTableChange = (pagination, filters, sorter) => {
    triggerGAEvent("Students", "Changing sort and filters in Students table");
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter
    });
  };
  onClickClose = () => {
    this.setState({ fixedFeedback: false });
  };
  getExpertFeedback = student_id => {
    this.setState({ loading: true, student_id });

    let query = GET_EXPERT_JOURNAL;
    query.variables = {
      card_id: parseInt(this.props.match.params.id),
      students_id: parseInt(student_id)
    };

    axios
      .post(`${process.env.REACT_APP_API}`, query)
      .then(res => {
        const overall_journals =
          res.data.data.GetExpertJournal.overall_journal_list[0] || {};
        this.setState({
          feedbackData: res.data.data.GetExpertJournal,
          overall_journals: overall_journals.card_details,
          fixedFeedback: true,
          loading: false
        });
      })
      .catch(err => {
        console.log(err);
        this.setState({ loading: false });
      });
  };
  handleModalCancel = () => {
    this.setState({
      imageVisible: false
    });
  };
  onFeedbackVoiceUploadSuccess = audio_url => {
    triggerGAEvent("Students", "Recording Audio for Feedback");
    this.setState({ feedback_voice: audio_url });
  };
  onGeneralFeedbackSubmit = () => {
    triggerGAEvent("Students", "Adding General Feedback");
    if (
      this.state.feedbackText.length > 0 ||
      this.state.feedback_voice.length > 0
    ) {
      let def_id = this.state.overall_journals.length
        ? this.state.overall_journals[0].default_activities_id
        : 0;
      let Ftype =
        def_id === 4
          ? "Do"
          : def_id === 1
          ? "Read"
          : def_id === 2
          ? "Watch"
          : def_id === 3
          ? "Listen"
          : "general";
      let mutation = STUDENT_GENERAL_FEEDBACK;
      mutation.variables = {
        student_id: parseInt(this.state.student_id, 10),
        feedback_type: Ftype,
        feedback: this.state.feedbackText,
        feedback_voice: this.state.feedback_voice,
        card_id: this.props.match.params.id
      };
      axios
        .post(`${process.env.REACT_APP_API}`, mutation)
        .then(res => {
          if (res.data.data.lcFeedback.status) {
            openNotification("success", res.data.data.lcFeedback.message);
            const students = [...this.state.students];
            students.map(student => {
              if (student.id === this.state.student_id) {
                student.is_feedback = true;
              }
              return student;
            });
            this.setState({
              addFeedbackVisible: false,
              feedbackText: "",
              feedback_voice: "",
              fixedFeedback: false
            });
          }
        })
        .catch(err => console.log(err));
    } else {
      openNotification(
        "error",
        "Enter a feedback or record an Audio to add feedback"
      );
    }
  };
  onChangeFeedbackText = e => {
    if (e.target.value.length <= 2000) {
      this.setState({ [e.target.name]: e.target.value });
    }
  };
  onFeedbackAudioBtnClick = (index, type, journalIndex) => {
    if (type === "play") {
      this.setState({
        modalVisible: true,
        audio_type: "feedback",
        audio_url: this.state.overall_journals[index].feedback_voice,
        disp_time: this.state.overall_journals[index].disp_time,
        lc_name: this.state.overall_journals[index].lc_name
      });
    } else {
      this.setState({
        modalVisible: true,
        audio_type: "feedback",
        audio_url: this.state.overall_journals[journalIndex].pictures[index]
          .image,
        disp_time: this.state.overall_journals[journalIndex].pictures[index]
          .disp_time,
        lc_name: null
      });
    }
  };
  onCloseModal = () => {
    this.setState({ modalVisible: false });
  };

  render() {
    let { sortedInfo, filteredInfo, overall_journals } = this.state;
    sortedInfo = sortedInfo || {};
    filteredInfo = filteredInfo || {};

    const newColumns = [
      {
        title: "Name",
        dataIndex: "fname",
        sorter: (a, b) => a.fname.localeCompare(b.fname),
        sortOrder: sortedInfo.columnKey === "fname" && sortedInfo.order,
        defaultSortOrder: "ascend"
      },
      {
        title: "Age Category",
        dataIndex: "age_group",
        filters: [
          { value: "0-4", text: "0-4 years" },
          { value: "4-6", text: "4-6 years" },
          { value: "7-8", text: "7-8 years" },
          { value: "9-10", text: "9-10 years" },
          { value: "11-12", text: "11-12 years" },
          { value: "13-15", text: "13-15 years" },
          { value: "16-17", text: "16-17 years" },
          { value: "18+", text: "18+ years" }
        ],
        onFilter: (value, record) => record.age_group.includes(value),
        filteredValue: filteredInfo.age_group || null,
        width: "15%"
      },
      {
        title: "Learning center name",
        dataIndex: "lc_name",
        width: "20%"
      },
      {
        title: "Time Spent",
        dataIndex: "time_spend",
        width: "15%"
      },
      {
        title: "Completion Date",
        dataIndex: "completed_date",
        width: "15%",
        render: data => {
          return <div>{moment(data).format("DD MMM YYYY")}</div>;
        }
      },
      {
        title: `Feedback`,
        dataIndex: "is_feedback",
        render: att =>
          att ? (
            <Tag color="green">Given</Tag>
          ) : (
            <Tag color="gold">Not given</Tag>
          ),
        filters: [
          { value: "true", text: "Given" },
          { value: "false", text: "Not Given" }
        ],
        onFilter: (value, record) => record.is_feedback + "" === value,
        width: "10%"
      },
      {
        title: "Details",
        dataIndex: "",
        width: "10%",
        render: data => {
          return (
            <div
              className="journal-title-link small cursor-pointer"
              onClick={() => {
                this.getExpertFeedback(data.id);
              }}
            >
              View more
            </div>
          );
        }
      }
    ];
    
    const renderEmpty = () => (
      <div className="d-flex justify-content-center align-items-center flex-column">
        <img src={noFeedBacks} alt="no feedbacks" className="mb-4"/>
        <div className="text-center">
          <p className="FontAvenirMedium font-20 mb-2">
            No Results found
          </p>
        </div>
      </div>
    );
    
    return (
      <div>
        <Loading is_loading={this.state.loading} />
        <BreadcrumbView
          title={`${this.state.card_name}`}
          data={`/feedbacks/${this.state.card_name}`}
        />
        {!this.state.filteredInfo && (
          <div style={{ margin: "1rem" }} className="studentsDataTable">
            <Table
              className="table-full"
              columns={newColumns}
              rowKey={student => student.id}
              dataSource={this.state.students}
              onChange={this.handleTableChange}
              rowClassName="table-row"
              onRow={(record, rowIndex) => ({
                onClick: e => {
                  // this.props.history.push(`/learners/${record.id}`);
                }
              })}
            />
          </div>
        )}
        {this.state.filteredInfo && (
          <div style={{ margin: "1rem" }} className="studentsDataTable">
            <ConfigProvider renderEmpty={renderEmpty}>
              <Table
                className="table-full"
                columns={newColumns}
                rowKey={student => student.id}
                dataSource={this.state.students}
                onChange={this.handleTableChange}
                rowClassName="table-row"
                onRow={(record, rowIndex) => ({
                  onClick: e => {
                    // this.props.history.push(`/learners/${record.id}`);
                  }
                })}
              />
            </ConfigProvider>
          </div>
        )}
        <div
          className={`floatingFixed ${
            this.state.fixedFeedback ? "active" : ""
          }`}
        >
          <div className="d-flex">
            <div className="opacityContainer" onClick={this.onClickClose}></div>
            <div className="fixedContainer">
              <div>
                <div className="p-3 whiteContainer d-flex justify-content-between">
                  <div className="heading pb-0">
                    {`${this.state.feedbackData.student_name} - ${this.state
                      .feedbackData.lc_name || ""}`}
                  </div>
                  <div
                    className="desc pointer-cursor"
                    onClick={this.onClickClose}
                  >
                    Close
                  </div>
                </div>
              </div>
              <div className="p-3 mb-3">
                {this.state.addFeedbackVisible && (
                  <div className="bg-white mb-3" style={{ borderRadius: 10 }}>
                    <div className="journal-title-small pl-3 pr-3 pt-3">
                      Feedback
                    </div>
                    <div>
                      <div className="pb-4 p-3">
                        <Input.TextArea
                          style={
                            this.state.feedbackText.length >= 2000
                              ? { borderColor: "#FF0A00" }
                              : {}
                          }
                          autosize={{ minRows: 4, maxRows: 6 }}
                          name="feedbackText"
                          onChange={e => this.onChangeFeedbackText(e)}
                          value={this.state.feedbackText}
                          placeholder="Enter your feedback here"
                        />
                        <div
                          style={
                            this.state.feedbackText.length >= 2000
                              ? {
                                  color: "#FF0A00",
                                  float: "right",
                                  marginTop: 8
                                }
                              : { float: "right", marginTop: 8 }
                          }
                        >
                          {this.state.feedbackText.length} / 2000
                        </div>
                      </div>
                      <div className="p-3">
                        <AudioRecord
                          onSuccess={this.onFeedbackVoiceUploadSuccess}
                          feedback_voice={this.state.feedback_voice}
                          noMargin
                        >
                          <div
                            className="d-flex justify-content-between align-items-center pl-3 pr-3 p-1"
                            key={0}
                          >
                            <div className="fontColor-blue font-16 FontAvenirMedium" />
                            <div
                              className={
                                this.state.feedbackText === "" &&
                                this.state.feedback_voice === ""
                                  ? "disabledContainer"
                                  : ""
                              }
                            >
                              <Button
                                key="cancel"
                                size="large"
                                onClick={() => {
                                  this.setState({ addFeedbackVisible: false });
                                }}
                              >
                                Cancel
                              </Button>
                              <Button
                                type="primary"
                                size="large"
                                key="back"
                                className="ml-3"
                                onClick={this.onGeneralFeedbackSubmit}
                              >
                                Submit Feedback
                              </Button>
                            </div>
                          </div>
                        </AudioRecord>
                      </div>
                    </div>
                  </div>
                )}
                <div className="mb-3 d-flex justify-content-between">
                  <div className="journal-title-small">Journal updates</div>
                  {!this.state.addFeedbackVisible && (
                    <div
                      className="journal-title-link pointer-cursor"
                      onClick={() => {
                        this.setState({ addFeedbackVisible: true });
                      }}
                    >
                      Add feedback
                    </div>
                  )}
                </div>

                {overall_journals.length === 0 ? (
                  <Card
                    style={{
                      backgroundColor: "#E4EEFB",
                      paddingBotttom: "0",
                      borderRadius: "5px"
                    }}
                  >
                    <div className="journal-title">
                      No updates available. Kindly check another date or refresh
                      in a while.
                    </div>
                  </Card>
                ) : (
                  <Card
                    style={{
                      backgroundColor: "#E4EEFB",
                      paddingBotttom: "0",
                      borderRadius: "5px"
                    }}
                  >
                    <div className="line-in-middle">
                      {overall_journals.map((journal, JournalIndex) => {
                        if (journal.feedback_state) {
                          return (
                            <Row
                              style={{
                                marginTop: JournalIndex === 0 ? 0 : "16px",
                                marginBottom:
                                  JournalIndex === overall_journals.length - 1
                                    ? 0
                                    : "16px"
                              }}
                              key={JournalIndex}
                            >
                              <Col span={2}>
                                <Card className="time-card journal">
                                  <div className="time">
                                    {moment.utc(journal.disp_time).local().format(
                                      "hh:mm A"
                                    )}
                                  </div>
                                  <div>
                                    <ImageContainer
                                      iconFeedback={true}
                                      iconVoice={journal.feedback_voice}
                                    ></ImageContainer>
                                    {/*<img
                                    src={FeedbackIcon}
                                    alt="feedback icon"
                                    style={{
                                      marginTop:
                                        journal.name === "general" ? 16 : 32
                                    }}
                                  />*/}
                                  </div>
                                </Card>
                              </Col>
                              <Col span={22}>
                                <Card className="feedback-card">
                                  <div className="d-flex align-items-center justify-content-between">
                                    <div className="card-title-pd">
                                      <span
                                        style={{
                                          color: "#0076FF",
                                          fontSize: 14,
                                          fontWeight: "800"
                                        }}
                                      >
                                        FEEDBACK
                                      </span>
                                      <span className="dot" />
                                      <span
                                        style={{
                                          color: "#4A4A4A",
                                          fontSize: 14,
                                          opacity: 0.67
                                        }}
                                      >
                                        {journal.lc_name}
                                      </span>
                                    </div>
                                    {journal.feedback_voice && (
                                      <div
                                        className="play-audio-btn pointer-cursor"
                                        onClick={() =>
                                          this.onFeedbackAudioBtnClick(
                                            JournalIndex,
                                            "play",
                                            0
                                          )
                                        }
                                      >
                                        <img
                                          src={PlayAudio}
                                          alt="play button"
                                          className="mr-2"
                                        />
                                        <span
                                          style={{
                                            color: "#3EC0F1",
                                            marginTop: 3
                                          }}
                                        >
                                          Play audio feedback
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                  <div className="lc-name">
                                    {journal.name === "general"
                                      ? ""
                                      : journal.name}
                                  </div>
                                  <div className="font-16">
                                    {journal.feedback_desc}
                                  </div>
                                  {journal.student_response && (
                                    <div className="feedback-response">
                                      <Icon type="message" />
                                      <p>
                                        {base64regex.test(
                                          journal.student_response
                                        )
                                          ? window.atob(
                                              journal.student_response
                                            )
                                          : journal.student_response}
                                      </p>
                                    </div>
                                  )}
                                </Card>
                              </Col>
                            </Row>
                          );
                        } else if (journal.journal_image_notes_state) {
                          return (
                            <Row
                              style={{ margin: "16px 0" }}
                              key={JournalIndex}
                            >
                              <Col span={2}>
                                <Card className="time-card journal">
                                  <div className="time">
                                    {moment(journal.disp_time).format(
                                      "hh:mm A"
                                    )}
                                  </div>
                                </Card>
                              </Col>
                              <Col span={22}>
                                <Card className="feedback-card">
                                  <div className="d-flex align-items-center justify-content-between">
                                    <div className="journal-title">
                                      {journal.type === "audio"
                                        ? `Journal Audio`
                                        : `Journal pictures / notes`}
                                    </div>
                                    {journal.type === "audio" && (
                                      <div
                                        className="play-audio-btn pointer-cursor"
                                        onClick={() =>
                                          this.onJournalAudioBtnClick(
                                            JournalIndex
                                          )
                                        }
                                      >
                                        <img
                                          src={PlayAudio}
                                          alt="play button"
                                          className="mr-2"
                                        />
                                        <span
                                          style={{
                                            color: "#3EC0F1",
                                            marginTop: 3
                                          }}
                                        >
                                          Play audio feedback
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                  <div className="journal-content">
                                    {journal.type !== "audio" &&
                                      journal.image !== "" && (
                                        <img
                                          src={journal.image}
                                          alt="journal pic"
                                        />
                                      )}
                                    <div className="journal-data">
                                      {base64regex.test(journal.notes)
                                        ? decodeURIComponent(escape(window.atob(journal.notes)))
                                        : journal.notes}
                                    </div>
                                  </div>
                                </Card>
                              </Col>
                            </Row>
                          );
                        } else if (journal.journal_state === "FINISHED") {
                          return (
                            <Row
                              style={{ margin: "16px 0" }}
                              key={JournalIndex}
                            >
                              <Col span={2}>
                                <Card className="time-card journal">
                                  <div className="time">
                                    {moment(journal.disp_time).format(
                                      "hh:mm A"
                                    )}
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "flex-end",
                                      justifyContent: "flex-end",
                                      marginTop: 16
                                    }}
                                  >
                                    <ImageContainer
                                      iconWatch={
                                        journal.default_activities_id === 2
                                      }
                                      iconDo={
                                        journal.default_activities_id === 4
                                      }
                                      iconFeedback={journal.feedback_state}
                                    />
                                  </div>
                                </Card>
                              </Col>
                              <Col span={22}>
                                <Card className="activity-card-completed">
                                  <div className="card-title-pd">
                                    <span
                                      style={{
                                        color: "#63D886",
                                        fontSize: 14,
                                        fontWeight: "800"
                                      }}
                                    >
                                      FINISHED
                                    </span>
                                    <span className="dot" />
                                    <span
                                      style={{
                                        color: "#4A4A4A",
                                        fontSize: 14,
                                        opacity: 0.67
                                      }}
                                    >
                                      Total Time Spent -{" "}
                                      {moment
                                        .duration(journal.total_time_spent)
                                        .humanize()}
                                    </span>
                                  </div>
                                  <div className="activity-name">
                                    {journal.name}
                                  </div>
                                  <div className="activity-subtitle">
                                    At {journal.lc_name}
                                  </div>
                                  {(journal.video_view ||
                                    journal.pictures.length > 0 ||
                                    journal.steps_view) && (
                                    <hr
                                      style={{
                                        margin: "0.5rem 0rem 0.5rem 0rem"
                                      }}
                                    />
                                  )}
                                  {journal.video_view && (
                                    <div className="activity-done">
                                      <img src={PlayButton} alt="play button" />
                                      <span>Watched Video</span>
                                    </div>
                                  )}
                                  {journal.pictures.length > 0 && (
                                    <div className="row m-0">
                                      {journal.pictures.map(
                                        (picture, index) => {
                                          return (
                                            <div
                                              key={`Picture-${index}`}
                                              className="activity-done col-4"
                                            >
                                              <FeedbackContainer
                                                image={picture.image}
                                                type={
                                                  picture.type === "audio"
                                                    ? "audio"
                                                    : picture.type === "notes"
                                                    ? ""
                                                    : "image"
                                                }
                                                feedbackType={picture.type}
                                                createdDate={picture.disp_time}
                                                notes={picture.notes}
                                                onClick={() => {
                                                  if (
                                                    picture.type === "audio"
                                                  ) {
                                                    const imageData = {};
                                                    imageData.notes =
                                                      picture.notes;
                                                    this.setState({
                                                      imageData
                                                    });

                                                    this.onFeedbackAudioBtnClick(
                                                      index,
                                                      "picture",
                                                      JournalIndex
                                                    );
                                                  } else {
                                                    this.setState(
                                                      { imageVisible: true },
                                                      () => {
                                                        const imageData = {
                                                          ...this.state
                                                            .imageData
                                                        };
                                                        imageData.title = `${moment(
                                                          picture.disp_time ||
                                                            ""
                                                        ).format(
                                                          "DD MMM YYYY"
                                                        )} -
                                                    ${picture.type}`;
                                                        imageData.notes =
                                                          picture.notes;
                                                        imageData.image =
                                                          picture.image;
                                                        this.setState({
                                                          imageData
                                                        });
                                                      }
                                                    );
                                                  }
                                                }}
                                              />
                                              {/* <span>Added a picture of work</span> */}
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                  )}
                                  {journal.steps_view && (
                                    <div className="activity-done">
                                      <img src={SetpsUsed} alt="play button" />
                                      <span>Learnt steps</span>
                                    </div>
                                  )}
                                  {/*
                                <hr
                                  style={{ margin: "0.5rem 0rem 0.5rem 0rem" }}
                                />
                                  <div className="activity-completed">
                                  <img
                                    src={ActivityCompleted}
                                    alt="play button"
                                  />
                                  <span>
                                    Achievement unlocked - Completed learning
                                    content
                                  </span>
                                </div>*/}
                                </Card>
                              </Col>
                            </Row>
                          );
                        } else {
                          return (
                            <Row style={{ margin: "16px 0" }}>
                              <Col span={2}>
                                <Card className="time-card journal">
                                  <div className="time">
                                    {moment(journal.disp_time).format(
                                      "hh:mm A"
                                    )}
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "flex-end",
                                      justifyContent: "flex-end",
                                      marginTop: 16
                                    }}
                                  >
                                    <ImageContainer
                                      iconWatch={
                                        journal.default_activities_id === 2
                                      }
                                      iconDo={
                                        journal.default_activities_id === 4
                                      }
                                      iconFeedback={journal.feedback_state}
                                    ></ImageContainer>
                                  </div>
                                </Card>
                              </Col>
                              <Col span={22}>
                                <Card className="activity-card">
                                  <div className="card-title-pd">
                                    <span
                                      style={{
                                        color: "#64BEEA",
                                        fontSize: 14,
                                        fontWeight: "800"
                                      }}
                                    >
                                      STARTED
                                    </span>
                                  </div>
                                  <div className="activity-name">
                                    {journal.name}
                                  </div>
                                </Card>
                              </Col>
                            </Row>
                          );
                        }
                      })}
                    </div>
                  </Card>
                )}
              </div>
            </div>
          </div>
        </div>
        <Modal
          title=""
          visible={this.state.imageVisible}
          maskClosable={true}
          onCancel={this.handleModalCancel}
          width={600}
          footer={null}
        >
          <div className="p-3">
            {this.state.imageData.image && (
              <img
                src={this.state.imageData.image}
                alt="Student Added thier works"
                className="img-fluid mb-2"
              />
            )}
            <div className="floatingImage">
              <div className="textContainer w-100">
                <div
                  style={{
                    color: "#0076FF",
                    fontSize: 14,
                    fontWeight: "800",
                    textTransform: "uppercase"
                  }}
                  className="title FontAvenirHeavy pb-1 text-capitalize"
                >
                  {this.state.imageData.title}
                </div>
                {this.state.imageData.notes && (
                  <div className="title FontAvenirMedium">
                    {base64regex.test(this.state.imageData.notes)
                      ? window.atob(this.state.imageData.notes)
                      : this.state.imageData.notes}
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          title="Audio Feedback"
          visible={this.state.modalVisible}
          maskClosable={true}
          width={600}
          onCancel={this.onCloseModal}
          footer={[
            <Button
              key="close"
              size="large"
              type="primary"
              onClick={this.onCloseModal}
            >
              Close
            </Button>
          ]}
        >
          <div>
            <div>
              <Bars is_playing={this.state.is_playing} />
            </div>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <div className="card-title-pd">
                <span
                  style={{
                    color: "#0076FF",
                    fontSize: 14,
                    fontWeight: "800",
                    textTransform: "uppercase"
                  }}
                >
                  {this.state.audio_type}
                </span>
                {this.state.lc_name && (
                  <React.Fragment>
                    <span className="dot" />
                    <span
                      style={{ color: "#4A4A4A", fontSize: 14, opacity: 0.67 }}
                    >
                      {this.state.lc_name}
                    </span>
                  </React.Fragment>
                )}
              </div>
              <div className="time" style={{ fontSize: 14, color: "#999999" }}>
                {moment(this.state.disp_time).format("hh:mm A")}
              </div>
            </div>
            {this.state.modalVisible && (
              <Audio
                src={this.state.audio_url}
                className="w-100"
                controlsList="nodownload"
                onPlay={() => this.setState({ is_playing: true })}
                onPause={() => this.setState({ is_playing: false })}
                onEnded={() => this.setState({ is_playing: false })}
              />
            )}
            {this.state.imageData.notes && (
              <React.Fragment>
                <div
                  className="mt-3 mb-2"
                  style={{
                    color: "#0076FF",
                    fontSize: 14,
                    fontWeight: "800",
                    textTransform: "uppercase"
                  }}
                >
                  Notes
                </div>
                <div className="title FontAvenirMedium">
                  {this.state.imageData.notes}
                </div>
              </React.Fragment>
            )}
          </div>
        </Modal>
      </div>
    );
  }
}

export default ContentFeedback;
