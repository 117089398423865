import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Table, Icon } from 'antd';
import Axios from 'axios';
import moment from 'moment';
import TimeAgo from "react-timeago";
import { INVALID_YOUTUBE_URLS } from './graphql';

class InvalidYoutubeLinks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_loading: false,
      table_data: [],
      user_type: [],
      total: 0,
      total_count: 0,
      page: 1,
      cronDate: '',
    };
  }

  fetchData = () => {
    let { filteredInfo } = this.state;
    filteredInfo = filteredInfo || { card_type: [2, 4] };

    this.setState({ is_loading: true });
    let query = INVALID_YOUTUBE_URLS;
    query.variables = {
      page: this.state.page,
      cardType: (filteredInfo.card_type && filteredInfo.card_type.length) ? filteredInfo.card_type : [2, 4],
      roleType: (filteredInfo.role_type && filteredInfo.role_type.length) ? filteredInfo.role_type : [2, 4, 5],
      userType: (filteredInfo.user_type && filteredInfo.user_type.length) ? filteredInfo.user_type : [],
    };
    Axios
      .post(`${process.env.REACT_APP_API}`, query)
      .then(res => {

        let youtube_content = res.data.data.NotificationCheckUrl.youtubeUrlCheck_details
        .filter((data) => { 
          if(data.url && data.url == data.new_url) {
            return data;
          }
          if(data.trailer_url && data.trailer_url == data.new_trailer_url) {
            return data;
          }
        })
        .map(x => x);
        
        this.setState({
          table_data: youtube_content,
          user_type: res.data.data.NotificationCheckUrl.user_details.map((x, i) => {
            x['text'] = x['name'];
            x['value'] = parseInt(x['id']);
            return x;
          }),
          total: res.data.data.NotificationCheckUrl.count,
          cronDate: res.data.data.NotificationCheckUrl.cronDate,
          total_count: res.data.data.NotificationCheckUrl.total_count,
          is_loading: false,
        });
        this.props.onChangeCount(res.data.data.NotificationCheckUrl.total_count);
      }).catch(err => console.log(err));
  };

  componentDidMount() {
    this.fetchData();
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter
    }, () => this.fetchData());
  };

  render() {
    let { sortedInfo, filteredInfo } = this.state;
    sortedInfo = sortedInfo || {};
    filteredInfo = filteredInfo || {};

    const columns = [
      {
        title: "Learning content name",
        dataIndex: "name",
        width: "25%",
        render: (text, record, rowIndex) => (
          <div onClick={() => this.props.history.push(`/learningcontent/${record.activities_id}/view`)} className="cursor-pointer">
            {text}
          </div>
        ),
      },
      {
        title: "Created by",
        dataIndex: "user_type",
        width: "15%",
        filters: this.state.user_type,
        filteredValue: filteredInfo.user_type || null,
        onFilter: (value, record) => {
          if (record.user_id == value) {
            return record;
          }
        },
        render: (text, record, rowIndex) => `${record.user.name}`,
      },
      {
        title: "Creator Type",
        dataIndex: "role_type",
        width: "15%",
        filters: [
          { text: "Learning centre", value: 2 },
          { text: "Experts", value: 4 },
          { text: "Curators", value: 5 },
        ],
        filteredValue: filteredInfo.role_type || null,
        render: (text, record, rowIndex) => record.user.role_id === "2" ? 'Learning Center' : record.user.role_id === "4" ? 'Expert' : record.user.role_id === "5" ? 'Curator' : '',
      },
      {
        title: "Card Type",
        dataIndex: "card_type",
        width: "15%",
        filters: [
          { value: 4, text: "Do" },
          { value: 2, text: "Watch" },
        ],
        filteredValue: filteredInfo.card_type || null,
        render: (text, record, rowIndex) => record.default_activities_id === 2 ? 'Watch' : record.default_activities_id === 4 ? 'Do' : '',
      },
      {
        title: "Link type",
        dataIndex: "link_type",
        width: "50%",
        render: (text, record, rowIndex) => (
          <div>
            {record.url && (
              <div>
                <div style={{ height: 24, width: 24, display: record.url === record.new_url ? 'none' : 'block' }}>
                  <Icon type="check-circle" />
                </div>
                <span className="mr-2">Video link:</span>
                <a href={record.url} target="_blank">{record.url}</a>
              </div>
            )}
            {record.trailer_url && (
              <div>
                <div style={{ height: 24, width: 24, display: record.trailer_url === record.new_trailer_url ? 'none' : 'block' }}>
                  <Icon type="check-circle" />
                </div>
                <span className="mr-2">Trailer link:</span>
                <a href={record.trailer_url} target="_blank">{record.trailer_url}</a>
              </div>
            )}
          </div>
        ),
      }
    ];
    return (
      <div>
        <div className="single-notification pointer-normal d-flex align-items-center justify-content-between">
          YOUTUBE LINK VALIDATION
          <span
            style={{ color: '#000000', fontWeight: 700 }}
            className="font-14 font-weight-bold"
          >
            {`Last updated: `}<TimeAgo date={Number(moment(this.state.cronDate).format("x"))} />
          </span>
        </div>
        <Table
          className="notification-table"
          columns={columns}
          loading={this.state.is_loading}
          dataSource={this.state.table_data}
          rowKey={student => student.id}
          onChange={this.handleTableChange}
          rowClassName="table-row"
          pagination={{
            current: this.state.page,
            total: this.state.total,
            onChange: (page, pageSize) => this.setState({ page: page }, () => this.fetchData()),
          }}
          scroll={{ y: 500 }}
        />
      </div>
    );
  }
}

export default withRouter(InvalidYoutubeLinks);
