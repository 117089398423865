import { Button, Card, Collapse, message, Modal, Row, Tag } from "antd";
import axios from "axios";
import React, { Component } from "react";
import ProgressiveImage from "react-progressive-image";
import styled, { keyframes } from "styled-components";
import BreadcrumbView from "../../components/BreadCrumb/BreadcrumbPlayList";
import ExpertCard from "../../components/Expert/Card";
import Loading from "../../components/Loading";
import { ARCHIVE_COLLECTION } from "../../graphql";
import Error500Activity from "../../static/activity/500Err.svg";
import ErrorActivity from "../../static/activity/error.svg";
import Question from "../../static/activity/Question.svg";
import { isLink, openNotification, triggerGAEvent } from "../../utils";
import { ARCHIVE_PLAYLIST } from "../Playlists/api";
import {
  ADD_EXPERT_CONTENT_TO_COLLECTION,
  GET_ONE_COLLECTION,
  GET_ONE_PLAYLIST,
  PUBLISH_COLLECTION,
  PUBLISH_PLAYLIST,
  REMOVE_EXPERT_CONTENT_TO_COLLECTION
} from "./api";
import "./playlist.css";

const LoadingAnimation = keyframes`
  0% {
    background-position: 100% 50%;
}
100% {
    background-position: 0 50%;
}
`;
const ViewCollectionStyle = styled.div`
  .skeleton {
    min-height: 174px;
    height: 174px !important;
    background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
    background-size: 400% 100%;

    animation: ${LoadingAnimation} 1.4s ease infinite;
  }
`;
const Panel = Collapse.Panel;

export default class ViewCollection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      role_id: "",
      title: "",
      id: "",
      type: "",
      source_text: "",
      source_link: "",
      completion_msg: "",
      is_archive: false,
      is_publish: false,
      age_groups: [],
      interests: [],
      data_points: [],
      arrayToRenderPlaylist: [],
      isPlaylist: true,
      fetchData: false,
      is_error: false,
      is_500: false,
      is_collection: false
    };
  }

  fetchOneData = () => {
    let pListQuery = GET_ONE_PLAYLIST;
    pListQuery.variables = {
      id: this.props.match.params.id
    };
    this.setState({ loading: true });

    axios
      .post(`${process.env.REACT_APP_API}`, pListQuery)
      .then(res => {
        const arrayToRenderPlaylist = res.data.data.getOnePlaylist.array.lib_act.map(data => {
          let difficulty = { Easy: [], Medium: [], Hard: [] };
          (data.age_groups || []).map(ageGrp => {
            if (ageGrp) {
              if (ageGrp.age_group)
                difficulty[ageGrp.age_group_dificulty.dificulty].push(
                  ageGrp.age_group.age
                );
            }
          });
          data.age_diff = difficulty;
          return data;
        });
        this.setState({
          id: parseInt(res.data.data.getOnePlaylist.id),
          type: "playlist",
          arrayToRenderPlaylist: arrayToRenderPlaylist.sort(
            (a, b) => a.order - b.order
          ),
          title: res.data.data.getOnePlaylist.title,
          description: res.data.data.getOnePlaylist.description,
          completion_msg: res.data.data.getOnePlaylist.completion_msg,
          background_image: res.data.data.getOnePlaylist.background_image,
          source_text: res.data.data.getOnePlaylist.source_text,
          source_link: res.data.data.getOnePlaylist.source_link,
          interests: res.data.data.getOnePlaylist.array.intrest,
          data_points: res.data.data.getOnePlaylist.array.data_point,
          age_groups: res.data.data.getOnePlaylist.age_group,
          loading: false,
          is_archive: res.data.data.getOnePlaylist.is_archive,
          is_publish: res.data.data.getOnePlaylist.is_publish,
          is_owner:
            res.data.data.getOnePlaylist.current_user ===
            parseInt(res.data.data.getOnePlaylist.user_id),
          is_added: res.data.data.getOnePlaylist.is_added,
          fetchData: true
        });
      })
      .catch(err => {
        this.setState({ is_500: true, loading: false });
        console.log(err);
      });
  };
  fetchOneCollectionData = () => {
    let pListQuery = GET_ONE_COLLECTION;
    this.setState({ loading: true });

    pListQuery.variables = {
      id: this.props.match.params.id
    };

    axios
      .post(`${process.env.REACT_APP_API}`, pListQuery)
      .then(res => {
        if (res.data.data.GetOneCollection) {
          const arrayToRenderPlaylist = res.data.data.GetOneCollection.array.lib_act.map(data => {
            let difficulty = { Easy: [], Medium: [], Hard: [] };
            (data.age_groups || []).map(ageGrp => {
              if (ageGrp) {
                if (ageGrp.age_group)
                  difficulty[ageGrp.age_group_dificulty.dificulty].push(
                    ageGrp.age_group.age
                  );
              }
            });
            data.age_diff = difficulty;
            return data;
          });
          this.setState({
            arrayToRenderPlaylist: arrayToRenderPlaylist.sort(
              (a, b) => a.order - b.order
            ),
            id: parseInt(res.data.data.GetOneCollection.id),
            type: "collection",
            title: res.data.data.GetOneCollection.title,
            description: res.data.data.GetOneCollection.description,
            background_image: res.data.data.GetOneCollection.background_image,
            completion_msg: res.data.data.GetOneCollection.completion_msg,
            source_text: res.data.data.GetOneCollection.source_text,
            source_link: res.data.data.GetOneCollection.source_link,
            interests: res.data.data.GetOneCollection.array.intrest,
            data_points: res.data.data.GetOneCollection.array.data_point,
            age_groups: res.data.data.GetOneCollection.age_group,
            loading: false,
            is_archive: res.data.data.GetOneCollection.is_archive,
            is_publish: res.data.data.GetOneCollection.is_publish,
            is_owner:
              res.data.data.GetOneCollection.current_user ===
              parseInt(res.data.data.GetOneCollection.user_id),
            is_added: res.data.data.GetOneCollection.is_added,
            fetchData: true
          });
        } else {
          this.setState({ loading: false });
          if (res.data.errors)
            openNotification("error", res.data.errors[0].message);
          this.props.history.push("/learningcontent");
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ is_500: true, loading: false });
      });
  };

  publishPlaylist = () => {
    this.setState({ loading: true });
    const id = this.props.match.params.id;
    let mutation = PUBLISH_PLAYLIST;

    mutation.variables = {
      p_id: parseInt(id, 10)
    };

    axios
      .post(`${process.env.REACT_APP_API}`, mutation)
      .then(res => {
        if (res.data.data.publishPlaylist.status === true) {
          this.fetchOneData();
          openNotification("success", "Learning path Published");
        } else {
          openNotification("error", res.data.data.publishPlaylist.message);
        }
        this.setState({ loading: false });
      })
      .catch(err => {
        console.log(err);
        this.setState({ is_500: true, loading: false });
      });
  };
  publishCollection = () => {
    this.setState({ loading: true });
    const id = this.props.match.params.id;
    let mutation = PUBLISH_COLLECTION;

    mutation.variables = {
      collections_id: parseInt(id, 10)
    };
    axios
      .post(`${process.env.REACT_APP_API}`, mutation)
      .then(res => {
        if (res.data.errors) {
          openNotification("error", res.data.errors[0].message);
        }
        if (res.data.data.publishCollection.collection_details) {
          this.fetchOneCollectionData();
          openNotification("success", "Collection Published");
        } else {
          openNotification("error", "");
        }
        this.setState({ loading: false });
      })
      .catch(err => {
        console.log(err);
        this.setState({ is_500: true, loading: false });
      });
  };
  getLocalStorage = () => {
    this.setState({
      role_id: Number(localStorage.getItem("role_id")),
    });
  };
  componentDidMount() {
    this.getLocalStorage();
    if (this.props.history.location.pathname.includes("collection")) {
      this.fetchOneCollectionData();
      this.setState({ is_collection: true });
    } else if (this.props.history.location.pathname.includes("playlist")) {
      this.fetchOneData();
      this.setState({ is_collection: false });
    }
  }

  onCollapseChange = () => {
    triggerGAEvent("Playlist", "Changing Collapse in View Playlist");
  };
  onArchiveCollectionClick = () => {
    triggerGAEvent("Collection", "Archiving a collection");
    const confirmDialog = Modal.confirm({
      icon: <img src={Question} alt="question" />,
      title: `Archive ${
        this.props.history.location.pathname.includes("collection")
          ? "Collection"
          : "Learning Path"
      }`,
      content: `This will remove the ${
        this.props.history.location.pathname.includes("collection")
          ? "Collection"
          : "Learning Path"
      }. The ${
        this.props.history.location.pathname.includes("collection")
          ? "collection"
          : "learning path"
      } will anyways stored and saved under Archive Tab`,
      okText: "Continue Anyway",
      okType: "danger",
      onOk: () => {
        {
          if (this.props.history.location.pathname.includes("collection")) {
            this.ArchiveVisibleProgress();
          } else if (
            this.props.history.location.pathname.includes("playlist")
          ) {
            this.archivePlaylist();
          }
        }
      },
      centered: true
    });
    this.setState({ confirmDialog });
  };
  ArchiveVisibleProgress = () => {
    const query = ARCHIVE_COLLECTION;
    query.variables = { id: this.props.match.params.id };
    this.setState({ loading: true });
    axios
      .post(`${process.env.REACT_APP_API}`, query)
      .then(res => {
        this.setState({ loading: false });
        if (res.data.errors) {
          openNotification("error", res.data.errors[0].message);
        } else {
          message.success("Collection is Archived");
          this.props.history.push("/learningcontent");
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ is_500: true, loading: false });
      });
  };
  archivePlaylist = () => {
    triggerGAEvent("Playlist", "Archiving the Playlist");
    this.setState({ loading: true });
    let mutation = ARCHIVE_PLAYLIST;
    mutation.variables = {
      p_id: Number(this.props.match.params.id)
    };
    axios
      .post(`${process.env.REACT_APP_API}`, mutation)
      .then(res => {
        this.setState({ loading: false, shouldBlockNavigation: false });
        if (res.data.data.archivePlaylist.status === true) {
          this.props.history.push("/learningcontent");
          openNotification("success", "Learning path Archived");
        } else {
          openNotification("error", res.data.data.archivePlaylist.message);
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ is_500: true, loading: false });
      });
  };
  AddContentToLC = () => {
    const query = ADD_EXPERT_CONTENT_TO_COLLECTION;
    query.variables = {
      card_id: this.state.id,
      card_type: this.state.type
    };
    // console.log(query.variables);
    this.setState({
      loading: true
    });
    axios
      .post(`${process.env.REACT_APP_API}`, query)
      .then(res => {
        console.log(res);
        this.setState({ loading: false, is_added: true });
      })
      .catch(err => {
        console.log(err);
        this.setState({ loading: false });
      });
  };
  RemoveContentFromLC = () => {
    const query = REMOVE_EXPERT_CONTENT_TO_COLLECTION;
    query.variables = {
      card_id: this.state.id,
      card_type: this.state.type
    };
    // console.log(query.variables);
    this.setState({
      loading: true
    });
    axios
      .post(`${process.env.REACT_APP_API}`, query)
      .then(res => {
        console.log(res);
        this.setState({ loading: false, is_added: false });
      })
      .catch(err => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  render() {
    const {
      arrayToRenderPlaylist,
      data_points,
      interests,
      age_groups
    } = this.state;
    if (this.state.is_error || this.state.is_500) {
      return (
        <div className="col-8 mx-auto mt-5 pb-5 text-center">
          <img
            src={this.state.is_500 ? Error500Activity : ErrorActivity}
            alt="error"
            className="img-fluid"
          />
        </div>
      );
    } else {
      return (
        <React.Fragment>
          <ViewCollectionStyle className="view-playlist">
            <Loading is_loading={this.state.loading} />
            <div style={{ background: "white" }}>
              {this.state.fetchData && this.state.is_owner && (
                <React.Fragment>
                  {this.state.is_archive && (
                    <BreadcrumbView
                      title={this.state.title}
                      data={`${
                        this.props.history.location.pathname.includes(
                          "collection"
                        )
                          ? "/collection"
                          : "/learning path"
                      }/archived/${this.state.title}`}
                    />
                  )}
                  {!this.state.is_archive && this.state.is_publish && (
                    <BreadcrumbView
                      title={this.state.title}
                      data={`${
                        this.props.history.location.pathname.includes(
                          "collection"
                        )
                          ? "/collection"
                          : "/learning path"
                      }/published/${this.state.title}`}
                    >
                      <Button
                        ghost
                        size="large"
                        className="mr-3"
                        type="danger"
                        onClick={this.onArchiveCollectionClick}
                      >
                        Archive
                      </Button>
                      <Button
                        ghost
                        size="large"
                        type="primary"
                        onClick={() => {
                          if (
                            this.props.history.location.pathname.includes(
                              "collection"
                            )
                          ) {
                            this.props.history.push(
                              `/collection/${this.props.match.params.id}/edit`
                            );
                            // this.fetchOneCollectionData();
                          } else if (
                            this.props.history.location.pathname.includes(
                              "playlist"
                            )
                          ) {
                            // this.fetchOneData();
                            this.props.history.push(
                              `/playlist/${this.props.match.params.id}/edit`
                            );
                          }
                        }}
                      >
                        Edit
                      </Button>
                    </BreadcrumbView>
                  )}
                  {!this.state.is_archive && !this.state.is_publish && (
                    <BreadcrumbView
                      title={this.state.title}
                      data={`${
                        this.props.history.location.pathname.includes(
                          "collection"
                        )
                          ? "/collection"
                          : "/learning path"
                      }/un-published/${this.state.title}`}
                    >
                      <Button
                        ghost
                        size="large"
                        type="primary"
                        className="mr-3"
                        onClick={() =>
                          // this.props.history.push(
                          //   `/collection/${this.props.match.params.id}/edit`
                          // )
                          {
                            if (
                              this.props.history.location.pathname.includes(
                                "collection"
                              )
                            ) {
                              this.props.history.push(
                                `/collection/${this.props.match.params.id}/edit`
                              );
                            } else if (
                              this.props.history.location.pathname.includes(
                                "playlist"
                              )
                            ) {
                              this.props.history.push(
                                `/playlist/${this.props.match.params.id}/edit`
                              );
                            }
                          }
                        }
                      >
                        Edit
                      </Button>
                      <Button
                        size="large"
                        type="primary"
                        className="btn-w250 add-collec-btn"
                        // onClick={this.publishCollection}
                        onClick={() => {
                          {
                            if (
                              this.props.history.location.pathname.includes(
                                "collection"
                              )
                            ) {
                              this.publishCollection();
                            } else if (
                              this.props.history.location.pathname.includes(
                                "playlist"
                              )
                            ) {
                              this.publishPlaylist();
                            }
                          }
                        }}
                      >
                        {this.props.history.location.pathname.includes(
                          "collection"
                        )
                          ? "Publish Collection"
                          : "Publish Learning Path"}
                      </Button>
                    </BreadcrumbView>
                  )}
                </React.Fragment>
              )}
              {this.state.fetchData && !this.state.is_owner && (
                <React.Fragment>
                  <BreadcrumbView
                    title={this.state.title}
                    data={`/content creator/${this.state.title}`}
                  >
                    {!this.state.is_added && (
                      <Button
                        size="large"
                        type="primary"
                        onClick={() => {
                          this.AddContentToLC();
                        }}
                      >
                        Add to my learning centre
                      </Button>
                    )}
                    {this.state.is_added && (
                      <Button
                        ghost
                        size="large"
                        type="danger"
                        onClick={() => {
                          this.RemoveContentFromLC();
                        }}
                      >
                        Remove from learning centre
                      </Button>
                    )}
                  </BreadcrumbView>
                </React.Fragment>
              )}
            </div>
            <div style={{ margin: "2rem" }}>
              <Card className="plistViewTitle viewPlistCollapse">
                <div className="row m-0">
                  <div className="col-6">
                    <label className="mb-3">Title</label>
                    <p className="playlist-title text-initial">
                      {this.state.title}
                    </p>
                  </div>
                  <div className="col-6">
                    <label className="mb-3">Type</label>
                    <p className="playlist-title">
                      {this.props.history.location.pathname.includes(
                        "collection"
                      )
                        ? "Collection"
                        : "Learning Path"}
                    </p>
                  </div>
                </div>
                <div className="row m-0 mb-3">
                  <div className="col-6">
                    {this.state.background_image && (
                      <React.Fragment>
                        <label className="mb-3">Thumbnail</label>
                        <div className="row m-0">
                          <ProgressiveImage
                            src={this.state.background_image}
                            placeholder=""
                          >
                            {(src, loading) => {
                              return loading ? (
                                <div 
                                  className="skeleton" 
                                  style={{
                                    minHeight: this.state.role_id === 2 ? "274px" : "182px",
                                    maxHeight: this.state.role_id === 2 ? "274px" : "182px",
                                    minWidth: this.state.role_id === 2 ? "234px" : "325px",
                                    maxWidth: this.state.role_id === 2 ? "234px" : "325px",
                                    objectFit: 'cover',
                                  }}
                                />
                              ) : (
                                <img
                                  className="col-8 p-0"
                                  src={src}
                                  alt="activity card"
                                  style={{
                                    minHeight: this.state.role_id === 2 ? "274px" : "182px",
                                    maxHeight: this.state.role_id === 2 ? "274px" : "182px",
                                    minWidth: this.state.role_id === 2 ? "234px" : "325px",
                                    maxWidth: this.state.role_id === 2 ? "234px" : "325px",
                                    objectFit: 'cover',
                                  }}
                                />
                              );
                            }}
                          </ProgressiveImage>
                        </div>
                      </React.Fragment>
                    )}
                  </div>
                  <div className="col-6 d-flex flex-column align-items-start justify-content-start">
                    <label className="mb-3">Description</label>
                    <p className="playlist-title text-initial mb-3">
                      {this.state.description}
                    </p>
                    {this.state.completion_msg && (
                      <React.Fragment>
                        <label className="mb-3">COMPLETION MESSAGE</label>
                        <p className="playlist-title text-initial">
                          {this.state.completion_msg}
                        </p>
                      </React.Fragment>
                    )}
                  </div>
                </div>
              </Card>

              <div className="viewPlistCollapse">
                <div className="titleCard text-uppercase">
                  {`Details of this ${
                    this.state.is_collection ? "Collection" : "Learning Path"
                  }`}
                </div>
                <Card>
                  {interests.length > 0 && (
                    <Row>
                      <label className="mb-3">Interests</label>
                      <div>
                        {interests.map((ai, i) => (
                          <Tag key={i} color="blue" className="mb-2">
                            {ai.name}
                          </Tag>
                        ))}
                      </div>
                    </Row>
                  )}
                  {this.state.source_text && (
                    <Row>
                      <label className="mb-3">Source Credit</label>
                      <div className="font-16">
                        <span>{this.state.source_text}</span>
                        <span>
                        <a
                          href={
                            isLink.test(this.state.source_link)
                              ? this.state.source_link
                              : `http://${this.state.source_link}`
                          }
                          target="_blank"
                        >
                          {this.state.source_link
                            ? ` - ${this.state.source_link}`
                            : ""}
                        </a>
                      </span>
                      </div>
                    </Row>
                  )}
                  {data_points.length > 0 && (
                    <Row>
                      <label className="mb-3">Skill</label>
                      <div>
                        {data_points.map((ad, i) => (
                          <Tag key={i} color="blue" className="mb-2">
                            {ad.name}
                          </Tag>
                        ))}
                      </div>
                    </Row>
                  )}
                  <Row className="age_groups">
                    <label className="mb-3">Age group & Difficulty</label>
                    <div>
                      {age_groups.map((age, index) => (
                        <span key={index} className="borderful">
                          {age.age}
                        </span>
                      ))}
                    </div>
                  </Row>
                </Card>
              </div>
              {arrayToRenderPlaylist.length !== 0 && (
                <div className="viewPlistCollapse">
                  <div className="titleCard text-uppercase">
                    {/* Linked Activities and Libraries */}
                    Linked Learning Contents
                  </div>
                  <Card>
                    <div className="linkedActivities row m-0">
                      {arrayToRenderPlaylist.map((item, index) => (
                        <div className="col-4">
                          <ExpertCard
                            videoRefIcon={
                              item.default_activities_id === 2
                                ? item.video_link !== ""
                                : item.url !== ""
                            }
                            counter={index + 1}
                            // imgRefIcon={false}
                            // stepsRefIcon={false}
                            imgRefIcon={item.activity_reference_images}
                            stepsRefIcon={item.steps}
                            pdfRefIcon={item.pdf_url}
                            floatingContainer={true}
                            floatingContainerLeft={true}
                            title={item.name}
                            time={item.time}
                            difficulty={item.age_diff}
                            difficultyVisible={item.age_diff ? true : false}
                            defaultActivitiesId={item.default_activities_id}
                            Tag={[
                              {
                                name: item.status,
                                type:
                                  item.status === "Approved"
                                    ? "success"
                                    : "danger"
                              },
                              {
                                name: item.activity_state,
                                type:
                                  item.activity_state === "Active"
                                    ? "warning"
                                    : "danger"
                              }
                            ]}
                            src={
                              item.thumb_image
                                ? item.thumb_image.length
                                  ? item.thumb_image[0].image_url
                                  : ""
                                : ""
                            }
                          >
                            {/* <List.Item key={index}>
                        <div className="d-flex align-items-center justify-content-start">
                          <div className="badge">{index + 1}</div>
                          {item.thumb_image.length && (
                            <img
                              src={item.thumb_image[0].image_url}
                              className="img-fluid activityImage ml-3 mr-3"
                              alt=""
                            />
                          )}
                          <span>{item.name}</span>
                        </div>
                        <Tag color="lime">{item.status}</Tag>
                      </List.Item> */}
                          </ExpertCard>
                        </div>
                      ))}
                    </div>
                  </Card>
                </div>
              )}
            </div>
          </ViewCollectionStyle>
        </React.Fragment>
      );
    }
  }
}
