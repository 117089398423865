import {
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  Icon,
  Input,
  Layout,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Tag,
  Tooltip,
  DatePicker,
  TimePicker
} from 'antd'
import axios from 'axios'
import _ from 'lodash'
import React from 'react'
// import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Prompt } from 'react-router'
import RichTextEditor from 'react-rte'
import Form from 'react-validation/build/form'
import BreadcrumbView from '../../components/BreadCrumb'
import ButtonC from '../../components/ButtonC'
// import FeaturedImageUpload from "../../components/FeaturedImage";
import WorkshopImageUpload from '../../components/ImageUploadCrop/workshopUpload'
import InputC from '../../components/InputC'
import WorkshopPdfUpload from '../../components/workshopPdfUpload'
import TextEditor from '../../components/TextEditor'
import WorkshopDateTime from '../../components/WorkshopDateTime'
import cc from 'currency-codes'
import { DateUtils } from 'react-day-picker'

import {
  ADD_TAG,
  CHECK_YOUTUBE_LINK,
  GET_CREATE_ACTIVITY,
  GET_INTEREST,
  GET_TAGS
} from '../../graphql'

import { ADD_DRAFT_WORKSHOP, SEND_WORKSHOP_FOR_APPROVAL } from './graphql'
import moment, { duration } from 'moment'
import deleteImage from '../../static/card/asdelete.png'
import {
  dragDrop_reorder,
  featuredImage_listStyle,
  openNotification,
  titleCase,
  triggerGAEvent,
  YOUTUBE_VIDEO_REGEX
} from '../../utils'
import { isRequired, required, youtubeUrl } from '../../validation'

import './createWorkshop.css'

const Option = Select.Option
const RadioGroup = Radio.Group
const Panel = Collapse.Panel
const { TextArea } = Input
const { Content } = Layout

class WorkshopCreate extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      shouldBlockNavigation: false,
      longDescValue: RichTextEditor.createEmptyValue(''),
      learn_outcome: RichTextEditor.createEmptyValue(''),
      you_know: RichTextEditor.createEmptyValue(''),
      isPaid: '',
      isFormSubmitting: false,
      workshop_draft_id: null,
      draft_status: 0,
      fileList: [],
      ImagesfileList: [],
      pdfFileList: [],
      pdf_url: '',
      // pdf_source_text: "",
      // pdf_source_url: "",
      workshop_type: '',
      imageStepUpload: [],
      image_card_image: [],
      weekdays: [
        {
          name: 'M',
          day: 0,
          selected: false
        },
        {
          name: 'T',
          day: 1,
          selected: false
        },
        {
          name: 'W',
          day: 2,
          selected: false
        },
        {
          name: 'T',
          day: 3,
          selected: false
        },
        {
          name: 'F',
          day: 4,
          selected: false
        },
        {
          name: 'S',
          day: 5,
          selected: false
        },
        {
          name: 'S',
          day: 6,
          selected: false
        }
      ],
      material_required: [
        { name: '', image: '' },
        { name: '', image: '' }
        //{ name: "", image: "" }
      ],
      post_prereq: [],
      is_loading: false,
      is_dragging: false,
      image_card_steps: '',
      name: ['Workshops', 'Workshop', 'Untitled Workshop'],
      stepResult: [],
      items: [],
      selectedTags: [],
      previewVisible: false,
      displayNameVisible: false,
      imgVisible: false,
      previewImage: '',
      thumb_image: [
        { image_url: '', position: 0 },
        { image_url: '', position: 1 },
        { image_url: '', position: 2 }
      ],
      stepsView: false,
      act_int_cat: [],
      act_data_grade: [],
      act_int: [],
      act_int_id: '',
      act_suitfor: [],
      workshop_age_group: [],
      act_age_dificulty: [],
      act_prereq: [],
      getselectint: [],
      workshop_Int_cat: [],
      workshop_Int_cat_ids: [],
      workshop_date: null,
      dateValue: '',
      selectedDays: [],
      indSelectedDays: [],
      trailer_link: '',
      you_knowVal: '',
      learn_outcomeVal: '',
      formValue: {
        workshop_name: '',
        act_int_cat_val: '',
        work_long_desc: '',
        // act_type: "Centre Based",
        status: 'Draft',
        act_state: 'Active',
        durationVal: 'hr',
        // source: "",
        // video_link: "",
        // video_credit_link: "",
        // video_credit_text: "",
        // image_credit_link: "",
        // image_credit_text: "",
        // steps_credit_link: "",
        // steps_credit_text: "",
        // workshop_duration: '',
        workshop_durationTxt: '',
        workshop_minutes: '0m',
        minimum_participants: '1',
        maximum_participants: '',
        workshop_amount: '',
        workshop_currency: 'INR',
        repeatsType: 'dnr',
        workshop_time: '',
        workshop_Endtime: '',
        repeats_occurance: '1'
      },
      isConsecutive: true,
      isNC_commonTimings: true,
      editButtonVisibility: false,
      learning_outcome: '',
      reference_link: '',
      pdf_name: '',
      workshop_tags: [],
      m_workshop_tags: [],
      tags: [],
      tag_ids: [],
      currency_arr: [],
      truthObj: {
        // workshop_duration: false,
        workshop_durationTxt: false,
        workshop_minutes: false,
        workshop_iduration: false,
        workshop_name: false,
        workshop_name_length: false,
        act_int_cat_val: false,
        getselectint: false,
        workshop_type: false,
        work_long_desc: false,
        workshop_amount: false,
        minimum_participants: false,
        maximum_participants: false,
        // prerequisites: false,
        durationVal: false,
        selectedDays: false,
        // video_link: false,
        // mashupData: false,
        ageData: false,
        materials_req: false,
        thumb_image: false,
        pdf: false,
        workshop_currency: false,
        repeatsType: false
      }
    }
    this.callSaveDraftData = _.debounce(this.callSaveDraftData, 2000)
  }

  handleCancel = () => this.setState({ previewVisible: false })

  handleChange = ({ fileList }) => this.setState({ fileList })

  componentDidMount () {
    this.setState(
      {
        role_id: Number(localStorage.getItem('role_id')),
        selectedDays: [],
        workshop_date: new Date(moment().add(1, 'days')),
        workshopShow_date: new Date()
      },
      () => {
        this.state.selectedDays.map(x => {
          const dayNo = moment(x).day()
          let weekdays = this.state.weekdays.map(d => {
            d.selected = d.day == dayNo - 1
            return d
          })
          this.setState({
            weekdays
          })
        })
      }
    )

    axios
      .post(`${process.env.REACT_APP_API}`, GET_CREATE_ACTIVITY)
      .then(res => {
        const getResult = res.data.data
        let interestCategories = getResult.getGlobalInterestCategory.intrest_cat
          .filter(data => data.total_interest !== 0)
          .map(int => ({
            ...int,
            is_added:
              (getResult.getInterestCategory.intrest_cat || []).filter(
                data => data.id === int.id
              ).length !== 0
          }))
        const age_grp = getResult.getAge.age_groups.map(data => ({
          ...data,
          age_group_dificulty_id: '1',
          is_active: true,
          dificulty: ''
        }))
        this.setState({
          workshop_age_group: age_grp,
          act_int_cat: interestCategories,
          act_suitfor: getResult.getSuitableFor,
          act_age_dificulty: getResult.getAge.age_dificulty,
          is_loading: false,
          shouldBlockNavigation: true,
          currency_arr: cc.codes()
        })
      })
      .catch(err => console.log(err))
    this.getTags()
  }

  // getPrerequisites = () => {
  //   let query = GET_PREREQUISITES;
  //   query.variables = {
  //     user_id: null,
  //     age_group_id: this.state.workshop_age_group.filter((age) => !age.is_active)
  //       .length
  //       ? this.state.workshop_age_group
  //           .filter((age) => !age.is_active)
  //           .map((a) => Number(a.id))
  //       : [1],
  //   };
  //   axios
  //     .post(`${process.env.REACT_APP_API}`, query)
  //     .then((res) => {
  //       const getResult = res.data.data;
  //       const lc_prereq = getResult.getActivityndLPPrerequisites.activity_details.map(
  //         (data) => ({
  //           ...data,
  //           id: `c_${data.id}`,
  //           isPlaylist: false,
  //         })
  //       );
  //       const lp_prereq = getResult.getActivityndLPPrerequisites.learningpath_details.map(
  //         (data) => ({
  //           ...data,
  //           id: `p_${data.id}`,
  //           name: data.title,
  //           isPlaylist: true,
  //         })
  //       );
  //       this.setState({
  //         act_prereq: [...lc_prereq, ...lp_prereq],
  //       });
  //     })
  //     .catch((err) => console.log(err));
  // };

  getTags = () => {
    axios.post(`${process.env.REACT_APP_API}`, GET_TAGS).then(res => {
      const tags = res.data.data.getTags.tags
      let uniqTag = _.uniqBy(tags, 'name')
      this.setState({ workshop_tags: uniqTag, m_workshop_tags: uniqTag })
    })
  }

  onChangeDescEditor = (name, editor) => {
    this.setState({ [name]: editor })
  }

  handleSubmit = () => {
    this.setState({ is_loading: true })
    let mutation = SEND_WORKSHOP_FOR_APPROVAL
    mutation.variables = {
      workshop_draft_id: this.state.workshop_draft_id
    }
    axios
      .post(`${process.env.REACT_APP_API}`, mutation)
      .then(res => {
        if (res.data.errors) {
          openNotification('error', res.data.errors[0].message)
          this.setState({
            is_loading: false,
            loading: false,
            shouldBlockNavigation: false,
            isFormSubmitting: false
          })
        } else {
          if (res.data.data.sendWorkshopApproval) {
            this.setState({
              is_loading: false,
              loading: false,
              shouldBlockNavigation: false,
              isFormSubmitting: false
            })
            openNotification('success', 'Workshop content sent for Approval')
            this.props.history.push(
              `/workshops/${this.state.workshop_draft_id}/view/draft`
            )
          }
        }
      })
      .catch(err => console.log(err))
  }

  saveDraftData = () => {
    this.setState({ draft_status: 1 })
    let mutation = ADD_DRAFT_WORKSHOP
    let selectedDays1 = this.state.selectedDays
    if (this.state.selectedDays.length) {
      selectedDays1 = this.state.selectedDays.sort(function (a, b) {
        return moment(a).format('X') - moment(b).format('X')
      })
    }
    mutation.variables = {
      workshop_draft_id: this.state.workshop_draft_id,
      description: this.state.formValue.work_long_desc,
      status: this.state.formValue.status,
      workshop_name: titleCase(this.state.formValue.workshop_name.trim()),
      featured_image: this.state.thumb_image
        .filter(data => data.image_url)
        .map(data => ({ image_url: data.image_url })),
      // featured_image: this.state.thumb_image,
      workshop_type: this.state.isPaid,
      workshop_amount: this.state.formValue.workshop_amount,
      maximum_participants: this.state.formValue.maximum_participants,
      minimum_participants: this.state.formValue.minimum_participants,
      interest_categories_id: Number(this.state.formValue.act_int_cat_val),
      // skills_required: this.state.formValue.skills_required,
      // long_desc: this.state.longDescValue
      //   .getEditorState()
      //   .getCurrentContent()
      //   .hasText()
      //   ? this.state.formValue.act_long_desc.replace("<script", "")
      //   : "",
      workshop_time: this.state.formValue.workshop_time,
      workshop_duration: this.state.formValue.workshop_durationTxt,
      workshop_duration_type: this.state.formValue.durationVal,
      workshop_date: moment(selectedDays1[0]).format('YYYY-MM-DD'),
      workshop_dates: selectedDays1.map(x => moment(x).format('YYYY-MM-DD')),
      workshop_schedule_date: selectedDays1.map(x =>
        moment(x).format('YYYY-MM-DD')
      ),
      workshop_plan_id:
        this.state.selectedDays.length == 1
          ? 1
          : this.state.isConsecutive
          ? 2
          : 3,
      repeats_type: this.state.formValue.repeatsType,
      repeats_day:
        this.state.formValue.repeatsType != 'dnr'
          ? this.state.weekdays.filter(dd => dd.selected).map(d => d.day)
          : null,
      repeats_occurance:
        this.state.formValue.repeatsType == 'week'
          ? this.state.formValue.endsOn == 2
            ? this.state.formValue.repeats_occurance
            : null
          : null,
      workshop_EndDate:
        this.state.formValue.repeatsType == 'week'
          ? this.state.formValue.endsOn == 1
            ? this.state.workshop_date
            : null
          : null,
      tags_id: this.state.tag_ids,
      currency: this.state.formValue.workshop_currency,
      pdf_name: this.state.pdf_name,
      // workshopdatedraft: this.state.formValue.act_time,
      learning_outcome: this.state.learn_outcomeVal,
      reference_link: this.state.pdf_url,
      workshopmaterials: this.state.material_required
        .filter(item => item.name !== '' && item.image !== '')
        .map(item => ({ name: item.name, image: item.image })),
      prerequisites: this.state.you_knowVal,
      interest_id: this.state.workshop_Int_cat_ids.map(int =>
        parseInt(int, 10)
      ),
      age_group: this.state.workshop_age_group
        .filter(age => !age.is_active)
        .map(age => ({
          // age_group_difficulty_id: parseInt(age.age_group_dificulty_id, 10),
          age_group_id: age.id
        })),
      trailer_link: this.state.trailer_link,
      workshop_common_timing: this.state.isNC_commonTimings ? 1 : 0
    }

    if (!this.state.isConsecutive) {
      const indSelectDays = this.state.indSelectedDays.map(x => ({
        workshop_Date: moment(x.date).format('YYYY-MM-DD'),
        start_time: x.time,
        duration: x.durationTxt,
        duration_type: x.durationVal
      }))
      mutation.variables.workshop_nonConsecutive_type = indSelectDays
    }

    axios
      .post(`${process.env.REACT_APP_API}`, mutation)
      .then(res => {
        console.log('GraphQL Result ==> ', res.data)
        if (res.data.errors) {
          // openNotification('error', res.data.errors[0].message)
          this.setState({
            draft_status: 0,
            shouldBlockNavigation: false
          })
        } else {
          this.setState({
            draft_status: 2,
            shouldBlockNavigation: false,
            workshop_draft_id: res.data.data.addWorkshopDraft.id
          })
        }
      })
      .catch(err => console.log(err))
  }

  callSaveDraftData = () => {
    triggerGAEvent('Workshops', 'Save Workshop Draft')
    if (
      this.state.formValue.workshop_name !== '' &&
      !this.state.truthObj['workshop_name']
    ) {
      this.saveDraftData()
    }
  }

  onDraftApiCall = () => {
    this.setState({ draft_status: 0 })
    this.callSaveDraftData()
  }

  onChange = (key, value) => {
    triggerGAEvent('Workshop', 'Entering data for Editing an workshop')
    const state = { ...this.state }
    if (key == 'minimum_participants') {
      if (value.length && value.charAt(0) < 0) {
        return
      }
    }
    if (key == 'workshop_duration') {
      if (value.length) {
        if (isNaN(parseInt(value.charAt(0)))) {
          return
        } else if (parseInt(value.charAt(0)) <= 0) {
          return
        }
      }
    }
    state.formValue[key] = value
    this.setState({ formValue: state.formValue })
    this.onDraftApiCall()
  }

  onChangeDuration = (key, value, minkey = '') => {
    triggerGAEvent(
      'Workshop',
      'Entering duration for add or Editing an workshop'
    )
    const state = { ...this.state }

    // if (key == 'workshop_duration') {
    //   if (value.length) {
    //     // if (isNaN(value.charAt(0))) {
    //     //   return
    //     // } else
    //     if (value.charAt(0) <= 0) {
    //       return
    //     }
    //   }
    // }
    if (minkey == 'workshop_minutes') {
      state.formValue[minkey] = value
    }

    let min = '0'
    if (state.formValue['workshop_minutes'].includes('m')) {
      let mv = state.formValue['workshop_minutes'].split('m').shift()
      min = mv
        .split('')
        .reverse()
        .join('')
      min = min
        .slice(0, 2)
        .trim()
        .split('')
        .reverse()
        .join('')
    }
    let val =
      (parseInt(state.formValue[key]) ? `${state.formValue[key]}h` : ``) +
      (parseInt(state.formValue[key])
        ? parseInt(min) != 0
          ? ` ${min}m`
          : ``
        : parseInt(min) != 0
        ? `${min}m`
        : ``)
    // let minutes = parseInt(state.formValue[key]) * 60 + parseInt(min)
    state.formValue['workshop_durationTxt'] = val
    this.setState({ formValue: state.formValue })
    this.onDraftApiCall()
  }

  onChangeFeeAmount = (key, amnt) => {
    const { formValue } = { ...this.state }
    let val = amnt
    val = val.replace(/([^0-9.]+)/, '')
    val = val.replace(/^(0|\.)/, '')
    const match = /(\d{0,7})[^.]*((?:\.\d{0,2})?)/g.exec(val)
    const value = match[1] + match[2]
    amnt = value
    formValue[key] = value
    this.setState({ formValue })
  }

  onChangeMinpart = (key, value, valid = false) => {
    triggerGAEvent('Workshop', 'Entering data for creating a new workshop')
    const state = { ...this.state }
    const re = /^[0-9\b]+$/
    if (value.length && value.charAt(0) == 0) {
      return
    }
    if (valid) {
      if (
        value != '' &&
        state.formValue['maximum_participants'] != '' &&
        parseInt(state.formValue['minimum_participants']) >=
          parseInt(state.formValue['maximum_participants'])
      ) {
        openNotification(
          'error',
          'Please ensure minimum participants are lesser than maximum participants'
        )
        state.formValue[key] = ''
        this.setState({ formValue: state.formValue })
        return
      }
    }

    if (value != '' && !re.test(value)) {
      return
    }

    state.formValue[key] = value
    this.setState({ formValue: state.formValue })
    this.onDraftApiCall()
  }

  onChangeMaxpart = (key, value, valid = false) => {
    triggerGAEvent('Workshop', 'Entering data for creating a new workshop')
    const state = { ...this.state }
    const re = /^[0-9\b]+$/
    if (value.length && value.charAt(0) == 0) {
      return
    }
    if (valid) {
      if (
        value != '' &&
        state.formValue['minimum_participants'] != '' &&
        parseInt(state.formValue['minimum_participants']) >=
          parseInt(state.formValue['maximum_participants'])
      ) {
        openNotification(
          'error',
          'Please ensure maximum participants are greater than minimum participants'
        )
        state.formValue[key] = ''
        this.setState({ formValue: state.formValue })
        return
      }
    }

    if (value != '' && !re.test(value)) {
      return
    }

    state.formValue[key] = value
    this.setState({ formValue: state.formValue })
    this.onDraftApiCall()
  }

  onChangeNotReq = (key, value) => {
    triggerGAEvent('Workshop', 'Entering data for creating a new workshop')
    if (key == 'you_knowVal') {
      if (
        !this.state.you_know
          .getEditorState()
          .getCurrentContent()
          .hasText()
      ) {
        value = ''
      }
    }
    if (key == 'learn_outcomeVal') {
      if (
        !this.state.learn_outcome
          .getEditorState()
          .getCurrentContent()
          .hasText()
      ) {
        value = ''
      }
    }
    this.setState({ [key]: value })
    this.onDraftApiCall()
  }

  onChangeIndtimings = (i, val, key) => {
    triggerGAEvent('Workshop', 'Entering data for creating a new workshop')
    const { indSelectedDays } = this.state
    if (key == 'duration') {
      if (val.length) {
        // if (isNaN(parseInt(val.charAt(0)))) {
        //   return
        // } else
        if (parseInt(val.charAt(0)) <= 0) {
          return
        }
      }
    }
    indSelectedDays[i][key] = val
    this.setState({ indSelectedDays })
    this.onDraftApiCall()
  }

  onChangeIndDuration = (i, key, value, minkey = '') => {
    triggerGAEvent(
      'Workshop',
      'Entering duration for add or Editing an workshop'
    )
    const { indSelectedDays, truthObj } = this.state
    // if (key == 'duration') {
    //   if (value.length) {
    //     // if (isNaN(value.charAt(0))) {
    //     //   return
    //     // } else
    //     if (value.charAt(0) <= 0) {
    //       return
    //     }
    //   }
    // }
    // if (!value.includes('h') && !value.includes('m')) {
    //   truthObj['workshop_iduration'] = true
    // } else if (value.split(' ').length > 1) {
    //   if (value.includes('h') || value.includes('m')) {
    //     truthObj['workshop_iduration'] = false
    //   } else {
    //     truthObj['workshop_iduration'] = true
    //   }
    // } else {
    //   truthObj['workshop_iduration'] = false
    // }
    if (minkey == 'minutes') {
      indSelectedDays[i][minkey] = value
    }
    let min = '0'
    if (indSelectedDays[i]['minutes'].includes('m')) {
      let mv = indSelectedDays[i]['minutes'].split('m').shift()
      min = mv
        .split('')
        .reverse()
        .join('')
      min = min
        .slice(0, 2)
        .trim()
        .split('')
        .reverse()
        .join('')
    }

    let val =
      (parseInt(indSelectedDays[i][key]) ? `${indSelectedDays[i][key]}h` : ``) +
      (parseInt(indSelectedDays[i][key])
        ? parseInt(min) != 0
          ? ` ${min}m`
          : ``
        : parseInt(min) != 0
        ? `${min}m`
        : ``)
    // let minutes = parseInt(hr) * 60 + parseInt(min)
    indSelectedDays[i]['durationTxt'] = val
    this.setState({ indSelectedDays: indSelectedDays })
    this.onDraftApiCall()
  }

  onChangeFee = (key, value) => {
    // const state = { ...this.state };
    this.setState({ [key]: value })
    this.onDraftApiCall()
  }

  onSelectChange = (key, event) => {
    triggerGAEvent('Workshops', 'Entering data for creating a new workshop')
    const state = { ...this.state }
    state.formValue[key] = event
    this.setState({ formValue: state.formValue })
    this.onDraftApiCall()
  }

  callback = key => {
    console.log(key)
  }

  handleStepsView = () => {
    triggerGAEvent('Activities', 'Steps view')
    window.scrollTo(0, 0)
    this.setState({ stepsView: !this.state.stepsView })
  }

  checkYoutubeLink = key => {
    if (this.state[key] && YOUTUBE_VIDEO_REGEX.test(this.state[key])) {
      let query = CHECK_YOUTUBE_LINK
      query.variables = {
        url: this.state[key]
      }
      axios
        .post(`${process.env.REACT_APP_API}`, query)
        .then(res => {
          let truthObj = { ...this.state.truthObj }
          truthObj[key] = !res.data.data.checkValidUrl.embeddable
          this.setState({ truthObj })
          if (!res.data.data.checkValidUrl.embeddable) {
            message.error(
              'One of the video links added is not available for embedded view on iPad. Please check the highlighted section'
            )
          }
        })
        .catch(err => console.log(err))
    }
  }

  onViewLinkBlur = key => {
    if (this.state[key] && YOUTUBE_VIDEO_REGEX.test(this.state[key])) {
      this.checkYoutubeLink(key)
    } else {
      let truthObj = { ...this.state.truthObj }
      truthObj[key] = false
      this.setState({ truthObj })
    }
  }

  getInterest = val => {
    let query = GET_INTEREST
    query.variables = {
      interest_categories_id: val
    }
    axios
      .post(`${process.env.REACT_APP_API}`, query)
      .then(res => {
        const interest_details = res.data.data.getInterest.interest_details.map(
          data => {
            data.is_active = false
            return data
          }
        )
        this.setState({ getselectint: interest_details })
        return res.data.data.getInterest.interest_details
      })
      .catch(err => console.log(err))
  }

  onSearchSelect = (input, option) => {
    return option.props.children.props.children[1]
      .toLowerCase()
      .trim()
      .includes(input.toLowerCase().trim())
  }

  // onSelectPrerequisites = (index) => {
  //   triggerGAEvent("Activities", "Entering data for creating a new activity");
  //   this.setState({ post_prereq: index });
  //   if (index.length >= 4) {
  //     message.error("Only a maximum of 3 prerequisites can be added");
  //   }
  //   this.onDraftApiCall();
  // };

  onSelectTags = value => {
    triggerGAEvent('Workshops', 'Adding Tags to Workshop')
    let tag_ids = []
    let new_tags = []
    value.forEach(v => {
      let filter = this.state.workshop_tags.filter(
        tag => tag.name.trim().toLowerCase() === v.trim().toLowerCase()
      )
      if (filter.length) {
        tag_ids.push(filter[0].id)
      } else {
        new_tags.push(v)
      }
    })
    if (new_tags.length) {
      let mutation = ADD_TAG
      mutation.variables = { name: new_tags.map(t => t.trim()) }
      axios
        .post(`${process.env.REACT_APP_API}`, mutation)
        .then(res => {
          this.setState({
            tag_ids: [...tag_ids, ...res.data.data.AddTagsV2.tags_ids],
            tags: value
          })
          this.getTags()
        })
        .catch(err => console.log(err))
      this.onDraftApiCall()
    } else {
      this.setState({
        tag_ids: tag_ids,
        tags: value
      })
      this.onDraftApiCall()
    }
  }

  onSelectInterestCat = value => {
    triggerGAEvent('Workshops', 'Adding Interest Categories to Workshop')
    let intCat_ids = []
    value.forEach(v => {
      let filter = this.state.act_int_cat.filter(
        tag => tag.name.trim().toLowerCase() === v.trim().toLowerCase()
      )
      if (filter.length) {
        intCat_ids.push(filter[0].id)
      }
    })
    this.setState({
      workshop_Int_cat_ids: intCat_ids,
      workshop_Int_cat: value
    })
    this.onDraftApiCall()
  }

  filterByAgeGroup = (contents, age) => {
    return contents
      .map(c => {
        if (
          !this.state.act_prereq
            .filter(p => p.id === c)[0]
            .age_group_id.includes(age)
        ) {
          return c
        }
      })
      .filter(c => c)
  }

  setAgeGroupActive = (i, e) => {
    triggerGAEvent('Workshops', 'Setting Age group for an Workshop')
    let age_grp = [...this.state.workshop_age_group]
    let age_groupSel = age_grp.filter(x => !x.is_active).map(r => r.is_active)

    if (age_grp[i].is_active) {
      if (
        (age_groupSel.length > 0 && i == 0 && age_grp[i + 1].is_active) ||
        (i != 0 &&
          age_groupSel.length > 0 &&
          age_grp[i - 1].is_active &&
          i != 0 &&
          age_groupSel.length > 0 &&
          (i == 6 ? age_grp[i - 1].is_active : age_grp[i + 1].is_active))
      ) {
        openNotification(
          'error',
          'Non-consecutive age groups can’t be selected'
        )
        return
      }
    }
    if (!age_grp[i].is_active) {
      if (
        (age_groupSel.length > 1 && i == 0 && age_grp[i + 1].is_active) ||
        (i != 0 &&
          age_groupSel.length > 0 &&
          !age_grp[i - 1].is_active &&
          i != 0 &&
          i != 6 &&
          age_groupSel.length > 0 &&
          !age_grp[i + 1].is_active)
      ) {
        openNotification(
          'error',
          'Non-consecutive age groups can’t be selected'
        )
        return
      }
    }

    if (age_grp[i].is_active) {
      if (e.target) {
        age_grp[i].age_group_id = e.target.value
      } else {
        age_grp[i].age_group_id = e
      }
      age_grp[i].is_active = !age_grp[i].is_active
      this.setState({ workshop_age_group: age_grp })
    } else {
      // const confirmDialog = Modal.confirm({
      //   title: "Do you want to change the age group selection?",
      //   content:
      //     "Prerequisites you selected already fall under this group, this action will be remove those items from this content. Do you want to continue?",
      //   okText: "Yes",
      //   onOk: () => {
      if (e.target) {
        age_grp[i].age_group_id = e.target.value
      } else {
        age_grp[i].age_group_id = e
      }
      age_grp[i].is_active = !age_grp[i].is_active
      const post_prereq = this.filterByAgeGroup(
        this.state.post_prereq,
        this.state.workshop_age_group[i].id
      )
      this.setState({
        workshop_age_group: age_grp,
        post_prereq: post_prereq
      })
      //   },
      // });
      // this.setState({ confirmDialog });
    }
    this.onDraftApiCall()
  }

  setAgeGroupDiff = (i, e) => {
    triggerGAEvent('Activities', 'Setting Age group for an Activity')
    let age_grp = [...this.state.workshop_age_group]
    age_grp[i].age_group_dificulty_id = e
    this.setState({ workshop_age_group: age_grp })
    this.onDraftApiCall()
  }

  TagClick = (tag, check, index, tagItem) => {
    triggerGAEvent('Activities', 'Entering data for creating a new activity')
    if (tagItem !== 'data') {
      const state = [...this.state.getselectint]
      state[index].is_active = !state[index].is_active
      this.setState({ getselectint: state })
    } else {
      const state = [...this.state.getselectdatapoint]
      state[index].is_active = !state[index].is_active
      this.setState({ setselectdatapoint: state })
    }
    this.onDraftApiCall()
  }

  // recommendedAddMore = () => {
  //   triggerGAEvent("Activities", "Add more useful links");
  //   const state = { ...this.state };
  //   const recommended = {
  //     title: "",
  //     link: "",
  //   };
  //   state.recommended = state.recommended.concat(recommended);
  //   this.setState({ recommended: state.recommended });
  // };

  // removeRecommend = (index) => {
  //   triggerGAEvent("Activities", "Remove useful links");
  //   const state = { ...this.state };
  //   state.recommended.splice(index, 1);
  //   this.setState({ recommended: state.recommended });
  //   this.onDraftApiCall();
  // };

  // onChangeStep = (e) => {
  //   triggerGAEvent("Activities", "Entering data for creating a new activity");
  //   const stepLabel = e.target.name;
  //   const stepValue = e.target.value;
  //   this.setState({ [stepLabel]: stepValue });
  // };

  // onChangeRecommend = (key, event, index) => {
  //   triggerGAEvent("Activities", "Entering data for creating a new activity");
  //   const state = { ...this.state };
  //   state.recommended[index][key] = event.target.value;
  //   this.setState({ recommended: state.recommended });
  //   this.onDraftApiCall();
  // };

  // onFeaturedDragStart = (result) => {
  //   this.setState({ is_dragging: !this.state.modal_open });
  // };

  // onFeaturedDragEnd = (result) => {
  //   triggerGAEvent("Activities", "Drag drop in Featured Images");
  //   if (!result.destination) {
  //     return;
  //   }
  //   const thumb_image = dragDrop_reorder(
  //     this.state.thumb_image,
  //     result.source.index,
  //     result.destination.index
  //   );
  //   this.setState({ thumb_image, is_dragging: false });
  // };

  // onDragEnd = (result) => {
  //   triggerGAEvent("Activities", "Drag drop in Steps");
  //   if (!result.destination) {
  //     return;
  //   }
  //   const items = dragDrop_reorder(
  //     this.state.items,
  //     result.source.index,
  //     result.destination.index
  //   );
  //   this.setState({ items });
  // };

  saveSteps = () => {
    triggerGAEvent('Workshops', 'Save details in Steps')
    let truthObj = { ...this.state.truthObj }
    if (this.state.formValue.video_link.length > 0) {
      truthObj['video_link'] = !YOUTUBE_VIDEO_REGEX.test(
        this.state.formValue.video_link
      )
      truthObj['video_credit_text'] = !this.state.formValue.video_credit_text
    } else {
      truthObj['video_link'] = false
      truthObj['video_credit_text'] = false
    }
    truthObj['video_credit_link'] = false
    truthObj['image_credit_text'] =
      this.state.image_card_image.length !== 0
        ? !this.state.formValue.image_credit_text
        : false
    truthObj['image_credit_link'] = false
    truthObj['steps'] =
      this.state.items.filter(
        step =>
          (step.desc === '' && step.image !== '') ||
          (step.desc !== '' && step.image === '')
      ).length !== 0
    truthObj['steps_credit_text'] =
      this.state.items.length !== 0
        ? !this.state.formValue.steps_credit_text
        : false
    truthObj['steps_credit_link'] = false
    this.setState({ truthObj })
    // if (
    //   !truthObj["video_link"] &&
    //   !truthObj["video_credit_text"] &&
    //   !truthObj["image_credit_text"] &&
    //   !truthObj["steps"] &&
    //   !truthObj["steps_credit_text"]
    // ) {
    //   this.getSteps(this.state.items);
    // }
  }

  // getSteps = (data) => {
  //   this.setState({ stepResult: data });
  //   this.setState({ stepsView: false });
  //   this.onDraftApiCall();
  // };

  FeaturedImageUploadSuccess = (value, index) => {
    triggerGAEvent('Workshops', 'Uploading Featured Images')
    let thumb_image = [...this.state.thumb_image]
    thumb_image[index].image_url = value
    this.setState({ thumb_image })
    this.onDraftApiCall()
  }

  FeaturedImageStepUploadSuccess = value => {
    triggerGAEvent('Workshops', 'Upload Images')
    const state = { ...this.state }
    state.image_card_image = value
    this.setState({ image_card_image: state.image_card_image })
  }

  materialRequiredOnchange = (key, index, value) => {
    triggerGAEvent('Workshops', 'Entering data for creating a new activity')
    const state = { ...this.state }
    state.material_required[index][key] = value
    this.setState({ material_required: state.material_required })
    this.onDraftApiCall()
  }

  materialRequiredImage = (file, index) => {
    triggerGAEvent('Workshops', 'Entering data for creating a new activity')
    const state = { ...this.state }
    // state.material_required[index].fileList = file;
    state.material_required[index].fileList = file.map(data => {
      data.url = data.response
      return data
    })
    this.setState({ material_required: state.material_required })
  }

  ImagesUploadedFile = file => {
    this.setState({ ImagesfileList: file })
  }

  stepImageUpload = file => {
    // console.log(file)
    if (file.length < 2) {
      this.setState({ imageStepUpload: file })
    } else {
      openNotification('error', "You can't upload multiple images in steps")
    }
  }

  onPDFUpload = file => {
    if (file) {
      this.setState({
        displayNameVisible: file[0].type.split('/')[0] != 'image',
        imgVisible: file[0].type.split('/')[0] == 'image'
      })
    }
    this.setState({ pdfFileList: file })
  }

  onPDFUploadSuccess = value => {
    triggerGAEvent('Activities', 'Add PDF file')
    this.setState({ pdf_url: value[0] })
    this.onDraftApiCall()
  }

  onPDFChange = (key, value) => {
    triggerGAEvent('Activities', 'Entering data for creating a new activity')
    let state = { ...this.state }
    state[key] = value
    this.setState({ ...state })
    this.onDraftApiCall()
  }

  handleDayClick = (day, { selected, disabled }) => {
    if (disabled) {
      return
    }

    if (
      moment(day).format('X') <
      moment()
        .add(-1, 'days')
        .format('X')
    ) {
      return
    }

    const { selectedDays, indSelectedDays } = this.state

    if (selected) {
      const selectedIndex = selectedDays.findIndex(selectedDay =>
        DateUtils.isSameDay(selectedDay, day)
      )
      selectedDays.splice(selectedIndex, 1)
      const indselectedIndex = indSelectedDays.findIndex(selectedday =>
        DateUtils.isSameDay(selectedday.date, day)
      )
      indSelectedDays.splice(indselectedIndex, 1)
    } else {
      selectedDays.push(day)
      indSelectedDays.push({
        date: day,
        time: '',
        Endtime: '',
        duration: '',
        durationTxt: '',
        durationVal: 'hr'
      })
    }

    const selectedDays1 = selectedDays.sort(function (a, b) {
      return moment(a).format('X') - moment(b).format('X')
    })

    this.setState({ selectedDays: selectedDays1 })
    const isConsecutiveVal = []
    let selected_weekdays = []
    this.state.selectedDays.filter((day, i) => {
      let dayNo = moment(day).day()
      dayNo = dayNo == 0 ? 7 : dayNo
      selected_weekdays.push(dayNo - 1)

      if (
        i != this.state.selectedDays.length - 1 &&
        moment(selectedDays[i + 1]).format('YYYY-MM-DD') ===
          moment(selectedDays[i])
            .add(1, 'days')
            .format('YYYY-MM-DD')
      ) {
        isConsecutiveVal.push(true)
      } else {
        isConsecutiveVal.push(false)
      }
    })

    let weekdays = this.state.weekdays.map(d => {
      d.selected = selected_weekdays.includes(d.day)
      return d
    })
    this.setState({
      weekdays
    })

    const isconboolval = isConsecutiveVal.filter(boolval => !boolval).length > 1
    this.setState(
      {
        isConsecutive: !isconboolval
      },
      () => {
        const { formValue } = this.state
        formValue['repeatsType'] = this.state.isConsecutive
          ? 'dnr'
          : formValue['repeatsType']
        this.setState({
          isNC_commonTimings: this.state.isConsecutive
            ? true
            : this.state.isNC_commonTimings,
          formValue
        })
      }
    )
    this.onDraftApiCall()
  }

  onClickMaterialRequired = () => {
    triggerGAEvent('Activities', 'Add more materials required')
    const material_required = [
      { name: '', image: '' },
      { name: '', image: '' }
      // { name: "", image: "" }
    ]
    const state = { ...this.state }
    const mergematerial = state.material_required.concat(material_required)
    this.setState({ material_required: mergematerial })
  }

  onClickAddFeaturedImages = () => {
    triggerGAEvent('WorkshopFeatured_Images', 'Add more Featured Images')
    let position = this.state.thumb_image.length
    const AddThumb_img = [{ image_url: '', position: position - 1 + 1 }]
    const state = { ...this.state }
    const mergethumb_img = state.thumb_image.concat(AddThumb_img)
    this.setState({ thumb_image: mergethumb_img })
  }

  onRemoveMaterialRequired = index => {
    triggerGAEvent('Activities', 'remove materials required')
    const state = { ...this.state }
    state.material_required.splice(index, 1)
    this.setState({ material_required: state.material_required })
    this.onDraftApiCall()
  }

  FeaturedImageUploadedFile = file => {
    this.setState({ fileList: file })
  }

  stepsClick = e => {
    let sTitle = this.state.items[e].title
    let sImage = this.state.items[e].image
    let sDesc = this.state.items[e].desc
    this.setState({ editButtonVisibility: true, itemToEdit: e })
    this.setState({
      stepTitle: sTitle,
      image_card_steps: sImage,
      stepDesc: sDesc
    })
  }

  onEditClick = () => {
    triggerGAEvent('Activities', 'Edit Step')
    let copyOfState = { ...this.state }
    let indexOfItem = this.state.itemToEdit
    copyOfState.items[indexOfItem].desc = this.state.stepDesc
    copyOfState.items[indexOfItem].image = this.state.image_card_steps
    let sth = copyOfState.items
    this.setState({
      items: sth,
      stepTitle: '',
      image_card_steps: '',
      stepDesc: '',
      editButtonVisibility: false,
      itemToEdit: null
    })
  }

  onClickStep = e => {
    triggerGAEvent('Activities', 'Entering data for creating a new activity')
    const newObj = {
      image: `${this.state.image_card_steps}`,
      position: this.state.items.length + 1,
      title: `${this.state.stepTitle}`,
      desc: `${this.state.stepDesc}`
    }
    const newStep = this.state.items.concat(newObj)
    this.setState({ items: newStep })
    this.setState({
      stepTitle: '',
      stepDesc: '',
      image_card_steps: '',
      imageStepUpload: []
    })
  }

  onCancelStep = () => {
    triggerGAEvent('Activities', 'Cancel in Steps')
    this.setState({
      stepTitle: '',
      stepDesc: '',
      image_card_steps: '',
      imageStepUpload: [],
      editButtonVisibility: false
    })
  }

  deleteFeaturedImage = index => {
    triggerGAEvent('Activities', 'Deleting Featured Images')
    let copyState = { ...this.state }
    copyState.thumb_image[index].image_url = ''
    this.setState({
      thumb_image: copyState.thumb_image
    })
    this.onDraftApiCall()
  }

  deleteStepImage = index => {
    triggerGAEvent('Activities', 'Delete Images')
    let copyState = { ...this.state }
    copyState.ImagesfileList.splice(index, 1)
    copyState.image_card_image.splice(index, 1)
    this.setState({
      ImagesfileList: copyState.ImagesfileList,
      image_card_image: copyState.image_card_image
    })
  }

  onDeleteClick = e => {
    triggerGAEvent('Activities', 'Delete Step')
    let copyOfState = { ...this.state }
    let copyOfItems = copyOfState.items
    copyOfItems.splice(e, 1)
    this.setState({ items: copyOfItems })
  }

  onRemoveBtnClick = index => {
    triggerGAEvent('Workshops', 'Delete Workshop Image')
    const state = { ...this.state }
    state.thumb_image.splice(index, 1)
    this.setState({ thumb_image: state.thumb_image })
  }

  onChangeDatePick = (date, dateValue) => {
    let dayNo = moment(date).day()
    let sel_weekdays = this.state.weekdays.map(d => {
      if (d.selected) {
        return d.day
      }
    })
    dayNo = dayNo == 0 ? 7 : dayNo
    if (sel_weekdays.includes(dayNo - 1)) {
      let workDate = moment(date).format('YYYY-MM-DD')
      this.setState(
        {
          workshop_date: workDate
        },
        () => this.onDraftApiCall()
      )
    } else {
      let wdate = moment(date).format('YYYY-MM-DD')
      let ldate = moment(
        this.state.selectedDays.length > 0
          ? this.state.selectedDays.reverse()[0]
          : new Date()
      )
        .add(7, 'days')
        .format('YYYY-MM-DD')
      if (wdate < ldate) {
        openNotification(
          'error',
          'Please ensure the chosen end date is one week from the current date'
        )
      } else {
        openNotification(
          'error',
          'There seems to be a mismatch between the days, making a repetitive occurrence impossible'
        )
      }
    }
  }

  onChangeTimePick = (time, timeValue) => {
    let { formValue } = this.state
    if (timeValue && formValue.workshop_Endtime) {
      var startTime = moment(timeValue, 'HH:mm A')
      var endTime = moment(formValue.workshop_Endtime, 'HH:mm A')
      if (!startTime.isBefore(endTime)) {
        openNotification('error', 'Start time should be earlier than end time')
        return
      }
      var duration = moment.duration(endTime.diff(startTime))
      duration =
        (duration.hours() ? `${duration.hours()}h` : '') +
        (duration.minutes()
          ? duration.hours()
            ? ` ${duration.minutes()}m`
            : `${duration.minutes()}m`
          : '')
      formValue['workshop_durationTxt'] = duration
    }
    formValue.workshop_time = timeValue
      ? moment(timeValue, 'HH:mm A').format('HH:mm')
      : ''
    this.setState({
      formValue
    })
    this.onDraftApiCall()
  }

  onChangeEndTimePick = (time, timeValue) => {
    let { formValue } = this.state

    if (timeValue && formValue.workshop_time) {
      var startTime = moment(formValue.workshop_time, 'HH:mm A')
      var endTime = moment(timeValue, 'HH:mm A')
      if (!startTime.isBefore(endTime)) {
        openNotification(
          'error',
          'The end time should be later than start time'
        )
        return
      }
      var duration = moment.duration(endTime.diff(startTime))
      duration =
        (duration.hours() ? `${duration.hours()}h` : '') +
        (duration.minutes()
          ? duration.hours()
            ? ` ${duration.minutes()}m`
            : `${duration.minutes()}m`
          : '')
      formValue['workshop_durationTxt'] = duration
    }

    formValue.workshop_Endtime = timeValue
      ? moment(timeValue, 'HH:mm A').format('HH:mm')
      : ''
    this.setState({
      formValue
    })
    this.onDraftApiCall()
  }

  onChangeIndTimePick = (i, time, timeValue) => {
    const { indSelectedDays } = this.state
    if (timeValue && indSelectedDays[i].Endtime) {
      var startTime = moment(timeValue, 'HH:mm A')
      var endTime = moment(indSelectedDays[i].Endtime, 'HH:mm A')
      if (!startTime.isBefore(endTime)) {
        openNotification('error', 'Start time should be earlier than end time')
        return
      }
      var duration = moment.duration(endTime.diff(startTime))
      duration =
        (duration.hours() ? `${duration.hours()}h` : '') +
        (duration.minutes()
          ? duration.hours()
            ? ` ${duration.minutes()}m`
            : `${duration.minutes()}m`
          : '')
      indSelectedDays[i]['durationTxt'] = duration
    }
    indSelectedDays[i].time = timeValue
      ? moment(timeValue, 'HH:mm A').format('HH:mm')
      : ''
    this.setState({ indSelectedDays })
    this.onDraftApiCall()
  }

  onChangeIndEndTimePick = (i, time, timeValue) => {
    const { indSelectedDays } = this.state
    if (timeValue && indSelectedDays[i].time) {
      var startTime = moment(indSelectedDays[i].time, 'HH:mm A')
      var endTime = moment(timeValue, 'HH:mm A')
      if (!startTime.isBefore(endTime)) {
        openNotification(
          'error',
          'The end time should be later than start time'
        )
        return
      }
      var duration = moment.duration(endTime.diff(startTime))
      duration =
        (duration.hours() ? `${duration.hours()}h` : '') +
        (duration.minutes()
          ? duration.hours()
            ? ` ${duration.minutes()}m`
            : `${duration.minutes()}m`
          : '')
      indSelectedDays[i]['durationTxt'] = duration
    }
    indSelectedDays[i].Endtime = timeValue
      ? moment(timeValue, 'HH:mm A').format('HH:mm')
      : ''
    this.setState({ indSelectedDays })
    this.onDraftApiCall()
  }

  onChangerepeatsType = (key, value) => {
    const { formValue } = this.state
    formValue[key] = value
    formValue['endsOn'] = value == 'week' ? '1' : null
    if (this.state.isConsecutive && value != 'dnr') {
      const selectedDays1 = this.state.selectedDays
        .sort(function (a, b) {
          return moment(a).format('X') - moment(b).format('X')
        })
        .reverse()
      this.setState({
        workshop_date:
          this.state.selectedDays.length > 0
            ? new Date(moment(selectedDays1[0]).add(7, 'days'))
            : this.state.workshop_date
      })
    }
    this.setState({ formValue })
    this.onDraftApiCall()
  }

  minmaxval = e => {
    let val = e.target.value
    // console.log("value ==> ", val);

    // if (!Number(val)) {
    //   this.setState({
    //     [e.target.name]: ""
    //   });
    // }
    this.setState({
      [e.target.name]: val
    })
  }

  deleteUploadPDF = () => {
    this.setState({
      pdf_url: '',
      pdfFileList: [],
      displayNameVisible: false,
      imgVisible: false
    })
  }

  componentDidUpdate = () => {
    if (this.state.shouldBlockNavigation && this.state.draft_status === 0) {
      window.onbeforeunload = () => true
    } else {
      window.onbeforeunload = undefined
    }
  }

  onSearchTags = input => {
    let tagsObj = {}

    const tags = this.state.workshop_tags.filter(data => {
      if (
        data.name
          .toLowerCase()
          .trim()
          .includes(input.toLowerCase().trim())
      ) {
        if (
          tagsObj[
            data.name
              .toLowerCase()
              .trim()
              .indexOf(input.toLowerCase().trim())
          ]
        ) {
          tagsObj[
            data.name
              .toLowerCase()
              .trim()
              .indexOf(input.toLowerCase().trim())
          ].push(data)
        } else {
          tagsObj[
            data.name
              .toLowerCase()
              .trim()
              .indexOf(input.toLowerCase().trim())
          ] = []
          tagsObj[
            data.name
              .toLowerCase()
              .trim()
              .indexOf(input.toLowerCase().trim())
          ].push(data)
        }
        return data
      }
      // return data.name.toLowerCase().trim()
    })
    // console.log(tagsObj)
    let orderedObj = []
    Object.keys(tagsObj)
      .sort()
      .forEach(function (key) {
        orderedObj.push(
          tagsObj[key].sort((a, b) =>
            a.name.toLowerCase().trim() > b.name.toLowerCase().trim()
              ? 1
              : b.name.toLowerCase().trim() > a.name.toLowerCase().trim()
              ? -1
              : 0
          )
        )
      })
    this.setState({ m_workshop_tags: orderedObj.flat(1) })
  }

  onSearchInterestCat = input => {
    let intCatObj = {}

    const tags = this.state.act_int_cat.filter(data => {
      if (
        data.name
          .toLowerCase()
          .trim()
          .includes(input.toLowerCase().trim())
      ) {
        if (
          intCatObj[
            data.name
              .toLowerCase()
              .trim()
              .indexOf(input.toLowerCase().trim())
          ]
        ) {
          intCatObj[
            data.name
              .toLowerCase()
              .trim()
              .indexOf(input.toLowerCase().trim())
          ].push(data)
        } else {
          intCatObj[
            data.name
              .toLowerCase()
              .trim()
              .indexOf(input.toLowerCase().trim())
          ] = []
          intCatObj[
            data.name
              .toLowerCase()
              .trim()
              .indexOf(input.toLowerCase().trim())
          ].push(data)
        }
        return data
      }
      // return data.name.toLowerCase().trim()
    })
    // console.log(tagsObj)
    let orderedObj = []
    Object.keys(intCatObj)
      .sort()
      .forEach(function (key) {
        orderedObj.push(
          intCatObj[key].sort((a, b) =>
            a.name.toLowerCase().trim() > b.name.toLowerCase().trim()
              ? 1
              : b.name.toLowerCase().trim() > a.name.toLowerCase().trim()
              ? -1
              : 0
          )
        )
      })
    this.setState({ act_int_cat: orderedObj.flat(1) })
  }

  validateStateValues = () => {
    this.setState({ isFormSubmitting: true })
    let truthObj = { ...this.state.truthObj }
    let getKeys = Object.keys(this.state.formValue)
    getKeys.forEach((key, index) => {
      let getItem = this.state.formValue[key]
      let required = isRequired(getItem)
      truthObj[key] = required
    })
    truthObj['title'] = this.state.formValue.title === ''
    if (this.state.formValue.workshop_name.length > 140) {
      openNotification(
        'error',
        'Name of the Workshop can only be upto 140 characters'
      )
      truthObj['workshop_name_length'] =
        this.state.formValue.workshop_name.length > 140
    }
    let filterAgeGroup = _.filter(this.state.workshop_age_group, {
      is_active: false
    })
    truthObj['ageData'] = filterAgeGroup.length <= 0
    truthObj['thumb_image'] =
      this.state.thumb_image.filter(data => data.image_url).length < 1

    // if (this.state.fileList.length > 0) {
    //   truthObj["fileList"] = this.state.fileList.length > 3;
    // } else {
    //   truthObj["fileList"] = true;
    // }
    // if (this.state.formValue.act_safety === "Not required") {
    //   truthObj["act_safety"] = false;
    //   truthObj["act_sfty_desc"] = this.state.formValue.act_sfty_desc > 100;
    // } else {
    //   truthObj["act_sfty_desc"] = this.state.formValue.act_sfty_desc === "" || this.state.formValue.act_sfty_desc > 100;
    // }
    truthObj['materials_req'] =
      this.state.material_required.filter(x => {
        if (
          (x.name == '' && x.image != '') ||
          (x.name != '' && x.image == '')
        ) {
          return true
        }
      }).length > 0
    truthObj['work_int_cat_val'] = this.state.workshop_Int_cat_ids.length == 0
    truthObj['act_int_cat_val'] = this.state.workshop_Int_cat_ids.length == 0
    // let interests_ids = this.state.getselectint
    //   .filter(int => int.is_active)
    //   .map(int => parseInt(int.id, 10))
    // truthObj['getselectint'] = interests_ids.length < 1
    truthObj['selectedDays'] = this.state.selectedDays.length == 0
    truthObj['repeatsType'] = this.state.formValue.repeatsType === ''
    truthObj['endsOn'] =
      this.state.formValue.repeatsType == 'week'
        ? this.state.formValue.endsOn == ''
        : false
    truthObj['repeats_occurance'] =
      this.state.formValue.repeatsType == 'week'
        ? this.state.formValue.repeats_occurance == ''
        : false
    truthObj['workshop_date'] = this.state.workshop_date === ''
    let weekDays = this.state.weekdays.filter(dd => dd.selected).map(d => d.day)
    truthObj['weekdays'] =
      this.state.formValue.repeatsType == 'week' ? weekDays.length < 1 : false
    if (this.state.selectedDays.length > 0 && !this.state.isNC_commonTimings) {
      truthObj['selectedDays'] = !this.state.isNC_commonTimings
        ? this.state.indSelectedDays.filter(
            x => x.time == '' || x.Endtime == '' || x.durationVal == ''
          ).length > 0
        : false
    }
    truthObj['workshop_time'] = !this.state.isNC_commonTimings
      ? false
      : this.state.formValue.workshop_time == ''
    truthObj['workshop_Endtime'] = !this.state.isNC_commonTimings
      ? false
      : this.state.formValue.workshop_Endtime == ''
    // truthObj['workshop_duration'] = !this.state.isNC_commonTimings
    //   ? false
    //   : this.state.formValue.workshop_duration == ''
    truthObj['workshop_durationTxt'] = !this.state.isNC_commonTimings
      ? false
      : this.state.formValue.workshop_durationTxt == ''
    // truthObj['workshop_minutes'] = !this.state.isNC_commonTimings
    //   ? false
    //   : this.state.formValue.workshop_minutes == ''
    truthObj['work_long_desc'] = this.state.formValue.work_long_desc === ''
    truthObj['workshop_type'] = this.state.isPaid == ''
    truthObj['workshop_amount'] =
      this.state.isPaid == 'Paid'
        ? this.state.formValue.workshop_amount == ''
        : false
    truthObj['workshop_currency'] =
      this.state.isPaid == 'Paid'
        ? this.state.formValue.workshop_currency == ''
        : false
    truthObj['minimum_participants'] =
      this.state.formValue.minimum_participants === ''
    truthObj['maximum_participants'] =
      this.state.formValue.maximum_participants === ''
    truthObj['pdfDisplayName'] =
      this.state.pdf_url.split('.').reverse()[0] == 'pdf' &&
      this.state.pdf_name == ''
    setTimeout(() => this.setState({ truthObj: truthObj }), 100)
    setTimeout(() => this.checkErrorExistence(), 500)
  }

  checkErrorExistence = () => {
    let errors = _.values(this.state.truthObj)
    let includesErrors = _.includes(errors, true)
    if (includesErrors) {
      openNotification('error', 'Please Fill all mandatory fields correctly')
      this.setState({ isFormSubmitting: false })
    } else {
      this.saveDraftData()
      setTimeout(() => {
        if (this.state.workshop_draft_id) this.handleSubmit()
      }, 1000)
    }
  }

  render () {
    if (!(this.props.match.params.id === 'workshop')) {
      return <div>Invalid</div>
    }

    return (
      <Layout className=''>
        <Prompt
          when={this.state.shouldBlockNavigation}
          message='You have unsaved changes, are you sure you want to leave?'
        />
        <div className='bg-white'>
          <BreadcrumbView
            title={`${
              this.state.formValue.workshop_name
                ? this.state.formValue.workshop_name
                : `Untitled ${this.props.match.params.id}`
            }`}
            data={
              !this.state.stepsView
                ? `/Workshops/${(this.state.formValue.workshop_name
                    ? this.state.formValue.workshop_name
                    : `Untitled ${this.props.match.params.id}`
                  ).replace(/\//g, '~~')}`
                : `/Workshops/${(this.state.formValue.workshop_name
                    ? this.state.formValue.workshop_name
                    : `Untitled ${this.props.match.params.id}`
                  ).replace(/\//g, '~~')}`
            }
          >
            {this.state.stepsView && (
              <div>
                <ButtonC
                  category='Activities'
                  action='Cancel changes in steps'
                  size='large'
                  className='mr-3'
                  onClick={() =>
                    this.setState({
                      items: this.state.stepResult,
                      stepsView: false
                    })
                  }
                >
                  Cancel
                </ButtonC>
                <Button
                  size='large'
                  className='mr-3'
                  type='primary'
                  onClick={this.saveSteps}
                >
                  Save details
                </Button>
              </div>
            )}
            {!this.state.stepsView && (
              <div className='d-flex'>
                <div
                  className='d-flex justify-content-center align-items-center mr-4'
                  style={{ flexDirection: 'column' }}
                >
                  <span
                    className='font-14'
                    style={{
                      color: '#DA375B',
                      fontWeight: 800,
                      alignSelf: 'flex-end'
                    }}
                  >
                    Draft
                  </span>
                  {this.state.draft_status === 0 && (
                    <span
                      className='font-14'
                      style={{ color: '#9B9B9B', fontWeight: 800 }}
                    >
                      Unsaved Changes
                    </span>
                  )}
                  {this.state.draft_status === 1 && (
                    <span
                      className='font-14'
                      style={{ color: '#9B9B9B', fontWeight: 800 }}
                    >
                      Saving
                    </span>
                  )}
                  {this.state.draft_status === 2 && (
                    <span
                      className='font-14'
                      style={{ color: '#9B9B9B', fontWeight: 800 }}
                    >
                      Saved
                    </span>
                  )}
                </div>
                <ButtonC
                  category='Workshops'
                  action='Sending Workshop for Approval'
                  type='primary'
                  size='large'
                  onClick={this.validateStateValues}
                  className='btn-w250'
                  disabled={
                    this.state.formValue.workshop_name == '' ||
                    this.state.isFormSubmitting
                  }
                  loading={this.state.isFormSubmitting}
                >
                  Send for Approval
                </ButtonC>
              </div>
            )}
          </BreadcrumbView>
        </div>
        {this.state.is_loading && (
          <div className='vh-100 z-index-1 opacity-black-5 align-items-center position-fixed col-12 fixed-top d-flex justify-content-center'>
            <Icon type='loading' theme='outlined' className='loading' />
          </div>
        )}
        <Content className='activityPageScroll' style={{ padding: 32 }}>
          <div style={{ minHeight: 280 }} className='create-activity'>
            {!this.state.stepsView && (
              <Form layout='vertical' ref='get_form_values'>
                <div className='bg-white p-4 rounded'>
                  <InputC
                    title='WORKSHOP NAME'
                    id={
                      this.state.truthObj['workshop_name'] ||
                      this.state.truthObj['workshop_name_length']
                        ? 'has-error'
                        : ''
                    }
                    className='col-12 ant-input ant-input-lg mb-4'
                    titleStyle='mb-3 FontAvenirMedium text-uppercase text-secondary font-16 requiredAsterisk'
                    placeholder='Enter workshop name'
                    value={this.state.formValue.workshop_name}
                    stateKey='workshop_name'
                    onChange={this.onChange}
                    // onBlur={this.checkTitle}
                    validations={[required]}
                    autoFocus
                  />

                  <div className='mb-2' />

                  <div
                    className='mb-4 FontAvenirRoman text-secondary text-uppercase font-16'
                    id={
                      this.state.truthObj['thumb_image']
                        ? 'has-error-label'
                        : ''
                    }
                  >
                    Featured Images
                    <span className='requiredAsterisk'></span>
                    <div
                      className='d-inline addmorebutton font-12 ml-2 pl-2 pr-2 pointer-cursor rounded'
                      onClick={this.onClickAddFeaturedImages}
                    >
                      Add more
                    </div>
                  </div>
                  <div className='row mt-1'>
                    {this.state.thumb_image.map((data, index) => (
                      <div
                        className='col-4 featured-img-upload pl-2 pr-2 pt-3'
                        key={index}
                      >
                        <WorkshopImageUpload
                          onSuccess={data =>
                            this.FeaturedImageUploadSuccess(data, index)
                          }
                          imageUrl={data.image_url}
                          index={index}
                          aspect={16 / 9}
                          width={378}
                          height={212}
                          imageClassName='position-relative d-flex align-items-center justify-content-center'
                          imageStyle={{ height: 212, width: 378 }}
                          onClick={this.onRemoveBtnClick}
                        />
                      </div>
                    ))}
                  </div>
                  <div className='pt-4 mb-2' />

                  <div
                    className='mb-4 FontAvenirRoman text-secondary font-16 requiredAsterisk'
                    id={
                      this.state.truthObj['selectedDays'] ||
                      this.state.truthObj['workshop_time'] ||
                      this.state.truthObj['workshop_Endtime'] ||
                      this.state.truthObj['workshop_iduration']
                        ? 'has-error-label'
                        : ''
                    }
                  >
                    DATE AND TIME
                  </div>

                  <WorkshopDateTime
                    selectedDays={this.state.selectedDays}
                    workshopShow_date={this.state.workshopShow_date}
                    setSelectedDays={day =>
                      this.setState({
                        day
                      })
                    }
                    setHandleDayClick={this.handleDayClick}
                    onChangeIndTimePick={this.onChangeIndTimePick}
                    onChangeIndEndTimePick={this.onChangeIndEndTimePick}
                    onChangeIndtimings={this.onChangeIndtimings}
                    onChangeIndDuration={this.onChangeIndDuration}
                    durationVal={this.state.formValue.durationVal}
                    onSelectChange={this.onSelectChange}
                    onChangeDatePick={this.onChangeDatePick}
                    workshop_date={this.state.workshop_date}
                    onChangeTimePick={this.onChangeTimePick}
                    onChangeEndTimePick={this.onChangeEndTimePick}
                    repeats_occuranceVal={
                      this.state.formValue.repeats_occurance
                    }
                    setonChangeAfter={repeats_occurance => {
                      let { formValue } = this.state
                      formValue['repeats_occurance'] = repeats_occurance
                      this.setState(
                        {
                          formValue
                        },
                        () => this.onDraftApiCall()
                      )
                    }}
                    endsOnVal={this.state.formValue.endsOn}
                    setEndsOn={(endsOn, repeats_occurance) => {
                      let { formValue } = this.state
                      formValue['endsOn'] = endsOn
                      formValue['repeats_occurance'] = repeats_occurance
                      this.setState(
                        {
                          formValue
                        },
                        () => this.onDraftApiCall()
                      )
                    }}
                    workshop_time={this.state.formValue.workshop_time}
                    workshop_Endtime={this.state.formValue.workshop_Endtime}
                    datetime_truthObj={this.state.truthObj}
                    // workshop_duration={this.state.formValue.workshop_duration}
                    // workshop_minutes={this.state.formValue.workshop_minutes}
                    onChange={this.onChange}
                    onChangeDuration={this.onChangeDuration}
                    IndselectedDays={this.state.indSelectedDays}
                    onChangeRepeatsType={this.onChangerepeatsType}
                    repeatsType={
                      !this.state.isConsecutive
                        ? 'dnr'
                        : this.state.formValue.repeatsType
                    }
                    isConsecutiveType={this.state.isConsecutive}
                    setIsConsecutiveType={isConsecutive =>
                      this.setState(
                        {
                          isConsecutive
                        },
                        () => {
                          this.onDraftApiCall()
                        }
                      )
                    }
                    isNC_commonTimings={this.state.isNC_commonTimings}
                    setIsNC_commonTimings={isNC_commonTimings =>
                      this.setState(
                        {
                          isNC_commonTimings
                        },
                        () => {
                          this.onDraftApiCall()
                        }
                      )
                    }
                    weekdays={this.state.weekdays}
                    onChangeDaySelect={weekdays =>
                      this.setState(
                        {
                          weekdays
                        },
                        () => {
                          this.onDraftApiCall()
                        }
                      )
                    }
                  />

                  <div className='pt-4 mb-2' />
                  <React.Fragment>
                    <Row>
                      <InputC
                        id={
                          this.state.truthObj['trailer_link'] ? 'has-error' : ''
                        }
                        title='TRAILER LINK'
                        className='col-12 ant-input ant-input-lg'
                        placeholder='Enter trailer link'
                        value={this.state.trailer_link}
                        titleStyle='mb-3 FontAvenirMedium text-uppercase text-secondary font-16 '
                        stateKey='trailer_link'
                        onChange={this.onChangeNotReq}
                        onBlur={() => this.onViewLinkBlur('trailer_link')}
                        validations={[youtubeUrl]}
                      />
                    </Row>
                    <div className='pt-4 mb-2' />
                    <div
                      className='mb-3 FontAvenirRoman text-secondary font-16 mt-4 requiredAsterisk'
                      id={
                        this.state.truthObj['work_long_desc']
                          ? 'has-error-label'
                          : ''
                      }
                    >
                      WORKSHOP DESCRIPTION
                    </div>
                    <TextEditor
                      value={this.state.formValue.work_long_desc}
                      data={this.state.longDescValue}
                      onEditorChange={e =>
                        this.onChangeDescEditor('longDescValue', e)
                      }
                      onChange={value => {
                        this.onChange('work_long_desc', value)
                      }}
                    />

                    <div className='pt-4 mb-2' />

                    <div
                      className='mb-3 FontAvenirRoman text-secondary font-16 requiredAsterisk'
                      id={
                        this.state.truthObj['getselectint']
                          ? 'has-error-label'
                          : ''
                      }
                    >
                      AREAS OF INTEREST
                    </div>

                    <Select
                      size='large'
                      placeholder='Select Interest Category'
                      value={
                        this.state.workshop_Int_cat
                          ? this.state.workshop_Int_cat
                          : undefined
                      }
                      onChange={
                        this.onSelectInterestCat
                        // this.getInterest(this.state.formValue.act_int_cat_val)
                      }
                      className={
                        this.state.truthObj['work_int_cat_val']
                          ? 'has-error'
                          : ''
                      }
                      mode='tags'
                      // onSearch={value => {
                      //   this.onSearchInterestCat(value)
                      // }}
                      filterOption={false}
                      tokenSeparators={[',']}
                    >
                      <Select.OptGroup label='Preferred Interest Categories'>
                        {this.state.act_int_cat
                          .filter(data => data.is_added)
                          .map((data, i) => (
                            <Option
                              size='large'
                              key={`Pref-${i}`}
                              value={data.name}
                              id={`Pref-${i}`}
                            >
                              {data.name}
                            </Option>
                          ))}
                      </Select.OptGroup>
                      <Select.OptGroup label='Other Interest Categories'>
                        {this.state.act_int_cat
                          .filter(data => !data.is_added)
                          .map((data, i) => (
                            <Option
                              size='large'
                              key={`Other-${i}`}
                              value={data.name}
                              id={`Other-${i}`}
                            >
                              {data.name}
                            </Option>
                          ))}
                      </Select.OptGroup>
                    </Select>

                    {/* <div
                      className='p-3 mt-3 rounded border height-80'
                      id={
                        this.state.truthObj['getselectint']
                          ? 'has-error-label'
                          : ''
                      }
                    >
                      {this.state.getselectint.map((tag, index) => {
                        if (!tag.is_active) {
                          return (
                            <Tag
                              color='blue'
                              className='mb-2'
                              onClick={checked =>
                                this.TagClick(tag, checked, index, 'interest')
                              }
                              key={index}
                            >
                              {tag.name}
                            </Tag>
                          )
                        }
                      })}
                    </div> */}

                    {/* <div className='row ml-0 mr-0 mt-2'>
                      {this.state.getselectint.length > 0 && (
                        <div
                          className='col-12 border rounded p-2 height-40'
                          id={
                            this.state.truthObj['getselectint']
                              ? 'has-error-label'
                              : ''
                          }
                        >
                          {this.state.getselectint.map((tag, index) => {
                            if (tag.is_active) {
                              return (
                                <Tag
                                  closable
                                  onClose={() =>
                                    this.TagClick(
                                      tag,
                                      'checked',
                                      index,
                                      'interest'
                                    )
                                  }
                                  color='blue'
                                  className='mb-2'
                                  key={index}
                                >
                                  {tag.name}
                                </Tag>
                              )
                            }
                          })}
                        </div>
                      )}
                    </div> */}

                    <div className='pt-4' />
                  </React.Fragment>
                </div>
                <div>
                  <Card bordered={false}>
                    <Row>
                      <div
                        className='mb-3 text-uppercase FontAvenirRoman text-secondary font-16 requiredAsterisk'
                        id={
                          this.state.truthObj['ageData']
                            ? 'has-error-label'
                            : ''
                        }
                      >
                        {`AGE GROUP`}
                      </div>
                      <div
                        className={
                          this.props.match.params.id === 'workshop'
                            ? 'd-flex align-items-center justify-content-between'
                            : ''
                        }
                      >
                        {this.state.workshop_age_group.map((age_data, i) => {
                          return (
                            <div
                              className={`age FontAvenirRoman mr-5`}
                              // ${
                              //   i !== this.state.workshop_age_group.length - 1
                              //     ? ""
                              //     : "mr-5"
                              // }
                              // `}
                              key={i}
                            >
                              <Checkbox
                                key={i}
                                checked={!age_data.is_active}
                                value={age_data.id}
                                onChange={e => this.setAgeGroupActive(i, e)}
                              >
                                {age_data.age}
                              </Checkbox>
                            </div>
                          )
                        })}
                      </div>
                    </Row>
                    <div className='pt-4 mb-2' />

                    <React.Fragment>
                      <div className='pt-4 mb-2' />

                      <div
                        className='mb-3 FontAvenirRoman text-secondary font-16 mb-4 requiredAsterisk'
                        id={
                          this.state.truthObj['workshop_type']
                            ? 'has-error-label'
                            : ''
                        }
                      >
                        FEE
                      </div>
                      <Row>
                        <Col span={6}>
                          <Radio.Group
                            className='p-2'
                            onChange={e =>
                              this.onChangeFee('isPaid', e.target.value)
                            }
                            value={this.state.isPaid}
                          >
                            <Radio value={'free'}>Free</Radio>
                            <Radio value={'Paid'}>Paid</Radio>
                          </Radio.Group>
                        </Col>
                        <Col span={12}>
                          {this.state.isPaid === 'Paid' && (
                            <div>
                              <Col span={6}>
                                <Select
                                  value={
                                    this.state.formValue.workshop_currency
                                      ? this.state.formValue.workshop_currency
                                      : undefined
                                  }
                                  onChange={e => {
                                    this.onSelectChange('workshop_currency', e)
                                  }}
                                  size='large'
                                  style={{ width: 120 }}
                                  disabled
                                >
                                  {this.state.currency_arr.map((cur, i) => (
                                    <Option
                                      key={`CUR-${i}`}
                                      id={`CURID-${i}`}
                                      value={cur}
                                      size='large'
                                    >
                                      {cur}
                                    </Option>
                                  ))}
                                </Select>
                              </Col>
                              <Col span={6}>
                                <Input
                                  size='large'
                                  type='text'
                                  className='timeInput'
                                  id={
                                    this.state.truthObj['workshop_amount']
                                      ? 'has-error'
                                      : ''
                                  }
                                  placeholder='Enter fee'
                                  value={this.state.formValue.workshop_amount}
                                  onChange={e =>
                                    this.onChangeFeeAmount(
                                      'workshop_amount',
                                      e.target.value
                                    )
                                  }
                                  step='any'
                                />
                              </Col>
                            </div>
                          )}
                        </Col>
                      </Row>
                    </React.Fragment>

                    <div className='pt-4 mb-2' />

                    <div className='FontAvenirRoman text-secondary font-16 mb-4'>
                      YOU SHOULD ALREADY KNOW
                    </div>
                    <TextEditor
                      value={this.state.you_knowVal}
                      data={this.state.you_know}
                      onEditorChange={e => {
                        this.onChangeDescEditor('you_know', e)
                      }}
                      onChange={value => {
                        this.onChangeNotReq('you_knowVal', value)
                      }}
                    />

                    <div className='pt-4 mb-2' />

                    <div
                      className='mb-3 FontAvenirRoman text-secondary font-16 mt-4'
                      id={
                        this.state.truthObj['materials_req']
                          ? 'has-error-label'
                          : ''
                      }
                    >
                      YOU WOULD NEED
                      <div
                        className='d-inline addmorebutton font-12 ml-2 pl-2 pr-2 pointer-cursor rounded'
                        onClick={this.onClickMaterialRequired}
                      >
                        Add more
                      </div>
                    </div>
                    <div className='row'>
                      {this.state.material_required.map((data, index) => (
                        <div className='col-6 mb-3' key={index}>
                          <div className='d-flex justify-content-start align-items-center'>
                            <WorkshopImageUpload
                              width={95}
                              height={95}
                              imageUrl={data.image}
                              imageClassName='position-relative d-flex align-items-center justify-content-center'
                              onSuccess={value =>
                                this.materialRequiredOnchange(
                                  'image',
                                  index,
                                  value
                                )
                              }
                              aspect={1}
                            />
                            <div
                              className='ml-3 d-flex flex-column align-items-end justify-content-start'
                              style={{ width: '80%' }}
                            >
                              <div className='w-100 d-flex align-items-center justify-content-center'>
                                <Input
                                  size='large'
                                  value={data.name}
                                  className='mb-2'
                                  stateKey='name'
                                  onChange={e => {
                                    if (e.target.value.length <= 50) {
                                      this.materialRequiredOnchange(
                                        'name',
                                        index,
                                        e.target.value
                                      )
                                    }
                                  }}
                                  onPaste={e => {
                                    if (
                                      e.clipboardData.getData('Text').length >
                                      50
                                    ) {
                                      this.materialRequiredOnchange(
                                        'name',
                                        index,
                                        e.clipboardData
                                          .getData('Text')
                                          .substring(0, 50)
                                      )
                                    }
                                  }}
                                  placeholder='Enter material'
                                />
                                {this.state.material_required.length > 2 && (
                                  <Button
                                    className='d-inline addmorebutton font-12 ml-2 pl-2 pr-2 pointer-cursor rounded'
                                    onClick={() =>
                                      this.onRemoveMaterialRequired(index)
                                    }
                                    size='large'
                                    style={{ marginBottom: '9px' }}
                                  >
                                    Remove
                                  </Button>
                                )}
                              </div>
                              <span
                                style={
                                  data.name.length >= 50
                                    ? { color: '#FF0A00' }
                                    : {}
                                }
                              >
                                {data.name.length} / 50
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>

                    <div className='pt-4 mb-2' />

                    <div className='FontAvenirRoman text-secondary font-16 mb-4'>
                      LEARNING OUTCOME
                    </div>
                    <TextEditor
                      value={this.state.learn_outcomeVal}
                      data={this.state.learn_outcome}
                      onEditorChange={e =>
                        this.onChangeDescEditor('learn_outcome', e)
                      }
                      onChange={value => {
                        this.onChangeNotReq('learn_outcomeVal', value)
                      }}
                    />

                    <div className='pt-4 mb-2' />

                    <div className='row'>
                      <div className='col-12'>
                        <div className='d-flex justify-content-center align-items-center'>
                          <div className='mr-5 w-100'>
                            <div className='FontAvenirRoman text-secondary font-16 mt-4 mb-2 requiredAsterisk'>
                              MINIMUM PARTICIPANTS
                            </div>
                            <Input
                              className='col-12 ant-input ant-input-lg mb-4'
                              value={this.state.formValue.minimum_participants}
                              stateKey='minPart_name'
                              name='minimum_participants'
                              onChange={e =>
                                this.onChangeMinpart(
                                  'minimum_participants',
                                  e.target.value
                                )
                              }
                              onBlur={e => {
                                this.onChangeMinpart(
                                  'minimum_participants',
                                  e.target.value,
                                  true
                                )
                              }}
                              type='number'
                              id={
                                this.state.truthObj['minimum_participants']
                                  ? 'has-error'
                                  : ''
                              }
                              placeholder='Enter Minimum Participants'
                              onKeyPress={evt => {
                                if (evt.which < 48 || evt.which > 57) {
                                  evt.preventDefault()
                                }
                              }}
                            />
                          </div>
                          <div className='mr-5 w-100'>
                            <div className='FontAvenirRoman text-secondary font-16 mb-2 requiredAsterisk'>
                              MAXIMUM PARTICIPANTS
                            </div>
                            <Input
                              className='col-12 ant-input ant-input-lg'
                              value={this.state.formValue.maximum_participants}
                              stateKey='maxPart_name'
                              name='maximum_participants'
                              type='text'
                              id={
                                this.state.truthObj['maximum_participants']
                                  ? 'has-error'
                                  : ''
                              }
                              onChange={e => {
                                this.onChangeMaxpart(
                                  'maximum_participants',
                                  e.target.value
                                )
                              }}
                              onBlur={e => {
                                this.onChangeMaxpart(
                                  'maximum_participants',
                                  e.target.value,
                                  true
                                )
                              }}
                              disabled={
                                this.state.formValue.minimum_participants == ''
                              }
                              placeholder='Enter Maximum Participants'
                              disabled={
                                this.state.formValue.minimum_participants == ''
                              }
                              onKeyPress={evt => {
                                if (evt.which < 48 || evt.which > 57) {
                                  evt.preventDefault()
                                }
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='pt-4 mb-2' />

                    <div
                      className='mb-3 FontAvenirRoman text-secondary font-16 mt-4'
                      // id={this.state.truthObj["pdf"] ? "has-error-label" : ""}
                    >
                      REFERENCE TO SHARE WITH PARTICIPANTS POST WORKSHOP
                    </div>
                    <div className='row'>
                      <div
                        className={`col-${
                          this.state.imgVisible ? '6' : '12'
                        } pdf-upload`}
                      >
                        <WorkshopPdfUpload
                          multiple={false}
                          handleChange={this.onPDFUpload}
                          fileList={this.state.pdfFileList}
                          onSuccess={this.onPDFUploadSuccess}
                          previewImage={this.state.pdf_url}
                          isPdf={this.state.displayNameVisible}
                        />
                        {this.state.pdfFileList.length === 1 ? (
                          <div
                            onClick={this.deleteUploadPDF}
                            className='delete-img'
                          >
                            <img
                              src={deleteImage}
                              className='img-fluid'
                              style={{ height: 15, width: 15 }}
                              alt='delete pdf'
                            />
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className='mt-4' />

                    {this.state.displayNameVisible && (
                      <div className='row'>
                        <div className='col-12'>
                          <div className='d-flex justify-content-center align-items-center'>
                            <div className='w-100'>
                              <div className='FontAvenirRoman text-secondary font-16 mt-2 mb-2'>
                                DISPLAY NAME
                              </div>
                              <Input
                                className='col-12 ant-input ant-input-lg mb-4'
                                value={this.state.pdf_name}
                                stateKey='pdf_name'
                                name='pdf_name'
                                onChange={e =>
                                  this.setState({
                                    pdf_name: e.target.value
                                  })
                                }
                                type='text'
                                id={
                                  this.state.truthObj['pdfDisplayName']
                                    ? 'has-error'
                                    : ''
                                }
                                placeholder='Enter name of PDF'
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className='pt-2 mb-2' />
                    <div className='mb-2 FontAvenirRoman text-secondary font-16 mt-2'>
                      TAGS
                    </div>
                    <Select
                      mode='tags'
                      size='large'
                      placeholder='Select Tags'
                      // className={this.state.truthObj["tags"] ? "has-error" : ""}
                      value={this.state.tags ? this.state.tags : undefined}
                      style={{ width: '100%' }}
                      onChange={this.onSelectTags}
                      onSearch={value => {
                        this.onSearchTags(value)
                      }}
                      filterOption={false}
                      // filterOption={this.onSearchTags}
                      tokenSeparators={[',']}
                    >
                      {this.state.m_workshop_tags.map((data, index) => (
                        <Option
                          key={data.name}
                          value={data.name}
                          id={`Tags-${index}`}
                        >{` ${data.name} `}</Option>
                      ))}
                    </Select>
                    <div className='pt-4 mb-4' />
                  </Card>
                </div>
              </Form>
            )}
          </div>
        </Content>
      </Layout>
    )
  }
}

export default WorkshopCreate
