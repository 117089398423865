import { Badge, Button, Dropdown, Icon, Menu, notification, Tag, Modal, Drawer } from 'antd'
import axios from 'axios'
import _ from 'lodash'
import Moment from 'moment'
import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import TimeAgo from 'react-timeago'
import { version } from '../../../package.json'
import { CLEAR_NOTIFICATION, GET_NOTIFICATIONS_FOR_ADMIN } from '../../graphql'
import { GET_CART } from '../../pages/workshop/graphql'
import ExpertLogo from '../../static/nav/breadcrumb_expert_logo.png'
import ParentLogo from '../../static/nav/breadcrumb_parent_logo.png'
import ParentSelfLogo from '../../static/nav/parentBC_self_logo.svg'
import LcLogo from '../../static/nav/breadcrumb_lc_logo.svg'
import Logo from '../../static/nav/breadcrumb_logo.png'
import LcNotification from '../../static/nav/lc_notofication_icon.svg'
import MaNotification from '../../static/nav/ma_notification_icon.svg'
import ParentNotification from '../../static/nav/parent_notification_icon.svg'
import ParentNotification1 from '../../static/nav/parent_notification_icon1.svg'
import ExpertNotification from '../../static/nav/expert_notification_icon.svg'
import ParentWorkshopCart from '../../static/workshop/workshop_cart.svg'
import MblNavMenu from '../../static/nav//mbl_menu.svg'
import LcProfile from '../../static/nav/lc_profile_icon.svg'
import MaProfile from '../../static/nav/ma_profile_icon.svg'
import ParentProfile from '../../static/nav/parent_profile_icon.svg'
import ExpertProfile from '../../static/nav/expert_profile_icon.svg'
import noNotifications from '../../static/nav/noNotifications.png'
import './nav.css'
import styled, { css, keyframes } from 'styled-components';


console.log(version)
const SubMenu = Menu.SubMenu

const MblNavBar = styled.div`
    @media (min-width: 576px) {
      display: none;
    }
    background: #5432BA;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
export default class Nav extends Component {
  constructor (props) {
    super(props)
    this.state = {
      lc_requests: [],
      workshop_content: [],
      count: 0,
      interval: '',
      clearNotification: true,
      cart_details: [],
      cart_count: 0,
      visible: false,
      placement: 'right'
    }
    this.openNotification = _.debounce(this.openNotification, 5000)
  }

  onClose () {}

  getLocalStorage = () => {
    this.setState({
      role_id: Number(localStorage.getItem('role_id')),
      count: Number(localStorage.getItem('notification_count'))
    })
  }

  setLocalStorage = count => {
    localStorage.setItem('notification_count', count)
  }
  onClear = () => {
    const query = CLEAR_NOTIFICATION
    axios.post(`${process.env.REACT_APP_API}`, query).then(res => {
      console.log(res)
    })
  }

  showConfirmLogout = () => {
    const confirmDialog = Modal.confirm({
      title: "Are you sure you want to Sign Out?",
      okText: "Yes",
      onOk: () => {
        localStorage.clear();
        this.props.history.push("/");
      }
    });
    this.setState({
      visible: false
    }, () => {
      this.setState({ confirmDialog });
    });
  };

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  fetchData = () => {
    axios
      .post(`${process.env.REACT_APP_API}`, GET_NOTIFICATIONS_FOR_ADMIN)
      .then(res => {
        if (res.data.data.masterNotificationV2) {
          if (
            this.state.role_id === 1 &&
            res.data.data.masterNotificationV2.count > this.state.count
          ) {
            let numberOfNewRequests =
              res.data.data.masterNotificationV2.count - this.state.count
            this.setLocalStorage(res.data.data.masterNotificationV2.count)
            this.openNotification('info', numberOfNewRequests)
          }
          this.setState({
            lc_requests: (
              res.data.data.masterNotificationV2.notification_details || []
            ).filter(not => not.name === 'learning content')[0].notifications,
            workshop_content: (
              res.data.data.masterNotificationV2.notification_details || []
            ).filter(not => not.name == 'Workshop Notification')[0]
              .notifications,
            count: res.data.data.masterNotificationV2
              ? res.data.data.masterNotificationV2.count
              : 0,
            loading: false,
            clearNotification: true
          })
        }
      })
      .catch(err => console.log(err))
  }

  fetchWorkshopCartData = () => {
    let query = GET_CART
    axios.post(`${process.env.REACT_APP_API}`, query).then(res => {
      if (res.data.errors) {
      } else {
        if (this.state.role_id == 3 && res.data.data.getCart) {
          this.setState({
            cart_details: res.data.data.getCart.cart,
            cart_count: res.data.data.getCart.cart_count
          })
        }
      }
    })
  }

  openNotification = (type, message) => {
    if (type === 'info') {
      notification['info']({
        message: `Notification`,
        description: `${message} Requests needs your action`
      })
    } else {
      notification['error']({
        message: 'Something went wrong',
        description: `${message}`
      })
    }
  }

  callTheAPI = () => {
    if (this.state.role_id !== 3) {
      this.fetchData()
      let interval = setInterval(() => {
        this.fetchData()
      }, 10000)
      this.setState({ interval: interval })
    }
  }

  componentWillUnmount () {
    clearInterval(this.state.interval)
    this.setState({ interval: '' })
  }

  componentDidMount () {
    this.getLocalStorage()
    setTimeout(() => {
      this.callTheAPI()
      this.fetchWorkshopCartData()
    }, 1000)
  }

  onRouteChange = req => {
    if (req.notification_type === 'student_feedback') {
      if (this.state.role_id === 4 || this.state.role_id === 5) {
        this.props.history.push({
          pathname: `/contentfeedback/${req.notification_id}`,
          state: {
            id: req.id,
            notification_id: req.notification_id
          }
        })
      } else {
        this.props.history.push({
          pathname: `/learners/${req.library_default_id}/feedbacks`,
          state: {
            id: req.id,
            notification_id: req.notification_id
          }
        })
      }
    } else if (req.notification_type === 'data point') {
      window.location.href = `/data/${req.notification_id}`
    } else if (req.notification_type === 'interest') {
      window.location.href = `/interests/${req.notification_id}`
    } else {
      window.location.href = `${
        req.notification_type === 'Watch' || req.notification_type === 'Do'
          ? `/learningcontent/${req.notification_id}/view/draft`
          : req.library_default_id === 1
          ? `/library/book/draft/view/`
          : req.library_default_id === 2
          ? `/library/documentary/draft/view/`
          : `/library/music/draft/view/`
      }`
    }
  }

  render () {
    const createExpertMenu = (
      <Menu>
        <Menu.Item
          onClick={() =>
            this.props.history.replace(`/learningcontent/do/create`)
          }
          id='create-do-expert'
        >
          Do
        </Menu.Item>
        {/* <Menu.Item
          onClick={() =>
            this.props.history.replace(`/learningcontent/read/create`)
          }
          id="create-read-expert"
        >
          Read
        </Menu.Item> */}
        <Menu.Item
          onClick={() =>
            this.props.history.replace(`/learningcontent/watch/create`)
          }
          id='create-watch-expert'
        >
          Watch
        </Menu.Item>
        {/* <Menu.Item
          onClick={() =>
            this.props.history.replace(`/learningcontent/listen/create`)
          }
          id="create-listen-expert"
        >
          Listen
        </Menu.Item> */}
        <Menu.Item
          onClick={() => {
            this.props.history.push(`/collection/create`, null)
          }}
          id='create-collection-expert'
        >
          Collection
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            this.props.history.replace(`/workshops/workshop/create`)
          }}
          id='create-workshop'
        >
          Workshop
        </Menu.Item>
      </Menu>
    )
    if (this.state.role_id === 4 || this.state.role_id === 5) {
      return (
        <div className='navbarContainer mx-auto row m-0 FontAvenirMedium position-relative expert'>
          {/* <div className="navStrip">
            <img src={LogoCard} alt="card" />
          </div> */}
          {process.env.REACT_APP_ENV !== 'production' && (
            <div className='VersionContainer'>{`v - ${version}`}</div>
          )}
          
          <Menu className='pr-2 w-100' mode='horizontal'>
            <Menu.Item
              key='logo'
              index='1'
              className=' pl-0'
              title={`BREADCRUMB`}
            >
              <img
                src={ExpertLogo}
                alt='breadcrumb logo'
                style={{ width: 230, marginBottom: -1 }}
              />
            </Menu.Item>
            {/* <Menu.Item
              key="dashboard"
              index="2"
              className="pl-0 pt-2 pb-2 text-uppercase"
              title="Dashboard"
            >
              <NavLink activeClassName="navSelected" to="/dashboard">
                Dashboard
              </NavLink>
            </Menu.Item> */}
            <Menu.Item
              key='learningcontent'
              index='11'
              className='pl-0 pt-2 pb-2 text-uppercase'
              title='learningcontent'
            >
              <NavLink activeClassName='navSelected' to='/learningcontent'>
                Learning Content
              </NavLink>
            </Menu.Item>
            <Menu.Item
              key='interests'
              index='14'
              className='pl-4 pt-2 pb-2 text-uppercase'
              title='interests'
            >
              <NavLink activeClassName='navSelected' to='/interests'>
                Interests
              </NavLink>
            </Menu.Item>
            {this.state.role_id === 4 && (
              <Menu.Item
                key='feedbacks'
                index='15'
                className='pl-4 pt-2 pb-2 text-uppercase'
                title='Feedbacks'
              >
                <NavLink activeClassName='navSelected' to='/feedbacks'>
                  Feedback
                </NavLink>
              </Menu.Item>
            )}
            <Menu.Item
              index='10'
              key='workshops'
              className='mr-2 text-uppercase'
              title='Workshops'
            >
              <NavLink activeClassName='navSelected' to='/workshops'>
                Workshops
              </NavLink>
            </Menu.Item>
            <Menu.Item
              index='10'
              key='Quick Create'
              className='text-uppercase pt-2 pb-2'
              title='Quick Create Cards'
            >
              <Dropdown overlay={createExpertMenu}>
                <Button>
                  Create New
                  <Icon type='down' style={{ verticalAlign: 'middle' }} />
                </Button>
              </Dropdown>
            </Menu.Item>
            <Menu.ItemGroup
              style={{
                float: 'right',
                display: 'flex',
                flexWrap: 'wrap',
                flexDirection: 'row'
              }}
            >
              {(this.state.role_id === 4 || this.state.role_id === 5) && (
                <SubMenu
                  isOpen={true}
                  className='pt-2 pb-2'
                  title={
                    <Badge dot={this.state.count !== 0}>
                      <img
                        src={ExpertNotification}
                        style={{ height: 25, width: 25 }}
                        alt='notifications'
                      />
                    </Badge>
                  }
                  // onMouseOver={() => {
                  //   console.log("Chckeding");
                  // }}
                  onMouseLeave={() => {
                    this.onClear()
                    // console.log("Mouse leave");
                  }}
                >
                  {this.state.lc_requests.length === 0 && (
                    <Menu.Item key='1' className='submenu-popup-scrollarea '>
                      <div className='no-lc-notifications'>
                        <img src={noNotifications} alt='no notifications' />
                        <div className='no-notifications-text'>
                          <div>You’re all caught up!</div>
                          <div>Check back later for notifications</div>
                        </div>
                      </div>
                    </Menu.Item>
                  )}
                  {/* {this.state.lc_requests.length !== 0 && (
                      <Menu.Item
                        className="pt-1 pb-1 mt-0 clearFilter"
                        onClick={this.onClear}
                      >
                        <div className=" text-center   w-100">Clear All</div>
                      </Menu.Item>
                    )} */}
                  {this.state.lc_requests.length !== 0 && (
                    <div className='ant-menu-item submenu-popup-scrollarea notification-popup'>
                      {/* {this.state.clearNotification && (
                          <React.Fragment>
                            {this.setState({ clearNotification: false }, () => {
                              this.onClear();
                            })}
                          </React.Fragment>
                        )} */}
                      {this.state.lc_requests.map((req, index) => (
                        <Menu.Item key={index}>
                          <div
                            className='notification-lc-card'
                            onClick={() => this.onRouteChange(req)}
                          >
                            <div className='notification-content-name'>
                              {req.notification_name}
                            </div>
                            <div className='d-flex justify-content-between align-items-center w-100'>
                              {req.notification_type === 'Watch' && (
                                <Tag color='orange'>Watch</Tag>
                              )}
                              {req.notification_type === 'Do' && (
                                <Tag color='cyan'>Do</Tag>
                              )}
                              {req.notification_type === 'activity' && (
                                <Tag color='orange'>Activity</Tag>
                              )}
                              {req.notification_type === 'library' && (
                                <Tag color='cyan'>Library</Tag>
                              )}
                              {req.notification_type === 'student_feedback' && (
                                <Tag color='green'>Feedback</Tag>
                              )}
                              <div>
                                <TimeAgo
                                  date={Number(
                                    Moment(req.updatedAt).format('x')
                                  )}
                                />
                              </div>
                            </div>
                          </div>
                        </Menu.Item>
                      ))}
                    </div>
                  )}
                </SubMenu>
              )}
              <Menu.Item
                index='10'
                className='d-flex float-right pt-2 pb-2 mr-3 UserDropDown'
              >
                <NavLink activeClassName='navSelected' to='/profile'>
                  <img
                    src={ExpertProfile}
                    style={{ height: 25, width: 25 }}
                    alt='Profile'
                  />
                </NavLink>
              </Menu.Item>
            </Menu.ItemGroup>
          </Menu>
        </div>
      )
    } else if (this.state.role_id === 3) {
      return (
        <React.Fragment>
          <MblNavBar className='pr-2 w-100 mbl-parentMenu'>
            <div className="cursor-pointer">
            <NavLink
                  activeClassName='navSelected'
                  to='/parent/dashboard'
                >
              <img
                  src={ParentLogo}
                  alt='breadcrumb logo'
                  style={{ width: 200 }}
                />
            </NavLink>
            </div>
            <div className="d-flex">
              <div className="m-1 p-2">
                <NavLink
                    to={{ pathname: '/parent/workshops/cart' }}
                  >
                    <Badge
                      className='parentNotiCount'
                      count={this.state.cart_count}
                    >
                      <img
                        src={ParentWorkshopCart}
                        style={{ height: 25, width: 25 }}
                        alt='Parent Cart'
                      />
                    </Badge>
                </NavLink>
              </div>
              <div className="m-1 p-2 cursor-pointer" onClick={this.showDrawer}>
                <img
                  src={MblNavMenu}
                  style={{ height: 25, width: 25 }}
                  alt='Parent mbl menu ico'
                />
            </div>
            </div>
          </MblNavBar>
          <Drawer
          title={
            <div className="mbl-drawer">
              Menu
            </div>
          }
          placement={this.state.placement}
          closable={true}
          onClose={this.onClose}
          destroyOnClose={true}
          onClose={()=> {
            this.setState({
              visible: false
            })
          }}
          visible={this.state.visible}
          zIndex={4000}
        >
          <div className="d-flex flex-column justify-content-between h-100">
            <div className="d-flex flex-column">
              <div
                key='parent_dashboard'
                index='2'
                className='UserDropDown mbl-navText'
                title='Student Details'
              >
                <NavLink
                  activeClassName='navSelected'
                  to='/parent/dashboard'
                  onClick={()=> {
                    this.setState({
                      visible: false
                    })
                  }}
                >
                  Learner details
                </NavLink>
              </div>
              <div
                key='workshops'
                className='UserDropDown mbl-navText'
                title='Workshops'
              >
                <NavLink
                  activeClassName='navSelected'
                  to='/parent/workshops'
                >
                  Workshops
                </NavLink>
              </div>
              <div className='UserDropDown mbl-navText'>
                  <NavLink activeClassName='navSelected' to='/profile' onClick={()=> {
                    this.setState({
                      visible: false
                    })
                  }}>
                    Profile
                  </NavLink>
              </div>
              {/* <div className='UserDropDown mbl-navText'
                    activeClassName='navSelected'
                    onClick={() => {
                      window.location.href = `/parent/dashboard?isTour=1`
                    }}
                  >
                    User guide
              </div> */}
              <div className='UserDropDown mbl-navText' onClick={this.showConfirmLogout}>
                    Sign out
              </div>
            </div>
            <div className="">
              <img
                  src={ParentSelfLogo}
                  alt='breadcrumb parent self logo'
                  style={{ width: 200 }}
                />
            </div>
          </div>
        </Drawer>
        <div className='navbarContainer mx-auto row m-0 FontAvenirMedium position-relative parent'>
          {process.env.REACT_APP_ENV !== 'production' && (
            <div className='VersionContainer'>{`v - ${version}`}</div>
          )}
          <Menu className='pr-2 w-100 parentMenu' mode='horizontal'>
            <Menu.Item
              key='logo'
              index='1'
              className='pl-0'
              title={`BREADCRUMB`}
            >
              <img
                src={ParentLogo}
                alt='breadcrumb logo'
                style={{ width: 230, marginBottom: -1 }}
              />
            </Menu.Item>
            <Menu.Item
              key='parent_dashboard'
              index='2'
              className='pl-4 text-uppercase align-middle'
              title='Student Details'
            >
              <NavLink
                activeClassName='navSelected selectedLine'
                to='/parent/dashboard'
              >
                Learner Details
              </NavLink>
            </Menu.Item>
            <Menu.Item
              index='10'
              key='workshops'
              className='mr-2 text-uppercase'
              title='Workshops'
            >
              <NavLink
                activeClassName='navSelected selectedLine'
                to='/parent/workshops'
              >
                Workshops
              </NavLink>
            </Menu.Item>
            <Menu.ItemGroup
              style={{
                float: 'right',
                display: 'flex',
                flexWrap: 'wrap',
                flexDirection: 'row',
                paddingTop: 6
              }}
            >
              <Menu.Item index='10' className='UserDropDown'>
                <div
                  activeClassName='navSelected'
                  onClick={() => {
                    window.location.href = `/parent/dashboard?isTour=1`
                  }}
                >
                  <img
                    src={ParentNotification}
                    style={{ height: 25, width: 25 }}
                    alt='Question'
                  />
                </div>
              </Menu.Item>
              <Menu.Item index='10' className='UserDropDown'>
                <NavLink
                  activeClassName='navSelected'
                  to={{ pathname: '/parent/workshops/cart' }}
                >
                  <Badge
                    className='parentNotiCount'
                    count={this.state.cart_count}
                  >
                    <img
                      src={ParentWorkshopCart}
                      style={{ height: 25, width: 25 }}
                      alt='Parent Cart'
                    />
                  </Badge>
                </NavLink>
              </Menu.Item>

              {/* <SubMenu
                  isOpen={true}
                  className="pb-2"
                  title={
                    <Badge dot={this.state.count !== 0 }>
                      <img
                        src={ParentNotification1}
                        style={{ height: 25, width: 25 }}
                        alt="notifications"
                      />
                    </Badge>
                  }
                  // onMouseOver={() => {
                  //   console.log("Chckeding");
                  // }}
                  onMouseLeave={() => {
                    this.onClear();
                    // console.log("Mouse leave");
                  }}
                >
                  {this.state.lc_requests.length === 0 && (
                    <Menu.Item key="1" className="submenu-popup-scrollarea ">
                      <div className="no-lc-notifications">
                        <img src={noNotifications} alt="no notifications" />
                        <div className="no-notifications-text">
                          <div>You’re all caught up!</div>
                          <div>Check back later for notifications</div>
                        </div>
                      </div>
                    </Menu.Item>
                  )}
                </SubMenu> */}
              <Menu.Item index='12' className='UserDropDown'>
                <NavLink activeClassName='navSelected' to='/profile'>
                  <img
                    src={ParentProfile}
                    style={{ height: 25, width: 25 }}
                    alt='Profile'
                  />
                </NavLink>
              </Menu.Item>
            </Menu.ItemGroup>
          </Menu>
        </div>
      </React.Fragment>
      )
    } else {
      return (
        <div>
          <div
            className={`${
              this.state.role_id === 2 ? 'lc' : 'ma'
            } navbarContainer mx-auto row m-0 FontAvenirMedium ant-layout`}
          >
            {version && (
              <React.Fragment>
                {process.env.REACT_APP_ENV !== 'production' && (
                  <div className='VersionContainer'>{`v - ${version}`}</div>
                )}
              </React.Fragment>
            )}
            <Menu
              className={`${this.state.role_id === 1 ? 'p-2' : 'pr-2'} w-100`}
              mode='horizontal'
            >
              <Menu.Item
                key='logo'
                index='1'
                className=' pl-0'
                title={`BREADCRUMB`}
              >
                <NavLink to='/dashboard'>
                  {this.state.role_id === 1 ? (
                    <img
                      src={Logo}
                      alt='breadcrumb logo'
                      style={{ width: 152, height: 25 }}
                    />
                  ) : (
                    <img
                      src={LcLogo}
                      alt='breadcrumb logo'
                      style={{ width: 230, marginBottom: -1 }}
                    />
                  )}
                </NavLink>
              </Menu.Item>
              {this.state.role_id === 2 && (
                <Menu.Item
                  key='dashboard'
                  index='2'
                  className='pl-4 pt-1 text-uppercase'
                  title='Dashboard'
                >
                  <NavLink activeClassName='navSelected' to='/dashboard'>
                    Dashboard
                  </NavLink>
                </Menu.Item>
              )}
              <Menu.Item
                key='learningcontent'
                index='13'
                className='pt-1 text-uppercase'
                title='learningcontent'
              >
                <NavLink activeClassName='navSelected' to='/learningcontent'>
                  Learning Content
                </NavLink>
              </Menu.Item>
              {this.state.role_id === 1 && (
                <Menu.Item
                  index='4'
                  key='data'
                  className='mr-2 pt-1 text-uppercase'
                  title='data'
                >
                  <NavLink activeClassName='navSelected' to='/data'>
                    Data
                  </NavLink>
                </Menu.Item>
              )}
              <Menu.Item
                index='5'
                key='interest'
                className='mr-2 pt-1 text-uppercase'
                title='interest'
              >
                <NavLink activeClassName='navSelected' to='/interests'>
                  Interests
                </NavLink>
              </Menu.Item>
              <Menu.Item
                index='6'
                key='profile'
                className='mr-2 pt-1 text-uppercase'
                title='Student profiles'
              >
                <NavLink activeClassName='navSelected' to='/learners'>
                  Learners
                </NavLink>
              </Menu.Item>
              {this.state.role_id === 1 && (
                <Menu.Item
                  index='7'
                  key='parent'
                  className='mr-2 pt-1 text-uppercase'
                  title='Parent profiles'
                >
                  <NavLink activeClassName='navSelected' to='/parents'>
                    Parents
                  </NavLink>
                </Menu.Item>
              )}
              {this.state.role_id === 1 && (
                <Menu.Item
                  index='8'
                  key='dashboard'
                  className='mr-2 pt-1 text-uppercase'
                  title='Dashboard'
                >
                  <NavLink activeClassName='navSelected' to='/dashboard'>
                    Dashboard
                  </NavLink>
                </Menu.Item>
              )}
              {this.state.role_id === 1 && (
                <Menu.Item
                  index='9'
                  key='reports'
                  className='mr-2 pt-1 text-uppercase'
                  title='Reports'
                >
                  <NavLink activeClassName='navSelected' to='/reports'>
                    Reports
                  </NavLink>
                </Menu.Item>
              )}
              {this.state.role_id === 1 && (
                <Menu.Item
                  index='10'
                  key='workshops'
                  className='mr-2 pt-1 text-uppercase'
                  title='Workshops'
                >
                  <NavLink activeClassName='navSelected' to='/workshops'>
                    Workshops
                  </NavLink>
                </Menu.Item>
              )}
              {this.state.role_id === 2 && (
                <Menu.Item
                  index='10'
                  key='Content Creators'
                  className='pt-1 text-uppercase'
                  title='Content Creators'
                >
                  <NavLink activeClassName='navSelected' to='/contentcreators'>
                    Content Creators
                  </NavLink>
                </Menu.Item>
              )}
              {this.state.role_id === 2 && (
                <Menu.Item
                  index='10'
                  key='workshops'
                  className='mr-2 pt-1 text-uppercase'
                  title='Workshops'
                >
                  <NavLink activeClassName='navSelected' to='/workshops'>
                    Workshops
                  </NavLink>
                </Menu.Item>
              )}
              {this.state.role_id === 2 && (
                <Menu.Item
                  index='11'
                  key='Quick Create'
                  className='text-uppercase'
                  title='Quick Create Cards'
                >
                  <Dropdown overlay={createExpertMenu}>
                    <Button>
                      Create New{' '}
                      <Icon type='down' style={{ verticalAlign: 'middle' }} />
                    </Button>
                  </Dropdown>
                </Menu.Item>
              )}

              <Menu.ItemGroup
                style={{
                  float: 'right',
                  display: 'flex',
                  flexWrap: 'wrap',
                  flexDirection: 'row',
                  paddingTop: 8
                }}
              >
                {this.state.role_id === 1 && (
                  <Menu.Item index='12' className='UserDropDown'>
                    <NavLink
                      activeClassName='navSelected'
                      to={{ pathname: '/notifications' }}
                    >
                      <Badge count={this.state.count}>
                        <img
                          src={MaNotification}
                          style={{ height: 25, width: 25 }}
                          alt='notifications'
                        />
                      </Badge>
                    </NavLink>
                  </Menu.Item>
                )}
                {this.state.role_id === 2 && (
                  <SubMenu
                    {...this.props}
                    isOpen={true}
                    title={
                      <Badge dot={this.state.count !== 0}>
                        <img
                          src={LcNotification}
                          style={{ height: 25, width: 25 }}
                          alt='notifications'
                        />
                      </Badge>
                    }
                    // onMouseOver={() => {
                    //   console.log("Chckeding");
                    // }}
                    onMouseLeave={() => {
                      this.onClear()
                      // console.log("Mouse leave");
                    }}
                  >
                    {(this.state.lc_requests.length &&
                      this.state.workshop_content.length) === 0 && (
                      <Menu.Item key='1' className='submenu-popup-scrollarea '>
                        <div className='no-lc-notifications'>
                          <img src={noNotifications} alt='no notifications' />
                          <div className='no-notifications-text'>
                            <div>You’re all caught up!</div>
                            <div>Check back later for notifications</div>
                          </div>
                        </div>
                      </Menu.Item>
                    )}
                    {/* {this.state.lc_requests.length !== 0 && (
                      <Menu.Item
                        className="pt-1 pb-1 mt-0 clearFilter"
                        onClick={this.onClear}
                      >
                        <div className=" text-center   w-100">Clear All</div>
                      </Menu.Item>
                    )} */}
                    {(this.state.workshop_content || []).length !== 0 && (
                      <div className='ant-menu-item submenu-popup-scrollarea notification-popup'>
                        {this.state.workshop_content.map((req, index) => (
                          <Menu.Item key={index}>
                            <div className='d-flex flex-column justify-content-start align-items-start'>
                              <div>
                                {req.workshop_details &&
                                  req.workshop_details.status == 'Approved' && (
                                    <span>
                                      <strong style={{ fontWeight: 800 }}>
                                        {req.workshop_details.workshop_name}
                                      </strong>{' '}
                                      workshop has been approved.
                                    </span>
                                  )}
                              </div>
                              <div>
                                {req.workshop_details &&
                                  ['Rejected', 'Pending'].includes(
                                    req.workshop_details.status
                                  ) && (
                                    <div>
                                      <span>
                                        <strong style={{ fontWeight: 800 }}>
                                          {req.workshop_details.workshop_name}
                                        </strong>{' '}
                                        workshop requires changes as per the
                                        comment.
                                      </span>
                                      <div
                                        style={{
                                          backgroundColor: '#F2F2F2',
                                          borderRadius: 5,
                                          padding: 5,
                                          color: '#000'
                                        }}
                                      >
                                        {req.comments
                                          ? req.comments
                                          : ``}
                                      </div>
                                    </div>
                                  )}
                              </div>
                              <div>
                                <TimeAgo
                                  date={Number(
                                    Moment(req.updatedAt).format('x')
                                  )}
                                />
                              </div>
                              <div className="border-bottom"></div>
                            </div>
                          </Menu.Item>
                        ))}
                      </div>
                    )}
                    {this.state.lc_requests.length !== 0 && (
                      <div className='ant-menu-item submenu-popup-scrollarea notification-popup'>
                        {/* {this.state.clearNotification && (
                          <React.Fragment>
                            {this.setState({ clearNotification: false }, () => {
                              this.onClear();
                            })}
                          </React.Fragment>
                        )} */}
                        {this.state.lc_requests.map((req, index) => (
                          <Menu.Item key={index}>
                            <div
                              className='notification-lc-card'
                              onClick={() => this.onRouteChange(req)}
                            >
                              <div className='notification-content-name'>
                                {req.notification_name}
                              </div>
                              <div className='d-flex justify-content-between align-items-center w-100'>
                                {req.notification_type === 'Watch' && (
                                  <Tag color='orange'>Watch</Tag>
                                )}
                                {req.notification_type === 'Do' && (
                                  <Tag color='cyan'>Do</Tag>
                                )}
                                {req.notification_type === 'activity' && (
                                  <Tag color='orange'>Activity</Tag>
                                )}
                                {req.notification_type === 'library' && (
                                  <Tag color='cyan'>Library</Tag>
                                )}
                                {req.notification_type ===
                                  'student_feedback' && (
                                  <Tag color='green'>Feedback</Tag>
                                )}
                                <div>
                                  <TimeAgo
                                    date={Number(
                                      Moment(req.updatedAt).format('x')
                                    )}
                                  />
                                </div>
                              </div>
                            </div>
                          </Menu.Item>
                        ))}
                      </div>
                    )}
                  </SubMenu>
                )}

                <Menu.Item
                  index='14'
                  className='UserDropDown'
                  style={{ marginRight: '10px' }}
                >
                  <NavLink activeClassName='navSelected' to='/profile'>
                    <img
                      src={this.state.role_id === 1 ? MaProfile : LcProfile}
                      style={{ height: 25, width: 25 }}
                      alt='Profile'
                    />
                  </NavLink>
                </Menu.Item>
              </Menu.ItemGroup>
            </Menu>
          </div>
        </div>
      )
    }
  }
}
