import {
  Button,
  Checkbox,
  Col,
  Collapse,
  Icon,
  Input,
  message,
  Modal,
  Radio,
  Row,
  Tag,
} from "antd";
import axios, { post } from "axios";
import _ from "lodash";
import React from "react";
import { Link } from "react-router-dom";
import pdf from "../../assets/pdf.png";
import BreadcrumbView from "../../components/BreadCrumb";
import ButtonC from "../../components/ButtonC";
import CollectionCard from "../../components/Expert/CollectionCard";
import {
  ADD_ACTIVITY_COLLECTION,
  ADD_ACTIVITY_PLAYLIST,
  APPROVE_ACTIVITY,
  CHANGE_ACTIVITY_STATE,
  GET_ALL_COLLECTION,
  VIEW_ACTIVITY,
  VIEW_DRAFT_ACTIVITY,
} from "../../graphql";
import Error500Activity from "../../static/activity/500Err.svg";
import emptyState from "../../static/activity/emptyStateSearch.png";
import ErrorActivity from "../../static/activity/error.svg";
import DoIcon from "../../static/card/do.svg";
import WatchIcon from "../../static/card/watch.svg";
import PlayListIcon from "../../static/expert/icons/collection.svg";
import EmptyList from "../../static/nav/emptyList.png";
import {
  isLink,
  openNotification,
  titleCase,
  triggerGAEvent,
} from "../../utils";
import DoCard from "./../../static/activity/DoCard.png";
import WatchCard from "./../../static/activity/watch.png";
import Empty from "./../../static/empty.svg";
import "./activities.css";

const Panel = Collapse.Panel;
const RadioGroup = Radio.Group;
const Search = Input.Search;

class ViewLearningCenter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_selected: false,
      is_loading: true,
      is_error: false,
      is_500: false,
      is_updated: false,
      showAllPlaylist: true,
      showAllCollection: true,
      activity_details: {
        insights_on_activity: {
          is_viewed: "",
          is_finished: "",
          like_count: "",
          dislike_count: "",
          average_time_complete: "",
          easy: { age_group: "", count: "" },
          medium: { age_group: "", count: "" },
          hard: { age_group: "", count: "" },
        },
        activities_id: "",
        user_id: "",
        user: {
          role_id: "",
        },
        user_activity_type: "",
        activity_state: "",
        status: "",
        name: "",
        notes: "",
        breif_desc: "",
        long_desc: "",
        time: "",
        suitable_for_id: "",
        suitable_for: "",
        safety: "",
        safety_desc: "",
        pdf_name: "",
        pdf_url: "",
        pdf_source_text: "",
        pdf_source_url: "",
      },
      age_groups: [],
      thumb_images: [],
      age_groups_dificulty: [],
      interests: [],
      materials_required: [],
      steps: [],
      tags: [],
      prerequisites: [],
      mashup_data: [],
      recommended: [],
      getCollectionPlaylist_details: [],
      oldActivityData: {
        activity_details: {
          user_activity_type: "",
          activity_state: "",
          status: "",
          name: "",
          notes: "",
          breif_desc: "",
          long_desc: "",
          time: "",
          suitable_for_id: "",
          suitable_for: "",
          safety: "",
          safety_desc: "",
          pdf_name: "",
          pdf_url: "",
          pdf_source_text: "",
          pdf_source_url: "",
        },
        age_groups: [],
        thumb_images: [],
        age_groups_dificulty: [],
        interests: [],
        materials_required: [],
        steps: [],
        tags: [],
        prerequisites: [],
        mashup_data: [],
        recommended: [],
      },
      name: "",
      collectionModal: false,
      sort: "newest",
      searchText: "",
      filter: {},
      allCollection: [],
      allOrgCollection: [],
      selectedCollection: [],
    };
    this.onSearchFetchData = _.debounce(this.onSearchFetchData, 700);
  }

  fetchActivityData = () => {
    let query = VIEW_ACTIVITY;
    query.variables = {
      id: parseInt(this.props.match.params.id, 10),
    };
    post(`${process.env.REACT_APP_API}`, query).then((res) => {
      // this.setState({
      //   is_selected:
      //     res.data.data.getOneActivityV2.activity_details[0].is_selected
      // });
      if (res.data.errors) {
        openNotification("error", res.data.errors[0].message);
        this.setState({ is_error: true });
      } else {
        this.setState({
          // default_activities_id:
          //   res.data.data.getOneActivityV2.activity_details[0]
          //     .default_activities_id,
          is_loading: false,
          is_selected:
            res.data.data.getOneActivityV2.activity_details[0].is_selected,
          activity_details: res.data.data.getOneActivityV2.activity_details[0],
          thumb_images: res.data.data.getOneActivityV2.thumb_images.map((x) => ({
            image_url: x.image_url,
          })),
          age_groups: res.data.data.getOneActivityV2.age_groups,
          age_groups_Comp: res.data.data.getOneActivityV2.age_groups.map(
            (aG) => ({
              age_group: { age: aG.age_group.age },
              age_group_dificulty: {
                dificulty: aG.age_group_dificulty.dificulty,
              },
            })
          ),
          interests: res.data.data.getOneActivityV2.interests,
          interests_Comp: res.data.data.getOneActivityV2.interests.map((int) => ({
            interest_category_name: { name: int.interest_category_name.name },
            interest_details: int.interest_details.map((x) => ({
              name: x.name,
            })),
          })),
          prerequisites: res.data.data.getOneActivityV2.prerequisites,
          prerequisites_Comp: res.data.data.getOneActivityV2.prerequisites.map(
            (preReq) => ({ activity_name: preReq.activity_name })
          ),
          recommended: res.data.data.getOneActivityV2.recommended,
          mashup_data: res.data.data.getOneActivityV2.mashup_data,
          materials_required: res.data.data.getOneActivityV2.materials_required,
          steps: res.data.data.getOneActivityV2.steps,
          images: res.data.data.getOneActivityV2.images.map((x) => ({
            image_url: x.image_url,
          })),
          tags: res.data.data.getOneActivityV2.activity_details[0].tags,
          tags_Comp: res.data.data.getOneActivityV2.activity_details[0].tags.map(
            (tag) => tag.name
          ),
          getCollectionPlaylist_details:
            res.data.data.getOneActivityV2.getCollectionPlaylist_details,            
        });
      }
    });
  };

  fetchDraftData = () => {
    try {
      let query = VIEW_DRAFT_ACTIVITY;
      query.variables = {
        activities_draft_id: parseInt(this.props.match.params.id, 10),
      };
      post(`${process.env.REACT_APP_API}`, query)
        .then((res) => {
          if (res.data.errors) {
            this.setState({ is_error: true });
            openNotification("error", res.data.errors[0].message);
          } else {
            if (
              res.data.data.getOneDraftActivity.activity_details[0].status ===
              "Approved"
            ) {
              window.location.href = `/learningcontent/${res.data.data.getOneDraftActivity.activity_details[0].activities_id}/view`;
              // this.props.history.push(

              // );
            } else {
              if (
                this.state.role_id === 1 &&
                res.data.data.getOneDraftActivity.activity_details[0]
                  .activities_id
              ) {
                this.fetchOldActivityData(
                  res.data.data.getOneDraftActivity.activity_details[0]
                    .activities_id
                );
                this.setState({ is_updated: true });
              }
              this.setState({
                is_loading: false,
                is_selected:
                  res.data.data.getOneDraftActivity.activity_details[0]
                    .is_selected,
                activity_details:
                  res.data.data.getOneDraftActivity.activity_details[0],
                thumb_images: res.data.data.getOneDraftActivity.thumb_images.map(
                  (x) => ({ image_url: x.image_url })
                ),
                age_groups: res.data.data.getOneDraftActivity.age_groups,
                age_groups_Comp: res.data.data.getOneDraftActivity.age_groups.map(
                  (aG) => ({
                    age_group: { age: aG.age_group.age },
                    age_group_dificulty: {
                      dificulty: aG.age_group_dificulty.dificulty,
                    },
                  })
                ),
                interests: res.data.data.getOneDraftActivity.interests,
                interests_Comp: res.data.data.getOneDraftActivity.interests.map(
                  (int) => ({
                    interest_category_name: {
                      name: int.interest_category_name
                        ? int.interest_category_name.name
                        : "",
                    },
                    interest_details: int.interest_details.map((x) => ({
                      name: x.name,
                    })),
                  })
                ),
                prerequisites: res.data.data.getOneDraftActivity.prerequisites,
                prerequisites_Comp: res.data.data.getOneDraftActivity.prerequisites.map(
                  (preReq) => ({ activity_name: preReq.activity_name })
                ),
                recommended: res.data.data.getOneDraftActivity.recommended,
                mashup_data: res.data.data.getOneDraftActivity.mashup_data,
                materials_required:
                  res.data.data.getOneDraftActivity.materials_required,
                steps: res.data.data.getOneDraftActivity.steps,
                images: res.data.data.getOneDraftActivity.images.map((x) => ({
                  image_url: x.image_url,
                })),
                tags:
                  res.data.data.getOneDraftActivity.activity_details[0].tags,
                tags_Comp: res.data.data.getOneDraftActivity.activity_details[0].tags.map(
                  (tag) => tag.name
                ),
                getCollectionPlaylist_details:
                  res.data.data.getOneDraftActivity
                    .getCollectionPlaylist_details,
              });
            }
          }
        })
        .catch((e) => {
          this.setState({ is_500: true });
        });
    } catch (e) {
      this.setState({ is_500: true });
    }
  };

  fetchOldActivityData = (id) => {
    let query = VIEW_ACTIVITY;
    query.variables = {
      id: id,
    };
    post(`${process.env.REACT_APP_API}`, query).then((res) => {
      this.setState({ is_loading: false });
      this.setState({
        is_selected:
          res.data.data.getOneActivityV2.activity_details[0].is_selected,
      });
      if (res.data.errors) {
        this.setState({ is_error: true });
        openNotification("error", res.data.errors[0].message);
      } else {
        let oldActivityData = {
          activity_details: res.data.data.getOneActivityV2.activity_details[0],
          thumb_images: res.data.data.getOneActivityV2.thumb_images.map((x) => ({
            image_url: x.image_url,
          })),
          age_groups: res.data.data.getOneActivityV2.age_groups,
          age_groups_Comp: res.data.data.getOneActivityV2.age_groups.map(
            (aG) => ({
              age_group: { age: aG.age_group.age },
              age_group_dificulty: {
                dificulty: aG.age_group_dificulty.dificulty,
              },
            })
          ),
          interests: res.data.data.getOneActivityV2.interests,
          interests_Comp: res.data.data.getOneActivityV2.interests.map((int) => ({
            interest_category_name: { name: int.interest_category_name.name },
            interest_details: int.interest_details.map((x) => ({
              name: x.name,
            })),
          })),
          prerequisites: res.data.data.getOneActivityV2.prerequisites,
          prerequisites_Comp: res.data.data.getOneActivityV2.prerequisites.map(
            (preReq) => ({ activity_name: preReq.activity_name })
          ),
          recommended: res.data.data.getOneActivityV2.recommended,
          mashup_data: res.data.data.getOneActivityV2.mashup_data,
          materials_required: res.data.data.getOneActivityV2.materials_required,
          steps: res.data.data.getOneActivityV2.steps,
          images: res.data.data.getOneActivityV2.images.map((x) => ({
            image_url: x.image_url,
          })),
          tags: res.data.data.getOneActivityV2.activity_details[0].tags,
          tags_Comp: res.data.data.getOneActivityV2.activity_details[0].tags.map(
            (tag) => tag.name
          ),
          getCollectionPlaylist_details:
            res.data.data.getOneActivityV2.getCollectionPlaylist_details,
        };
        this.setState({ oldActivityData: oldActivityData });
      }
    });
  };

  onSearchFetchData = () => {
    triggerGAEvent("Activities", "Search in adding activities to collection");
    this.fetchAllCollection();
  };

  fetchAllCollection = () => {
    const query = GET_ALL_COLLECTION;
    query.variables = {
      sort: this.state.sort,
      search: this.state.searchText,
      filter: this.state.filter,
      activities_id: parseInt(this.props.match.params.id, 10),
    };
    this.setState({ loading: true });
    axios
      .post(`${process.env.REACT_APP_API}`, query)
      .then((res) => {
        let inprogress_playlist = res.data.data.getAllCollection.inprogress_playlist.map(
          (playlist) => {
            playlist.is_Playlist = true;
            return playlist;
          }
        );
        let allCollection = [
          ...res.data.data.getAllCollection.inprogress_collection,
          ...inprogress_playlist,
          ...res.data.data.getAllCollection.published_collection,
        ];
        allCollection = allCollection.map((data) => ({
          ...data,
          name: data.is_Playlist ? data.title : data.name,
          isChecked: false,
        }));
        this.setState(
          {
            loading: false,
            allCollection: allCollection,
            allOrgCollection: allCollection,
          },
          () => {
            this.filterAllCollection();
          }
        );
      })
      .catch((err) => console.log(err));
  };

  approveActivity = () => {
    this.setState({ is_loading: true });
    let mutation = APPROVE_ACTIVITY;
    mutation.variables = {
      default_activities_id: this.state.activity_details.default_activities_id,
      activities_id: this.state.activity_details.activities_id,
      activities_draft_id: Number(this.props.match.params.id),
      user_activity_type: this.state.activity_details.user_activity_type,
      activity_state: this.state.activity_details.activity_state,
      name: titleCase(this.state.activity_details.name.trim()),
      pdf_name: this.state.activity_details.pdf_name,
      pdf_url: this.state.activity_details.pdf_url,
      pdf_source_text: this.state.activity_details.pdf_source_text,
      pdf_source_url: this.state.activity_details.pdf_source_url,
      breif_desc: this.state.activity_details.breif_desc,
      long_desc: this.state.activity_details.long_desc,
      time: this.state.activity_details.act_time,
      safety: this.state.activity_details.safety,
      safety_desc: this.state.activity_details.safety_desc,
      suitable_for_id: Number(this.state.activity_details.suitable_for_id),
      image_source_text: this.state.activity_details.image_source_text,
      image_source_link: this.state.activity_details.image_source_link,
      url: this.state.activity_details.url,
      channel_name: this.state.activity_details.channel_name,
      channel_url: this.state.activity_details.channel_url,
      steps_source_text: this.state.activity_details.steps_source_text,
      steps_source_link: this.state.activity_details.steps_source_link,
      material_required: this.state.materials_required,
      recommended: this.state.recommended,
      thumb_image: this.state.thumb_images.map((data) => ({
        image_url: data.image_url,
      })),
      image: this.state.images.map((x) => ({ image_url: x.image_url })),
      prerequisites: this.state.prerequisites.map((data) => ({
        isPlaylist: data.isPlaylist ? 1 : 0,
        prereq_activity_id: Number(data.activities_id),
      })),
      interest_categories_id: Number(
        this.state.interests[0].interest_details[0].interest_categories_id
      ),
      interest_id: _.compact(
        this.state.interests[0].interest_details.map((data) => data.id)
      ),
      age_group: _.compact(
        this.state.age_groups.map((data) => ({
          age_group_dificulty_id: Number(data.age_group_dificulty.id),
          age_group_id: Number(data.age_group.id),
        }))
      ),
      step: this.state.steps.map((step, index) => ({
        ...step,
        position: `${index + 1}`,
      })),
      mashup: this.state.mashup_data.map((md) => ({
        data_points_id: md.data_point_details.map((x) => x.id),
        data_grade_types_id: md.id,
      })),
      tags_id: this.state.tags.map((tag) => tag.id),
      source: this.state.activity_details.source,
      video_link: this.state.activity_details.video_link,
      video_credit_text: this.state.activity_details.video_credit_text,
      video_credit_link: this.state.activity_details.video_credit_link,
      trailer_url: this.state.activity_details.trailer_url,
      trailer_credit_text: this.state.activity_details.trailer_credit_text,
      trailer_credit_url: this.state.activity_details.trailer_credit_url,
    };
    axios
      .post(`${process.env.REACT_APP_API}`, mutation)
      .then((res) => {
        if (res.data.errors) {
          openNotification("error", res.data.errors[0].message);
          this.setState({
            is_loading: false,
            loading: false,
            shouldBlockNavigation: false,
          });
        } else {
          this.setState({
            is_loading: false,
            loading: false,
            shouldBlockNavigation: false,
          });
          openNotification("success", "Learning Content Approved successfully");
          this.props.history.push(
            `/learningcontent/${res.data.data.approveActivityV2.activity_details.id}/view`
          );
        }
      })
      .catch((err) => console.log(err));
  };

  approveActivityBtnClick = () => {
    if (this.state.mashup_data.length < 4) {
      openNotification(
        "error",
        "Enter mashup data before approving learning content"
      );
    } else {
      this.approveActivity();
    }
  };

  onChange = (e) => {
    triggerGAEvent("Activities", "Changing the state of the activity");
    let mutation = CHANGE_ACTIVITY_STATE;
    mutation.variables = {
      activities_id: this.props.match.params.id,
      activity_state: e,
    };
    axios
      .post(`${process.env.REACT_APP_API}`, mutation)
      .then((res) => {
        if (res.data.errors) {
          message.error(res.data.errors[0].message);
        } else {
          message.warning(
            res.data.data.changeActivityState.activity_details.activity_message
          );
        }
      })
      .catch((err) => console.log(err));
  };

  TitleAndDesc = (title, desc, isChanged) => {
    return (
      <React.Fragment>
        <div className="d-flex">
          <div className="title pb-18 text-uppercase">{title}</div>
          {this.state.is_updated &&
            this.props.history.location.pathname.includes("draft") &&
            isChanged &&
            this.state.role_id === 1 && (
              <div className="updated-box">Updated</div>
            )}
        </div>
        {desc !== "" && (
          <div className="desc">
            <h6>{desc}</h6>
          </div>
        )}
      </React.Fragment>
    );
  };

  addToCollection = () => {
    this.setState({ loading: true });
    let collection_id = this.state.allCollection
      .filter((data) => data.isChecked && !data.is_Playlist)
      .map((data) => data.id);
    let playlist_id = this.state.allCollection
      .filter((data) => data.isChecked && data.is_Playlist)
      .map((data) => data.id);
    let axios_calls = [];
    if (collection_id.length) {
      let collection_mutation = ADD_ACTIVITY_COLLECTION;
      collection_mutation.variables = {
        collections_id: this.state.allCollection
          .filter((data) => data.isChecked && !data.is_Playlist)
          .map((data) => data.id),
        activities_id: this.props.match.params.id,
      };
      axios_calls.push(
        axios.post(`${process.env.REACT_APP_API}`, collection_mutation)
      );
    }
    if (playlist_id.length) {
      let playlist_mutation = ADD_ACTIVITY_PLAYLIST;
      playlist_mutation.variables = {
        playlist_id: this.state.allCollection
          .filter((data) => data.isChecked && data.is_Playlist)
          .map((data) => data.id),
        activities_id: this.props.match.params.id,
      };
      axios_calls.push(
        axios.post(`${process.env.REACT_APP_API}`, playlist_mutation)
      );
    }

    axios
      .all(axios_calls)
      .then(
        axios.spread((res) => {
          message.success("Learning content added to collection");
          this.setState({ visible: false });
          this.fetchActivityData();
          this.fetchAllCollection();
          this.setState({
            loading: false,
            collectionModal: false,
            selectedCollection: [],
          });
        })
      )
      .catch((err) => {
        message.error("Error Occur");
        console.log(err);
      });
  };

  getLocalStorage = () => {
    this.setState({
      role_id: Number(localStorage.getItem("role_id")),
    });
  };

  componentDidMount() {
    this.getLocalStorage();
    if (this.props.history.location.pathname.includes("draft")) {
      this.fetchDraftData();
    } else {
      this.fetchActivityData();
    }
    setTimeout(() => {
      (this.state.role_id === 2 ||
        this.state.role_id === 4 ||
        this.state.role_id === 5) &&
        this.fetchAllCollection();
    }, 500);
  }

  onCollapseChange = () => {
    triggerGAEvent("Activities", "Changing collapse in activity view");
  };
  onChangeCheckbox = (index, value) => {
    const allCollection = [...this.state.allCollection];
    allCollection[index].isChecked = !allCollection[index].isChecked;
    let selectedCollection = this.state.allCollection.filter(
      (data) => data.isChecked
    );
    this.setState({ allCollection, selectedCollection });
  };
  filterAllCollection = () => {
    const allCollection = [...this.state.allOrgCollection];
    if (this.state.showAllCollection && this.state.showAllPlaylist) {
      this.setState({ allCollection });
    } else if (!this.state.showAllCollection && !this.state.showAllPlaylist) {
      this.setState({ allCollection: [] });
    } else {
      const filteredCollection = allCollection
        .map((collection) => {
          if (this.state.showAllCollection) {
            if (!collection.is_Playlist) {
              return collection;
            }
          } else if (this.state.showAllPlaylist) {
            if (collection.is_Playlist) {
              return collection;
            }
          }
        })
        .filter((data) => data);
      this.setState({ allCollection: filteredCollection });
    }
  };
  onChangeCollectionType = (key, value) => {
    this.setState({ [key]: value }, () => {
      this.filterAllCollection();
    });
  };
  render() {
    const radioStyle = {
      display: "block",
      height: "30px",
      lineHeight: "30px",
    };
    let canShowInsights = false;
    let isVideoChanged =
      this.state.role_id !== 1
        ? false
        : Boolean(
            this.state.activity_details.video_link !==
              this.state.oldActivityData.activity_details.video_link ||
              this.state.activity_details.video_credit_text !==
                this.state.oldActivityData.activity_details.video_credit_text ||
              this.state.activity_details.video_credit_link !==
                this.state.oldActivityData.activity_details.video_credit_link
          );

    let isTrailerChanged =
      this.state.role_id !== 1
        ? false
        : Boolean(
            this.state.activity_details.trailer_url !==
              this.state.oldActivityData.activity_details.trailer_url ||
              this.state.activity_details.trailer_credit_text !==
                this.state.oldActivityData.activity_details
                  .trailer_credit_text ||
              this.state.activity_details.trailer_credit_url !==
                this.state.oldActivityData.activity_details.trailer_credit_url
          );
    let isPdfChanged =
      this.state.role_id !== 1
        ? false
        : Boolean(
            this.state.activity_details.pdf_url !==
              this.state.oldActivityData.activity_details.pdf_url ||
              this.state.activity_details.pdf_name !==
                this.state.oldActivityData.activity_details.pdf_name ||
              this.state.activity_details.pdf_source_url !==
                this.state.oldActivityData.activity_details.pdf_source_url ||
              this.state.activity_details.pdf_source_text !==
                this.state.oldActivityData.activity_details.pdf_source_text
          );

    let isStepsChanged =
      this.state.role_id !== 1
        ? false
        : Boolean(
            JSON.stringify(this.state.steps) !==
              JSON.stringify(this.state.oldActivityData.steps) ||
              JSON.stringify(this.state.images) !==
                JSON.stringify(this.state.oldActivityData.images) ||
              this.state.activity_details.url !==
                this.state.oldActivityData.activity_details.url ||
              this.state.activity_details.channel_name !==
                this.state.oldActivityData.activity_details.channel_name ||
              this.state.activity_details.channel_url !==
                this.state.oldActivityData.activity_details.channel_url ||
              this.state.activity_details.steps_source_link !==
                this.state.oldActivityData.activity_details.steps_source_link ||
              this.state.activity_details.steps_source_text !==
                this.state.oldActivityData.activity_details.steps_source_text ||
              this.state.activity_details.image_source_text !==
                this.state.oldActivityData.activity_details.image_source_text ||
              this.state.activity_details.image_source_link !==
                this.state.oldActivityData.activity_details.image_source_link
          );

    if (this.state.activity_details.insights_on_activity) {
      const insights_on_activity = this.state.activity_details
        .insights_on_activity;
      canShowInsights = Boolean(
        insights_on_activity.is_finished ||
          insights_on_activity.is_viewed ||
          insights_on_activity.average_time_complete ||
          insights_on_activity.like_count ||
          insights_on_activity.dislike_count
      );
    }
    if (this.state.is_error || this.state.is_500) {
      return (
        <div className="col-8 mx-auto mt-5 pb-5 text-center">
          <img
            src={this.state.is_500 ? Error500Activity : ErrorActivity}
            alt="error"
            className="img-fluid"
          />
        </div>
      );
    } else {
      return (
        <React.Fragment>
          <div className="bg-white">
            <BreadcrumbView
              title={this.state.activity_details.name}
              data={`/Learning Content/${this.state.activity_details.name.replace(
                /\//g,
                "~~"
              )}`}
              activityId={this.props.match.params.id}
            >
              {this.state.role_id === 1 &&
                this.props.history.location.pathname.includes("draft") && (
                  <div className="d-flex">
                    {!this.state.is_loading && (
                      <React.Fragment>
                        <ButtonC
                          category="Activities"
                          action="Edit draft Learning Content"
                          size="large"
                          ghost
                          className="mr-3"
                          type="primary"
                          onClick={() =>
                            this.props.history.push(
                              `/learningcontent/${this.props.match.params.id}/edit/draft`
                            )
                          }
                        >
                          Edit
                        </ButtonC>
                        <ButtonC
                          category="Activities"
                          action="Approve Learning Content"
                          size="large"
                          block
                          className="btn-w250 mr-3"
                          type="primary"
                          onClick={this.approveActivityBtnClick}
                        >
                          Approve
                        </ButtonC>
                      </React.Fragment>
                    )}
                  </div>
                )}
              {(this.state.role_id === 2 ||
                this.state.role_id === 4 ||
                this.state.role_id === 5) && (
                <div>
                  {this.props.history.location.pathname.includes("draft") ? (
                    <div className="d-flex">
                      <Button
                        size="large"
                        className="btn-w250"
                        ghost
                        disabled={
                          this.state.activity_details.status === "Pending"
                        }
                      >
                        Pending Approval
                      </Button>
                    </div>
                  ) : (
                    <div className="d-flex">
                      <ButtonC
                        category="Activities"
                        action="Edit Approved Content"
                        size="large"
                        ghost
                        className="mr-3"
                        type="primary"
                        onClick={() =>
                          this.props.history.push(
                            `/learningcontent/${this.props.match.params.id}/edit`
                          )
                        }
                      >
                        Edit
                      </ButtonC>
                      <ButtonC
                        category="Activities"
                        action="Adding Learning Content to collection"
                        size="large"
                        className="mr-3 add-collec-btn"
                        type="primary"
                        onClick={() => this.setState({ collectionModal: true })}
                      >
                        Add to Collection / Learning Path
                      </ButtonC>
                    </div>
                  )}
                </div>
              )}
            </BreadcrumbView>
          </div>
          {this.state.is_loading && (
            <div className="vh-100 z-index-1 opacity-black-5 align-items-center position-fixed col-12 fixed-top d-flex justify-content-center">
              <Icon type="loading" theme="outlined" className="loading" />
            </div>
          )}
          <Modal
            visible={this.state.collectionModal}
            maskClosable={false}
            footer={[
              <div className="d-flex justify-content-between align-items-center pl-3 pr-3 p-1">
                <div className="fontColor-blue font-16 FontAvenirMedium" />
                <div>
                  <ButtonC
                    category="Activities"
                    action="Cancel in add to collection"
                    size="large"
                    key="back"
                    onClick={() =>
                      this.setState({
                        collectionModal: false,
                        selectedCollection: [],
                      })
                    }
                  >
                    Cancel
                  </ButtonC>
                  <Button
                    key="submit"
                    category="Learning Contents"
                    action="Adding Learning Content to collection"
                    type="primary"
                    size="large"
                    onClick={this.addToCollection}
                    disabled={this.state.selectedCollection.length < 1}
                  >
                    Add to Collection / Learning Path
                  </Button>
                </div>
              </div>,
            ]}
            onCancel={() =>
              this.setState({ collectionModal: false, selectedCollection: [] })
            }
            width="70%"
          >
            <h6 className="FontAvenirMedium pb-3 mb-3">
              Select Learning Contents (Approved) to be added in this collection{" "}
            </h6>
            <div>
              <Checkbox
                checked={this.state.showAllCollection}
                onChange={(e) => {
                  this.onChangeCollectionType(
                    "showAllCollection",
                    !this.state.showAllCollection
                  );
                }}
              >
                <span className="FontAvenirMedium font-16 text-dark">
                  Collection
                </span>
              </Checkbox>
              <Checkbox
                checked={this.state.showAllPlaylist}
                onChange={(e) => {
                  this.onChangeCollectionType(
                    "showAllPlaylist",
                    !this.state.showAllPlaylist
                  );
                }}
              >
                <span className="FontAvenirMedium font-16 text-dark">
                  Learning path
                </span>
              </Checkbox>
            </div>
            <div className="mt-3 mb-3">
              <Search
                onChange={(e) => {
                  this.setState({ searchText: e.target.value }, () =>
                    this.onSearchFetchData()
                  );
                }}
                style={{ border: "1px solid #4A90E2" }}
                size="large"
                placeholder="Search"
              />
              <div
                style={{
                  height: 300,
                  overflow: "scroll",
                  padding: "10px 30px",
                  border: "1px solid #4A90E2",
                }}
              >
                {this.state.allCollection.length === 0 && !this.state.loading && (
                  <div>
                    <div className=" d-flex justify-content-center align-items-center flex-column mt-4">
                      <img
                        className="mb-3"
                        src={
                          this.state.searchText === "" ? emptyState : EmptyList
                        }
                        alt=""
                      />
                      <div className="text-center">
                        <p className="FontAvenirMedium font-20 mb-2">
                          {this.state.searchText === ""
                            ? "No Collections are yet be created. Please wait until collections are created."
                            : "No results found. Please try again with different keywords"}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
                {/**
                  <RadioGroup
                     onChange={(e) =>
                       this.setState({ selectedCollection: e.target.value })
                     }
                     value={this.state.selectedCollection}
                     style={{ width: "100%" }}
                   >
                */}
                {this.state.allCollection.map((data, index) => (
                  <div
                    className="p-4 mt-4 rounded mb-4 border d-flex justify-content-between align-items-center"
                    key={index}
                  >
                    <Checkbox
                      checked={data.isChecked}
                      onChange={(e) => {
                        this.onChangeCheckbox(index);
                      }}
                    >
                      {/* <Radio
                    value={data.id}
                    key={index}
                    style={{ ...radioStyle, width: "100%" }}
                   >*/}
                      <span className="FontAvenirMedium font-16 text-dark">
                        {data.name}
                      </span>
                      {/**
                    </Radio>
                   */}
                    </Checkbox>
                    <div>
                      {data.is_Playlist && (
                        <img src={PlayListIcon} width="24" alt="" />
                      )}
                      <span style={{ margin: "0 1rem", marginRight: 0 }}>
                        {data.is_publish ? (
                          <Tag
                            className="text-center"
                            style={{ minWidth: "83px" }}
                            color="green"
                          >
                            Published
                          </Tag>
                        ) : (
                          <Tag color="blue">Unpublished</Tag>
                        )}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Modal>
          <div className="m-3">
            <div className="border-style">
              <Row>
                <Col span={16}>
                  <Row>
                    <Col span={6}>
                      {/* {this.TitleAndDesc(
                        "Content type",
                        this.state.activity_details.user_activity_type,
                        this.state.activity_details.user_activity_type !==
                          this.state.oldActivityData.activity_details
                            .user_activity_type
                      )} */}
                      {this.TitleAndDesc(
                        "Content type",
                        this.state.activity_details.default_activities_id ===
                          1 ? (
                          "Read"
                        ) : this.state.activity_details
                            .default_activities_id === 2 ? (
                          <img src={WatchCard} className="img-fluid " alt="" />
                        ) : this.state.activity_details
                            .default_activities_id === 3 ? (
                          "Listen"
                        ) : this.state.activity_details
                            .default_activities_id === 4 ? (
                          <img src={DoCard} className="img-fluid " alt="" />
                        ) : (
                          ""
                        )
                      )}
                    </Col>
                    {this.state.role_id !== 4 && this.state.role_id !== 5 && (
                      <Col span={6}>
                        {this.state.activity_details.user.role_id !== 4 &&
                          this.state.activity_details.user.role_id !== 5 && (
                            <React.Fragment>
                              {this.TitleAndDesc(
                                "Availability type",
                                this.state.activity_details.user_activity_type,
                                this.state.activity_details
                                  .user_activity_type !==
                                  this.state.oldActivityData.activity_details
                                    .user_activity_type
                              )}
                            </React.Fragment>
                          )}
                      </Col>
                    )}
                    <Col span={6}>
                      {this.TitleAndDesc("Status", "")}
                      {this.state.activity_details.status === "Pending" && (
                        <Tag color="red">Pending</Tag>
                      )}
                      {this.state.activity_details.status === "Approved" && (
                        <Tag color="green">Approved</Tag>
                      )}
                    </Col>
                    <Col span={6}>
                      {this.TitleAndDesc("State", "")}
                      <Radio.Group
                        onChange={(e) => {
                          let activity_details = {
                            ...this.state.activity_details,
                            activity_state: e.target.value,
                          };
                          this.setState({ activity_details: activity_details });
                          this.onChange(e.target.value);
                        }}
                        value={this.state.activity_details.activity_state}
                        disabled={this.state.role_id !== 2}
                      >
                        <Radio value={"Active"}>Active</Radio>
                        <Radio value={"Hidden"}>Hidden</Radio>
                      </Radio.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12}>
                      <div className="mtb-34" />
                      {this.TitleAndDesc(
                        "Title",
                        this.state.activity_details.name,
                        this.state.activity_details.name !==
                          this.state.oldActivityData.activity_details.name
                      )}
                    </Col>
                    <Col span={12}>
                      <div className="mtb-34" />
                      {this.state.role_id === 1 &&
                        this.TitleAndDesc(
                          "Created By",
                          this.state.activity_details.user_name
                        )}
                    </Col>
                  </Row>
                </Col>

                <Col span={8}>
                  {canShowInsights && (
                    <div className="col-12 bg-white b-l">
                      {this.TitleAndDesc("Insights on learning content", "")}
                      <div className="font16 mb-3">
                        Finished -{" "}
                        {
                          this.state.activity_details.insights_on_activity
                            .is_finished
                        }
                      </div>
                      <div className="font16 mb-3">
                        Viewed -{" "}
                        {
                          this.state.activity_details.insights_on_activity
                            .is_viewed
                        }
                      </div>
                      <div className="row m-0">
                        <div className="col-8 mb-2 p-0 font-16">
                          Average time to complete -{" "}
                          {this.state.activity_details.insights_on_activity
                            .average_time_complete || 0}{" "}
                          mins
                        </div>
                        <div className="col-4">
                          <Icon type="like" theme="outlined" />
                          {
                            this.state.activity_details.insights_on_activity
                              .like_count
                          }
                          <Icon
                            type="dislike"
                            theme="outlined"
                            className="ml-4"
                          />
                          {
                            this.state.activity_details.insights_on_activity
                              .dislike_count
                          }
                        </div>
                      </div>
                    </div>
                  )}
                </Col>
              </Row>

              <div className="border-bottom mt-4 mb-4"></div>

              <div className="mtb-34" />
              <Row>
                <Col span={24}>
                  {this.TitleAndDesc("FEATURED IMAGES", "", JSON.stringify(this.state.thumb_images) !== JSON.stringify(this.state.oldActivityData.thumb_images))}
                  <div className="row col-12 m-0 p-0 featured-img">
                    {this.state.thumb_images.map((data, index) => (
                      <div className="col-4 p-0" key={index}>
                        <img
                          className="col-12 p-0"
                          style={{ height: 212, width: 378, objectFit: 'cover' }}
                          src={data.image_url}
                          alt="featured images"
                        />
                      </div>
                    ))}
                  </div>
                </Col>
              </Row>
            </div>

            {/* <Collapse
            defaultActiveKey={["1", "2"]}
            className="ant-collapse mt-4 ml-0 mr-0 row col-12 collapse-header"
            onChange={this.onCollapseChange}
          > */}
            {/* <div className="border-style"> */}
            <div className="titleCard">DETAILS OF THIS LEARNING CONTENT</div>
            <div className="row m-0">
              <div
                header="DETAILS OF THIS ACTIVITY"
                className="col-12 border-style"
                key="1"
              >
                <div>
                  {this.state.activity_details.default_activities_id === 2 && (
                    <React.Fragment>
                      {this.state.activity_details.source && (
                        <React.Fragment>
                          <Row>
                            <Col span={24}>
                              {this.TitleAndDesc(
                                "SOURCE",
                                this.state.activity_details.source,
                                this.state.activity_details.source !==
                                  this.state.oldActivityData.activity_details
                                    .source
                              )}
                            </Col>
                          </Row>
                          <div className="mtb-24" />
                        </React.Fragment>
                      )}
                      <Row>
                        <Col span={24}>
                          {this.TitleAndDesc("VIDEO", "", isVideoChanged)}
                          <div className="desc editor">
                            <a
                              href={this.state.activity_details.video_link}
                              target="_blank"
                            >
                              {this.state.activity_details.video_link}
                            </a>
                          </div>
                          <div className="mtb-24" />
                          {this.state.activity_details.video_credit_link ? (
                            <div className="desc">
                              <a
                                href={
                                  this.state.activity_details.video_credit_link
                                }
                                target="_blank"
                              >
                                {this.state.activity_details.video_credit_text}
                              </a>
                            </div>
                          ) : (
                            <span className="font-16">
                              {this.state.activity_details.video_credit_text}
                            </span>
                          )}
                        </Col>
                      </Row>

                      <div className="mtb-24 border-bottom" />
                    </React.Fragment>
                  )}
                  {this.state.activity_details.trailer_url && (
                    <div>
                      <Row>
                        <Col span={24}>
                          {this.TitleAndDesc("TRAILER", "", isTrailerChanged)}
                          <div className="desc">
                            <a
                              href={this.state.activity_details.trailer_url}
                              target="_blank"
                            >
                              {this.state.activity_details.trailer_url}
                            </a>
                          </div>
                          <div className="mtb-24" />
                          {this.state.activity_details.trailer_credit_url ? (
                            <div className="desc">
                              <a
                                href={
                                  this.state.activity_details.trailer_credit_url
                                }
                                target="_blank"
                              >
                                {
                                  this.state.activity_details
                                    .trailer_credit_text
                                }
                              </a>
                            </div>
                          ) : (
                            <span className="font-16">
                              {this.state.activity_details.trailer_credit_text}
                            </span>
                          )}
                        </Col>
                      </Row>
                      <div className="mtb-24 border-bottom" />
                    </div>
                  )}
                  <div>
                    {this.TitleAndDesc(
                      `Age group${
                        this.state.activity_details.default_activities_id !== 2
                          ? "& difficulty"
                          : ""
                      }`,
                      "",
                      JSON.stringify(this.state.age_groups_Comp) !==
                        JSON.stringify(
                          this.state.oldActivityData.age_groups_Comp
                        )
                    )}
                  </div>
                  <div className="m-0">
                    {this.state.age_groups.map((data, index) => (
                      <span key={index}>
                        <span
                          key={index}
                          className={index === 0 ? "mr-3 ml-0" : "m-3"}
                        >
                          <span>{`${data.age_group.age}`}</span>
                          {this.state.activity_details.default_activities_id !==
                            2 && (
                            <span className="color-blue-6">{`: ${data.age_group_dificulty.dificulty}`}</span>
                          )}
                        </span>
                        <span
                          className={
                            index === this.state.age_groups.length - 1
                              ? ""
                              : "border-right-grey"
                          }
                        />
                      </span>
                    ))}
                  </div>
                </div>
                <div className="border-bottom mt-4 mb-4" />
                <div className="row">
                  {canShowInsights &&
                    (this.state.activity_details.insights_on_activity.easy
                      .length > 0 ||
                      this.state.activity_details.insights_on_activity.medium
                        .length > 0 ||
                      this.state.activity_details.insights_on_activity.hard
                        .length > 0) && (
                      <div className="col-12 bg-white">
                        {this.TitleAndDesc("Feedback", "")}
                        {this.state.activity_details.insights_on_activity.easy
                          .length > 0 && (
                          <div className="row ptb-8">
                            <span className="col-1">Easy</span>
                            <div className="d-inline pl-2 pr-2"> - </div>
                            {this.state.activity_details.insights_on_activity.easy.map(
                              (data, index) => (
                                <Tag
                                  color="blue"
                                  key={index}
                                >{`${data.age_group} | ${data.count}`}</Tag>
                              )
                            )}
                          </div>
                        )}
                        {this.state.activity_details.insights_on_activity.medium
                          .length > 0 && (
                          <div className="row ptb-8">
                            <span className="col-1">Medium</span>
                            <div className="d-inline pl-2 pr-2"> - </div>
                            {this.state.activity_details.insights_on_activity.medium.map(
                              (data, index) => (
                                <Tag
                                  color="blue"
                                  key={index}
                                >{`${data.age_group} | ${data.count}`}</Tag>
                              )
                            )}
                          </div>
                        )}
                        {this.state.activity_details.insights_on_activity.hard
                          .length > 0 && (
                          <div className="row ptb-8">
                            <span className="col-1">Hard</span>
                            <div className="d-inline pl-2 pr-2"> - </div>
                            {this.state.activity_details.insights_on_activity.hard.map(
                              (data, index) => (
                                <Tag
                                  color="blue"
                                  key={index}
                                >{`${data.age_group} | ${data.count}`}</Tag>
                              )
                            )}
                          </div>
                        )}
                        <div className="border-bottom mt-4 mb-4" />
                      </div>
                    )}
                </div>
                <div>
                  {this.TitleAndDesc(
                    "Interests",
                    "",
                    JSON.stringify(this.state.interests_Comp) !==
                      JSON.stringify(this.state.oldActivityData.interests_Comp)
                  )}
                  {this.state.interests.map((data, index) => (
                    <div className="desc d-flex" key={index}>
                      {data.interest_category_name && (
                        <h6>{data.interest_category_name.name}</h6>
                      )}
                      {data.interest_details !== 0 && (
                        <div className="d-inline pl-2 pr-2"> - </div>
                      )}
                      <div>
                        {data.interest_details.map((InterestData, i) => (
                          <Tag color="blue" className="mb-2" key={i}>
                            {InterestData.name}
                          </Tag>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
                <div className="border-bottom mt-4 mb-4" />
                <div>
                  {this.TitleAndDesc(
                    "Brief Description",
                    this.state.activity_details.breif_desc,
                    this.state.activity_details.breif_desc !==
                      this.state.oldActivityData.activity_details.breif_desc
                  )}
                </div>
                <div className="border-bottom mt-4 mb-4" />
                {this.state.activity_details.long_desc && (
                  <div>
                    <div>
                      {this.TitleAndDesc(
                        "Long Description",
                        "",
                        this.state.activity_details.long_desc !==
                          this.state.oldActivityData.activity_details.long_desc
                      )}
                      <div className="long-description">
                        <h6
                          dangerouslySetInnerHTML={{
                            __html: this.state.activity_details.long_desc,
                          }}
                        />
                      </div>
                    </div>
                    <div className="border-bottom mt-4 mb-4" />
                  </div>
                )}
                <div className="row m-0">
                  <div className="col-6 b-r p-0">
                    <div className="mb-3">
                      {this.TitleAndDesc(
                        this.state.activity_details.default_activities_id === 2
                          ? "TOTAL WATCH TIME"
                          : "TIME",
                        `${this.state.activity_details.time} mins`,
                        this.state.activity_details.time !==
                          this.state.oldActivityData.activity_details.time
                      )}
                    </div>
                    <div className="mb-3">
                      {this.TitleAndDesc(
                        "Suitable for",
                        this.state.activity_details.suitable_for,
                        this.state.activity_details.suitable_for !==
                          this.state.oldActivityData.activity_details
                            .suitable_for
                      )}
                    </div>
                  </div>
                  <div className="col-6 pdl-2">
                    {this.TitleAndDesc(
                      "Adult supervision",
                      "",
                      this.state.activity_details.safety !==
                        this.state.oldActivityData.activity_details.safety ||
                        this.state.activity_details.safety_desc !==
                          this.state.oldActivityData.activity_details
                            .safety_desc
                    )}
                    <div className="mb-3">
                      <h6>{this.state.activity_details.safety}</h6>
                    </div>
                    <div className="mb-3 word-break">
                      {this.state.activity_details.safety_desc}
                    </div>
                  </div>
                </div>
                <div className="border-bottom mt-4 mb-4" />
                {this.state.materials_required.length !== 0 && (
                  <div>
                    <div className="row m-0">
                      {this.TitleAndDesc(
                        "Materials Required",
                        "",
                        JSON.stringify(this.state.materials_required) !==
                          JSON.stringify(
                            this.state.oldActivityData.materials_required
                          )
                      )}
                      <div className="col-12 row m-0 p-0">
                        {this.state.materials_required.map((data, index) => (
                          <div className="col-4 p-0 row m-0" key={index}>
                            <div className="col-3 p-0 pr-2">
                              <img
                                className="pr-2 pl-2"
                                src={data.image}
                                style={{height: 80}}
                                alt="materials"
                              />
                            </div>
                            <div
                              className="col-9 p-0 d-flex align-items-center"
                              style={{ height: 80 }}
                            >
                              <span>{data.name}</span>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="border-bottom mt-4 mb-4" />
                  </div>
                )}
                {this.state.activity_details.pdf_url && (
                  <div>
                    <div className="ml-3">
                      {this.TitleAndDesc("PDF", "", isPdfChanged)}
                      <div className="d-flex justify-content-between align-items-center">
                        <div>
                          <a
                            href={this.state.activity_details.pdf_url}
                            target="_blank"
                          >
                            <img
                              src={pdf}
                              alt="uploaded pdf"
                              style={{ width: 66 }}
                            />{" "}
                            <span className="ml-3 text-secondary">
                              {this.state.activity_details.pdf_name}
                            </span>
                          </a>
                        </div>
                        <div className="mr-3">
                          Source
                          {this.state.activity_details.pdf_source_url ? (
                            <a
                              href={this.state.activity_details.pdf_source_url}
                              target="_blank"
                            >{` - ${this.state.activity_details.pdf_source_text}`}</a>
                          ) : (
                            <span>{` - ${this.state.activity_details.pdf_source_text}`}</span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="border-bottom m-4" />
                  </div>
                )}
                {this.state.activity_details.default_activities_id !== 2 && (
                  <div>
                    <Link
                      to={`${this.props.location.pathname}/images,videos&steps`}
                    >
                      <div className="col-12 m-0 p-0">
                        {this.TitleAndDesc(
                          "Images, Videos & Steps",
                          "",
                          isStepsChanged
                        )}
                        <div
                          className={`pointer-cursor d-flex justify-content-between FontAvenirRoman font-16 align-items-center p-2 rounded  ${
                            this.state.activity_details.video_credit_text !==
                              "" ||
                            this.state.activity_details.video_link !== "" ||
                            this.state.steps.length !== 0 ||
                            this.state.images.length !== 0
                              ? "stepsImages-filled"
                              : "stepsImages-empty"
                          }`}
                        >
                          <div className="position-relative">
                            <div className="outline-circle" />
                          </div>
                          <div>
                            Click to view Videos, Images or Steps for this
                            Learning content
                          </div>
                          <Icon type="right" theme="outlined" />
                        </div>
                      </div>
                    </Link>
                    <div className="border-bottom mt-4 mb-4" />
                  </div>
                )}
                {this.state.prerequisites &&
                  this.state.prerequisites.length > 0 && (
                    <div>
                      <div className="row m-0">
                        <div className="w-100">
                          <div className="col-12 m-0">
                            {this.TitleAndDesc(
                              "Prerequisites",
                              "",
                              JSON.stringify(this.state.prerequisites_Comp) !==
                                JSON.stringify(
                                  this.state.oldActivityData.prerequisites_Comp
                                )
                            )}
                            {this.state.prerequisites.map((data, i) => (
                              <Tag color="blue" key={i}>
                                {data.isPlaylist ? (
                                  <div className="d-flex align-items-center justify-content-start">
                                    <img
                                      src={PlayListIcon}
                                      className="img-fluid mr-2"
                                      alt="playlist icon"
                                      style={{ height: 15, weight: 15 }}
                                    />
                                    {data.activity_name}
                                  </div>
                                ) : (
                                  <div className="d-flex align-items-center justify-content-start">
                                    {data.default_activities_id === 1 ? (
                                      "Read"
                                    ) : data.default_activities_id === 2 ? (
                                      <img
                                        src={WatchIcon}
                                        className="img-fluid mr-2"
                                        alt="watch icon"
                                        style={{ height: 15, weight: 15 }}
                                      />
                                    ) : data.default_activities_id === 3 ? (
                                      "Listen"
                                    ) : data.default_activities_id === 4 ? (
                                      <img
                                        src={DoIcon}
                                        className="img-fluid mr-2"
                                        alt="do icon"
                                        style={{ height: 15, weight: 15 }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                    {data.activity_name}
                                  </div>
                                )}
                              </Tag>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                {this.state.recommended && this.state.recommended.length > 0 && (
                  <div>
                    <div className="border-bottom mt-4 mb-4" />
                    <div className="row m-0">
                      <div className="w-100">
                        <div className="col-12 m-0 pb-2">
                          {this.TitleAndDesc(
                            "USEFUL LINKS TO BROWSE",
                            "",
                            JSON.stringify(this.state.recommended) !==
                              JSON.stringify(
                                this.state.oldActivityData.recommended
                              )
                          )}
                          {this.state.recommended.map((data, i) => (
                            <a
                              href={
                                isLink.test(data.link)
                                  ? data.link
                                  : `http://${data.link}`
                              }
                              target="_blank"
                              key={i}
                            >
                              {data.title}
                            </a>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {this.state.tags && this.state.tags.length > 0 && (
                  <div>
                    <div className="border-bottom mt-4 mb-4" />
                    <div className="row m-0">
                      <div className="w-100">
                        <div className="col-12 m-0">
                          {this.TitleAndDesc(
                            "TAGS",
                            "",
                            JSON.stringify(this.state.tags_Comp) !==
                              JSON.stringify(
                                this.state.oldActivityData.tags_Comp
                              )
                          )}
                          {this.state.tags.map((data, i) => (
                            <Tag color="blue" key={i}>
                              {data.name}
                            </Tag>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="m-4" />
              </div>
            </div>
            {this.state.role_id !== 1 && (
              <React.Fragment>
                <div className="titleCard text-uppercase">
                  THIS LEARNING CONTENT IS ADDED TO
                </div>
                <div className="">
                  <div
                    header="Mashup data for this learning content"
                    className="col-12 border-style"
                    key="1"
                  >
                    <div className="m-0 p-4">
                      {this.state.getCollectionPlaylist_details.length ===
                        0 && (
                        <div className="d-flex flex-column align-items-center justify-content-between">
                          <img src={Empty} alt="Empty" />
                          <p className="font-20 text-dark mt-5">
                            The list of Collections / Learning paths this
                            learning content is added to will be shown here.
                          </p>
                          <ButtonC
                            category="Activities"
                            action="Adding Learning Content to collection"
                            size="large"
                            className="mt-5 add-collec-btn"
                            type="primary"
                            disabled={this.props.history.location.pathname.includes(
                              "draft"
                            )}
                            onClick={() =>
                              this.setState({ collectionModal: true })
                            }
                          >
                            Add to Collection / Learning Path
                          </ButtonC>
                        </div>
                      )}
                      {this.state.getCollectionPlaylist_details.length !==
                        0 && (
                        <div className="row">
                          {this.state.getCollectionPlaylist_details.map(
                            (data, index) => (
                              <div className="col-3" key={index}>
                                <Link
                                  to={{
                                    pathname: `/${
                                      data.isPlaylist
                                        ? "playlist"
                                        : "collection"
                                    }/${data.id}/view`,
                                  }}
                                >
                                  <CollectionCard
                                    loading={false}
                                    title={data.title}
                                    src={data.background_image}
                                    collectionIcon={data.isPlaylist}
                                  />
                                </Link>
                              </div>
                            )
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )}
            {this.state.mashup_data.length !== 0 && (
              <React.Fragment>
                <div className="titleCard text-uppercase">
                  Mashup data for this learning content
                </div>
                <div className="">
                  <div
                    header="Mashup data for this learning content"
                    className="col-12 border-style"
                    key="1"
                  >
                    <div className="m-0">
                      {this.state.mashup_data.map((data, index) => {
                        if (index <= 2) {
                          return (
                            <div key={index}>
                              {this.TitleAndDesc(data.name, "")}
                              {data.data_point_details.map(
                                (point_details, i) => (
                                  <div className="mb-3" key={i}>
                                    {point_details.name}
                                  </div>
                                )
                              )}
                            </div>
                          );
                        } else {
                          return (
                            <div key={index}>
                              {index === 3 &&
                                this.TitleAndDesc("Data points", "")}
                              <div style={{ margin: "0.7rem 0" }}>
                                <span>{data.name}</span>
                                {data.data_point_details !== 0 && (
                                  <div className="d-inline pl-2 pr-2"> - </div>
                                )}
                                {data.data_point_details.map(
                                  (InterestData, i) => (
                                    <Tag color="blue" key={i}>
                                      {InterestData.name}
                                    </Tag>
                                  )
                                )}
                              </div>
                            </div>
                          );
                        }
                      })}
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )}

            {/* </Collapse> */}
          </div>
        </React.Fragment>
      );
    }
  }
}

export default ViewLearningCenter;
