import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Table, Breadcrumb, Tag, Tabs, Icon } from 'antd'
import axios from 'axios'
import moment from 'moment'
import { GET_REGISTERED_STUDENTS_LIST } from './../graphql'
import TimeAgo from 'react-timeago'
import './index.css'
import {
  isLink,
  openNotification,
  titleCase,
  triggerGAEvent
} from '../../../utils'

const { TabPane } = Tabs
class WorkshopRegisteredStudents extends Component {
  constructor (props) {
    super(props)
    this.state = {
      is_loading: true,
      stud_data: [],
      registered_data: [],
      page: 1,
      total: 0,
      filterInfo: null,
      workshop_name: '',
      workshop_details: {},
      zoom_details: {},
      workshop_id: '',
      sorterInfo: {
        columnKey: 'last_login',
        field: 'last_login',
        order: 'descend'
      }
    }
  }

  fetchData = () => {
    this.setState({ is_loading: true })
    let { sorterInfo } = this.state
    sorterInfo = sorterInfo || { columnKey: '', order: '' }
    let query = GET_REGISTERED_STUDENTS_LIST
    query.variables = {
      workshop_id: this.state.workshop_id,
      page: this.state.page
    }
    axios
      .post(`${process.env.REACT_APP_API}`, query)
      .then(res => {
        if (res.data.errors) {
          openNotification('error', res.data.errors[0].message)
        } else {
          console.log(
            'registerd details ==> ',
            res.data.data.registeredStudentListV2
          )
          let zoom_det = res.data.data.registeredStudentListV2.workshop_details
            .zoom_meeting
            ? JSON.parse(
                res.data.data.registeredStudentListV2.workshop_details
                  .zoom_meeting
              )
            : {}
          this.setState({
            // stud_data: res.data.data.registeredStudentListV2.registered_list,
            registered_data: res.data.data.registeredStudentListV2.final_list,
            // total: res.data.data.registeredStudentListV2.total_count,
            workshop_name: res.data.data.registeredStudentListV2.workshop_name,
            workshop_details:
              res.data.data.registeredStudentListV2.workshop_details,
            zoom_details: zoom_det,
            is_loading: false
          })
        }
      })
      .catch(err => console.log(err))
  }

  componentDidMount () {
    this.setState(
      { is_loading: true, workshop_id: this.props.match.params.id },
      () => this.fetchData()
    )
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.setState(
      {
        sorterInfo: sorter,
        filterInfo: filters
      },
      () => this.fetchData()
    )
  }

  render () {
    let { sorterInfo } = this.state
    sorterInfo = sorterInfo || { columnKey: '', order: '' }
    const columns = [
      {
        title: 'S No',
        dataIndex: 'name',
        width: 80,
        fixed: 'left',
        render: (text, record, index) => (
          <span>{`${(this.state.page - 1) * 10 + (index + 1)}`}</span>
        )
      },
      {
        title: 'Learner’s First Name',
        dataIndex: 'fname',
        width: 200,
        fixed: 'left',
        render: (text, record, index) => (
          <div
            className='cursor-pointer'
            // onClick={() => this.props.history.push(`/learners/${record.id}`)}
          >
            {record.fname ? record.fname : ''}
          </div>
        )
      },
      {
        title: 'Learner’s Last Name',
        dataIndex: 'fname',
        width: 200,
        fixed: 'left',
        render: (text, record, index) => (
          <div
            className='cursor-pointer'
            // onClick={() => this.props.history.push(`/learners/${record.id}`)}
          >
            {record.lname ? record.lname : ''}
          </div>
        )
      },
      {
        title: 'Learner’s Username',
        dataIndex: 'username',
        width: 200,
        fixed: 'left'
      },
      {
        title: 'Date of birth',
        dataIndex: 'dob',
        width: 150,
        render: (text, record, index) => (
          <div
            className='cursor-pointer'
            // onClick={() => this.props.history.push(`/learners/${record.id}`)}
          >
            {`${record.dob ? moment(record.dob).format('ll') : ''}`}
          </div>
        )
      },
      {
        title: 'Age',
        dataIndex: 'age',
        width: 80
      },
      {
        title: 'Parent’s Name',
        dataIndex: 'parent_details',
        width: 150,
        render: (text, record, index) => {
          if (!record.parent_details) {
            return
          }
          return (
            <div
              key={index}
              className='cursor-pointer'
              // onClick={() => this.props.history.push(`/parents/${parent.status}/${parent.email}`)}
            >
              {`${record.parent_details.name || ''} ${record.parent_details
                .last_name || ''}`}
            </div>
          )
        }
      },
      {
        title: 'Parent’s email ID',
        width: 200,
        dataIndex: 'parent_details',
        render: (text, record, index) => {
          if (!record.parent_details) {
            return
          }
          return <div key={index}>{record.parent_details.email}</div>
        }
      },
      {
        title: 'Parent’s Phone Number',
        width: 200,
        dataIndex: 'parent_details',
        render: (text, record, index) => {
          if (!record.parent_details) {
            return
          }
          return <div key={index}>{record.parent_details.phone_no}</div>
        }
      },
      {
        title: 'City',
        width: 150,
        dataIndex: 'parent_details',
        render: (text, record, index) => {
          if (!record.parent_details) {
            return
          }
          return <div key={index}>{record.parent_details.address}</div>
        }
      },
      {
        title: 'Status',
        width: 200,
        dataIndex: 'registration_status',
        filters: [
          {
            text: 'Pending Approval',
            value: 'Pending'
          },
          {
            text: 'Not attended',
            value: false
          },
          {
            text: 'Attended',
            value: true
          }
        ],
        onFilter: (value, record) =>
          record.registration_status === value || record.is_attended === value,
        render: (text, record, index) =>
          record.registration_status == 'Pending' ? (
            <Tag className='pendingTag' key='Pending'>
              Pending approval
            </Tag>
          ) : record.is_attended ? (
            <Tag className='registerTag' key='Attended'>
              Attended
            </Tag>
          ) : (
            <Tag className='notAttendedTag' key='notAttended'>
              Not attended
            </Tag>
          )
      }
    ]
    return (
      <div className='mt-4 p-4'>
        {this.state.is_loading && (
          <div className='vh-100 z-index-1 opacity-black-5 align-items-center position-fixed col-12 fixed-top d-flex justify-content-center'>
            <Icon type='loading' theme='outlined' className='loading' />
          </div>
        )}
        {!this.state.is_loading && (
          <div className='d-flex align-items-center justify-content-start'>
            <div className='mb-4'>
              <Breadcrumb>
                <Breadcrumb.Item href={`/workshops`}>Workshops</Breadcrumb.Item>
                <Breadcrumb.Item
                  className='breadcrumbCursor'
                  // href={`/workshops/${this.props.match.params.id}/view`}
                  onClick={() => this.props.history.goBack()}
                >
                  {this.state.workshop_name}
                </Breadcrumb.Item>
                <Breadcrumb.Item className='registeredBreadcrumb'>
                  Workshop insights
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
        )}
        {!this.state.is_loading && (
          <React.Fragment>
            <div className='d-flex align-items-center justify-content-between mb-4'>
              <div className='d-flex align-items-center justify-content-start'>
                <h4 className='m-0 reg_studTitle'>Workshop insights</h4>
              </div>
            </div>
            <div className='row border-style m-0'>
              <div className='col-2'>
                <h6>Workshop Name</h6>
                <p className='conText'>{this.state.workshop_name}</p>
              </div>
              <div className='col-4'>
                <h6>Meeting Details</h6>
                <a
                  href={this.state.zoom_details.join_url}
                  target='__blank'
                  className='meetingText'
                >
                  {this.state.zoom_details.join_url}
                </a>
              </div>
              <div className='col-3'>
                <h6>Primary Host ID</h6>
                <p className='conText'>
                  {this.state.workshop_details.primary_host
                    ? this.state.workshop_details.primary_host
                    : 'Primary host not set'}
                </p>
              </div>
              <div className='col-3'>
                <h6>Facilitator</h6>
                <p className='conText'>
                  {this.state.workshop_details.user_details
                    ? this.state.workshop_details.user_details.name
                    : ''}
                </p>
              </div>
            </div>
          </React.Fragment>
        )}
        <div>
          <Tabs defaultActiveKey='1'>
            {this.state.registered_data &&
              this.state.registered_data.length > 0 &&
              this.state.registered_data.map((x, i) => {
                return (
                  <TabPane
                    tab={
                      <div className='d-flex flex-column'>
                        <div
                          className='tab-color'
                          style={{
                            fontWeight: 800,
                            fontSize: 16
                          }}
                        >
                          {`Session ${i + 1}`}
                        </div>
                        <div
                          className='tab-color1'
                          style={{
                            fontWeight: 500,
                            fontSize: 10
                          }}
                        >
                          {moment(parseInt(x.session_date)).format('ll')}
                        </div>
                      </div>
                    }
                    key={`${i + 1}`}
                  >
                    <div>
                      <Table
                        rowClassName='bg-white'
                        loading={this.state.is_loading}
                        columns={columns}
                        dataSource={x.registered_list}
                        onChange={this.handleTableChange}
                        rowKey={record => record.id}
                        scroll={{ x: 1300 }}
                        pagination={{
                          current: this.state.page,
                          defaultCurrent: 1,
                          total: (x.total_count || 0),
                          onChange: (page, pageSize) =>
                            this.setState({ page: page }, () =>
                              this.fetchData()
                            )
                        }}
                      />
                    </div>
                  </TabPane>
                )
              })}
          </Tabs>
        </div>
      </div>
    )
  }
}

export default withRouter(WorkshopRegisteredStudents)
