import { Button, Card, Modal } from "antd";
import { Select } from "antd/lib/index";
import axios from "axios";
import React from "react";
import { Link } from "react-router-dom";
import Form from "react-validation/build/form";
import "../../App.css";
import logo from "../../assets/breadcrumb_logo.png";
import arrowLeft from "../../assets/arrow_left.svg";
import InviteLogo from "../../assets/Invite_sent.svg";
import InputC from "../../components/InputC";
import { country_codes } from "../../static/countryCodes.json";
import { openNotification, triggerGAEvent } from "../../utils";
import { email } from "../../validation";
import { SEND_PARENT_INVITE } from "../Students/api";
import "./Signin.css";
import _ from 'lodash'
import { isRequired } from '../../validation'
const { confirm } = Modal;

class SignUp extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			login_type: "",
			login: {
				email: "",
				name: "",
				last_name: "",
				address: "",
				phone_no: "",
				country: "India",
				country_code: "+91"
				// status: ""
			},
			truthObj: {
				email: false,
				name: false,
				last_name: false,
				address: false,
				phone_no: false,
			},
			form: React.createRef(),
			submitted: false,
			showHide: {
				password: false
			}
		};
	}

	showConfirm = () => {
		let confirmPopup = confirm({
		  icon: null,
		  content: (
			  <React.Fragment>
				  <div className="d-flex align-items-center flex-column">
					<div>
						<img src={InviteLogo} className="" alt="logo" />
					</div>
					<div className="mt-4 mb-2">
						<h3 style={{
						color: '#333',
						fontSize: 24,
						fontWeight: 800
					}}>
							Invite sent
						</h3>
					</div>
					<div style={{
						textAlign: 'center',
						padding: '0 30px',
						color: '#595959',
						fontSize: 16,
						fontWeight: 400
					}}> 
						An invitation to join the Breadcrumb community has been sent to your email ID. Please check.
					</div>
					<div className="mt-3">
						<Button 
							style={{
								height: 40,
								color: '#fff',
								background: '#0076FF',
								borderRadius: 8,
								width: 100
							}}
							type="primary"
							block
							size="large"
							onClick={
								() => confirmPopup.destroy()
							}
						>
							Okay
						</Button>
					</div>
				  </div>
			  </React.Fragment>
		  ),
		  okButtonProps: { style: { display: 'none' } },
		  cancelButtonProps: { style: { display: 'none' } },
		  centered: true
		});
	  }

	parentInviteEmail = () => {

		let mutation = SEND_PARENT_INVITE;
		mutation.variables = {
			invite: [{
				first_name: this.state.login.name,
				last_name: this.state.login.last_name,
				email: this.state.login.email,
				address: this.state.login.address,
				phone_no: `${this.state.login.country_code} ${this.state.login.phone_no}`,
				country: this.state.login.country
			}],
			// student_id: parseInt(this.props.match.params.id, 10)
		};
		axios
			.post(`${process.env.REACT_APP_API}`, mutation)
			.then(res => {
				if (res.data.errors) {
					triggerGAEvent("Signup", "Signup as Parent");

					openNotification("error", res.data.errors[0].message);
				} else {
					this.setState({
						login: {
							email: "",
							name: "",
							last_name: "",
							address: "",
							phone_no: "",
							country: "India",
							country_code: "+91"
							// status: ""
						},
						truthObj: {
							email: false,
							name: false,
							last_name: false,
							address: false,
							phone_no: false,
						}
					}, () => {
						// openNotification("success", "Invite has been sent to your email ID. Please check")
						this.showConfirm()
					})
				}
			})
			.catch(err => console.log(err));
	};

	validateStateValues = () => {
		this.setState({ isFormSubmitting: true })
		let truthObj = { ...this.state.truthObj }
		let getKeys = Object.keys(this.state.login)
		getKeys.forEach((key, index) => {
		  let getItem = this.state.login[key]
		  let required = isRequired(getItem)
		  truthObj[key] = required
		});
		let re = /\S+@\S+\.\S+/;
		truthObj['email'] = !re.test(this.state.login.email)
		setTimeout(() => this.setState({ truthObj: truthObj }), 100)
		setTimeout(() => this.onInviteBtnClick(), 500)
	}

	onInviteBtnClick = () => {
		// e.preventDefault();
		this.setState({ isSubmit: true });
		this.state.form.current.validateAll();
		let errors = _.values(this.state.truthObj)
		let includesErrors = _.includes(errors, true)
		if (!includesErrors) {
			this.parentInviteEmail();
		}
		// setTimeout(() => {
		// 	if (document.querySelectorAll(".form-error").length === 0) {
		// 		this.parentInviteEmail();
		// 	}
		// }, 1);
	};

	onChange = (key, value) => {
		triggerGAEvent("Signup", "Enter data for Login");
		const state = { ...this.state };
		state.login[key] = value;
		this.setState({ login: state.login });
	};

	showPwdClick = key => {
		triggerGAEvent("Signup", "Show password in Login");
		const state = { ...this.state };
		state.showHide[key] = !state.showHide[key];
		this.setState({ showHide: state.showHide });
	};

	updateParentData = (key, value) => {
		let login = { ...this.state.login };
		login[key] = value;
		this.setState({
			login
		});
	}

	render() {
		return (
			<div className="pt-5 pb-5">
					<div className="mbl-signup-card mbl-card-radius d-flex flex-column align-items-center justify-content-between mx-auto" style={{ width: 521 }}>
						<div className="d-flex align-items-center justify-content-between signupLogo" style={{
							padding: 20
						}}>
							<div className="p-4">
								<img src={arrowLeft} className="cursor-pointer" alt="left arrow" 
								onClick={
									() => this.props.history.goBack()
								} />
							</div>
							<div className="p-4">
								<img src={logo} className="signupLogo" alt="logo" />
							</div>
						</div>
						<div className="mb-4">
							<h3 className="max-signup-title pb-3 mb-3 FontAvenirHeavy font-18 text-center" style={{ minWidth: 350 }}>
								PARENT SIGN UP
							</h3>
							{
								this.props.history.location.state && this.props.history.location.state.is_email ? (
									<div className="max-dev-NotFoundTxt mb-3 pb-2">
										Sorry, we couldn't find this account. Create a new one!
									</div>
								) : ``
							}
							<img src={logo} className="mbl-signup-logo" alt="logo" />
							<h3 className="mbl-signup-title mb-3 FontAvenirHeavy font-20 text-center">
								SIGN UP
							</h3>
							<div className="mbl-alreadymem FontAvenirMedium font-16 m-0 mr-4 text-muted text-center mb-3">
								Already a member?
								<Link to="/">
									<span className="ml-1 font-16 FontAvenirMedium mt-3" style={{ textTransform: 'uppercase', textDecoration: 'none', fontWeight: 900 }}>
										Sign in
									</span>
								</Link>
							</div>
							<Form
								ref={this.state.form}
								className={this.state.submitted ? "formSubmitted" : ""}
							>

								<InputC
									title="First Name"
									type="text"
									placeholder="Enter Parent's First Name"
									id={this.state.truthObj["name"] ? "has-error" : ""}
									className="mbl-signup-input col-12 ant-input ant-input-lg mb-4 font-16"
									titleStyle="mb-2 FontAvenirMedium text-darkBlack font-16 requiredAsterisk"
									value={this.state.login.name}
									stateKey="name"
									validations={[]}
									onChange={(key, value) =>
										this.updateParentData(key, value)
									}
								/>
								<InputC
									title="Last Name"
									type="text"
									placeholder="Enter Parent's Last Name"
									id={this.state.truthObj["last_name"] ? "has-error" : ""}
									className="mbl-signup-input col-12 ant-input ant-input-lg mb-4 font-16"
									titleStyle="mb-2 FontAvenirMedium text-darkBlack font-16 requiredAsterisk"
									value={this.state.login.last_name}
									stateKey="last_name"
									validations={[]}
									onChange={(key, value) =>
										this.updateParentData(key, value)
									}
								/>
								<InputC
									title="Email ID"
									type="text"
									placeholder="Enter Parent's Email ID"
									id={this.state.truthObj["email"] ? "has-error" : ""}
									className="mbl-signup-input col-12 ant-input ant-input-lg mb-4 font-16"
									titleStyle="mb-2 FontAvenirMedium text-darkBlack font-16 requiredAsterisk"
									value={this.state.login.email}
									stateKey="email"
									validations={[email]}
									onChange={(key, value) =>
										this.updateParentData(key, value)
									}
								/>
								<InputC
									title="City"
									type="text"
									placeholder="Enter Parent's City"
									id={this.state.truthObj["address"] ? "has-error" : ""}
									className="mbl-signup-input col-12 ant-input ant-input-lg mb-4 font-16"
									titleStyle="mb-2 FontAvenirMedium text-darkBlack font-16 requiredAsterisk"
									value={this.state.login.address}
									stateKey="address"
									validations={[]}
									onChange={(key, value) =>
										this.updateParentData(key, value)
									}
								/>
								<div className="position-relative w-100 mb-4">
									<div className="mb-2 FontAvenirMedium text-darkBlack font-16 requiredAsterisk">
										Country
									</div>
									<div className="position-relative ">
										<Select
											defaultValue="+91~~India"
											className="w-100"
											size="large"
											value={`${this.state.login.country_code}~~${this.state.login.country}`}
											onChange={(value) => {

												let login = { ...this.state.login };
												login.country_code = value.split("~~")[0];
												login.country = value.split("~~")[1];
												this.setState({
													login
												});
											}}
										>


											{country_codes.map((code, index) => (
												<Select.Option key={index} value={`${code.dial_code}~~${code.name}`}>{`${code.name} (${code.dial_code})`}</Select.Option>
											))}
										</Select>
									</div>
								</div>
								<InputC
									title="Phone Number"
									type="number"
									size="large"
									placeholder="Enter Parent’s Phone Number"
									id={this.state.truthObj["phone_no"] ? "has-error" : ""}
									className="mbl-signup-input mb-4 font-16 w-100"
									titleStyle="mb-2 FontAvenirMedium text-darkBlack font-16 requiredAsterisk"
									value={this.state.login.phone_no}
									stateKey="phone_no"
									onChange={(key, value) => this.updateParentData(key, value)}
								/>
								<Button
									className="mbl-btn-bottom"
									type="primary"
									block
									size="large"
									onClick={this.validateStateValues}
								>
									Send Invite
								</Button>
							</Form>
							<div className="max-alreadymem FontAvenirMedium font-16 m-0 mr-4 text-muted text-center mt-3">
								Already a member?
								<Link to="/">
									<span className="ml-1 font-16 mt-3" style={{ color: '#0076FF', textDecoration: 'underline', fontWeight: 800 }}>
										Sign in
									</span>
								</Link>
							</div>
						</div>
					</div>
			</div>
		);
	}
}

export default SignUp;
