import { Avatar, Col, Input, Row, Tabs, Tag } from "antd";
import axios from "axios";
import _ from "lodash";
import React, { Component } from "react";
import CategoryCard from "../../components/Card/CategoryCard";
import Card from "../../components/Expert/Card";
import Loading from "../../components/Loading";
import Sort from "../../components/Sort";
import {
  GET_ACTIVITIES_OF_ONE_LEARNING_CENTRE,
  GET_ONE_LEARNING_CENTRE
} from "../../graphql";
import emptyStateActivity from "../../static/activity/emptystateactivity.svg";
import { triggerGAEvent } from "../../utils";

const TabPane = Tabs.TabPane;
const Search = Input.Search;

export default class LCView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      total_count: 0,
      loading: false,
      sort: "newest",
      searchText: "",
      tabActive: "1",
      user_details: {},
      interest_categories: [],
      activitiesInsideLC: [],
      librariesInsideLC: [],
      interests: []
    };
    this.onSearchFetchData = _.debounce(this.onSearchFetchData, 700);
  }

  fetchData() {
    this.setState({ loading: true });

    let query = GET_ONE_LEARNING_CENTRE;
    query.variables = {
      name: this.props.match.params.name
    };

    axios
      .post(`${process.env.REACT_APP_API}`, query)
      .then(res => {
        this.setState({
          user_details: res.data.data.getLearningCentre.user_details,
          interest_categories:
            res.data.data.getLearningCentre.interest_categories,
          loading: false,
          interests: res.data.data.getLearningCentre.interests
        });
        this.fetchActivities(res.data.data.getLearningCentre.user_details.id);
      })
      .catch(err => console.log(err));
  }

  fetchActivities = userId => {
    this.setState({ loading: true });

    let query = GET_ACTIVITIES_OF_ONE_LEARNING_CENTRE;
    query.variables = {
      userId: Number(userId),
      sort: this.state.sort,
      search: this.state.searchText
    };

    axios
      .post(`${process.env.REACT_APP_API}`, query)
      .then(res => {
        this.setState({
          total_count: res.data.data.getDashboardActivity.total_count,
          activitiesInsideLC:
            res.data.data.getDashboardActivity.activity_details,
          loading: false
        });
      })
      .catch(err => console.log(err));
  };

  componentDidMount() {
    this.fetchData();
  }

  sortChangeActivity = value => {
    triggerGAEvent(
      "View Learning centre",
      "Changing sort in Activities and Libraries"
    );
    this.setState({ sort: value }, () => {
      this.fetchActivities(this.state.user_details.id);
    });
  };

  tabChange = key => {
    triggerGAEvent(
      "View Learning centre",
      "Changing Tabs in Activities and Libraries"
    );
    this.setState({ tabActive: key });
  };

  onSearch = e => {
    triggerGAEvent(
      "View Learning centre",
      "Search in Activities and Libraries"
    );
    this.setState({ searchText: e.target.value }, () =>
      this.onSearchFetchData()
    );
  };

  onSearchFetchData = () => {
    this.fetchActivities(this.state.user_details.id);
  };

  render() {
    const {
      user_details,
      interest_categories,
      activitiesInsideLC,
      interests
    } = this.state;
    const extraAction = (
      <div>
        <React.Fragment>
          <Search
            style={{ width: 400 }}
            size="large"
            placeholder={this.state.tabActive === "1" ? "Search" : "Search"}
            onChange={this.onSearch}
            className="col-24"
          />
        </React.Fragment>
      </div>
    );
    return (
      <React.Fragment>
        <Loading is_loading={this.state.loading} />
        <div style={{ margin: "1.5rem 5rem 0rem", padding: "0.5rem" }}>
          <h4>{user_details.name}</h4>
        </div>
        <div
          className="deetsCard"
          style={{
            margin: "1.5rem 5rem 0rem",
            backgroundColor: "white",
            padding: "1rem"
          }}
        >
          <Row>
            <Col span={7} style={{ textAlign: "left" }}>
              <div style={{ padding: "0.5rem" }}>
                <Avatar
                  src={user_details.profile_image}
                  size={270}
                  shape="square"
                />
              </div>
            </Col>
            <Col span={17}>
              <div style={{ padding: "0.5rem" }}>
                <div className="mb-4 font-16">
                  <div className="text-uppercase">Learning Centre Name</div>
                  <h5>{user_details.name}</h5>
                </div>
                <div className="mb-4 font-16">
                  <div className="text-uppercase">Address</div>
                  <h5>{user_details.address}</h5>
                </div>
                <Row>
                  <Col span={9}>
                    <div className="mb-4 font-16">
                      <div className="text-uppercase">Email</div>
                      <h5>{user_details.email}</h5>
                    </div>
                  </Col>
                  <Col span={9}>
                    <div className="mb-4 font-16">
                      <div className="text-uppercase">Phone number</div>
                      <h5>{user_details.phone_no}</h5>
                    </div>
                  </Col>
                </Row>
                <div className="mb-4 font-16">
                  <div className="text-uppercase">Bio</div>
                  <h5>{user_details.bio}</h5>
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <hr style={{ margin: "1.5rem 5rem 0rem" }} />

        <div className="intCatSection" style={{ margin: "1.5rem 5rem 0rem" }}>
          <Row gutter={6}>
            <h5>Interest Categories - {interest_categories.length}</h5>
            {interest_categories.map((intCat, index) => {
              return (
                <Col span={6} key={index} style={{ padding: "1rem" }}>
                  <CategoryCard
                    title={intCat.name}
                    loading={this.state.loading}
                    image={intCat.background_image}
                  />
                </Col>
              );
            })}
          </Row>
        </div>
        <div style={{ margin: "1.5rem 5rem 0rem" }}>
          <h5>Interests Contributed - {interests.length}</h5>
        </div>
        <div
          className="intSection"
          style={{
            margin: "0rem 5rem 0rem",
            backgroundColor: "white",
            padding: "1rem",
            boxShadow: "2px 2px 9px 0px #d6d6d6"
          }}
        >
          <Row gutter={6}>
            {interests.map((int, index) => (
              <Tag className="mb-2" color="blue" key={index}>
                {int.name}
              </Tag>
            ))}
          </Row>
        </div>
        <hr style={{ margin: "1.5rem 5rem 0rem" }} />
        <div style={{ margin: "1.5rem 5rem 0rem", padding: "1rem" }}>
          <Tabs
            defaultActiveKey="1"
            tabBarExtraContent={extraAction}
            onChange={this.tabChange}
          >
            <TabPane
              tab={`Learning Content - ${this.state.total_count}`}
              key="1"
            >
              <div className="activitySection">
                {activitiesInsideLC.length > 0 && (
                  <Row gutter={6}>
                    <Sort
                      data={this.state.sort}
                      sortChange={this.sortChangeActivity}
                    />
                    {activitiesInsideLC.map((data, index) => (
                      <Col span={8} key={index} style={{ padding: "1rem" }}>
                        <Card
                          videoRefIcon={
                            data.default_activities_id === 2
                              ? data.video_link !== ""
                              : data.url !== ""
                          }
                          imgRefIcon={data.activity_reference_images}
                          stepsRefIcon={data.steps}
                          pdfRefIcon={data.pdf_url}
                          floatingContainer={true}
                          floatingContainerLeft={true}
                          title={data.name}
                          time={data.time}
                          // isLoading={props.loader}
                          difficulty={data.age_diff}
                          difficultyVisible={data.age_diff ? true : false}
                          Tag={[
                            {
                              name: data.status,
                              type:
                                data.status === "Approved"
                                  ? "success"
                                  : "danger"
                            },
                            {
                              name: data.activity_state,
                              type:
                                data.activity_state === "Active"
                                  ? "warning"
                                  : "danger"
                            },
                            {
                              name: data.is_changed
                                ? "Unpublished changes"
                                : "",
                              type: data.is_changed ? "danger" : "success"
                            }
                          ]}
                          src={
                            data.thumb_images
                              ? data.thumb_images.length
                                ? data.thumb_images[0].image_url
                                : ""
                              : ""
                          }
                        ></Card>
                      </Col>
                    ))}
                  </Row>
                )}

                {this.state.loading && (
                  <div className="row">
                    {[{}, {}, {}].map((data, index) => {
                      return (
                        <div
                          className="col-4 pointer-events-none"
                          key={`loading-${index}`}
                        >
                          <Card
                            isLoading={true}
                            videoRefIcon={
                              data.default_activities_id === 2
                                ? data.video_link !== ""
                                : data.url !== ""
                            }
                            stepsRefIcon={data.steps}
                            pdfRefIcon={data.pdf_url}
                            imgRefIcon={data.activity_reference_images}
                            floatingContainerLeft={true}
                            title={data.name}
                            time={data.time}
                            difficultyVisible={data.age_diff ? true : false}
                            difficulty={data.age_diff}
                          ></Card>
                        </div>
                      );
                    })}
                  </div>
                )}
                {activitiesInsideLC.length < 1 && !this.state.loading && (
                  <Row gutter={6} style={{ textAlign: "center" }}>
                    <img className="mb-3" src={emptyStateActivity} alt="" />
                    <p className="FontAvenirMedium font-20 mb-2">
                      Learning Contents of {user_details.name} will show up here
                      when available.
                    </p>
                  </Row>
                )}
              </div>
            </TabPane>
          </Tabs>
        </div>
      </React.Fragment>
    );
  }
}
