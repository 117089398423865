import React, {Component} from 'react';
import {Row, Col, Button} from "antd";
import Form from "react-validation/build/form";
import InputC from "../../components/InputC";
import {required} from "../../validation";
import './index.css';

class VerifyDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isSubmit: false,
			form: React.createRef(),
		}
	}
	
	handleSubmit = (e) => {
		e.preventDefault();
		this.setState({ isSubmit: true });
		this.state.form.current.validateAll();
		setTimeout(() => {
			if (document.querySelectorAll('.form-error').length === 0) {
				this.props.nextStep();
			}
		}, 1);
	};

	componentDidMount() {
		setTimeout(() => {
			this.forceUpdate();
		}, 100)
	}
	
	
	render() {
		return (
			<Form ref={this.state.form} className={this.state.isSubmit ? "formSubmitted" : ""}>
				<h3 className="pb-3 mb-3 border-bottom FontAvenirHeavy font-18 max-dev-parentSignupTitle">
					{`Hi ${this.props.data.name}, let’s setup your parent profile`}
				</h3>
				<h3 className="mb-3 FontAvenirHeavy text-center font-16 mbl-dev-parentSignupTitle">
					{`Hi ${this.props.data.name}, please verify if the given details are correct`}
				</h3>
				<Row gutter={48} className="mbl-mr-neg">
					<Col xl={12} lg={12} xs={24} sm={24}>
						<InputC
							type="text"
							title="Parent First Name"
							titleStyle="mb-3 FontAvenirMedium text-secondary font-16"
							className="col-12 ant-input ant-input-lg mb-4"
							defaultValue={this.props.data.name}
							value={this.props.data.name}
							stateKey="name"
							name="name"
							onChange={this.props.updateData}
							validations={[required]}
						/>
					</Col>
					<Col xl={12} lg={12} xs={24} sm={24}>
						<InputC
							type="text"
							title="Parent Last Name"
							titleStyle="mb-3 FontAvenirMedium text-secondary font-16"
							className="col-12 ant-input ant-input-lg mb-4"
							defaultValue={this.props.data.name}
							value={this.props.data.last_name}
							stateKey="last_name"
							name="last_name"
							onChange={this.props.updateData}
							validations={[required]}
						/>
					</Col>
				</Row>
				{/* {!this.props.data.isChild && ( */}
					<Row className="mbl-mr-neg" gutter={48} style={{ display: this.props.data.isChild ? 'none' : 'block'}}>
						<Col xl={12} lg={12} xs={24} sm={24}>
							<InputC
								type="text"
								title="Child's First Name"
								titleStyle="mb-3 FontAvenirMedium text-secondary font-16"
								className="col-12 ant-input ant-input-lg mb-4"
								value={this.props.data.student_fname}
								stateKey="student_fname"
								name="student_fname"
								onChange={this.props.updateData}
								validations={this.props.data.isChild ? [] : [required]}
							/>
						</Col>
						<Col xl={12} lg={12} xs={24} sm={24}>
							<InputC
								type="text"
								title="Child's Last Name"
								titleStyle="mb-3 FontAvenirMedium text-secondary font-16"
								className="col-12 ant-input ant-input-lg mb-4"
								value={this.props.data.student_lname}
								stateKey="student_lname"
								name="student_lname"
								onChange={this.props.updateData}
								validations={this.props.data.isChild ? [] : [required]}
							/>
						</Col>
					</Row>
				{/* )} */}
				<Row gutter={48} className="mbl-mr-neg">
					<Col xl={12} lg={12} xs={24} sm={24}>
						<InputC
							type="text"
							title="Phone Number"
							titleStyle="mb-3 FontAvenirMedium text-secondary font-16"
							className="mb-4 font-16 w-100"
							value={this.props.data.phone_no}
							stateKey="phone_no"
							name="phone_no"
							onChange={this.props.updateData}
							disabled={true}
							size="large"
						/>
					</Col>
					<Col xl={12} lg={12} xs={24} sm={24}>
						<InputC
							type="text"
							title="Country"
							titleStyle="mb-3 FontAvenirMedium text-secondary font-16"
							className="mb-4 font-16 w-100"
							value={this.props.data.country}
							stateKey="country"
							name="country"
							onChange={this.props.updateData}
							disabled={true}
							size="large"
						/>
					</Col>
				</Row>
				<Row gutter={48} className="mbl-mr-neg">
					<Col xl={12} lg={12} xs={24} sm={24}>
						<InputC
							type="text"
							title="City"
							titleStyle="mb-3 FontAvenirMedium text-secondary font-16"
							className="col-12 ant-input ant-input-lg mb-4"
							value={this.props.data.city}
							stateKey="city"
							name="city"
							onChange={this.props.updateData}
							disabled={true}
						/>
					</Col>
				</Row>
				<div className="mbl-txt-note">
					<span className="mbl-noteTxt">Please Note:</span>
					<p>If you have more than one child and wish to provide all of them access to Breadcrumb, you can add their details after you complete these steps.</p>
				</div>
				<div className="d-flex align-items-center justify-content-end max-dev-parentSignup-Btn">
					<Button
						size="large"
						type="primary"
						onClick={this.handleSubmit}
						className="pl-5 pr-5 max-dev-parentSignup-Btn"
						style={{ backgroundColor: '#3EC1F0', color: '#FFFFFF', border: 'none' }}
					>
						NEXT
					</Button>
				</div>
				<div className="mbl-dev-parentSignup-Btn">
					<Button
						size="large"
						type="primary"
						onClick={this.handleSubmit}
						style={{ width: '100%', backgroundColor: '#42C0EF', color: '#FFFFFF', border: 'none' }}
					>
						NEXT
					</Button>
				</div>
			</Form>
		);
	}
}

export default VerifyDetails;
