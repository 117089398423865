import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { Table, Icon } from 'antd';
import axios from 'axios';
import { GET_PARENTS_CREATED_LEARNERS_LIST } from './graphql';

class ParentsCreatedLearners extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_loading: true,
      table_data: [],
      page: 1,
      total: 0,
      filterInfo: null,
      sorterInfo: null,
    };
  }

  fetchData = () => {
    this.setState({ is_loading: true });
    let { sorterInfo } = this.state;
    sorterInfo = sorterInfo || { columnKey: "", order: "" };
    let query = GET_PARENTS_CREATED_LEARNERS_LIST;
    query.variables = {
      page: this.state.page,
      isParent: true,
      isStudent: true,
      filter: {
        parent_name: sorterInfo.columnKey === "name" ? sorterInfo.order === "ascend" ? "ASC" : "DESC" : "",
      }
    };
    axios
      .post(`${process.env.REACT_APP_API}`, query)
      .then(res => {
        this.setState({
          table_data: res.data.data.getParentSignedupReports.parents_signedup_result,
          total: res.data.data.getParentSignedupReports.totalPages,
          is_loading: false
        })
      })
      .catch(err => console.log(err));
  };

  componentDidMount() {
    this.fetchData();
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.setState({
      sorterInfo: sorter,
      filterInfo: filters
    }, () => this.fetchData());
  };

  render() {
    let sorterInfo = this.state.sorterInfo || {};
    const columns = [
      {
        title: "PARENT NAME",
        dataIndex: "name",
        sorter: true,
        sortOrder: sorterInfo.columnKey === "name" && sorterInfo.order,
        width: '12%',
        render: (text, record, index) => (
          <div
            className="cursor-pointer"
            onClick={() => this.props.history.push(`/parents/${record.status}/${record.email}`)}
          >
            {`${record.name || ""} ${record.last_name || ""}`}
          </div>
        ),
      },
      {
        title: "CHILD NAME",
        dataIndex: "cild_name",
        width: '12%',
        render: (text, record, index) => record.students.map((student, index) => (
          <div
            className="cursor-pointer"
            onClick={() => this.props.history.push(`/learners/${student.id}`)}
          >
            {`${student.fname} ${student.lname}`}
          </div>
        )),
      },
      {
        title: "USERNAME",
        dataIndex: "username",
        width: '10%',
        render: (text, record, index) => record.students.map((student, index) => (
          <div key={index}>{`${student.username}`}</div>
        )),
      },
      {
        title: "DOB",
        dataIndex: "dob",
        width: '10%',
        render: (text, record, index) => record.students.map((student, index) => (
          <div key={index}>{`${student.dob}`}</div>
        )),
      },
      {
        title: "COUNTRY",
        dataIndex: "country",
        width: '8%',
      },
      {
        title: "CITY",
        dataIndex: "city",
        width: '8%',
      },
      {
        title: "EMAIL",
        dataIndex: "email",
        width: '18%',
      },
      {
        title: "MOBILE",
        dataIndex: "phone_no",
        width: '10%',
      },
      {
        title: "MODE OF SIGN UP",
        dataIndex: "signupType",
        width: '12%',
      },
    ];
    return (
      <div className="mt-4 p-4">
        <div className="d-flex align-items-center justify-content-between mb-4">
          <div className="d-flex align-items-center justify-content-start">
            <Icon
              type="arrow-left"
              style={{ color: '#0076FF', cursor: 'pointer', fontSize: 25 }}
              onClick={() => this.props.history.push(`/reports`)}
            />
            <h5 className="m-0 ml-4">Number of parents created learner profile - {this.state.total}</h5>
          </div>
        </div>
        <div>
          <Table
            rowClassName="bg-white"
            loading={this.state.is_loading}
            columns={columns}
            dataSource={this.state.table_data}
            onChange={this.handleTableChange}
            rowKey={record => record.students_id}
            pagination={{
              current: this.state.page,
              total: this.state.total,
              onChange: (page, pageSize) => this.setState({ page: page }, () => this.fetchData()),
            }}
          />
        </div>
      </div>
    )
  }
}

export default withRouter(ParentsCreatedLearners);
