import {
  Button,
  Dropdown,
  Icon,
  Input,
  Menu,
  message,
  Modal,
  Select,
  Table,
  Tabs,
  Tag,
  Pagination
} from "antd";
import axios from "axios";
import jwtDecode from "jwt-decode";
import _ from "lodash";
import React from "react";
import validator from "validator";
import ButtonC from "../../components/ButtonC";
import DashboardCard from "../../components/Card/DashboardCard";
import Loading from "../../components/Loading";
import Radar from "../../components/RadarChart";
import Legend from "../../components/RadarChart/chartLegend";
import Sort from "../../components/Sort";
import {
  ADD_LEARNING_CENTER,
  GET_ALL_LEARNING_CENTRE,
  GET_LC_CHART_DATA,
  LC_DASHBOARD
} from "../../graphql";
import { openNotification, triggerGAEvent } from "../../utils";

const TabPane = Tabs.TabPane;
const Search = Input.Search;

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_loading: true,
      is_loadingChart: true,
      is_loadingLC: false,
      is_loadingTable: false,
      is_loadingLibraries: false,
      modalVisible: false,
      lcSortedInfo: null,
      curatorSortedInfo: null,
      curatorFilteredInfo: null,
      tabActive: "1",
      activeKey: "1",
      role_id: "",
      selected_role_id: 2,
      user_id: "",
      token: "",
      searchText: "",
      page: 1,
      totalCount: 0,
      total: 0,
      sort: "newest",
      learning_centers: [],
      content_creators: [],
      selectedLc: "",
      activities: [],
      libraries: [],
      lcEmail: "",
      sortedInfo: {
        columnKey: "students_count",
        field: "students_count",
        order: "descend"
      },
      chartData: {
        BloomsTaxonomy: {
          data: [],
          captions: {},
          legend: []
          // legend: [
          //   " R : Remember",
          //   " R-U : Remember - Understand",
          //   " R-U-A : Remember - Understand - Apply",
          //   " R-U-A-A : Remember - Understand - Apply - Analyse",
          //   " R-U-A-A-E : Remember - Understand - Apply - Analyse - Evaluate",
          //   " R-U-A-A-E-C : Remember - Understand - Apply - Analyse - Evaluate - Create"
          // ]
        },
        MultipleIntellegences: {
          data: [],
          captions: {},
          legend: [
            "Linguistic intelligence : Word Smart",
            "Logical-mathematical intelligence : Number/Reasoning Smart",
            "Spatial intelligence : Picture Smart",
            "Bodily-Kinesthetic Intelligence : Body Smart",
            "Musical Intelligence - Music Smart",
            "Interpersonal Intelligence : People Smart",
            "Intrapersonal Intelligence : Self Smart",
            "Naturalist Intelligence : Nature Smart"
          ]
        },
        CenturyLearningAndInnovationSkills: {
          data: [],
          captions: {},
          legend: [
            "Critical Thinking & Problem Solving",
            "Communication",
            "Collabration",
            "Creativity & Innovation"
          ]
        }
      }
    };
    this.onSearchFetchData = _.debounce(this.onSearchFetchData, 700);
  }

  fetchChartData = () => {
    this.setState({ is_loadingChart: true });
    let query = GET_LC_CHART_DATA;
    query.variables = {
      lc_id:
        this.state.role_id === 2 ? this.state.user_id : this.state.selectedLc
    };
    axios
      .post(`${process.env.REACT_APP_API}`, query)
      .then(res => {
        const data = JSON.parse(res.data.data.getDataForGraph.data);
        data.CenturyLearningAndInnovationSkills.data.forEach(
          (points, index) => {
            for (var obj in points.data) {
              points.data[obj] = isNaN(
                points.data[obj] /
                  data.CenturyLearningAndInnovationSkills.max_value
              )
                ? 0
                : points.data[obj] /
                  data.CenturyLearningAndInnovationSkills.max_value;
            }
            data.BloomsTaxonomy.legend = this.state.chartData.BloomsTaxonomy.legend;
            data.MultipleIntellegences.legend = this.state.chartData.MultipleIntellegences.legend;
            data.CenturyLearningAndInnovationSkills.legend = this.state.chartData.CenturyLearningAndInnovationSkills.legend;
          }
        );
        data.MultipleIntellegences.data.forEach((points, index) => {
          for (var obj in points.data) {
            points.data[obj] =
              points.data[obj] / data.MultipleIntellegences.max_value;
          }
        });
        data.BloomsTaxonomy.data.forEach((points, index) => {
          for (var obj in points.data) {
            points.data[obj] = points.data[obj] / data.BloomsTaxonomy.max_value;
          }
        });
        this.setState({ chartData: data, is_loadingChart: false });
      })
      .catch(err => {
        console.log(err);
        this.setState({ is_loadingChart: false });
      });
  };

  fetchAllLc = () => {
    this.setState({ is_loadingTable: true });
    let { curatorSortedInfo, curatorFilteredInfo } = this.state;
    curatorSortedInfo = curatorSortedInfo || { columnKey: "", order: "" };
    curatorFilteredInfo = curatorFilteredInfo || { role_id: [] };
    let query = GET_ALL_LEARNING_CENTRE;
    query.variables = {
      search: this.state.searchText,
      page: this.state.page,
      filter: {
        name: curatorSortedInfo.columnKey === "name" ? curatorSortedInfo.order === "ascend" ? "ASC" : "DESC" : "",
        contents: curatorSortedInfo.columnKey === "cards_count" ? curatorSortedInfo.order === "ascend" ? "ASC" : "DESC" : "",
        coll_ply: curatorSortedInfo.columnKey === "coll_plycount" ? curatorSortedInfo.order === "ascend" ? "ASC" : "DESC" : "",
        creatorType: curatorFilteredInfo.role_id || []
      },
    };
    axios
      .post(`${process.env.REACT_APP_API}`, query)
      .then(res => {
        this.setState({
          learning_centers: res.data.data.getDashboardLearningCentreV2.learningCenterDetails,
          content_creators: res.data.data.getDashboardContentCreator.contentCreatorDetails.contentCreator_details,
          total: res.data.data.getDashboardContentCreator.contentCreatorDetails.totalPages,
          selectedLc: res.data.data.getDashboardLearningCentreV2.learningCenterDetails[0].id
        }, () => {
          this.fetchChartData();
          this.setState({ is_loadingTable: false });
        });
      })
      .catch(err => {
        console.log(err);
        this.setState({ is_loadingTable: false });
      });
  };

  fetchLcActivitiesLibraries = () => {
    this.setState({ is_loadingLibraries: true });

    const query = LC_DASHBOARD;
    query.variables = {
      sort: this.state.sort,
      userId: this.state.user_id,
      search: this.state.searchText,
      page: this.state.page
    };
    axios
      .post(`${process.env.REACT_APP_API}`, query)
      .then(res => {
        this.setState(
          {
            totalCount: res.data.data.getDashboardActivity.totalCount,
            activities: res.data.data.getDashboardActivity.activity_details,
            libraries: []
            // libraries: res.data.data.getDashboardLibrary.library_details
          },
          () => {
            this.setState({ is_loadingLibraries: false });
          }
        );
      })
      .catch(err => {
        console.log(err);
        this.setState({ is_loadingLibraries: false });
      });
  };

  onPageChange = (page) => {
    this.setState({
      page
    }, () => {
      this.fetchLcActivitiesLibraries();
    })
  }

  onSendLcInvite = () => {
    if (this.state.lcEmail === "") {
      message.error("Enter an email-ID to send an invite.");
    } else if (validator.isEmail(this.state.lcEmail)) {
      let mutation = ADD_LEARNING_CENTER;
      mutation.variables = {
        email: this.state.lcEmail,
        role_id: this.state.selected_role_id
      };
      axios
        .post(`${process.env.REACT_APP_API}`, mutation)
        .then(res => {
          if (res.data.errors) {
            message.error(res.data.errors[0].message);
          } else {
            this.setState({ modalVisible: false, lcEmail: "" });
            openNotification("success", "Invite Sent Successfully");
          }
        })
        .catch(err => console.log(err));
    } else {
      message.error("Enter an valid email-ID");
    }
  };

  getLocalStorage = () => {
    this.setState(
      {
        role_id: Number(localStorage.getItem("role_id")),
        token: localStorage.getItem("token")
      },
      () => this.fetchData()
    );
  };

  getJWTPayloadData = () => {
    let jwt = jwtDecode(this.state.token);
    this.setState(
      {
        user_id: Number(jwt.id)
      },
      () => {
        this.fetchChartData();
        this.fetchLcActivitiesLibraries();
      }
    );
  };

  fetchData = () => {
    if (this.state.role_id === 1) {
      this.fetchAllLc();
    } else {
      this.getJWTPayloadData();
    }
  };

  componentDidMount() {
    this.getLocalStorage();
  }

  onSearchFetchData = () => {
    if (this.state.role_id === 1) {
      triggerGAEvent("Dashboard", "Searching in LC Table");
      this.setState({
        page: 1,
        total: 0
      }, () => this.fetchAllLc())
    } else {
      triggerGAEvent("Dashboard", "Searching in Activities and Libraries");
      this.fetchLcActivitiesLibraries();
    }
  };

  onSearch = e => {
    this.setState({ searchText: e.target.value }, () => {
      // _.debounce(this.onSearchFetchData, 1000);
      this.onSearchFetchData();
    });
  };

  onSelectChange = e => {
    triggerGAEvent("Dashboard", "Changing LC in Chart");
    this.setState({ selectedLc: e }, () => this.fetchChartData());
  };

  sortChange = value => {
    triggerGAEvent("Dashboard", "Changing sort in Activities and Libraries");
    this.setState({ sort: value }, () => {
      this.fetchLcActivitiesLibraries();
    });
  };

  handleLcTableChange = (pagination, filters, sorter) => {
    triggerGAEvent("Dashboard", "Changing sort in LC Table");
    this.setState({ lcSortedInfo: sorter });
  };
  
  handleCuratorTableChange = (pagination, filters, sorter) => {
    triggerGAEvent("Dashboard", "Changing sort & filters in Curators Table");
    this.setState({ 
      curatorSortedInfo: sorter, 
      curatorFilteredInfo: filters 
    }, () => this.fetchAllLc());
  };
  
  handleTabPage = (activeKey) => {
    triggerGAEvent("Dashboard", "Changing LC and Curator Tabs");
    this.setState({ activeKey, searchText: "", page: 1, total: 0 }, () => this.fetchAllLc());
  };

  tabChange = key => {
    triggerGAEvent("Dashboard", "Changing Tab for Activities and Libraries");
    this.setState({ tabActive: key });
  };

  viewDetailsActivity = index => {
    triggerGAEvent("Dashboard", "View Details for Activities");
    const state = { ...this.state };
    state.activities[index].is_details = !state.activities[index].is_details;
    this.setState({ activities: state.activities });
  };

  viewDetailsLibrary = index => {
    triggerGAEvent("Dashboard", "View Details for Libraries");
    const state = { ...this.state };
    state.libraries[index].is_details = !state.libraries[index].is_details;
    this.setState({ libraries: state.libraries });
  };

  handleCancel = () => {
    this.setState({ modalVisible: false, lcEmail: "" });
  };

  onInputChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  intFormatter = (text, record, index) => {
    if (record.interest_categories.length > 3) {
      return (
        <React.Fragment>
          <Tag className="mb-2">{record.interest_categories[0].name}</Tag>
          <Tag className="mb-2">{record.interest_categories[1].name}</Tag>
          <Tag>{record.interest_categories[2].name}</Tag>
          <Tag>{record.interest_categories.length - 3} More..</Tag>
        </React.Fragment>
      );
    } else {
      return record.interest_categories.map((intCat, index) => {
        return <Tag key={index}>{intCat.name}</Tag>;
      });
    }
  };

  render() {
    let lcSortedInfo = this.state.lcSortedInfo || {};
    let curatorSortedInfo = this.state.curatorSortedInfo || {};
    let curatorFilteredInfo = this.state.curatorFilteredInfo || {};
    const createExpertMenu = (
      <Menu>
        <Menu.Item
          onClick={() => {
            this.setState({
              modalVisible: true,
              lcEmail: "",
              selected_role_id: 2
            }); // this.props.history.replace(`/learningcontent/do/create`)
          }}
          id="create-learningContent"
        >
          Learning centre
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            this.setState({
              modalVisible: true,
              lcEmail: "",
              selected_role_id: 4
            });
            // this.props.history.replace(`/learningcontent/read/create`)
          }}
          id="create-expert"
        >
          Expert
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            this.setState({
              modalVisible: true,
              lcEmail: "",
              selected_role_id: 5
            });
            // this.props.history.replace(`/learningcontent/watch/create`)
          }}
          id="create-curator"
        >
          Curator
        </Menu.Item>
      </Menu>
    );
    const newColumns = [
      {
        title: "Learning centre Name",
        dataIndex: "name",
        sorter: (a, b) => a.name.localeCompare(b.name),
        sortOrder: lcSortedInfo.columnKey === "name" && lcSortedInfo.order,
        width: "30%"
      },
      {
        title: "number of Learners",
        dataIndex: "students_count",
        sorter: (a, b) => a.students_count - b.students_count,
        sortOrder: lcSortedInfo.columnKey === "students_count" && lcSortedInfo.order,
        defaultSortOrder: "descend",
        width: "27%"
      },
      {
        title: "Interest Categories",
        dataIndex: "interest_categories",
        render: this.intFormatter,
        width: "53%"
      }
    ];
    const ContentCreatorsColumns = [
      {
        title: "Name",
        dataIndex: "name",
        sorter: (a, b) => a.name.localeCompare(b.name),
        sortOrder: curatorSortedInfo.columnKey === "name" && curatorSortedInfo.order,
        width: "25%"
      },
      {
        title: "Creator type",
        dataIndex: "role_id",
        render: (text, record, index) => text === 4 ? `Expert` : `Curator`,
        filters: [
          { value: 4, text: "Expert" },
          { value: 5, text: "Curator" },
        ],
        onFilter: (value, record) => record.role_id && record.role_id === value,
        filteredValue: curatorFilteredInfo.role_id || null,
        width: "25%"
      },
      {
        title: "Number of Learning Contents",
        dataIndex: "cards_count",
        sorter: (a, b) => a.cards_count - b.cards_count,
        sortOrder: curatorSortedInfo.columnKey === "cards_count" && curatorSortedInfo.order,
        defaultSortOrder: "descend",
        width: "25%"
      },
      {
        title: "Number of Collections / Learning Paths",
        dataIndex: "coll_plycount",
        sorter: (a, b) => a.coll_plycount - b.coll_plycount,
        sortOrder: curatorSortedInfo.columnKey === "coll_plycount" && curatorSortedInfo.order,
        defaultSortOrder: "descend",
        width: "25%"
      }
    ];

    const selectLcAction = this.state.role_id === 1 && (
      <React.Fragment>
        <label>Select a Learning Centre</label>
        <Select
          size="large"
          value={this.state.selectedLc}
          onChange={this.onSelectChange}
          style={{ width: 400, marginLeft: 10 }}
        >
          {this.state.learning_centers.map((data, i) => (
            <Select.Option size="large" key={i} value={data.id}>
              {data.name}
            </Select.Option>
          ))}
        </Select>
      </React.Fragment>
    );

    const allLcAction = (
      <React.Fragment>
        <Search
          placeholder={`Search`}
          style={{ width: 400 }}
          onChange={this.onSearch}
          value={this.state.searchText}
          size="large"
          className="mr-3"
        />
        <Dropdown overlay={createExpertMenu}>
          <Button type="primary" size={"large"}>
            Add New
            <Icon type="down" style={{ verticalAlign: "middle" }} />
          </Button>
        </Dropdown>
      </React.Fragment>
    );

    return (
      <React.Fragment>
        <Loading
          is_loading={this.state.is_loadingLC || this.state.is_loadingLibraries}
        />
        {/* <div className="col-3">

          <Card
            videoRefIcon={true}
            imgRefIcon={true}
            stepsRefIcon={true}
            pdfIcon={true}
            floatingContainerLeft={true}
            src="http://altius-testing-images.s3-website.ap-south-1.amazonaws.com/341x192/2JH3BTbKx5batman2.jpg"
          ></Card>
        </div> */}
        <div className="ml-5 mr-5 ml-5 mt-4 dashboard">
          <Tabs
            defaultActiveKey="1"
            tabBarExtraContent={selectLcAction}
            onChange={() =>
              triggerGAEvent("Dashboard", "Changing Tab in charts")
            }
          >
            <TabPane
              className="position-relative"
              tab="Blooms Taxonomy"
              key="1"
            >
              <Loading
                absolute
                is_loading={
                  this.state.is_loadingLC || this.state.is_loadingLibraries
                    ? false
                    : this.state.is_loadingChart
                }
              />
              <div className="row m-0">
                <div className="col-6 bg-white">
                  <Radar
                    data={this.state.chartData.BloomsTaxonomy.data}
                    captions={this.state.chartData.BloomsTaxonomy.captions}
                  />
                </div>
                <div className="col-6 bg-white">
                  <Legend
                    data={this.state.chartData.BloomsTaxonomy.legend}
                    studentCount={this.state.chartData.student_count}
                  />
                </div>
              </div>
            </TabPane>
            <TabPane
              className="position-relative"
              tab="Multiple Intelligences"
              key="2"
            >
              <Loading
                absolute
                is_loading={
                  this.state.is_loadingLC || this.state.is_loadingLibraries
                    ? "false"
                    : this.state.is_loadingChart
                }
              />
              <div className="row m-0">
                <div className="col-6 bg-white">
                  <Radar
                    data={this.state.chartData.MultipleIntellegences.data}
                    captions={
                      this.state.chartData.MultipleIntellegences.captions
                    }
                  />
                </div>
                <div className="col-6 bg-white">
                  <Legend
                    data={this.state.chartData.MultipleIntellegences.legend}
                    studentCount={this.state.chartData.student_count}
                  />
                </div>
              </div>
            </TabPane>
            <TabPane
              tab="21st Century Learning Skills"
              key="3"
              className="position-relative"
            >
              <Loading
                absolute
                is_loading={
                  this.state.is_loadingLC || this.state.is_loadingLibraries
                    ? false
                    : this.state.is_loadingChart
                }
              />
              <div className="row m-0">
                <div className="col-6 bg-white">
                  <Radar
                    data={
                      this.state.chartData.CenturyLearningAndInnovationSkills
                        .data
                    }
                    captions={
                      this.state.chartData.CenturyLearningAndInnovationSkills
                        .captions
                    }
                  />
                </div>
                <div className="col-6 bg-white">
                  <Legend
                    data={
                      this.state.chartData.CenturyLearningAndInnovationSkills
                        .legend
                    }
                    studentCount={this.state.chartData.student_count}
                  />
                </div>
              </div>
            </TabPane>
          </Tabs>
          {this.state.role_id === 1 && (
            <div className="pt-4 mb-4">
              <Tabs
                defaultActiveKey="1"
                activeKey={this.state.activeKey}
                tabBarExtraContent={allLcAction}
                onChange={this.handleTabPage}
              >
                <TabPane tab="Learning Centres" key="1">
                  <Table
                    className="table-full"
                    loading={this.state.is_loadingTable}
                    columns={newColumns}
                    rowKey={lc => lc.id}
                    dataSource={this.state.learning_centers}
                    onChange={this.handleLcTableChange}
                    rowClassName="table-row"
                    onRow={(record, rowIndex) => ({
                      onClick: e =>
                        this.props.history.push(`/lc/${record.name}`)
                    })}
                  />
                </TabPane>
                <TabPane tab="Content Creators" key="2">
                  <Table
                    className="table-full"
                    loading={this.state.is_loadingTable}
                    columns={ContentCreatorsColumns}
                    rowKey={creator => creator.id}
                    dataSource={this.state.content_creators}
                    onChange={this.handleCuratorTableChange}
                    rowClassName="table-row"
                    pagination={{
                      current: this.state.page,
                      total: this.state.total,
                      onChange: (page, pageSize) => this.setState({ page: page }, () => this.fetchAllLc()),
                    }}
                  />
                </TabPane>
              </Tabs>
            </div>
          )}
          {this.state.role_id === 2 && (
            <div className="pt-4 mb-4">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h3 className="FontAvenirHeavy font-18">
                  {`Contents ${
                    this.state.activities.length
                      ? `- ${this.state.totalCount}`
                      : ""
                  }`}
                </h3>
                <Search
                  placeholder="Search"
                  value={this.state.searchText}
                  style={{ width: 400 }}
                  onChange={this.onSearch}
                  size="large"
                />
              </div>
              <Sort data={this.state.sort} sortChange={this.sortChange} />
              <div className="mt-4">
                <div className="row">
                  {!this.state.is_loadingLC &&
                    !this.state.is_loadingLibraries && (
                      <React.Fragment>
                        {this.state.activities.length === 0 && (
                          <div className="col-12">No Contents available</div>
                        )}
                      </React.Fragment>
                    )}
                  {this.state.activities.map((data, index) => (
                    <div key={index} className="col-4 mb-4">
                      <DashboardCard
                        name={data.name}
                        img={
                          data.thumb_images.length
                            ? data.thumb_images[0].image_url
                            : ""
                        }
                        interests={data.interests ? data.interests : []}
                        imgRefIcon={data.activity_reference_images}
                        videoRefIcon={data.url}
                        stepsRefIcon={data.steps}
                        pdfRefIcon={data.pdf_url}
                        insights_on_activity={data.insights_on_activity}
                        viewDetails={() => this.viewDetailsActivity(index)}
                        is_details={data.is_details}
                      />
                    </div>
                  ))}
                  {(this.state.activities && this.state.activities.length !== 0) && (
                          <div className="d-flex align-items-center justify-content-center col-12 mb-4">
                            <Pagination 
                            size="Pagination"
                            defaultCurrent={1} 
                            current={this.state.page} 
                            onChange={this.onPageChange} 
                            total={this.state.totalCount} />
                          </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        <Modal
          title={`Add a new ${
            this.state.selected_role_id === 2
              ? "Learning centre"
              : this.state.selected_role_id === 4
              ? "Expert"
              : "Curator"
          }`}
          visible={this.state.modalVisible}
          onCancel={this.handleCancel}
          maskClosable={false}
          footer={[
            <ButtonC
              key="back"
              size="large"
              category="Dashboard"
              action={`Close Modal for Invite ${
                this.state.selected_role_id === 2
                  ? "Learning centre"
                  : this.state.selected_role_id === 4
                  ? "Expert"
                  : "Curator"
              }`}
              onClick={this.handleCancel}
            >
              Cancel
            </ButtonC>,
            <ButtonC
              key="submit"
              type="primary"
              size="large"
              category="Dashboard"
              action={`Invite ${
                this.state.selected_role_id === 2
                  ? "Learning centre"
                  : this.state.selected_role_id === 4
                  ? "Expert"
                  : "Curator"
              }`}
              onClick={this.onSendLcInvite}
            >
              Send Invite
            </ButtonC>
          ]}
        >
          <label className="FontAvenirRoman text-secondary text-capitalize font16">
            {`Enter Email ID of ${
              this.state.selected_role_id === 2
                ? "Learning centre"
                : this.state.selected_role_id === 4
                ? "Expert"
                : "Curator"
            }`}
          </label>
          <Input
            size="large"
            name="lcEmail"
            value={this.state.lcEmail}
            onChange={this.onInputChange}
            placeholder="Enter an email ID to send Invite"
          />
        </Modal>
      </React.Fragment>
    );
  }
}

export default Dashboard;
