// import gql from "graphql-tag";

export const GET_DATA_FOR_SELECTION = {
  query: `
    query getDataForSelection($filter: ID!) {
      getAllActivity(filter: $filter) {
        activity_details {
          id
          name
          status
        }
      }
      getAllLibrary(filter: $filter) {
        library_details {
          id
          name
          status
        }
      }
    }
  `,
};

export const GET_ALL_ACTIVITY_COLLECTION = {
  query: `
    query getAllCollecion($sort:String!, $search:String!, $filter:[FilterInputType]){
      getAllCollection(sort:$sort, search: $search, filter: $filter){
        inprogress_collection{
          id
          name
          is_publish
        }
        published_collection {
          id
          name
          is_publish
        }
      }
    }
  `,
};

export const GET_ALL_LIBRARY_COLLECTION = {
  query: `
    query getAllLibraryCollection($filter:[FilterInputType]) {
      getAllLibraryCollection(filter: $filter){
        inprogress_libcollection {
          id
          name
          is_publish
          default_libraries_id
        }
        published_libcollection {
          id
          name
          is_publish
          default_libraries_id
        }
      }
    }
  `,
};

export const GET_ACTIVITY = {
  query: `
    query getAllActivity($collections_id: ID!, $filter: [FilterInputType]){
      getAllActivity( collections_id:$collections_id, filter: $filter)   {
        activity_details {
          name
          status
          id
          activity_state
        }
        collection_details{
          id
          name
          is_publish
        }
      }
    }
  `,
};

export const GET_LIBRARY = {
  query: `
    query getAllLibrary($libcollections_id: ID!, $filter: [FilterInputType]){
     getAllLibrary( libcollections_id:$libcollections_id, filter: $filter)   {
       library_details {
        id,
        default_libraries_id
        name,
        status,
      }
    }
  }
`,
};

export const GET_ASSOCIATED_DATA = {
  query: `
    query getListLibIntrest($array:[arrayInputtype]){
      getListLibIntrest(array:$array)   {
        lib_act{
          id,
          name,
          order,
          type,
          status,
          age_group{
            age,
            id
          }
          thumb_image{
            image_url
          }
        }
        intrest {
          id
          name
        }
        data_point {
          id
          name
        }
      }
    }
  `,
};

export const GET_AGE_DATA = {
  query: `
    query getAge{
      getAge{
        age_groups {
          id
          age
          is_active
        }
      }
    }
  `,
};
export const CREATE_COLLECTION = {
  query: `
    mutation PostCollectionPlayList($title: String!, $description: String!, $source_text: String!, $source_link: String!, $age_group: [ActivityAgeGroupType], $array: [arrayInputtype], $background_image: String, $is_play_list: Boolean, $completion_msg: String) {
      PostCollectionPlayList(title: $title, description: $description, source_text: $source_text, source_link: $source_link, age_group: $age_group, array: $array, is_play_list: $is_play_list, background_image: $background_image, completion_msg: $completion_msg) {
        id
      }
    }
  `,
};
export const EDIT_COLLECTION = {
  query: `
    mutation EditCollectionPlaylist($title: String!, $description: String!, $source_text: String!, $source_link: String!, $age_group: [ActivityAgeGroupType], $array: [arrayInputtype], $background_image:String, $is_play_list:Boolean, $id:ID,$change_to_play_list:Boolean, $change_to_collection:Boolean, $completion_msg: String) {
      EditCollectionPlaylist(title: $title, description: $description, source_text: $source_text, source_link:$source_link, age_group: $age_group, array: $array, is_play_list:$is_play_list, background_image:$background_image, id:$id, change_to_play_list:$change_to_play_list, change_to_collection:$change_to_collection, completion_msg: $completion_msg) {
        id
      }
    }
  `,
};
export const CREATE_PLAYLIST = {
  query: `
    mutation addPlaylist($title: String!, $source_text: String!, $source_link: String!, $age_group: [ActivityAgeGroupType], $array: [arrayInputtype] ) {
      addPlayList(title: $title, description: $description, source_text: $source_text, source_link:$source_link, age_group: $age_group, array: $array ) {
        id
        title
        source_link
        source_text
      }
    }
  `,
};

export const EDIT_PLAYLIST = {
  query: `
    mutation editPlaylist($title: String!, $source_text: String!, $source_link: String!, $age_group: [ActivityAgeGroupType], $array: [arrayInputtype], $id: ID! ) {
      editPlaylist(title: $title, source_text: $source_text, source_link:$source_link, age_group: $age_group, array: $array, id: $id ) {
        id
        title
        source_link
        source_text
      }
    }
  `,
};

export const GET_ALL_PLAYLIST = {
  query: `
    query getAllPlaylist($interest_ids: [Int], $sort: String!, $search : String!) {
      getAllPlaylist (interest_ids: $interest_ids, sort: $sort, search: $search){
        playlists {
          id
          user_id
          title
          source_text
          source_link
          color_code
          array {
            lib_act {
              id
              name
              type
              order
            }
            intrest {
              id
              name
            }
          }
        }
        unpublised {
          id
          user_id
          title
          source_text
          source_link
          color_code
          array {
            lib_act {
              id
              name
              type
              order
            }
            intrest {
              id
              name
            }
          }
        }
        archive {
          id
          user_id
          title
          source_text
          source_link
          color_code
          array {
            lib_act {
              id
              name
              type
              order
            }
            intrest {
              id
              name
            }
          }
        }
      }
    }
  `,
};
export const ADD_EXPERT_CONTENT_TO_COLLECTION = {
  query: `
  query AddExpertCollectioPlaylist($card_id:Int,$card_type:String){
    AddExpertCollectioPlaylist(card_id:$card_id,card_type:$card_type){
      id
    }
  }
`,
};
export const REMOVE_EXPERT_CONTENT_TO_COLLECTION = {
  query: `
  query RemoveExpertCollectionPlaylist($card_id:Int,$card_type:String){
    RemoveExpertCollectionPlaylist(card_id:$card_id,card_type:$card_type){
      id
    }
  }
`,
};
export const GET_EXPERT_JOURNAL = {
  query: `
    query GetExpertJournal($card_id:Int,$students_id:Int){
      GetExpertJournal(card_id:$card_id,students_id:$students_id){
        student_id
        student_name
        lc_name
        overall_journal_list
        {
          lc_name
          date
          card_details{
            id
            name

            card_id
            playlist_name
            image
            notes
            activity_status
            library_status
            default_activities_id
            resumed_time
            time_spent
            total_time_spent
            time
            url
            steps
            video_link
            video_view
            steps_view
            pictures { id image type notes disp_time }
            journal_state
            journal_image_notes_state
            disp_time
            journal_time
            lc_name
            feedback_state
            feedback_desc
            feedback_voice
            type
            student_response

          }
        }
      }
    }
  `,
};
export const GET_ONE_CONTENT_FEEDBACK = {
  query: `
    query CompletedStudent($card_id:ID){
      CompletedStudent(card_id:$card_id){
        card_name
        students{
          id
          fname
          lname
          lc_name
          dob
          age_group
          attendance
          username
          updatedAt
          is_feedback
          time_spend
          completed_date
        }
      }
    }
  `,
};
export const GET_ALL_FEEDBACKS = {
  query: `
  query CompleteCardsList($sort:String,$search:String){
    CompleteCardsList(sort:$sort,search:$search){
      activity_details {
        name
        status
        id
        activity_state
        completed_student_count
        default_activities_id
        thumb_images{
          id
          image_url
        }
        difficulty,
          user_activity_type,
          time,
          activity_reference_images,
          url,
          pdf_url,
          steps,
          id,
          activities_draft_id,

          user_name,
          activity_state,
          status,
          is_draft,
          is_changed,
          activities_draft_state
        }
      }
    }
  `,
};
export const GET_ONE_COLLECTION = {
  query: `
    query GetOneCollection($id: ID!) {
      GetOneCollection(id: $id) {
        id
        user_id
        current_user
        user{
          id
          name
        }
        is_added
        is_content_creater
        title
        description
        completion_msg
        source_text
        source_link
        is_archive
        is_publish
        background_image
        age_group {
          id
          age
        }
        array {
          lib_act {
            id
            name
            type
            order
            status
            activity_state
            steps
            default_activities_id
            video_link
            activity_reference_images
            url
            pdf_url
            time
            thumb_image{
              image_url
            }
            age_group {
              id
              age
            }
            age_groups {
              age_group{
                id,
                age,
                is_active
              }
              age_group_dificulty{
                id,
                dificulty,
                is_active
              }
            }
          }
          intrest {
            id
            name
          }
          data_point {
            id
            name
          }
        }
      }
    }
  `,
};
export const GET_ONE_PLAYLIST = {
  query: `
    query getOnePlaylist($id: ID!) {
      getOnePlaylist(id: $id) {
        id
        user_id
        current_user
        user{
          name
          id
        }
        is_added
        is_content_creater
        title
        description
        completion_msg
        source_text
        source_link
        is_archive
        is_publish
        background_image

        age_group {
          id
          age
        }
        array {
          lib_act {
            id
            name
            type
            order
            status
            activity_state
            steps
            default_activities_id
            video_link
            activity_reference_images
            url
            pdf_url
            time
            thumb_image{
              image_url
            }
            age_group {
              id
              age
            }
            age_groups {
              age_group{
                id,
                age,
                is_active
              }
              age_group_dificulty{
                id,
                dificulty,
                is_active
              }
            }
          }
          intrest {
            id
            name
          }
          data_point {
            id
            name
          }
        }
      }
    }
  `,
};

export const PUBLISH_COLLECTION = {
  query: `
    mutation publishCollection($collections_id: ID!) {
      publishCollection(collections_id: $collections_id) {
        total_collection{
          count
        }
        collection_details{
          id
        }
      }
    }
  `,
};
export const PUBLISH_PLAYLIST = {
  query: `
    mutation publishPlaylist($p_id: Int!) {
      publishPlaylist(p_id: $p_id) {
        status
        message
      }
    }
  `,
};

export const UNPUBLISH_PLAYLIST = {
  query: `
    mutation unpublishPlaylist($p_id: Int!) {
      unpublishPlaylist(p_id: $p_id) {
        status
        message
      }
    }
  `,
};

export const ARCHIVE_PLAYLIST = {
  query: `
    mutation archivePlaylist($p_id: Int!) {
      archivePlaylist(p_id: $p_id) {
        status
        message
      }
    }
  `,
};
export const COLLECTION_PLAYLIST_ACTIVITY = {
  query: `
    query ColectionPlaylistActivites($search:String,$default_activities_id:[Int],$age:[Int],$activities_id:[Int]){
      ColectionPlaylistActivites(search:$search,default_activities_id:$default_activities_id,age:$age,activities_id:$activities_id){
        activity_details{
          name
          title
          id
          activities_id
          default_activities_id
          thumb_images{
						id,image_url
          }
          interests{
						interest_details{
							id,
							name
						}
          }
            age_groups{
              age_group{
                id,
                age,
                is_active
              }
              age_group_dificulty{
                id,
                dificulty,
                is_active
              }
            }
            age_group{
              id
              age
              is_active
            }
            difficulty,

            user_activity_type,
            time,
            activity_reference_images,
            url,
            pdf_url,
            steps,
            id,
            activities_draft_id,

            user_name,
            activity_state,
            status,
            is_draft,
            is_changed,
            activities_draft_state,

      }
      }
    }
  `,
};
