import React from 'react';
import noStudents from "../../static/journal/noStudents.svg";
import {Button} from "antd";
import './index.css';

const Confirm = (props) => (
	<div>
		<h3 className="mb-3 FontAvenirHeavy font-18 text-center max-dev-parentSignupTitle">
			You have successfully completed creating your profile.
		</h3>
		<h3 className="mb-3 FontAvenirHeavy text-center font-16 mbl-dev-parentSignupTitle">
							<span>
								You have successfully completed creating
							</span>
							<br />
							<span style={{padding: '0 16px'}}>
								your profile. Proceed to set up your child's account.
							</span>	
		</h3>
		{!props.data.isChild && (
			<h3 className="pb-3 mb-3 FontAvenirHeavy font-18 text-center max-dev-parentSignupTitle">
				Proceed to set up your child's account.
			</h3>
		)}
		<div className="d-flex align-items-center justify-content-center mbl-noLearners">
			<img
				src={noStudents}
				alt="No learners"
			/>
		</div>
		<div className="d-flex align-items-center justify-content-end w-100 mt-4 max-dev-parentSignup-Btn">
			<Button
				size="large"
				type="primary"
				onClick={() => props.nextStep()}
				className="pl-5 pr-5"
				style={{ backgroundColor: '#3EC1F0', color: '#FFFFFF', border: 'none' }}
			>
				PROCEED
			</Button>
		</div>
		<div className="mbl-dev-parentSignup-Btn">
			<Button
				size="large"
				type="primary"
				onClick={() => props.nextStep()}
				style={{ width: '100%', backgroundColor: '#42C0EF', color: '#FFFFFF', border: 'none' }}
			>
				PROCEED
			</Button>
		</div>
	</div>
);

export default Confirm;
