import {Button, Card, Checkbox, Col, Collapse, Icon, Input, Layout, message, Modal, Radio, Row, Select, Tag, Tooltip} from "antd";
import axios from "axios";
import _ from "lodash";
import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Prompt } from "react-router";
import RichTextEditor from "react-rte";
import Form from "react-validation/build/form";
import BreadcrumbView from "../../components/BreadCrumb";
import ButtonC from "../../components/ButtonC";
import FeaturedImageUpload from "../../components/FeaturedImage";
import ImageUploadCrop from "../../components/ImageUploadCrop";
import InputC from "../../components/InputC";
import PdfUpload from "../../components/PdfUpload";
import Steps from "../../components/Steps";
import TextEditor from "../../components/TextEditor";
import {
  ADD_DRAFT_ACTIVITY,
  ADD_TAG,
  CHECK_ACTIVITY_NAME,
  CHECK_YOUTUBE_LINK,
  GET_CREATE_ACTIVITY,
  GET_INTEREST,
  GET_PREREQUISITES,
  GET_TAGS,
  SEND_ACTIVITY_FOR_APPROVAL
} from "../../graphql";
import deleteImage from "../../static/card/asdelete.png";
import DoIcon from "../../static/card/do.svg";
import WatchIcon from "../../static/card/watch.svg";
import PlayListIcon from "../../static/expert/icons/collection.svg";
import {dragDrop_reorder, featuredImage_listStyle, openNotification, titleCase, triggerGAEvent, YOUTUBE_VIDEO_REGEX} from "../../utils";
import { isRequired, required, youtubeUrl } from "../../validation";
import close from "./../../static/activity/close.svg";
import DoCard from "./../../static/activity/DoCard.png";
import WatchCard from "./../../static/activity/watch.png";
import "./CreateActivity.css";

const Option = Select.Option;
const RadioGroup = Radio.Group;
const Panel = Collapse.Panel;
const { TextArea } = Input;
const { Content } = Layout;

class LearningCenterCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shouldBlockNavigation: false,
      longDescValue: RichTextEditor.createEmptyValue(""),
      isFormSubmitting: false,
      activities_draft_id: null,
      draft_status: 0,
      fileList: [],
      recommended: [{ title: "", link: "" }],
      ImagesfileList: [],
      pdfFileList: [],
      pdf_url: "",
      pdf_name: "",
      pdf_source_text: "",
      pdf_source_url: "",
      imageStepUpload: [],
      image_card_image: [],
      material_required: [
        { name: "", image: "" },
        { name: "", image: "" },
        //{ name: "", image: "" }
      ],
      post_prereq: [],
      is_loading: false,
      is_dragging: false,
      image_card_steps: "",
      name: ["Activities", "Activities Card", "Untitled Activity"],
      stepResult: [],
      items: [],
      stepTitle: "",
      stepDesc: "",
      selectedTags: [],
      previewVisible: false,
      previewImage: "",
      thumb_image: [{image_url: "", position: 0}, {image_url: "", position: 1}, {image_url: "", position: 2}],
      stepsView: false,
      act_int_cat: [],
      act_data_grade: [],
      act_int: [],
      act_int_id: "",
      act_suitfor: [],
      act_age_group: [],
      act_age_dificulty: [],
      act_prereq: [],
      getselectint: [],
      formValue: {
        act_name: "",
        act_int_cat_val: "",
        act_long_desc: "",
        act_sfty_desc: "",
        act_safety: "",
        act_brf_desc: "",
        act_type: "Centre Based",
        act_state: "Active",
        act_time: "",
        act_suitfor_val: "",
        source: "",
        video_link: "",
        video_credit_link: "",
        video_credit_text: "",
        image_credit_link: "",
        image_credit_text: "",
        steps_credit_link: "",
        steps_credit_text: "",
        trailer_url: "",
      },
      stepValue: "",
      editButtonVisibility: false,
      act_tags: [],
      m_act_tags: [],
      tags: [],
      tag_ids: [],
      truthObj: {
        act_name: false,
        act_name_length: false,
        act_desc: false,
        act_innovation_val: false,
        act_intelligence_val: false,
        act_taxonomy_val: false,
        act_int_cat_val: false,
        act_data_grade_val: false,
        act_suitfor: false,
        act_age_group_val: false,
        act_age_dificulty_val: false,
        act_long_desc: false,
        act_sfty_desc: false,
        act_safety: false,
        act_brf_desc: false,
        act_prereq_val: false,
        act_time: false,
        act_suitfor_val: false,
        prerequisites: false,
        act_type: false,
        act_state: false,
        video_link: false,
        video_credit_link: false,
        video_credit_text: false,
        image_credit_link: false,
        image_credit_text: false,
        steps_credit_link: false,
        steps_credit_text: false,
        steps: false,
        mashupData: false,
        ageData: false,
        fileList: false,
        dataPoints: false,
        materials_req: false,
        thumb_image: false,
        pdf: false,
      },
    };
    this.callSaveDraftData = _.debounce(this.callSaveDraftData, 2000);
  }

  handleCancel = () => this.setState({ previewVisible: false });

  handleChange = ({ fileList }) => this.setState({ fileList });

  componentDidMount() {
    this.setState({
      role_id: Number(localStorage.getItem("role_id")),
    });
    axios
      .post(`${process.env.REACT_APP_API}`, GET_CREATE_ACTIVITY)
      .then((res) => {
        const getResult = res.data.data;
        let interestCategories = getResult.getGlobalInterestCategory.intrest_cat
          .filter((data) => data.total_interest !== 0)
          .map((int) => ({
            ...int,
            is_added:
              (getResult.getInterestCategory.intrest_cat || []).filter(
                (data) => data.id === int.id
              ).length !== 0,
          }));
        const age_grp = getResult.getAge.age_groups.map((data) => ({
          ...data,
          age_group_dificulty_id: "1",
          is_active: true,
          dificulty: "",
        }));
        this.setState({
          act_age_group: age_grp,
          act_int_cat: interestCategories,
          act_suitfor: getResult.getSuitableFor,
          act_age_dificulty: getResult.getAge.age_dificulty,
          is_loading: false,
          shouldBlockNavigation: true,
        });
      })
      .catch((err) => console.log(err));
    this.getTags();
  }

  getPrerequisites = () => {
    let query = GET_PREREQUISITES;
    query.variables = {
      user_id: null,
      age_group_id: this.state.act_age_group.filter((age) => !age.is_active)
        .length
        ? this.state.act_age_group
            .filter((age) => !age.is_active)
            .map((a) => Number(a.id))
        : [1],
    };
    axios
      .post(`${process.env.REACT_APP_API}`, query)
      .then((res) => {
        const getResult = res.data.data;
        const lc_prereq = getResult.getActivityndLPPrerequisites.activity_details.map(
          (data) => ({
            ...data,
            id: `c_${data.id}`,
            isPlaylist: false,
          })
        );
        const lp_prereq = getResult.getActivityndLPPrerequisites.learningpath_details.map(
          (data) => ({
            ...data,
            id: `p_${data.id}`,
            name: data.title,
            isPlaylist: true,
          })
        );
        this.setState({
          act_prereq: [...lc_prereq, ...lp_prereq],
        });
      })
      .catch((err) => console.log(err));
  };

  getTags = () => {
    axios.post(`${process.env.REACT_APP_API}`, GET_TAGS).then((res) => {
      const tags = res.data.data.getTags.tags;
      let uniqTag = _.uniqBy(tags, "name");
      this.setState({ act_tags: uniqTag, m_act_tags: uniqTag });
    });
  };
  onChangeDescEditor = (editor) => {
    this.setState({ longDescValue: editor });
  };
  handleSubmit = () => {
    this.checkTitle();
    this.setState({ is_loading: true });
    let mutation = SEND_ACTIVITY_FOR_APPROVAL;
    mutation.variables = {
      activities_draft_id: this.state.activities_draft_id,
    };
    axios
      .post(`${process.env.REACT_APP_API}`, mutation)
      .then((res) => {
        console.log(res);
        if (res.data.errors) {
          openNotification("error", res.data.errors[0].message);
          this.setState({
            is_loading: false,
            loading: false,
            shouldBlockNavigation: false,
            isFormSubmitting: false,
          });
        } else {
          if (res.data.data.sendForApprovalV2) {
            this.setState({
              is_loading: false,
              loading: false,
              shouldBlockNavigation: false,
              isFormSubmitting: false,
            });
            openNotification("success", "Learning content sent for Approval");
            this.props.history.push(
              `/learningcontent/${this.state.activities_draft_id}/view/draft`
            );
          }
        }
      })
      .catch((err) => console.log(err));
  };

  saveDraftData = () => {
    this.setState({ draft_status: 1 });
    let mutation = ADD_DRAFT_ACTIVITY;
    mutation.variables = {
      activities_draft_id: this.state.activities_draft_id,
      user_activity_type: this.state.formValue.act_type,
      activity_state: this.state.formValue.act_state,
      name: titleCase(this.state.formValue.act_name.trim()),
      thumb_image: this.state.thumb_image.filter(data => data.image_url).map((data) => ({ image_url: data.image_url })),
      pdf_name: this.state.pdf_name,
      pdf_url: this.state.pdf_url,
      pdf_source_text: this.state.pdf_source_text,
      pdf_source_url: this.state.pdf_source_url,
      interest_categories_id: Number(this.state.formValue.act_int_cat_val),
      breif_desc: this.state.formValue.act_brf_desc,
      long_desc: this.state.longDescValue
        .getEditorState()
        .getCurrentContent()
        .hasText()
        ? this.state.formValue.act_long_desc.replace("<script", "")
        : "",
      time: this.state.formValue.act_time,
      safety: this.state.formValue.act_safety,
      safety_desc: this.state.formValue.act_sfty_desc,
      suitable_for_id: Number(this.state.formValue.act_suitfor_val),
      image: this.state.image_card_image.map((x) => ({ image_url: x })),
      step: this.state.stepResult.map((step, index) => ({
        ...step,
        position: `${index + 1}`,
      })),
      image_source_text: this.state.formValue.image_credit_text,
      image_source_link: this.state.formValue.image_credit_link,
      url: this.state.formValue.video_link,
      channel_name: this.state.formValue.video_credit_text,
      channel_url: this.state.formValue.video_credit_link,
      steps_source_text: this.state.formValue.steps_credit_text,
      steps_source_link: this.state.formValue.steps_credit_link,
      material_required: this.state.material_required
        .filter((item) => item.name !== "" && item.image !== "")
        .map((item) => ({ name: item.name, image: item.image })),
      prerequisites: this.state.post_prereq.map((data) => ({
        isPlaylist: data.split("_")[0] === "p" ? 1 : 0,
        prereq_activity_id: parseInt(data.split("_")[1], 10),
      })),
      recommended: this.state.recommended.filter(
        (item) => item.title !== "" && item.link !== ""
      ),
      interest_id: this.state.getselectint
        .filter((int) => int.is_active)
        .map((int) => parseInt(int.id, 10)),
      age_group: this.state.act_age_group
        .filter((age) => !age.is_active)
        .map((age) => ({
          age_group_dificulty_id: parseInt(age.age_group_dificulty_id, 10),
          age_group_id: age.id,
        })),
      tags_id: this.state.tag_ids,
      default_activities_id:
        this.props.match.params.id === "do"
          ? 4
          : this.props.match.params.id === "read"
          ? 1
          : this.props.match.params.id === "watch"
          ? 2
          : this.props.match.params.id === "listen"
          ? 3
          : 0,
      source: this.state.formValue.source,
      video_link: this.state.formValue.video_link,
      video_credit_text: this.state.formValue.video_credit_text,
      video_credit_link: this.state.formValue.video_credit_link,
      trailer_url: this.state.formValue.trailer_url,
      trailer_credit_text: this.state.formValue.trailer_credit_text,
      trailer_credit_url: this.state.formValue.trailer_credit_url,
    };
    axios
      .post(`${process.env.REACT_APP_API}`, mutation)
      .then((res) => {
        if (res.data.errors) {
          openNotification("error", res.data.errors[0].message);
          this.setState({
            draft_status: 0,
            shouldBlockNavigation: false,
          });
        } else {
          this.setState({
            draft_status: 2,
            shouldBlockNavigation: false,
            activities_draft_id:
              res.data.data.addDraftActivity.activity_details
                .activities_draft_id,
          });
        }
      })
      .catch((err) => console.log(err));
  };

  callSaveDraftData = () => {
    triggerGAEvent("Activities", "Save Activity Draft");
    if (
      this.state.formValue.act_name !== "" &&
      !this.state.truthObj["act_name"]
    ) {
      this.saveDraftData();
    }
  };

  onDraftApiCall = () => {
    this.setState({ draft_status: 0 });
    this.callSaveDraftData();
  };

  onChange = (key, value) => {
    triggerGAEvent("Activities", "Entering data for creating a new activity");
    const state = { ...this.state };
    if (key == 'act_time') {
      if (value.length) {
        if (isNaN(parseInt(value.charAt(0)))) {
          return
        } else if (parseInt(value.charAt(0)) <= 0) {
          return
        }
      }
    }
    state.formValue[key] = value;
    this.setState({ formValue: state.formValue });
    this.onDraftApiCall();
  };

  onSelectChange = (key, event) => {
    triggerGAEvent("Activities", "Entering data for creating a new activity");
    const state = { ...this.state };
    state.formValue[key] = event;
    this.setState({ formValue: state.formValue });
    this.onDraftApiCall();
  };

  callback = (key) => {
    console.log(key);
  };

  handleStepsView = () => {
    triggerGAEvent("Activities", "Steps view");
    window.scrollTo(0, 0);
    this.setState({ stepsView: !this.state.stepsView });
  };

  checkTitle = () => {
    let query = CHECK_ACTIVITY_NAME;
    query.variables = {
      name: this.state.formValue.act_name.trim(),
    };
    axios
      .post(`${process.env.REACT_APP_API}`, query)
      .then((res) => {
        let truthObj = { ...this.state.truthObj };
        truthObj["act_name"] = res.data.data.checkActivityName.is_available;
        this.setState({ truthObj });
        if (res.data.data.checkActivityName.is_available) {
          message.error("Name already exist");
        }
      })
      .catch((err) => console.log(err));
  };
  
  checkYoutubeLink = (key) => {
    if (this.state.formValue[key] && YOUTUBE_VIDEO_REGEX.test(this.state.formValue[key])) {
      let query = CHECK_YOUTUBE_LINK;
      query.variables = {
        url: this.state.formValue[key],
      };
      axios
        .post(`${process.env.REACT_APP_API}`, query)
        .then((res) => {
          let truthObj = { ...this.state.truthObj };
          truthObj[key] = !res.data.data.checkValidUrl.embeddable;
          this.setState({ truthObj });
          if (!res.data.data.checkValidUrl.embeddable) {
            message.error("One of the video links added is not available for embedded view on iPad. Please check the highlighted section");
          }
        })
        .catch((err) => console.log(err));
    }
  };
  
  onViewLinkBlur = (key) => {
    if (this.state.formValue[key] && YOUTUBE_VIDEO_REGEX.test(this.state.formValue[key])) {
      this.checkYoutubeLink(key);
    } else {
      let truthObj = { ...this.state.truthObj };
      truthObj[key] = false;
      this.setState({ truthObj });
    }
  };

  getInterest = (val) => {
    let query = GET_INTEREST;
    query.variables = {
      interest_categories_id: val,
    };
    axios
      .post(`${process.env.REACT_APP_API}`, query)
      .then((res) => {
        const interest_details = res.data.data.getInterest.interest_details.map(
          (data) => {
            data.is_active = false;
            return data;
          }
        );
        this.setState({ getselectint: interest_details });
        return res.data.data.getInterest.interest_details;
      })
      .catch((err) => console.log(err));
  };

  onSearchSelect = (input, option) => {
    console.log(
      "option.props.children",
      option.props.children.props.children[1]
    );
    return option.props.children.props.children[1]
      .toLowerCase()
      .trim()
      .includes(input.toLowerCase().trim());
  };

  onSelectPrerequisites = (index) => {
    triggerGAEvent("Activities", "Entering data for creating a new activity");
    this.setState({ post_prereq: index });
    if (index.length >= 4) {
      message.error("Only a maximum of 3 prerequisites can be added");
    }
    this.onDraftApiCall();
  };

  onSelectTags = (value) => {
    triggerGAEvent("Activities", "Adding Tags to Learning Content");
    let tag_ids = [];
    let new_tags = [];
    value.forEach((v) => {
      let filter = this.state.act_tags.filter((tag) => tag.name.trim().toLowerCase() === v.trim().toLowerCase());
      if (filter.length) {
        tag_ids.push(filter[0].id);
      } else {
        new_tags.push(v)
      }
    });
    if(new_tags.length) {
      let mutation = ADD_TAG;
      mutation.variables = { name: new_tags.map(t => t.trim()) };
      axios
        .post(`${process.env.REACT_APP_API}`, mutation)
        .then((res) => {
          this.setState({
            tag_ids: [...tag_ids, ...res.data.data.AddTagsV2.tags_ids],
            tags: value,
          });
          this.getTags();
        })
        .catch((err) => console.log(err));
        this.onDraftApiCall();
    } else {
      this.setState({
        tag_ids: tag_ids,
        tags: value,
      });
      this.onDraftApiCall();
    }
  };

  filterByAgeGroup = (contents, age) => {
    return contents
      .map((c) => {
        if (
          !this.state.act_prereq
            .filter((p) => p.id === c)[0]
            .age_group_id.includes(age)
        ) {
          return c;
        }
      })
      .filter((c) => c);
  };

  setAgeGroupActive = (i, e) => {
    triggerGAEvent("Activities", "Setting Age group for an Activity");
    let age_grp = [...this.state.act_age_group];
    if (age_grp[i].is_active) {
      if (e.target) {
        age_grp[i].age_group_id = e.target.value;
      } else {
        age_grp[i].age_group_id = e;
      }
      age_grp[i].is_active = !age_grp[i].is_active;
      this.setState({ act_age_group: age_grp });
    } else {
      const confirmDialog = Modal.confirm({
        title: "Do you want to change the age group selection?",
        content:
          "Prerequisites you selected already fall under this group, this action will be remove those items from this content. Do you want to continue?",
        okText: "Yes",
        onOk: () => {
          if (e.target) {
            age_grp[i].age_group_id = e.target.value;
          } else {
            age_grp[i].age_group_id = e;
          }
          age_grp[i].is_active = !age_grp[i].is_active;
          const post_prereq = this.filterByAgeGroup(
            this.state.post_prereq,
            this.state.act_age_group[i].id
          );
          this.setState({
            act_age_group: age_grp,
            post_prereq: post_prereq,
          });
        },
      });
      this.setState({ confirmDialog });
    }
    this.onDraftApiCall();
  };

  setAgeGroupDiff = (i, e) => {
    triggerGAEvent("Activities", "Setting Age group for an Activity");
    let age_grp = [...this.state.act_age_group];
    age_grp[i].age_group_dificulty_id = e;
    this.setState({ act_age_group: age_grp });
    this.onDraftApiCall();
  };

  TagClick = (tag, check, index, tagItem) => {
    triggerGAEvent("Activities", "Entering data for creating a new activity");
    if (tagItem !== "data") {
      const state = [...this.state.getselectint];
      state[index].is_active = !state[index].is_active;
      this.setState({ getselectint: state });
    } else {
      const state = [...this.state.getselectdatapoint];
      state[index].is_active = !state[index].is_active;
      this.setState({ setselectdatapoint: state });
    }
    this.onDraftApiCall();
  };

  recommendedAddMore = () => {
    triggerGAEvent("Activities", "Add more useful links");
    const state = { ...this.state };
    const recommended = {
      title: "",
      link: "",
    };
    state.recommended = state.recommended.concat(recommended);
    this.setState({ recommended: state.recommended });
  };

  removeRecommend = (index) => {
    triggerGAEvent("Activities", "Remove useful links");
    const state = { ...this.state };
    state.recommended.splice(index, 1);
    this.setState({ recommended: state.recommended });
    this.onDraftApiCall();
  };

  onChangeStep = (e) => {
    triggerGAEvent("Activities", "Entering data for creating a new activity");
    const stepLabel = e.target.name;
    const stepValue = e.target.value;
    this.setState({ [stepLabel]: stepValue });
  };

  onChangeRecommend = (key, event, index) => {
    triggerGAEvent("Activities", "Entering data for creating a new activity");
    const state = { ...this.state };
    state.recommended[index][key] = event.target.value;
    this.setState({ recommended: state.recommended });
    this.onDraftApiCall();
  };

  onFeaturedDragStart = (result) => {
    this.setState({ is_dragging: !this.state.modal_open });
  };

  onFeaturedDragEnd = (result) => {
    triggerGAEvent("Activities", "Drag drop in Featured Images");
    if (!result.destination) {
      return;
    }
    const thumb_image = dragDrop_reorder(
      this.state.thumb_image,
      result.source.index,
      result.destination.index
    );
    this.setState({ thumb_image, is_dragging: false });
  };

  onDragEnd = (result) => {
    triggerGAEvent("Activities", "Drag drop in Steps");
    if (!result.destination) {
      return;
    }
    const items = dragDrop_reorder(
      this.state.items,
      result.source.index,
      result.destination.index
    );
    this.setState({ items });
  };

  saveSteps = () => {
    triggerGAEvent("Activities", "Save details in Steps");
    let truthObj = { ...this.state.truthObj };
    if (this.state.formValue.video_link.length > 0) {
      truthObj["video_link"] = !YOUTUBE_VIDEO_REGEX.test(
        this.state.formValue.video_link
      );
      truthObj["video_credit_text"] = !this.state.formValue.video_credit_text;
    } else {
      truthObj["video_link"] = false;
      truthObj["video_credit_text"] = false;
    }
    truthObj["video_credit_link"] = false;
    truthObj["image_credit_text"] =
      this.state.image_card_image.length !== 0
        ? !this.state.formValue.image_credit_text
        : false;
    truthObj["image_credit_link"] = false;
    truthObj["steps"] =
      this.state.items.filter(
        (step) =>
          (step.desc === "" && step.image !== "") ||
          (step.desc !== "" && step.image === "")
      ).length !== 0;
    truthObj["steps_credit_text"] =
      this.state.items.length !== 0
        ? !this.state.formValue.steps_credit_text
        : false;
    truthObj["steps_credit_link"] = false;
    this.setState({ truthObj });
    if (
      !truthObj["video_link"] &&
      !truthObj["video_credit_text"] &&
      !truthObj["image_credit_text"] &&
      !truthObj["steps"] &&
      !truthObj["steps_credit_text"]
    ) {
      this.getSteps(this.state.items);
    }
  };

  getSteps = (data) => {
    this.setState({ stepResult: data });
    this.setState({ stepsView: false });
    this.onDraftApiCall();
  };

  FeaturedImageUploadSuccess = (value, index) => {
    triggerGAEvent("Activities", "Uploading Featured Images");
    let thumb_image = [...this.state.thumb_image];
    thumb_image[index].image_url = value;
    this.setState({ thumb_image });
    this.onDraftApiCall();
  };

  FeaturedImageStepUploadSuccess = (value) => {
    triggerGAEvent("Activities", "Upload Images");
    const state = { ...this.state };
    state.image_card_image = value;
    this.setState({ image_card_image: state.image_card_image });
  };

  materialRequiredOnchange = (key, index, value) => {
    triggerGAEvent("Activities", "Entering data for creating a new activity");
    const state = { ...this.state };
    state.material_required[index][key] = value;
    this.setState({ material_required: state.material_required });
    this.onDraftApiCall();
  };

  materialRequiredImage = (file, index) => {
    triggerGAEvent("Activities", "Entering data for creating a new activity");
    const state = { ...this.state };
    // state.material_required[index].fileList = file;
    state.material_required[index].fileList = file.map((data) => {
      data.url = data.response;
      return data;
    });
    this.setState({ material_required: state.material_required });
  };

  ImagesUploadedFile = (file) => {
    this.setState({ ImagesfileList: file });
  };

  stepImageUpload = (file) => {
    // console.log(file)
    if (file.length < 2) {
      this.setState({ imageStepUpload: file });
    } else {
      openNotification("error", "You can't upload multiple images in steps");
    }
  };

  onPDFUpload = (file) => {
    this.setState({ pdfFileList: file });
  };

  onPDFUploadSuccess = (value) => {
    triggerGAEvent("Activities", "Add PDF file");
    this.setState({ pdf_url: value[0] });
  };

  onPDFChange = (key, value) => {
    triggerGAEvent("Activities", "Entering data for creating a new activity");
    let state = { ...this.state };
    state[key] = value;
    this.setState({ ...state });
    this.onDraftApiCall();
  };

  onClickMaterialRequired = () => {
    triggerGAEvent("Activities", "Add more materials required");
    const material_required = [
      { name: "", image: "" },
      { name: "", image: "" },
      // { name: "", image: "" }
    ];
    const state = { ...this.state };
    const mergematerial = state.material_required.concat(material_required);
    this.setState({ material_required: mergematerial });
  };

  onRemoveMaterialRequired = (index) => {
    triggerGAEvent("Activities", "remove materials required");
    const state = { ...this.state };
    state.material_required.splice(index, 1);
    this.setState({ material_required: state.material_required });
    this.onDraftApiCall();
  };

  FeaturedImageUploadedFile = (file) => {
    this.setState({ fileList: file });
  };

  stepsClick = (e) => {
    let sTitle = this.state.items[e].title;
    let sImage = this.state.items[e].image;
    let sDesc = this.state.items[e].desc;
    this.setState({ editButtonVisibility: true, itemToEdit: e });
    this.setState({
      stepTitle: sTitle,
      image_card_steps: sImage,
      stepDesc: sDesc,
    });
  };

  onEditClick = () => {
    triggerGAEvent("Activities", "Edit Step");
    let copyOfState = { ...this.state };
    let indexOfItem = this.state.itemToEdit;
    copyOfState.items[indexOfItem].desc = this.state.stepDesc;
    copyOfState.items[indexOfItem].image = this.state.image_card_steps;
    let sth = copyOfState.items;
    this.setState({
      items: sth,
      stepTitle: "",
      image_card_steps: "",
      stepDesc: "",
      editButtonVisibility: false,
      itemToEdit: null,
    });
  };

  onClickStep = (e) => {
    triggerGAEvent("Activities", "Entering data for creating a new activity");
    const newObj = {
      image: `${this.state.image_card_steps}`,
      position: this.state.items.length + 1,
      title: `${this.state.stepTitle}`,
      desc: `${this.state.stepDesc}`,
    };
    const newStep = this.state.items.concat(newObj);
    this.setState({ items: newStep });
    this.setState({
      stepTitle: "",
      stepDesc: "",
      image_card_steps: "",
      imageStepUpload: [],
    });
  };

  onCancelStep = () => {
    triggerGAEvent("Activities", "Cancel in Steps");
    this.setState({
      stepTitle: "",
      stepDesc: "",
      image_card_steps: "",
      imageStepUpload: [],
      editButtonVisibility: false,
    });
  };

  deleteFeaturedImage = (index) => {
    triggerGAEvent("Activities", "Deleting Featured Images");
    let copyState = { ...this.state };
    copyState.thumb_image[index].image_url = "";
    this.setState({
      thumb_image: copyState.thumb_image,
    });
    this.onDraftApiCall();
  };

  deleteStepImage = (index) => {
    triggerGAEvent("Activities", "Delete Images");
    let copyState = { ...this.state };
    copyState.ImagesfileList.splice(index, 1);
    copyState.image_card_image.splice(index, 1);
    this.setState({
      ImagesfileList: copyState.ImagesfileList,
      image_card_image: copyState.image_card_image,
    });
  };

  onDeleteClick = (e) => {
    triggerGAEvent("Activities", "Delete Step");
    let copyOfState = { ...this.state };
    let copyOfItems = copyOfState.items;
    copyOfItems.splice(e, 1);
    this.setState({ items: copyOfItems });
  };

  deleteUploadPDF = () => {
    this.setState({ pdf_url: "", pdfFileList: [] });
  };

  componentDidUpdate = () => {
    if (this.state.shouldBlockNavigation && this.state.draft_status === 0) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
  };
  onSearchTags = (input) => {
    let tagsObj = {};

    const tags = this.state.act_tags.filter((data) => {
      if (data.name.toLowerCase().trim().includes(input.toLowerCase().trim())) {
        if (
          tagsObj[
            data.name.toLowerCase().trim().indexOf(input.toLowerCase().trim())
          ]
        ) {
          tagsObj[
            data.name.toLowerCase().trim().indexOf(input.toLowerCase().trim())
          ].push(data);
        } else {
          tagsObj[
            data.name.toLowerCase().trim().indexOf(input.toLowerCase().trim())
          ] = [];
          tagsObj[
            data.name.toLowerCase().trim().indexOf(input.toLowerCase().trim())
          ].push(data);
        }
        return data;
      }
      // return data.name.toLowerCase().trim()
    });
    // console.log(tagsObj)
    let orderedObj = [];
    Object.keys(tagsObj)
      .sort()
      .forEach(function (key) {
        orderedObj.push(
          tagsObj[key].sort((a, b) =>
            a.name.toLowerCase().trim() > b.name.toLowerCase().trim()
              ? 1
              : b.name.toLowerCase().trim() > a.name.toLowerCase().trim()
              ? -1
              : 0
          )
        );
      });
    this.setState({ m_act_tags: orderedObj.flat(1) });
  };

  validateStateValues = () => {
    this.setState({ isFormSubmitting: true });
    let truthObj = {...this.state.truthObj};
    let getKeys = Object.keys(this.state.formValue);
    getKeys.forEach((key, index) => {
      let getItem = this.state.formValue[key];
      let required = isRequired(getItem);
      truthObj[key] = required;
    });
    truthObj["title"] = this.state.formValue.title === "";
    if (this.state.formValue.act_name.length > 140) {
      openNotification(
        "error",
        "Name of the activity can only be upto 140 characters"
      );
      truthObj["act_name_length"] = this.state.formValue.act_name.length > 140;
    }
    let filterAgeGroup = _.filter(this.state.act_age_group, {
      is_active: false,
    });
    truthObj["ageData"] = filterAgeGroup.length <= 0;
    if (this.state.thumb_image.filter(data => data.image_url).length > 0) {
      truthObj["thumb_image"] = this.state.thumb_image.filter(data => data.image_url).length > 3;
    } else {
      truthObj["thumb_image"] = true;
    }
    if (this.props.match.params.id === "watch") {
      if (this.state.formValue.trailer_url.length > 0) {
        if (YOUTUBE_VIDEO_REGEX.test(this.state.formValue.trailer_url)) {
          this.checkYoutubeLink('trailer_url');
        } else {
          truthObj["trailer_url"] = true;
        }
      } else {
        truthObj["trailer_url"] = false;
      }
      truthObj["trailer_credit_text"] = false;
      truthObj["trailer_credit_url"] = false;
      truthObj["act_suitfor_val"] = this.state.formValue.act_suitfor_val === "";
      truthObj["source"] = this.state.formValue.source === "";
    } else {
      truthObj["source"] = false;
      truthObj["trailer_url"] = false;
      truthObj["source"] = false;
    }
    // if (this.state.fileList.length > 0) {
    //   truthObj["fileList"] = this.state.fileList.length > 3;
    // } else {
    //   truthObj["fileList"] = true;
    // }
    if (this.state.formValue.act_safety === "Not required") {
      truthObj["act_safety"] = false;
      truthObj["act_sfty_desc"] = this.state.formValue.act_sfty_desc > 100;
    } else {
      truthObj["act_sfty_desc"] = this.state.formValue.act_sfty_desc === "" || this.state.formValue.act_sfty_desc > 100;
    }
    truthObj["act_brf_desc"] = this.state.formValue.act_brf_desc === "" || this.state.formValue.act_brf_desc > 300;
    truthObj["act_long_desc"] = false;
    if (this.state.formValue.video_link.length > 0 || this.props.match.params.id === "watch") {
      if (YOUTUBE_VIDEO_REGEX.test(this.state.formValue.video_link)) {
        this.checkYoutubeLink('video_link');
      } else {
        truthObj["trailer_url"] = true;
      }
      truthObj["video_credit_text"] = !this.state.formValue.video_credit_text;
    } else {
      truthObj["video_link"] = false;
      truthObj["video_credit_text"] = false;
    }
    truthObj["video_credit_link"] = false;
    truthObj["act_int_cat_val"] = this.state.formValue.act_int_cat_val === "";
    truthObj["image_credit_text"] =
      this.state.image_card_image.length !== 0
        ? !this.state.formValue.image_credit_text
        : false;
    truthObj["image_credit_link"] = false;
    truthObj["steps"] =
      this.state.stepResult.filter(
        (step) =>
          (step.desc === "" && step.image !== "") ||
          (step.desc !== "" && step.image === "")
      ).length !== 0;
    truthObj["steps_credit_text"] =
      this.state.stepResult.length !== 0
        ? !this.state.formValue.steps_credit_text
        : false;
    truthObj["steps_credit_link"] = false;
    if (this.state.post_prereq.length > 3) {
      openNotification("error", "You can only enter upto three prerequisites");
      truthObj["prerequisites"] = true;
    } else {
      truthObj["prerequisites"] = false;
    }
    truthObj["act_time"] =
      this.state.formValue.act_time === ""
        ? true
        : isNaN(this.state.formValue.act_time)
        ? true
        : parseInt(this.state.formValue.act_time) < 0;
    truthObj["materials_req"] =
      this.state.material_required.filter(
        (matReq) =>
          (matReq.name === "" && matReq.image !== "") ||
          (matReq.name !== "" && matReq.image === "") ||
          matReq.name > 50
      ).length !== 0;
    truthObj["pdf"] =
      this.state.pdf_url ||
      this.state.pdf_name ||
      this.state.pdf_source_text ||
      this.state.pdf_source_url
        ? !(
            this.state.pdf_url &&
            this.state.pdf_name &&
            this.state.pdf_source_text
          )
        : false;
    setTimeout(() => this.setState({ truthObj: truthObj }), 100);
    setTimeout(() => this.checkErrorExistence(), 1500);
  };

  checkErrorExistence = () => {
    let errors = _.values(this.state.truthObj);
    let includesErrors = _.includes(errors, true);
    if (includesErrors) {
      openNotification("error", "Please Fill all mandatory fields correctly");
      this.setState({ isFormSubmitting: false });
    } else {
      this.saveDraftData();
      setTimeout(() => {
        if (this.state.activities_draft_id) this.handleSubmit();
      }, 100);
    }
  };

  render() {
    if (
      !(
        this.props.match.params.id === "do" ||
        this.props.match.params.id === "read" ||
        this.props.match.params.id === "watch" ||
        this.props.match.params.id === "listen"
      )
    ) {
      return <div>Invalid</div>;
    }
    return (
      <Layout className="">
        <Prompt
          when={this.state.shouldBlockNavigation}
          message="You have unsaved changes, are you sure you want to leave?"
        />
        <div className="bg-white">
          <BreadcrumbView
            title={`${
              this.state.formValue.act_name
                ? this.state.formValue.act_name
                : `Untitled ${this.props.match.params.id} Learning Content`
            }`}
            data={
              !this.state.stepsView
                ? `/Learning Content/${(this.state.formValue.act_name
                    ? this.state.formValue.act_name
                    : `Untitled ${this.props.match.params.id} Learning Content`
                  ).replace(/\//g, "~~")}`
                : `/Learning Content/${(this.state.formValue.act_name
                    ? this.state.formValue.act_name
                    : `Untitled ${this.props.match.params.id} Learning Content`
                  ).replace(/\//g, "~~")}`
            }
          >
            {this.state.stepsView && (
              <div>
                <ButtonC
                  category="Activities"
                  action="Cancel changes in steps"
                  size="large"
                  className="mr-3"
                  onClick={() =>
                    this.setState({
                      items: this.state.stepResult,
                      stepsView: false,
                    })
                  }
                >
                  Cancel
                </ButtonC>
                <Button
                  size="large"
                  className="mr-3"
                  type="primary"
                  onClick={this.saveSteps}
                >
                  Save details
                </Button>
              </div>
            )}
            {!this.state.stepsView && (
              <div className="d-flex">
                <div
                  className="d-flex justify-content-center align-items-center mr-4"
                  style={{ flexDirection: "column" }}
                >
                  <span
                    className="font-14"
                    style={{
                      color: "#DA375B",
                      fontWeight: 800,
                      alignSelf: "flex-end",
                    }}
                  >
                    Draft
                  </span>
                  {this.state.draft_status === 0 && (
                    <span
                      className="font-14"
                      style={{ color: "#9B9B9B", fontWeight: 800 }}
                    >
                      Unsaved Changes
                    </span>
                  )}
                  {this.state.draft_status === 1 && (
                    <span
                      className="font-14"
                      style={{ color: "#9B9B9B", fontWeight: 800 }}
                    >
                      Saving
                    </span>
                  )}
                  {this.state.draft_status === 2 && (
                    <span
                      className="font-14"
                      style={{ color: "#9B9B9B", fontWeight: 800 }}
                    >
                      Saved
                    </span>
                  )}
                </div>
                <ButtonC
                  category="Activities"
                  action="Sending Activity for Approval"
                  type="primary"
                  size="large"
                  onClick={this.validateStateValues}
                  className="btn-w250"
                  disabled={this.state.isFormSubmitting}
                  loading={this.state.isFormSubmitting}
                >
                  Send for Approval
                </ButtonC>
              </div>
            )}
          </BreadcrumbView>
        </div>
        {this.state.is_loading && (
          <div className="vh-100 z-index-1 opacity-black-5 align-items-center position-fixed col-12 fixed-top d-flex justify-content-center">
            <Icon type="loading" theme="outlined" className="loading" />
          </div>
        )}
        <Content className="activityPageScroll" style={{ padding: 32 }}>
          <div style={{ minHeight: 280 }} className="create-activity">
            {!this.state.stepsView && (
              <Form layout="vertical" ref="get_form_values">
                <div className="bg-white p-4 rounded">
                  <Row className="mb-4">
                    <Col span={8}>
                      <div className="mb-3 FontAvenirRoman text-secondary font-16 ">
                        CONTENT TYPE
                      </div>
                      {this.props.match.params.id === "do" && (
                        <img src={DoCard} className="img-fluid " alt="" />
                      )}
                      {this.props.match.params.id === "watch" && (
                        <img src={WatchCard} className="img-fluid " alt="" />
                      )}
                      {/* <h6 className="text-capitalize">{this.props.match.params.id}</h6> */}
                    </Col>
                    {this.state.role_id !== 4 && this.state.role_id !== 5 && (
                      <React.Fragment>
                        <Col span={8}>
                          <div
                            className="mb-3 FontAvenirRoman text-secondary font-16 requiredAsterisk"
                            id={
                              this.state.truthObj["act_type"]
                                ? "has-error-label"
                                : ""
                            }
                          >
                            ACTIVITY TYPE
                          </div>
                          <RadioGroup
                            value={this.state.formValue.act_type}
                            onChange={(e) =>
                              this.onChange("act_type", e.target.value)
                            }
                          >
                            <Radio value="Centre Based">Centre Based</Radio>
                            <Radio value="Public">Public</Radio>
                          </RadioGroup>
                        </Col>
                        <Col span={8}>
                          <div
                            className="mb-3 FontAvenirRoman text-secondary font-16 requiredAsterisk"
                            id={
                              this.state.truthObj["act_state"]
                                ? "has-error-label"
                                : ""
                            }
                          >
                            STATE
                          </div>

                          <RadioGroup
                            value={this.state.formValue.act_state}
                            onChange={(e) =>
                              this.onChange("act_state", e.target.value)
                            }
                          >
                            <Radio value="Active">Active</Radio>
                            <Radio value="Hidden">Hidden</Radio>
                          </RadioGroup>
                        </Col>
                      </React.Fragment>
                    )}
                  </Row>

                  <InputC
                    title="title"
                    id={
                      this.state.truthObj["act_name"] ||
                      this.state.truthObj["act_name_length"]
                        ? "has-error"
                        : ""
                    }
                    className="col-12 ant-input ant-input-lg mb-4"
                    titleStyle="mb-3 FontAvenirMedium text-uppercase text-secondary font-16 requiredAsterisk"
                    placeholder="Enter learning content title"
                    value={this.state.formValue.act_name}
                    stateKey="act_name"
                    onChange={this.onChange}
                    onBlur={this.checkTitle}
                    validations={[required]}
                    autoFocus
                  />

                  <div className="border-bottom mb-4" />

                  <div className="FontAvenirRoman text-secondary text-uppercase font-16 requiredAsterisk">
                    Featured Images
                  </div>
                  <div className="row mt-1">
                    {/* <DragDropContext
                      onDragStart={this.onFeaturedDragStart}
                      onDragEnd={this.onFeaturedDragEnd}
                    >
                      <Droppable droppableId="droppable" direction="horizontal">
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            style={{
                              backgroundColor: this.state.is_dragging ? "#E4EEFB" : "#FFFFFF",
                              display: "flex",
                              ...featuredImage_listStyle(
                                snapshot.isDraggingOver
                              ),
                            }}
                            {...provided.droppableProps}
                          >
                            {this.state.thumb_image.map((data, index) => (
                              <Draggable
                                key={index}
                                draggableId={`${index}steps`}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <div className="col-4 featured-img-upload">
                                      <ImageUploadCrop
                                        onSuccess={(data) => this.FeaturedImageUploadSuccess(data, index)}
                                        imageUrl={data.image_url}
                                        aspect={16 / 9}
                                        width={378}
                                        height={212}
                                        imageClassName="position-relative d-flex align-items-center justify-content-center"
                                        imageStyle={{ height: 212, width: 378, }}
                                      />
                                    </div>
                                  </div>
                                )}
                              </Draggable>
                            ))}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext> */}
                    {this.state.thumb_image.map((data, index) => (
                      <div className="col-4 featured-img-upload pl-2 pr-2" key={index}>
                        <ImageUploadCrop
                          onSuccess={(data) => this.FeaturedImageUploadSuccess(data, index)}
                          imageUrl={data.image_url}
                          aspect={16 / 9}
                          width={378}
                          height={212}
                          imageClassName="position-relative d-flex align-items-center justify-content-center"
                          imageStyle={{ height: 212, width: 378, }}
                        />
                      </div>
                    ))}
                  </div>
                </div>
                <div className="mt-4">
                  <div className="titleCard text-uppercase">
                    Details of this Learning Content
                  </div>
                  <Card>
                    <Row>
                      <div
                        className="mb-3 text-uppercase FontAvenirRoman text-secondary font-16 requiredAsterisk"
                        id={
                          this.state.truthObj["ageData"]
                            ? "has-error-label"
                            : ""
                        }
                      >
                        {`AGE GROUP${
                          this.props.match.params.id === "do"
                            ? " & DIFFICULTY"
                            : ""
                        }`}
                      </div>
                      <div
                        className={
                          this.props.match.params.id === "do"
                            ? ""
                            : "d-flex align-items-center justify-content-between  "
                        }
                      >
                        {this.state.act_age_group.map((age_data, i) => {
                          return (
                            <div
                              className={`age FontAvenirRoman ${
                                i !== this.state.act_age_group.length - 1
                                  ? ""
                                  : "mr-5"
                              }`}
                              key={i}
                            >
                              <Checkbox
                                key={i}
                                checked={!age_data.is_active}
                                value={age_data.id}
                                onChange={(e) => this.setAgeGroupActive(i, e)}
                              >
                                {age_data.age}
                              </Checkbox>
                              {this.props.match.params.id === "do" && (
                                <React.Fragment>
                                  :
                                  <Select
                                    size="large"
                                    className={
                                      age_data.is_active ? "no-color" : "color"
                                    }
                                    value={age_data.age_group_dificulty_id}
                                    onSelect={() => {
                                      if (age_data.is_active)
                                        this.setAgeGroupActive(i, age_data.id);
                                    }}
                                    onChange={(e) => {
                                      this.onSelectChange(
                                        "act_age_dificulty_val",
                                        e
                                      );

                                      this.setAgeGroupDiff(i, e);
                                    }}
                                  >
                                    {this.state.act_age_dificulty.map(
                                      (data, index) => {
                                        return (
                                          <Option
                                            size="large"
                                            key={index}
                                            value={data.id}
                                            id={`${age_data.age}-${index}`}
                                          >
                                            {data.dificulty}
                                          </Option>
                                        );
                                      }
                                    )}
                                  </Select>
                                </React.Fragment>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </Row>
                    <div className="border-bottom pt-4 mb-4" />

                    {this.props.match.params.id === "watch" && (
                      <React.Fragment>
                        <Row>
                          {/* <div className="border-bottom mb-4" /> */}
                          <InputC
                            title="source"
                            id={
                              this.state.truthObj["source"] ? "has-error" : ""
                            }
                            className="col-12 ant-input ant-input-lg mb-4"
                            placeholder="Enter source of Documentary (YouTube, DVD, Blu-ray Disc, Ultra HD Blu-ray, Netflix, Amazon Prime, 3D Blu-ray Disc etc.)"
                            titleStyle="mb-3 FontAvenirMedium text-uppercase text-secondary font-16 requiredAsterisk"
                            value={this.state.formValue.source}
                            stateKey="source"
                            onChange={this.onChange}
                            // onChange={e => this.onChange("source", e.target.value)}

                            validations={[required]}
                          />

                          <InputC
                            title="TRAILER LINK"
                            id={this.state.truthObj["trailer_url"] ? "has-error" : ""}
                            className="col-12 ant-input ant-input-lg"
                            placeholder="Enter Trailer video link"
                            value={this.state.formValue.trailer_url}
                            titleStyle="mb-3 FontAvenirMedium text-uppercase text-secondary font-16 "
                            stateKey="trailer_url"
                            onChange={this.onChange}
                            onBlur={() => this.onViewLinkBlur('trailer_url')}
                            validations={[required]}
                          />

                          {/* <div className="border-bottom mb-4" /> */}
                          <Row className="m-0">
                            <Col span={12} style={{ paddingRight: 10 }}>
                              <InputC
                                title=""
                                id={this.state.truthObj["trailer_credit_text"] ? "has-error" : ""}
                                className="col-12 ant-input ant-input-lg mb-3"
                                placeholder="Enter display text for credit link"
                                value={this.state.formValue.trailer_credit_text}
                                stateKey="trailer_credit_text"
                                onChange={this.onChange}
                                validations={[required]}
                              />
                            </Col>
                            <Col span={12}>
                              {/* <div className="border-bottom mb-4" /> */}
                              <InputC
                                title=""
                                id={this.state.truthObj["trailer_credit_url"] ? "has-error" : ""}
                                className="col-12 ant-input ant-input-lg mb-3"
                                placeholder="Enter credit link "
                                value={this.state.formValue.trailer_credit_url}
                                stateKey="trailer_credit_url"
                                onChange={this.onChange}
                                validations={[required]}
                              />
                            </Col>
                          </Row>
                        </Row>
                        {/* <div className="border-bottom mb-4" /> */}
                        <InputC
                          title="VIDEO LINK"
                          id={this.state.truthObj["video_link"] ? "has-error" : ""}
                          className={`col-12 ant-input ant-input-lg  ${this.state.truthObj["video_link"] ? "has-error" : ""}`}
                          placeholder="Enter video link"
                          value={this.state.formValue.video_link}
                          stateKey="video_link"
                          onChange={this.onChange}
                          onBlur={() => this.onViewLinkBlur('video_link')}
                          titleStyle="mb-3 FontAvenirMedium text-uppercase text-secondary font-16 requiredAsterisk"
                          validations={[youtubeUrl]}
                        />
  
                        {/* <div className="border-bottom mb-4" /> */}
                        <Row className="m-0 mb-3">
                          <Col span={12} style={{ paddingRight: 10 }}>
                            <InputC
                              title=""
                              id={
                                this.state.truthObj["video_credit_text"]
                                  ? "has-error"
                                  : ""
                              }
                              className="col-12 ant-input ant-input-lg mb-3"
                              placeholder="Enter display text for credit link"
                              value={this.state.formValue.video_credit_text}
                              stateKey="video_credit_text"
                              onChange={this.onChange}
                              // onChange={e => this.onChange("video_credit_text", e.target.value)}
        
                              validations={[required]}
                            />
                          </Col>
                          <Col span={12}>
                            {/* <div className="border-bottom mb-4" /> */}
                            <InputC
                              title=""
                              id={
                                this.state.truthObj["video_credit_link"]
                                  ? "has-error"
                                  : ""
                              }
                              className="col-12 ant-input ant-input-lg mb-3"
                              placeholder="Enter credit link "
                              value={this.state.formValue.video_credit_link}
                              stateKey="video_credit_link"
                              onChange={this.onChange}
                              // onChange={e => this.onChange("video_credit_link", e.target.value)}
        
                              validations={[required]}
                            />
                          </Col>
                        </Row>
                        <div className="border-bottom mb-4" />
                      </React.Fragment>
                    )}

                    <div className="mb-3 FontAvenirRoman text-secondary font-16 requiredAsterisk">
                      INTEREST CATEGORIES AND INTERESTS
                    </div>

                    <Select
                      size="large"
                      placeholder="Select Interest Category"
                      value={
                        this.state.formValue.act_int_cat_val
                          ? this.state.formValue.act_int_cat_val
                          : undefined
                      }
                      onChange={(e) => {
                        this.onSelectChange("act_int_cat_val", e);
                        this.getInterest(this.state.formValue.act_int_cat_val);
                      }}
                      className={
                        this.state.truthObj["act_int_cat_val"]
                          ? "has-error"
                          : ""
                      }
                    >
                      <Select.OptGroup label="Preferred Interest Categories">
                        {this.state.act_int_cat
                          .filter((data) => data.is_added)
                          .map((data, i) => (
                            <Option
                              size="large"
                              key={`Pref-${i}`}
                              value={data.id}
                              id={`Pref-${i}`}
                            >
                              {data.name}
                            </Option>
                          ))}
                      </Select.OptGroup>
                      <Select.OptGroup label="Other Interest Categories">
                        {this.state.act_int_cat
                          .filter((data) => !data.is_added)
                          .map((data, i) => (
                            <Option
                              size="large"
                              key={`Other-${i}`}
                              value={data.id}
                              id={`Other-${i}`}
                            >
                              {data.name}
                            </Option>
                          ))}
                      </Select.OptGroup>
                    </Select>
                    <div className="p-3 mt-3 rounded border  height-80">
                      {this.state.getselectint.map((tag, index) => {
                        if (!tag.is_active) {
                          return (
                            <Tag
                              color="blue"
                              className="mb-2"
                              onClick={(checked) =>
                                this.TagClick(tag, checked, index, "interest")
                              }
                              key={index}
                            >
                              {tag.name}
                            </Tag>
                          );
                        }
                      })}
                    </div>
                    <div className="row ml-0 mr-0 mt-2">
                      {this.state.getselectint.length > 0 && (
                        <div className="col-12 border rounded p-2 height-40">
                          {this.state.getselectint.map((tag, index) => {
                            if (tag.is_active) {
                              return (
                                <Tag
                                  closable
                                  onClose={() =>
                                    this.TagClick(
                                      tag,
                                      "checked",
                                      index,
                                      "interest"
                                    )
                                  }
                                  color="blue"
                                  className="mb-2"
                                  key={index}
                                >
                                  {tag.name}
                                </Tag>
                              );
                            }
                          })}
                        </div>
                      )}
                    </div>

                    <div className="border-bottom pt-4 mb-4" />

                    <div className="mb-3 FontAvenirRoman text-secondary font-16 mt-4 requiredAsterisk">
                      BRIEF DESCRIPTION
                    </div>

                    <TextArea
                      rows={4}
                      id={
                        this.state.truthObj["act_brf_desc"] ? "has-error" : ""
                      }
                      placeholder="Enter brief description"
                      value={this.state.formValue.act_brf_desc}
                      onChange={(e) => {
                        if (e.target.value.length <= 300) {
                          this.onChange("act_brf_desc", e.target.value)
                        }
                      }}
                      onPaste={(e) => {
                        if (e.clipboardData.getData('Text').length > 300) {
                          this.onChange("act_brf_desc", e.clipboardData.getData('Text').substring(0, 300));
                        }
                      }}
                    />
                    <span className="d-flex align-items-center justify-content-end" style={this.state.formValue.act_brf_desc.length >= 300 ? { color: "#FF0A00" } : {}}>
                      {this.state.formValue.act_brf_desc.length} / 300
                    </span>

                    <div className="mb-3 FontAvenirRoman text-secondary font-16 mt-4">
                      LONG DESCRIPTION
                      {/* - <span className="text-opt">Optional</span> */}
                    </div>
                    <TextEditor
                      value={this.state.formValue.act_long_desc}
                      data={this.state.longDescValue}
                      onEditorChange={this.onChangeDescEditor}
                      onChange={(value) => {
                        this.onChange("act_long_desc", value);
                      }}
                    />
                    {/*<TextArea
                      rows={8}
                      id={
                        this.state.truthObj["act_long_desc"] ? "has-error" : ""
                      }
                      placeholder="Enter long description"
                      value={this.state.formValue.act_long_desc}
                      onChange={e =>
                        this.onChange("act_long_desc", e.target.value)
                      }
                    />*/}
                    <div className="border-bottom pt-4 mb-4" />

                    <div className="row">
                      <div className="col-6">
                        <div className="mb-3 FontAvenirRoman text-secondary font-16 requiredAsterisk">
                          {this.props.match.params.id === "watch"
                            ? "TOTAL WATCH TIME"
                            : "TIME"}
                        </div>

                        <Input
                          size="large"
                          type="number"
                          addonAfter="mins"
                          className="timeInput"
                          id={
                            this.state.truthObj["act_time"] ? "has-error" : ""
                          }
                          placeholder="Enter duration in mins"
                          value={this.state.formValue.act_time}
                          onChange={(e) =>
                            this.onChange("act_time", e.target.value)
                          }
                          onKeyPress={(evt) => {
                            if (evt.which < 48 || evt.which > 57) {
                              evt.preventDefault();
                            }
                          }}
                        />

                        <div
                          className="mb-3 FontAvenirRoman text-secondary font-16 mt-4 requiredAsterisk"
                          // id={this.state.truthObj["act_suitfor_val"] ? "has-error-label": ""}
                        >
                          SUITABLE FOR
                        </div>

                        <Select
                          size="large"
                          placeholder="Select number of people"
                          className={
                            this.state.truthObj["act_suitfor_val"]
                              ? "has-error"
                              : ""
                          }
                          value={
                            this.state.formValue.act_suitfor_val
                              ? this.state.formValue.act_suitfor_val
                              : undefined
                          }
                          onChange={(e) =>
                            this.onSelectChange("act_suitfor_val", e)
                          }
                        >
                          {this.state.act_suitfor.map((data, i) => {
                            return (
                              <Option
                                size="large"
                                key={i}
                                value={data.id}
                                id={`Suitable-${i}`}
                              >
                                {data.people}
                              </Option>
                            );
                          })}
                        </Select>
                      </div>
                      <div className="col-6 border-left">
                        <div
                          className="mb-3 FontAvenirRoman text-secondary font-16 mt-4 requiredAsterisk"
                          id={
                            this.state.truthObj["act_safety"]
                              ? "has-error-label"
                              : ""
                          }
                        >
                          ADULT SUPERVISION
                        </div>

                        <RadioGroup
                          value={this.state.formValue.act_safety}
                          onChange={(e) => {
                            // console.log(e.target.value)
                            this.onChange("act_safety", e.target.value);
                          }}
                        >
                          <Radio value="Required">Required</Radio>
                          <Radio value="Not required">Not Required</Radio>
                        </RadioGroup>
                        <TextArea
                          className="mt-2"
                          id={
                            this.state.truthObj["act_sfty_desc"]
                              ? "has-error"
                              : ""
                          }
                          rows={4}
                          placeholder="Enter details"
                          value={this.state.formValue.act_sfty_desc}
                          onChange={(e) => {
                            if (e.target.value.length <= 100) {
                              this.onChange("act_sfty_desc", e.target.value);
                            }
                          }}
                          onPaste={(e) => {
                            if (e.clipboardData.getData('Text').length > 100) {
                              this.onChange("act_sfty_desc", e.clipboardData.getData('Text').substring(0, 100));
                            }
                          }}
                        />
                        <div
                          style={
                            this.state.formValue.act_sfty_desc.length >= 100
                              ? {
                                  color: "#FF0A00",
                                  float: "right",
                                  marginTop: 8,
                                }
                              : { float: "right", marginTop: 8 }
                          }
                        >
                          {this.state.formValue.act_sfty_desc.length} / 100
                        </div>
                      </div>
                    </div>
                    <div className="border-bottom pt-4 mb-4" />
                    <div
                      className="mb-3 FontAvenirRoman text-secondary font-16 mt-4"
                      id={this.state.truthObj["materials_req"] ? "has-error-label" : ""}
                    >
                      MATERIALS REQUIRED
                      <div
                        className="d-inline addmorebutton font-12 ml-2 pl-2 pr-2 pointer-cursor rounded"
                        onClick={this.onClickMaterialRequired}
                      >
                        + Add more
                      </div>
                    </div>
                    <div className="row">
                      {this.state.material_required.map((data, index) => (
                        <div className="col-6 mb-3" key={index}>
                          <div className="d-flex justify-content-start align-items-center">
                            <ImageUploadCrop
                              width={95}
                              height={95}
                              imageUrl={data.image}
                              imageClassName="position-relative d-flex align-items-center justify-content-center"
                              onSuccess={(value) =>
                                this.materialRequiredOnchange(
                                  "image",
                                  index,
                                  value
                                )
                              }
                              aspect={1}
                            />
                            <div
                              className="ml-3 d-flex flex-column align-items-end justify-content-start"
                              style={{ width: "80%" }}
                            >
                              <div className="w-100 d-flex align-items-center justify-content-center">
                                <Input
                                  size="large"
                                  value={data.name}
                                  className="mb-2"
                                  stateKey="name"
                                  onChange={(e) => {
                                    if (e.target.value.length <= 50) {
                                      this.materialRequiredOnchange(
                                        "name",
                                        index,
                                        e.target.value
                                      );
                                    }
                                  }}
                                  onPaste={(e) => {
                                    if (e.clipboardData.getData('Text').length > 50) {
                                      this.materialRequiredOnchange(
                                        "name",
                                        index,
                                        e.clipboardData.getData('Text').substring(0, 50)
                                      );
                                    }
                                  }}
                                  placeholder="Enter material"
                                />
                                {this.state.material_required.length > 2 && (
                                  <Button
                                    className="d-inline addmorebutton font-12 ml-2 pl-2 pr-2 pointer-cursor rounded"
                                    onClick={() =>
                                      this.onRemoveMaterialRequired(index)
                                    }
                                    size="large"
                                    style={{ marginBottom: "9px" }}
                                  >
                                    Remove
                                  </Button>
                                )}
                              </div>
                              <span style={data.name.length >= 50 ? { color: "#FF0A00" } : {}}>
                                {data.name.length} / 50
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="border-bottom pt-4 mb-4" />

                    <div
                      className="mb-3 FontAvenirRoman text-secondary font-16 mt-4"
                      id={this.state.truthObj["pdf"] ? "has-error-label" : ""}
                    >
                      PDF
                    </div>
                    <div className="row">
                      <div className="col-12 pdf-upload">
                        <PdfUpload
                          multiple={false}
                          handleChange={this.onPDFUpload}
                          fileList={this.state.pdfFileList}
                          onSuccess={this.onPDFUploadSuccess}
                        />
                        {this.state.pdfFileList.length === 1 ? (
                          <div
                            onClick={this.deleteUploadPDF}
                            className="delete-img"
                          >
                            <img
                              src={deleteImage}
                              className="img-fluid"
                              style={{ height: 15, width: 15 }}
                              alt="delete pdf"
                            />
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <div className="d-flex justify-content-center align-items-center">
                          <div className="mr-3 w-100">
                            <InputC
                              className="col-12 ant-input ant-input-lg mb-4"
                              value={this.state.pdf_name}
                              stateKey="pdf_name"
                              onChange={(key, value) =>
                                this.onPDFChange(key, value)
                              }
                              placeholder="Enter name of PDF"
                            />
                          </div>
                          <div className="mr-3 w-100">
                            <InputC
                              className="col-12 ant-input ant-input-lg mb-4"
                              value={this.state.pdf_source_text}
                              stateKey="pdf_source_text"
                              onChange={(key, value) =>
                                this.onPDFChange(key, value)
                              }
                              placeholder="Enter display text for source name"
                            />
                          </div>
                          <div className="mr-0 w-100">
                            <InputC
                              className="col-12 ant-input ant-input-lg mb-4"
                              value={this.state.pdf_source_url}
                              stateKey="pdf_source_url"
                              onChange={(key, value) =>
                                this.onPDFChange(key, value)
                              }
                              placeholder="Enter source link"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="border-bottom mb-4" />
                    {this.props.match.params.id === "do" && (
                      <React.Fragment>
                        <div
                          className="mb-3 FontAvenirRoman text-secondary font-16 mt-4"
                          id="steps"
                        >
                          IMAGES, VIDEOS & STEPS
                        </div>
                        <div
                          onClick={this.handleStepsView}
                          className={`pointer-cursor d-flex justify-content-between FontAvenirRoman font-16 align-items-center p-2 rounded ${
                            this.state.formValue.video_credit_text !== "" ||
                            this.state.formValue.video_link !== "" ||
                            this.state.stepResult.length !== 0 ||
                            this.state.image_card_image.length !== 0
                              ? "stepsImages-filled"
                              : "stepsImages-empty"
                          }`}
                        >
                          <div className="position-relative">
                            <div className="outline-circle" />
                          </div>
                          <div>
                            Click to add Videos, Images or Steps for this
                            Learning Content
                          </div>
                          <Icon type="right" theme="outlined" />
                        </div>
                      </React.Fragment>
                    )}

                    <div className="mb-3 FontAvenirRoman text-secondary font-16 mt-4">
                      PREREQUISITES
                      <Tooltip
                        placement="right"
                        title="Only contents that doesn’t have any prerequisites will be listed here"
                      >
                        <Icon type="info-circle" className="ml-2" />
                      </Tooltip>
                    </div>

                    <Select
                      size="large"
                      mode="multiple"
                      className={
                        this.state.truthObj["prerequisites"] ? "has-error" : ""
                      }
                      value={this.state.post_prereq}
                      placeholder="Select prerequisites"
                      onFocus={this.getPrerequisites}
                      style={{ width: "100%" }}
                      onChange={this.onSelectPrerequisites}
                      filterOption={this.onSearchSelect}
                    >
                      {this.state.act_age_group.filter((age) => !age.is_active)
                        .length &&
                        this.state.act_prereq.map((data, index) => (
                          <Option
                            value={data.id}
                            key={index}
                            id={`Prerequisites-${index}`}
                          >
                            {data.isPlaylist ? (
                              <div className="d-flex align-items-center justify-content-start">
                                <img
                                  src={PlayListIcon}
                                  className="img-fluid mr-2"
                                  alt="playlist icon"
                                  style={{ height: 24, weight: 24 }}
                                />
                                {data.name}
                              </div>
                            ) : (
                              <div className="d-flex align-items-center justify-content-start">
                                {data.default_activities_id === "1" ? (
                                  "Read"
                                ) : data.default_activities_id === "2" ? (
                                  <img
                                    src={WatchIcon}
                                    className="img-fluid mr-2"
                                    alt="watch icon"
                                  />
                                ) : data.default_activities_id === "3" ? (
                                  "Listen"
                                ) : data.default_activities_id === "4" ? (
                                  <img
                                    src={DoIcon}
                                    className="img-fluid mr-2"
                                    alt="do icon"
                                  />
                                ) : (
                                  ""
                                )}
                                {data.name}
                              </div>
                            )}
                          </Option>
                        ))}
                    </Select>

                    <div className="FontAvenirRoman mb-3 text-secondary font-16 mt-4">
                      USEFUL LINKS TO BROWSE
                      {/* -<span className="text-opt"> Optional</span> */}
                      <div
                        className="d-inline pointer-cursor addmorebutton font-12 ml-2 pl-2 pr-2 rounded"
                        onClick={this.recommendedAddMore}
                      >
                        + Add more
                      </div>
                    </div>
                    {this.state.recommended.map((data, index) => {
                      return (
                        <Row className="m-0 mb-4" key={`Recommened${index}`}>
                          <Col span={11} style={{ marginRight: "1rem" }}>
                            <Input
                              size="large"
                              placeholder="Enter display text for suggested links"
                              value={data.title}
                              onChange={(e) =>
                                this.onChangeRecommend("title", e, index)
                              }
                            />
                          </Col>
                          <Col span={11}>
                            <Input
                              size="large"
                              placeholder="Enter suggested link"
                              value={data.link}
                              onChange={(e) =>
                                this.onChangeRecommend("link", e, index)
                              }
                            />
                          </Col>
                          <Col span={1}>
                            {this.state.recommended.length > 1 && (
                              <div
                                className="col-12 p-0 pointer-cursor"
                                style={{ margin: "0.5rem 0 0 1rem" }}
                                onClick={() => this.removeRecommend(index)}
                              >
                                <img className="" src={close} alt="close" />
                              </div>
                            )}
                          </Col>
                        </Row>
                      );
                    })}

                    <div className="border-bottom pt-4 mb-4" />
                    <div className="mb-3 FontAvenirRoman text-secondary font-16 mt-4">
                      TAGS
                    </div>
                    <Select
                      mode="tags"
                      size="large"
                      placeholder="Select Tags"
                      className={this.state.truthObj["tags"] ? "has-error" : ""}
                      value={this.state.tags ? this.state.tags : undefined}
                      style={{ width: "100%" }}
                      onChange={this.onSelectTags}
                      onSearch={(value) => {
                        this.onSearchTags(value);
                      }}
                      filterOption={false}
                      // filterOption={this.onSearchTags}
                      tokenSeparators={[","]}
                    >
                      {this.state.m_act_tags.map((data, index) => (
                        <Option
                          key={data.name}
                          value={data.name}
                          id={`Tags-${index}`}
                        >{` ${data.name} `}</Option>
                      ))}
                    </Select>
                  </Card>
                </div>
              </Form>
            )}

            {this.state.stepsView && (
              <Form className="formSubmitted">
                <div className="bg-white p-4 rounded">
                  <div
                    className="FontAvenirRoman text-secondary font-16"
                    id={this.state.truthObj["video_link"] || this.state.truthObj["video_credit_text"] ? "has-error-label" : ""}
                  >
                    VIDEOS
                  </div>
                  <Row gutter={24}>
                    <Col className="gutter-row" span={12}>
                      <InputC
                        id={this.state.truthObj["video_link"] ? "has-error" : ""}
                        className="col-12 ant-input ant-input-lg mb-4"
                        value={this.state.formValue.video_link}
                        stateKey="video_link"
                        placeholder="Enter video link"
                        onChange={this.onChange}
                        onBlur={() => this.onViewLinkBlur('video_link')}
                        validations={[youtubeUrl]}
                      />
                    </Col>
                  </Row>

                  <Row gutter={24}>
                    <Col className="gutter-row" span={12}>
                      <InputC
                        id={this.state.truthObj["video_credit_text"] ? "has-error" : ""}
                        className="col-12 ant-input ant-input-lg mb-4"
                        value={this.state.formValue.video_credit_text}
                        stateKey="video_credit_text"
                        onChange={this.onChange}
                        placeholder="Enter display text for credit link"
                      />
                    </Col>
                    <Col className="gutter-row" span={12}>
                      <InputC
                        className="col-12 ant-input ant-input-lg mb-4"
                        value={this.state.formValue.video_credit_link}
                        stateKey="video_credit_link"
                        onChange={this.onChange}
                        placeholder="Enter credit link"
                      />
                    </Col>
                  </Row>

                  <div className="border-bottom mb-4" />

                  <div
                    className="mb-3 FontAvenirRoman text-secondary font-16"
                    id={
                      this.state.truthObj["steps"] ||
                      this.state.truthObj["steps_credit_text"]
                        ? "has-error-label"
                        : ""
                    }
                  >
                    STEPS
                  </div>
                  <Steps
                    onChange={this.onChangeStep}
                    onDragEnd={this.onDragEnd}
                    onClick={this.onClickStep}
                    stepsClick={this.stepsClick}
                    saveSteps={this.saveSteps}
                    dataValue={this.state.stepResult}
                    handleChange={this.stepImageUpload}
                    onCancelClick={this.onCancelStep}
                    fileList={this.state.imageStepUpload}
                    data={this.getSteps}
                    items={this.state.items}
                    stepTitle={this.state.stepTitle}
                    fileUpload={(url) => this.setState({ image_card_steps: url })}
                    stepDesc={this.state.stepDesc}
                    image_card_steps={this.state.image_card_steps}
                    editButtonVisibility={this.state.editButtonVisibility}
                    onEditClick={this.onEditClick}
                    onDeleteClick={this.onDeleteClick}
                  />

                  <Row gutter={24}>
                    <Col className="gutter-row" span={12}>
                      <InputC
                        placeholder="Enter display text for credit link"
                        id={
                          this.state.truthObj["steps_credit_text"]
                            ? "has-error"
                            : ""
                        }
                        className="col-12 ant-input ant-input-lg mb-4"
                        value={this.state.formValue.steps_credit_text}
                        stateKey="steps_credit_text"
                        onChange={this.onChange}
                      />
                    </Col>
                    <Col className="gutter-row" span={12}>
                      <InputC
                        placeholder="Enter credit link"
                        className={`col-12 ant-input ant-input-lg mb-4 ${
                          this.state.truthObj["steps_credit_link"]
                            ? "has-error"
                            : ""
                        }`}
                        value={this.state.formValue.steps_credit_link}
                        stateKey="steps_credit_link"
                        onChange={this.onChange}
                      />
                    </Col>
                  </Row>

                  <div className="border-bottom mb-4" />

                  <div
                    className="mb-3 FontAvenirRoman text-secondary font-16"
                    id={
                      this.state.truthObj["image_credit_text"]
                        ? "has-error-label"
                        : ""
                    }
                  >
                    IMAGES
                  </div>
                  <FeaturedImageUpload
                    onSuccess={this.FeaturedImageStepUploadSuccess}
                    className="activity-image"
                    fileList={this.state.ImagesfileList}
                    onChange={this.ImagesUploadedFile}
                  />
                  <div className="row">
                    {this.state.ImagesfileList.filter(i => i.status === "done").map((data, index) => {
                      return (
                        <div className="col-4 activity-steps-images">
                          <img
                            className="col-12 p-0"
                            style={{ height: 212, width: 378, objectFit: 'cover' }}
                            src={data.response}
                            alt="featured images"
                          />
                          <div
                            onClick={this.deleteStepImage.bind(this, index)}
                            className="delete-img"
                          >
                            <img
                              src={deleteImage}
                              className="img-fluid"
                              style={{ height: 15, width: 15 }}
                              alt={data.uid}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <Row gutter={24} className="image_details">
                    <Col className="gutter-row" span={12}>
                      <InputC
                        id={
                          this.state.truthObj["image_credit_text"]
                            ? "has-error"
                            : ""
                        }
                        className="col-12 ant-input ant-input-lg mb-4"
                        value={this.state.formValue.image_credit_text}
                        stateKey="image_credit_text"
                        onChange={this.onChange}
                        placeholder="Enter display text for credit link"
                      />
                    </Col>
                    <Col className="gutter-row" span={12}>
                      <InputC
                        className={`col-12 ant-input ant-input-lg mb-4 ${
                          this.state.truthObj["image_credit_link"]
                            ? "has-error"
                            : ""
                        }`}
                        value={this.state.formValue.image_credit_link}
                        stateKey="image_credit_link"
                        placeholder="Enter credit links"
                        onChange={this.onChange}
                      />
                    </Col>
                  </Row>
                </div>
              </Form>
            )}
          </div>
        </Content>
      </Layout>
    );
  }
}

export default LearningCenterCreate;
