import { Button, Card } from "antd";
import axios from "axios";
import React from "react";
import Form from "react-validation/build/form";
import logo from "../../assets/breadcrumb_logo.png";
import InputC from "../../components/InputC";
import { LC_FORGOT_PSWD } from "../../graphql.js";
import { email, required } from "../../validation";
import "./Signin.css";
import {openNotification, triggerGAEvent} from "../../utils";

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        email: ""
      }
    };
  }

  handleSubmit = e => {
    triggerGAEvent('Forgot Password', 'Sending request for Reset Password');
    e.preventDefault();
    let errors = document.getElementsByClassName("form-error");
    if (errors.length < 1) {
      let mutation = LC_FORGOT_PSWD;
      mutation.variables = {
        email: this.state.formData.email
      };

      axios
        .post(`${process.env.REACT_APP_API}`, mutation)
        .then(res => {
          if (res.data.errors) {
            openNotification("error", res.data.errors[0].message);
          } else {
            openNotification("success", `We've sent you an e-mail to your ID, please follow the link to reset your password.`);
            setTimeout(() => {
              this.props.history.push("/");
            }, 1000);
          }
        }).catch(err => console.log(err));
    } else {
      openNotification("error", "Please enter valid credentials");
    }
  };

  handleOnChange = (key, value) => {
    triggerGAEvent('Forgot Password', 'Entering email for Forgot Password');
    const state = { ...this.state };
    state.formData[key] = value;
    this.setState({ formData: state.formData });
  };

  handleClick = e => {
    e.preventDefault();
  };

  routeChange = () => {
    this.props.history.push('/resetpassword');
  };

  render() {
    return (
      // <div>
      <div className="card-wrap">
        {/* Max dev forgot password */}
        <div className="max-dev-forgotPass">
          <img src={logo} className="logo" alt="logo" />
          <Card className="card-radius">
            <Form onSubmit={this.handleSubmit}>
              <h3 className="pb-3 mb-3 border-bottom FontAvenirHeavy font-18">
                Forgot Password
              </h3>
              <InputC
                title="Enter Email id"
                placeholder="Enter your Email ID"
                className="col-12 ant-input ant-input-lg mb-4"
                value={this.state.formData.email}
                stateKey="email"
                onChange={this.handleOnChange}
                validations={[required, email]}
              />
              <div className="border-bottom pt-1 mb-4" />
              <Button type="primary" htmlType="submit" block size="large">
                Request password reset
              </Button>
            </Form>
          </Card>
        </div>
        {/* Mbl dev forgot password */}
        <div className="mbl-dev-forgotPass border-style">
          <div className="mt-3 d-flex justify-content-center">
            <img src={logo} className="mbl-forgot-logo" alt="logo" />
          </div>
          <div className="mt-4 d-flex justify-content-center">
            <h3 className="pb-3 mb-3 FontAvenirHeavy font-20">
                  Forgot Password
            </h3>
          </div>
          <div className="mt-5 pt-5">
            <Form onSubmit={this.handleSubmit} className="mbl-forgotPass-input">
              <InputC
                title="Enter email ID"
                placeholder="Enter Your Email ID"
                className="col-12 ant-input ant-input-lg mb-4"
                value={this.state.formData.email}
                stateKey="email"
                onChange={this.handleOnChange}
                validations={[required, email]}
              />
              <div className="pt-1 mb-2" />
              <Button type="primary" htmlType="submit" block size="large">
                Request password reset
              </Button>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

export default ForgotPassword;
